import clsx from '@vangst/lib/clsx'
import { HUBSPOT_SUBJECTS } from '@vangst/services/hubspot'
import { useClientTrainings } from '@vangst/services/oogst/training/useTraining'
import { ClientTrainingsQueryVariables } from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import React, { Fragment, memo, useState } from 'react'
import { MdArrowDownward, MdDescription } from 'react-icons/md'
import Spinner from '../../components/assets/Spinner'
import EmptyState from '../../components/feedback/EmptyState'
import ClickyExplore from '../../components/navigation/ClickyExplore'
import ClickyLink from '../../components/navigation/ClickyLink'
import Counter from '../../components/views/Counter'
import TrainingRenderer from '../course/TrainingRenderer'
import DialogContact from '../job/feedback/DialogContact'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly as: 'avatar' | 'card' | 'header' | 'slip'
  readonly at: 'infinite' | 'preview' | 'sidebar'
  readonly classNameList?: string
  readonly take?: number
  readonly variables: ClientTrainingsQueryVariables
}

function CompanyTrainingsRenderer(props: PropsType) {
  const { as, at, classNameList, take, variables, ...rest } = props
  const { viewer } = useViewer()
  const { data, isFetching, isZero, pagination } = useClientTrainings(variables)
  const [showServicesModal, setShowServicesModal] = useState(false)

  const canTake = take != null
  const pages = canTake && data?.pages ? [data.pages[0]] : data?.pages
  const isOwner = viewer?.nameOrId === variables?.displayname

  return (
    <div {...rest}>
      {showServicesModal && (
        <DialogContact
          email={viewer?.email ?? ''}
          phone={viewer?.phoneNumber ?? ''}
          state={viewer?.location?.state ?? ''}
          firstName={viewer?.firstName ?? ''}
          lastName={viewer?.lastName ?? ''}
          handleCancel={() => setShowServicesModal(false)}
          hubspotContactSubject={HUBSPOT_SUBJECTS.COMPANY_SUPPORT}
        />
      )}
      {at === 'sidebar' && (
        <p className="relative mb-4 text-sm">
          <Counter to={pagination.totalCount} />
          {isFetching && (
            <Spinner
              className="absolute right-0 top-0"
              width={16}
              height={16}
            />
          )}
        </p>
      )}

      {at === 'infinite' && (
        <div className="flex items-center justify-between">
          <p className="relative mb-4 text-lg text-orange">
            <span className="mr-2">Results</span>
            <Counter to={pagination.totalCount} />
            {':'}
            {isFetching && (
              <Spinner
                className="absolute right-0 top-0"
                width={16}
                height={16}
              />
            )}
          </p>
        </div>
      )}

      <div className={clsx(!isZero && classNameList)}>
        {isZero && (
          <div>
            <EmptyState icon={MdDescription} className="sm:h-60">
              <span className="block max-w-xl">
                <span>No available courses at this time. </span>
                {isOwner && (
                  <span>
                    If you'd like to set up a training course,{' '}
                    <ClickyLink
                      className="link-orange"
                      onClick={() => setShowServicesModal(true)}
                    >
                      Contact Us
                    </ClickyLink>
                  </span>
                )}
              </span>
            </EmptyState>
          </div>
        )}

        {pages?.map((page, i) => {
          const list = page?.getClient?.clientTrainings?.edges
          const edges = canTake ? list?.slice(0, take) : list
          return (
            <Fragment key={`page-${i}`}>
              {edges?.map((edge) => {
                return edge?.node ? (
                  <TrainingRenderer
                    key={edge?.node?.id}
                    as={as}
                    training={edge?.node?.training}
                  />
                ) : null
              })}
              {!isZero && at === 'infinite' && (
                <p className="col-span-full my-4 border-b pb-2 text-right text-sm">
                  Page {i + 1} of {pagination?.totalPages}
                </p>
              )}
            </Fragment>
          )
        })}
      </div>
      {at === 'infinite' && pagination.nextHref && pagination.nextPage && (
        <div className="flex justify-center">
          <ClickyExplore
            as="icon-text"
            className={clsx('mt-4', !isFetching && 'motion-south')}
            href={pagination.nextHref}
            icon={isFetching ? Spinner : MdArrowDownward}
            onClick={pagination.nextPage}
          >
            {isFetching ? 'Loading...' : 'Load Next Page'}
          </ClickyExplore>
        </div>
      )}
    </div>
  )
}

export default memo(CompanyTrainingsRenderer)
