import clsx from 'clsx'
import { forwardRef, memo } from 'react'
import AnchorButton, { AnchorButtonType } from './internal/AnchorButton'

// TODO: Handle "as" icon patterns
const ClickyBtn = forwardRef<HTMLElement, AnchorButtonType>(
  function ClickyBtn(props, ref) {
    const { children, className, ...rezt } = props
    const rest = { ref, ...rezt }

    return (
      <AnchorButton className={clsx('btn rounded-md', className)} {...rest}>
        <span>{children}</span>
      </AnchorButton>
    )
  },
)

/**
 * ClickyBtn is a wrapper around `AnchorButton` that is context aware to provide
 * predefined layouts, states, motions with icons and classes.
 *
 * ClickyBtn  can be rendered `as: text | icon | icon-text | text-icon`. It adjusts
 * layouts handled by the `link-*` css class. It's the option you want when looking for a basic navigation link.
 *
 * @see [AnchorButton](https://github.com/vangst/oogmerk/blob/main/apps/web/components/navigation/internal/AnchorButton.tsx)
 * @see [Clicky Story](https://uat.vangst.com/oranje/stories?id=Components_Navigation_Clicky)
 *
 * @example
 * <ClickyBtn className="mood-orange">
 *   The most basic button with block color themes based on mood
 * </ClickyBtn>
 *
 */
export default memo(ClickyBtn)
