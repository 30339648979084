import clsx from '@vangst/lib/clsx'
import { IconType } from 'react-icons'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly as?: 'icon-text' | 'text-icon'
  readonly icon?: IconType
}

/**
 * Display alert text in a variety of styles.
 *
 * @see [MDN Alert Role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role)
 *
 * @example
 * // Alerts...
 * <AlertText className="text-red" icon={MdError}>
 *   Alert Text Failure
 * </AlertText>
 * <AlertText className="text-blue" icon={MdCheckCircle}>
 *   Alert Text Success
 * </AlertText>
 *
 * // Banners...
 * <AlertText className="contain-xc mood-red min-h-12" icon={MdError}>
 *   Alert Text Failure
 * </AlertText>
 * <AlertText className="contain-xc mood-blue min-h-12" icon={MdCheckCircle}>
 *   Alert Banner Success
 * </AlertText>
 */
function AlertText(props: PropsType) {
  const { as = 'icon-text', children, className, icon, ...rest } = props
  const Icon = icon
  return (
    <span
      className={clsx('flex items-center gap-2', className)}
      role="alert"
      {...rest}
    >
      <span className={clsx(as === 'icon-text' && 'order-2')}>{children}</span>
      {Icon && <Icon className={clsx(as === 'icon-text' && 'order-1')} />}
    </span>
  )
}

export default AlertText
