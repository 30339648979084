import { Popover } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import { memo } from 'react'
import { MdExpandMore } from 'react-icons/md'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly disabled?: boolean
  readonly isFiltering: boolean
  readonly label: string
  readonly minimal?: boolean
}

function FilterPopover(props: PropsType) {
  const { children, disabled, isFiltering, label, minimal, ...rest } = props
  return (
    <Popover {...rest}>
      <Popover.Button
        className={clsx(
          'group/popoverbutton',
          'flex h-8 w-full min-w-24 items-center justify-between  text-left text-sm outline-none',
          !minimal && 'rounded border border-grey-mid pl-2 pr-1',
          minimal &&
            'h-full min-w-24 items-center border-b border-b-grey-mid !bg-transparent',
          isFiltering ? 'bg-orange text-white' : 'bg-white text-grey',
          isFiltering
            ? 'hocus:border-orange hocus:bg-orange'
            : 'hocus:border-orange hocus:text-orange',
          isFiltering
            ? null
            : 'aria-expanded:border-orange aria-expanded:text-orange',
          disabled && 'pointer-events-none opacity-70',
        )}
      >
        {label}
        <MdExpandMore
          className={clsx(
            'transition-transform group-aria-expanded/popoverbutton:rotate-180',
            !minimal && 'ml-4',
          )}
        />
      </Popover.Button>
      <Popover.Panel className="mood-grey-lightest absolute inset-x-0 z-40 mt-4 max-h-72 w-full overflow-y-auto p-4 pr-8 shadow-md lg:inset-x-auto lg:mt-0 lg:w-fit">
        {children}
      </Popover.Panel>
    </Popover>
  )
}

export default memo(FilterPopover)
