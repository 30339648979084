import clsx from '@vangst/lib/clsx'
import { forwardRef } from 'react'

export type CheckboxType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly classNameLabel?: string
  readonly label: string
  readonly name: string
  readonly onInputChange?: () => void
  readonly reference?: React.Ref<any> | any
}

/**
 * The `li > label > input[type="checkbox"]` elements.
 *
 * Checkbox must be a direct descendant of a `ControlGroup` component or a
 * `ul/ol` element.
 *
 * @see [MDN Checkbox](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox)
 *
 * @example
 * <ControlGroup label="Checkbox Column" name="checkbox-1" type="checkbox">
 *   <Checkbox name="checkbox-1.Yes" label="Yes" />
 *   <Checkbox name="checkbox-1.No" label="No" />
 * </ControlGroup>
 *
 * <ControlGroup
 *   className="text-blue ring-blue checked:bg-blue checked:bg-none focus:ring-blue hocus:bg-blue/30"
 *   classNameLabel="items-end text-magenta"
 *   error="Some checkbox thing happened."
 *   hint="This is a hint"
 *   label="Checkbox Row"
 *   layout="row"
 *   name="checkbox-2"
 *   type="checkbox"
 *   required
 * >
 *   <Checkbox name="checkbox-2.Yes" label="Yes" />
 *   <Checkbox name="checkbox-2.No" label="No" />
 * </ControlGroup>
 */
const Checkbox = forwardRef<HTMLInputElement, CheckboxType>(
  function Checkbox(props, ref) {
    const {
      className,
      classNameLabel,
      disabled,
      label,
      name,
      reference,
      onInputChange,
      ...rest
    } = props
    const id = props.id || props.name
    return (
      <li key={id} className="list-none">
        <label
          htmlFor={id}
          className={clsx(
            'inline-flex cursor-pointer items-center gap-3',
            classNameLabel,
          )}
        >
          <input
            className={clsx(
              'size-6 cursor-pointer rounded-sm border-2 border-orange text-orange outline-orange transition focus:ring-0 hocus:bg-orange-light',
              className,
            )}
            disabled={disabled}
            id={id}
            name={name}
            onClick={onInputChange}
            ref={ref || reference}
            type="checkbox"
            {...rest}
          />
          <span>{label}</span>
        </label>
      </li>
    )
  },
)

export default Checkbox
