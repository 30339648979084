import Link, { LinkProps } from 'next/link'
import { forwardRef } from 'react'

type NextLinkType = React.PropsWithChildren<
  LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>

/**
 * A wrapper around `next/link`
 *
 * @see [next/link](https://nextjs.org/docs/api-reference/next/link)
 *
 * @see Workaround for https://github.com/vercel/next.js/issues/5533
 */
const NextLink = forwardRef(function NextLink(props: NextLinkType, ref: any) {
  const { children, ...rest } = props
  return (
    <Link ref={ref} {...rest}>
      {children}
    </Link>
  )
})

export type { NextLinkType }
export default NextLink
