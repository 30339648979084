import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import { jsx } from 'slate-hyperscript'
import { BaseElement, BlockFormatType, MarkFormatType } from './customTypes'

const ELEMENT_TAGS = {
  A: (el: HTMLElement) => ({
    type: BlockFormatType.link,
    url: el.getAttribute('href'),
  }),
  BLOCKQUOTE: () => ({ type: BlockFormatType.blockquote }),
  H1: () => ({ type: MarkFormatType.header }),
  H2: () => ({ type: MarkFormatType.header }),
  H3: () => ({ type: MarkFormatType.header }),
  H4: () => ({ type: MarkFormatType.header }),
  H5: () => ({ type: MarkFormatType.header }),
  H6: () => ({ type: MarkFormatType.header }),
  LI: () => ({ type: BlockFormatType.listItem }),
  OL: () => ({ type: BlockFormatType.orderedList }),
  P: () => ({ type: BlockFormatType.paragraph }),
  UL: () => ({ type: BlockFormatType.list }),
}

const TEXT_TAGS = {
  EM: () => ({ emphasis: true }),
  I: () => ({ emphasis: true }),
  STRONG: () => ({ strong: true }),
  B: () => ({ strong: true }),
}

// https://github.com/ianstormtaylor/slate/blob/main/site/examples/paste-html.tsx
export const deserializeHtml: any = (el: HTMLElement) => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }
  const { nodeName } = el
  let parent = el

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0] as HTMLElement
  }

  let children = Array.from(parent.childNodes).map(deserializeHtml).flat()

  if (children.length === 0) {
    children = [{ text: '' }]
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }
  if (ELEMENT_TAGS[nodeName as keyof typeof ELEMENT_TAGS]) {
    const attrs = ELEMENT_TAGS[nodeName as keyof typeof ELEMENT_TAGS](el)
    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName as keyof typeof TEXT_TAGS]) {
    const attrs = TEXT_TAGS[nodeName as keyof typeof TEXT_TAGS]()
    const found = children.filter((child) => typeof child === 'string')
    const results = found.map((child: any) => jsx('text', attrs, child))
    return isEmptyOrNil(results) ? children : results
  }

  return children
}

//  ******** Modified slate-docx-deserializer source code below (all notes were part of source code unless attributed) ********
// https://github.com/mdmjg/slate-docx-deserializer/blob/master/package/src/module.js
export const deserializeDoc = (el: HTMLElement) => {
  if (
    el.attributes &&
    el.attributes.getNamedItem('class') &&
    el.attributes.getNamedItem('class')?.value.match(/done/g)
  ) {
    return null
  }
  if (isList(el)) {
    return deserializeList(el)
  }
  return deserializeElement(el)
}

const deserializeList = (el: HTMLElement) => {
  const siblings = getSiblings(el)
  const type = 'UL'
  const list_wrapper = document.createElement(type)
  for (let i = 0; i < siblings.length; i++) {
    list_wrapper.appendChild(siblings[i])
  }

  const attrs = ELEMENT_TAGS[type]
  const children = Array.from(list_wrapper.childNodes)
    .map((child) => {
      return deserializeListItem(child as HTMLElement)
    })
    .flat() as any[]

  return jsx('element', attrs, children)
}
const deserializeElement = (el: HTMLElement) => {
  if (el.nodeType === 3) {
    if (el.parentNode?.nodeName === 'O:P') {
      if (el.parentNode.parentNode?.nodeName === 'P') {
        return el.textContent
      }
    }

    if (el.textContent?.match(/^[\s]*$/gm)) {
      return null
    } else {
      // sometimes word adds line breaks when pasting
      const regex = /\n(?!\n)/g
      el.textContent = el.textContent?.replace(regex, ' ') || null
      return el.textContent
    }
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const { nodeName } = el

  let parent = el

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0] as HTMLElement
  }

  const children = Array.from(parent.childNodes)
    .map((child) => {
      return deserializeDoc(child as HTMLElement)
    })
    .flat() as BaseElement[]

  if (el.nodeName === 'BODY') {
    const filler = jsx('element', { type: 'p', className: 'P' }, [
      { text: ' ' },
    ])
    children.unshift(filler)
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName as keyof typeof ELEMENT_TAGS]) {
    if (nodeName === 'IMG') {
      const attrs = ELEMENT_TAGS[nodeName as keyof typeof ELEMENT_TAGS](el)
      return jsx('element', attrs, children)
    }
    if (nodeName === 'H3' || nodeName === 'H2' || nodeName === 'H1') {
      return jsx('element', { type: nodeName, className: nodeName }, children)
    }
    const attrs = ELEMENT_TAGS[nodeName as keyof typeof ELEMENT_TAGS](el)
    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName as keyof typeof TEXT_TAGS]) {
    const attrs = TEXT_TAGS[nodeName as keyof typeof TEXT_TAGS]()
    return children.map((child) => jsx('text', attrs, child))
  }

  return children
}

const deserializeListItem = (el: HTMLElement) => {
  const level = el.getAttribute('style')
  const content = getTextfromList(el)
    .map((c) => {
      return deserializeElement(c)
    })
    .flat()
  return jsx(
    'element',
    { type: 'list-item', className: 'level'.concat(level || '') },
    content,
  )
}

// gets ALL the
const getSiblings = (el: HTMLElement) => {
  const siblings = []
  while (
    el &&
    el.attributes.getNamedItem('class') &&
    el.attributes.getNamedItem('class')?.value.match(/MsoListParagraph/g)
  ) {
    const levelCheck = el.attributes
      .getNamedItem('style')
      ?.value.match(/level(\d+)/)
    const level = levelCheck ? levelCheck[1] : ''
    el.setAttribute('class', 'done') // we set this attribute to avoid getting stuck in an infinite loop
    el.setAttribute('style', level)
    siblings.push(el)
    // this next line didn't seem to be doing anything -Steve
    // el = el.nextElementSibling
  }

  return siblings
}

// Docx lists begin with "MsoListParagraph".
const isList = (el: any) => {
  if (
    el.attributes &&
    el.attributes.getNamedItem('class') &&
    el.attributes.getNamedItem('class').value.match(/MsoListParagraph/g)
  ) {
    return true
  }
  return false
}

// receives a list item and returns the text inside it
// sometimes the text will be inside a text tag or inside a span tag.
// when it is inside a text tag, the span is irrelevant, but it contains empty text inside
const getTextfromList = (el: any) => {
  const children = Array.from(el.childNodes) as HTMLElement[]
  const result = [] as HTMLElement[]
  children.map((child) => {
    if (
      TEXT_TAGS[child.nodeName as keyof typeof TEXT_TAGS] ||
      child.nodeName === '#text'
    ) {
      result.push(child)
    } else if (child.nodeName === 'SPAN') {
      child.textContent =
        child.textContent?.replace(/(^(\W)(?=\s)*)|(o\s)(?!\w)/gm, '') || null
      result.push(child)
    }
  })
  return result
}
