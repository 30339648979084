import { memo } from 'react'
import Select, { SelectType } from '../../../components/forms/Select'

function SelectLevel(props: SelectType) {
  const { error, hint, label, name, reference, ...rest } = props
  const id = props?.id ?? name
  const keys = [1, 2, 3, 4]
  const options = keys.map((val) => ({
    label: JSON.stringify(val),
    value: JSON.stringify(val),
  }))
  return (
    <Select
      id={id}
      error={error}
      disabled={props.disabled}
      hint={hint}
      label={label}
      name={name}
      options={options}
      reference={reference}
      {...rest}
    />
  )
}

export default memo(SelectLevel)
