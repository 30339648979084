import clsx from '@vangst/lib/clsx'
import {
  dateAndTimeToWords,
  getDynamicTimestamp,
} from '@vangst/lib/formatDates'
import { ChatMessageConnectionFragment } from '@vangst/services/oogst/types'
import Routes from '@vangst/services/routes'
import { HTMLAttributes, useEffect } from 'react'
import { MdOutlineDoneAll } from 'react-icons/md'
import ClickyLink from '../../components/navigation/ClickyLink'
import Avatar from '../../components/views/Avatar'
import { serializeJsonToReact } from '../editor/serializers'
import ChatPromotion from './ChatPromotion'
import useMessages from './useMessages'

type PropsType = HTMLAttributes<HTMLUListElement> & {
  readonly isWidget: boolean
  readonly messageConnection: ChatMessageConnectionFragment
  readonly viewerId: string
}

// TODO: DRY this up with MessagesList & move logic to derived data (initials, chatbuddy etc)
function MessagesDetail(props: PropsType) {
  const { className, isWidget, messageConnection, viewerId } = props

  const {
    exitWidget,
    widgetView,
    handleSelectAllMessages,
    markMessageAsRead,
    recipient,
  } = useMessages({
    isWidget,
  })

  const messages = messageConnection.edges ?? []

  const isZero = messages != null && messages.length === 0
  const chatBuddy = isZero
    ? recipient
    : messages[0]?.node?.recipient?.id === viewerId
    ? messages[0]?.node?.sender
    : messages[0]?.node?.recipient

  const lastMessageReceived = messages.find(
    (m) => m?.node?.sender?.id === chatBuddy?.id,
  )
  useEffect(() => {
    markMessageAsRead(lastMessageReceived?.node?.id)
  }, [lastMessageReceived, markMessageAsRead])

  if (isZero && recipient == null) {
    return <ChatPromotion />
  }
  return (
    <>
      <span className="sticky top-0 z-10 mb-2 flex w-full justify-between border-b border-b-grey-light p-2 text-sm">
        <span className="flex w-full items-center gap-x-2">
          <Avatar
            alt={`${chatBuddy?.firstName[0]}${chatBuddy?.lastName[0]}`}
            src={chatBuddy?.avatarUrl}
          />
          <span className="grid">
            <ClickyLink
              className="link-orange font-600"
              href={Routes.MEMBERS_DETAIL.replace(
                ':username',
                chatBuddy?.username || '',
              )}
              onClick={() => exitWidget()}
            >
              {chatBuddy?.firstName} {chatBuddy?.lastName}
            </ClickyLink>
          </span>
        </span>
        {handleSelectAllMessages != null &&
          isWidget &&
          widgetView === 'detail' && (
            <ClickyLink
              className="motion-west link-orange"
              as="icon-text"
              onClick={handleSelectAllMessages}
            >
              All{' '}
              <span className={clsx(isWidget ? 'hidden' : 'hidden sm:inline')}>
                Messages
              </span>
            </ClickyLink>
          )}
      </span>
      <ul
        className={clsx(
          'flex flex-col-reverse overflow-y-auto text-sm',
          className,
        )}
      >
        {messages.map((m) => {
          if (m?.node == null) return
          const message = m.node
          const messageId = message?.id
          const viewerIsSender = message.sender.id === viewerId
          const isUnread = viewerIsSender && !message.recipientRead
          const dateTime = dateAndTimeToWords(m.node.updatedAt)

          return (
            <li key={`conversation-${messageId}`} className="list-none text-sm">
              <div className="p-2 text-left">
                <div className="flex items-start justify-start gap-2">
                  <Avatar
                    className="size-12"
                    alt={`${message.sender.username} Avatar`}
                    src={
                      message?.sender?.avatarUrl ?? Routes.GENERIC_USER_AVATAR
                    }
                  />

                  <div className="flex grow flex-col content-start">
                    <span className="flex justify-between">
                      <span className="flex items-center justify-start gap-2">
                        <span className="text-sm font-600">
                          {`${message?.sender?.firstName} ${message?.sender?.lastName}`}
                        </span>

                        <MdOutlineDoneAll
                          title="Message read status"
                          className={clsx(
                            'size-3 justify-self-end',
                            isUnread ? 'text-grey-mid' : 'text-orange',
                            viewerIsSender ? 'block' : 'hidden',
                          )}
                        />
                      </span>

                      <span
                        className="text-[12px] font-400 text-grey-dark"
                        title={`${dateTime.date} ${dateTime.time}`}
                      >
                        {getDynamicTimestamp(
                          new Date(),
                          new Date(message.updatedAt),
                        )}
                      </span>
                    </span>
                    <div className="flex items-start gap-2">
                      <div className="w-full font-400">
                        {serializeJsonToReact(message?.slateMessage)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default MessagesDetail
