import {
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import Routes from '../../routes'
import fetcher from '../fetcher'
import {
  CompanyFollowersDocument,
  CompanyFollowersQuery,
  CompanyFollowersQueryVariables as CompanyFollowersVars,
  FetcherError,
} from '../types'
import { getPageInfoInfinitePagination } from '../wtf/pagination'

type CompanyFollowersOptions = UseInfiniteQueryOptions<
  CompanyFollowersQuery,
  FetcherError,
  CompanyFollowersQuery
>

/**
 * @private
 * Retrieve the stringified `CompaniesFollowersInfinite` query key used in the internal cache.
 */
function getKey(variables?: CompanyFollowersVars) {
  const name = 'CompaniesFollowersInfinite'
  return variables ? [name, variables] : [name]
}

/**
 * @private
 * Retrieve the `Company.followers` from the queryClient's cache.
function getCache(queryClient: QueryClient, variables?: CompanyFollowersVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<CompanyFollowersQuery>(key)
}
 */

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function. If no
 * variables are passed all `CompaniesFollowersInfinite` queries will be
 * invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateCompanyFollowers(queryClient, { displayname: 'moontower', first: 4 })
 * invalidateCompanyFollowers(queryClient)
 */
function invalidateCompanyFollowers(
  queryClient: QueryClient,
  variables?: CompanyFollowersVars,
) {
  queryClient.invalidateQueries(
    variables ? getKey(variables) : ['CompaniesFollowersInfinite'],
  )
}

/**
 * Fetch an infinite query of the paginated `followers` field on a `Company`.
 *
 * @example
 * const variables = { displayname: slug, first: 24 }
 * const options = { enabled: authenticated }
 * const { data, isZero, pagination, uri } = useCompanyFollowers(variables, options)
 *
 * @TODO: Add optimistic Create|Update|Delete?
 * @TODO: Compute data in select?
 * @TODO: Tune options
 * @TODO: Add prefetching once profiles become public
 */
function useCompanyFollowers(
  variables: CompanyFollowersVars,
  options?: CompanyFollowersOptions,
) {
  const query = useInfiniteQuery<
    CompanyFollowersQuery,
    FetcherError,
    CompanyFollowersQuery
  >(
    getKey(variables),
    ({ pageParam }) =>
      fetcher<CompanyFollowersQuery, CompanyFollowersVars>(
        CompanyFollowersDocument,
        { ...variables, ...pageParam },
      )(),
    {
      getNextPageParam: (lastPage) => {
        const edges = lastPage.getClient.followers.edges
        const after = edges ? edges[edges.length - 1]?.cursor : undefined
        return { after }
      },
      getPreviousPageParam: (lastPage) => {
        const edges = lastPage.getClient.followers.edges
        const before = edges ? edges[0]?.cursor : undefined
        return { before }
      },
      keepPreviousData: true,
      ...options,
    },
  )

  const pages = query?.data?.pages
  const pageInfo = getPageInfoInfinitePagination(
    pages?.[0]?.getClient.followerCount || 0,
    pages?.map((p) => p.getClient.followers.pageInfo) || [],
    variables?.first || variables?.last || 24,
  )

  const nextPage = pageInfo?.nextHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchNextPage()
      }
    : undefined

  const prevPage = pageInfo?.prevHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchPreviousPage()
      }
    : undefined

  const pagination = { ...pageInfo, nextPage, prevPage }
  const isZero = pagination?.totalCount === 0
  const uri = Routes.COMPANIES_FOLLOWERS.replace(
    ':displayname',
    variables.displayname,
  )
  return { ...query, isZero, pagination, uri }
}

// -------------------------------------

export type { CompanyFollowersOptions, CompanyFollowersVars }
export { invalidateCompanyFollowers }
export default useCompanyFollowers
