import { JobPostingInput, LicenseFragment } from '@vangst/services/oogst/types'
import useLicenses from '@vangst/services/oogst/wtf/useLicenses'
import React from 'react'
import { MdCheckCircleOutline } from 'react-icons/md'
import Busy from '../../../components/feedback/Busy'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import JobPostingEditForm from '../JobPostingEditForm'

type PropsType = {
  readonly jobPosting?: JobPostingInput
  readonly handleCancel: () => void
}

function DialogEditJob({ jobPosting, handleCancel }: PropsType) {
  const { data: ld, isFetching } = useLicenses()
  const licenses = ld?.licenses ?? []

  return (
    <DialogModal onClose={handleCancel} open>
      <DialogModal.Panel icon={MdCheckCircleOutline} title="Edit Job Order">
        {isFetching && (
          <section className="contain-xyc">
            <Busy>Authenticating</Busy>
          </section>
        )}
        {!isFetching && jobPosting && (
          <JobPostingEditForm
            isDialog
            displayname={jobPosting.client?.displayname ?? ''}
            licenses={licenses as LicenseFragment[]}
            jobPostingInput={{
              ...jobPosting,
              recruiters: [],
              accountExecs: [],
              timeSheetManagers: [],
            }}
          />
        )}
        <div className="flex w-full items-center justify-center gap-4">
          <ClickyBtn className="mood-orange w-full" onClick={handleCancel}>
            Cancel
          </ClickyBtn>
        </div>
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default React.memo(DialogEditJob)
