import { Popover } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import useConnect from '@vangst/services/oogst/member/useConnect'
import { MemberComputed } from '@vangst/services/oogst/member/useMember'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { useRouter } from 'next/router'
import { memo, useState } from 'react'
import { MdMoreVert } from 'react-icons/md'
import AnchorButton from '../../../components/navigation/internal/AnchorButton'
import DialogDeletePlacement from '../../job/feedback/DialogDeletePlacement'
import DialogEditPlacement from '../../job/feedback/DialogEditPlacement'
import useMessages from '../../messaging/useMessages'
import Connect from '../Connect'
import AddNote from './AddNote'
import AddToJob from './AddToJob'
import ApplicationStatusDisclosure from './ApplicationStatusDisclosure'
import PlacementStatusDisclosure from './PlacementStatusDisclosure'
import ScreenApplicant from './ScreenApplicant'
import ViewScreening from './ViewScreening'

type PropsType = React.HTMLAttributes<HTMLElement> & {
  readonly jobPostingId?: string
  readonly member: NonNullable<MemberComputed>
  readonly popoverClassName?: string
  readonly url?: string
}

function MemberMenu(props: PropsType) {
  const { className, jobPostingId, member, popoverClassName, url } = props
  const { viewer } = useViewer()
  const { query, pathname } = useRouter()
  const [placementModal, setPlacementModal] = useState(false)
  const [deletePlacementModal, setDeletePlacementModal] = useState(false)
  const application = member.candidate?.jobApplications?.find(
    (a) => a.jobPosting.slug === (jobPostingId || query.id),
  )

  const level = application?.jobPosting?.level
  const placement = member.candidate?.placements?.find(
    (p) => p.jobPosting.slug === jobPostingId,
  )

  const { handleMessageUser } = useMessages({ isWidget: true })

  const { disconnectWithMember } = useConnect(member)

  const cn = clsx(
    'relative inline-flex w-full list-none items-center p-2',
    'border-t border-grey-light',
    'hocus:bg-green',
    'aria-[selected=true]:bg-green aria-[selected=true]:font-600',
    'aria-[current=true]:bg-green aria-[current=true]:font-600',
  )

  // Ugh.
  const appRoute =
    application?.jobPosting?.client && query
      ? Routes.COMPANIES_JOBS_APPLICATIONS_DETAIL.replace(
          ':displayname',
          application.jobPosting.client.displayname,
        )
          .replace(':jid', application.jobPosting.slug)
          .replace(':aid', application.id)
      : url

  const internalUser = viewer?.permissions.internalUser
  return (
    <>
      <Popover className={clsx('relative inline-flex', className)}>
        <Popover.Button className={clsx('group/popoverbutton')}>
          <MdMoreVert />
          <span className="sr-only">Options</span>
        </Popover.Button>
        <Popover.Panel
          className={clsx(
            'mood-white absolute right-0 top-full z-40 mt-4 flex w-72 animate-fadeIn flex-col rounded border border-t-4 border-orange text-sm shadow-lg outline-none',
            'max-h-72 overflow-y-auto',
            'before:absolute before:-top-3 before:right-3 before:border-x-[9px] before:border-b-[12px] before:border-x-transparent before:border-b-orange',
            popoverClassName,
          )}
        >
          <ul>
            {appRoute && (
              <li>
                <AnchorButton activate className={cn} href={appRoute}>
                  View{' '}
                  {appRoute?.includes('applications')
                    ? 'Application'
                    : 'Profile'}
                </AnchorButton>
              </li>
            )}
            {member.permissions.edit && (
              <li>
                <AnchorButton className={cn} href={member.routes?.edit}>
                  Edit Profile
                </AnchorButton>
              </li>
            )}
            {(internalUser || disconnectWithMember) && (
              <li>
                <button
                  className={cn}
                  onClick={() => handleMessageUser(member)}
                >
                  Message
                </button>
              </li>
            )}

            <Connect
              at="menu"
              className={cn}
              variables={{ username: member.username }}
            />

            {internalUser && member.candidate && (
              <li>
                <AddNote
                  endpoint="getMemberActivities"
                  className={cn}
                  relayId={member.id}
                  username={member.username}
                />
              </li>
            )}

            {internalUser &&
              (jobPostingId || (query.id && query.displayname)) &&
              member.candidate &&
              !placement &&
              !application && (
                <li>
                  <AddToJob
                    className={cn}
                    candidateId={member.candidate.id}
                    slug={jobPostingId ?? (query.id as string | undefined)}
                  />
                </li>
              )}
            {internalUser &&
              application?.status &&
              /applications/gi.test(pathname) && (
                <li>
                  <ApplicationStatusDisclosure
                    className={cn}
                    applicationId={application.id}
                    defaultValue={application.status}
                    level={level}
                  />
                </li>
              )}

            {internalUser && placement && /placements/gi.test(pathname) && (
              <>
                <li>
                  <button
                    className={cn}
                    onClick={() => setPlacementModal(true)}
                  >
                    Edit Placement Details
                  </button>
                </li>
                <li>
                  <button
                    className={cn}
                    onClick={() => setDeletePlacementModal(true)}
                  >
                    Delete Placement
                  </button>
                </li>
                <li>
                  <PlacementStatusDisclosure
                    className={cn}
                    defaultValue={placement.status}
                    placementId={placement.id}
                  />
                </li>
              </>
            )}
            {internalUser &&
              !!application?.jobPosting.jobPostingScreening &&
              !application?.screeningSubmissions?.length && (
                <li>
                  <ScreenApplicant
                    applicationId={application.id}
                    className={cn}
                  />
                </li>
              )}
            {internalUser && !!application?.screeningSubmissions?.length && (
              <li>
                <ViewScreening className={cn} applicationId={application.id} />
              </li>
            )}
          </ul>
        </Popover.Panel>
      </Popover>
      {placementModal && (
        <DialogEditPlacement
          // @ts-expect-error placement is loaded via the candidate
          placement={placement}
          handleCancel={() => setPlacementModal(false)}
        />
      )}
      {deletePlacementModal && placement?.id && (
        <DialogDeletePlacement
          placementId={placement.id}
          handleCancel={() => setDeletePlacementModal(false)}
        />
      )}
    </>
  )
}

export default memo(MemberMenu)
