import { HubSpotFormIDs, postToHubSpot } from '@vangst/services/hubspot'
import { useCallback, useState } from 'react'

function useHubSpotForm(formId: HubSpotFormIDs) {
  const [error, setError] = useState(false)
  const [saving, setSaving] = useState(false)

  const clearMessages = useCallback(() => {
    setError(false)
    setSaving(false)
  }, [])

  const handleSetError = useCallback((state: boolean) => setError(state), [])
  const handleSetSaving = useCallback((state: boolean) => setSaving(state), [])

  // TS Generic
  const handleSubmitHSForm = async (data: any) => {
    handleSetSaving(true)
    postToHubSpot(formId, data).then((resp) => {
      if (resp.status === 'error') {
        handleSetError(true)
        handleSetSaving(false)
        return
      }
      handleSetError(false)
      handleSetSaving(false)
      return
    })
  }

  return { clearMessages, error, handleSubmitHSForm, saving }
}

export default useHubSpotForm
