import clsx from '@vangst/lib/clsx'
import HttpError from '@vangst/services/HttpError'
import {
  HubSpotFormIDs,
  ROLE_OPTIONS,
  SUBJECT_OPTIONS,
} from '@vangst/services/hubspot'
import { useRouter } from 'next/router'
import React from 'react'
import { useForm } from 'react-hook-form'
import { MdCheckCircleOutline, MdError } from 'react-icons/md'
import AlertText from '../../components/feedback/AlertText'
import Input from '../../components/forms/Input'
import Select from '../../components/forms/Select'
import TextareaCounter from '../../components/forms/TextareaCounter'
import ClickyBtn from '../../components/navigation/ClickyBtn'
import QueryErrorFallback from './feedback/QueryErrorFallback'
import StateSelect from './forms/SelectState'
import useHubSpotForm from './useHubSpotForm'
import validations from './validations'

type PropsType = {
  readonly box?: boolean
  readonly formId: HubSpotFormIDs
  readonly handleCancel?: () => void
  readonly id?: string
  readonly onSuccessCallback?: () => void
  readonly title?: string
  readonly disableFields?: boolean
}

function HubSpotForm(props: PropsType) {
  // These match the names on the hubspot form
  const defaultValues = React.useMemo(
    () => ({
      company: '',
      contact_notes: '',
      contact_subject: '',
      email: '',
      firstname: '',
      lastname: '',
      state: '',
      mjbiz_23_sourcer: '',
      role: '',
      phone: '',
    }),
    [],
  )

  type UserInput = typeof defaultValues

  const {
    box,
    disableFields = false,
    formId,
    handleCancel,
    id,
    onSuccessCallback,
    title,
  } = props
  const [scene, setScene] = React.useState(1)
  const { handleSubmitHSForm, saving, error: hsError } = useHubSpotForm(formId)

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<UserInput>()

  const hasClientErrors = Object.keys(errors).length > 0
  const { asPath, query } = useRouter()
  const contact_notes = watch('contact_notes')
  const messageLen = contact_notes?.length ?? 0

  const submit = React.useCallback(
    async (userInput: UserInput) => {
      setScene(1)

      const defaultInput = {
        submittedAt: Date.now(),
        fields: [
          { name: 'firstname', value: userInput.firstname },
          { name: 'lastname', value: userInput.lastname },
          { name: 'email', value: userInput.email },
        ],
        context: {
          pageName: asPath,
        },
      }

      const contactInput = {
        ...defaultInput,
        fields: [
          { name: 'contact_subject', value: userInput.contact_subject },
          { name: 'state', value: userInput.state },
          { name: 'contact_notes', value: userInput.contact_notes ?? '' },
          ...defaultInput.fields,
        ],
      }

      const salesRep = (query?.sales as string)
        ?.toLowerCase()
        ?.includes('olivia')
        ? 'Olivia'
        : (query?.sales as string)?.toLowerCase()?.includes('paulina')
        ? 'Paulina'
        : (query?.sales as string)?.toLowerCase()?.includes('jonte')
        ? 'Jonte'
        : 'Home Page'

      const contactInputSales = {
        ...defaultInput,
        fields: [
          { name: 'company', value: userInput.firstname },
          { name: 'phone', value: userInput.email },
          { name: 'mjbiz_23_sourcer', value: salesRep },
          ...defaultInput.fields,
        ],
      }

      const contactInputReport = {
        ...defaultInput,
        fields: [
          { name: 'role', value: userInput.role },
          ...defaultInput.fields,
        ],
      }

      const inputMap = {
        [HubSpotFormIDs.Contact]: contactInput,
        [HubSpotFormIDs.ContactSales]: contactInputSales,
        [HubSpotFormIDs.Report]: contactInputReport,
      }

      const input = inputMap[formId as keyof typeof inputMap]

      await handleSubmitHSForm(input)

      if (!hasClientErrors && hsError === false) {
        !onSuccessCallback && setScene(2)
        onSuccessCallback != null && onSuccessCallback()
        reset(defaultValues)
      }
    },
    [
      asPath,
      defaultValues,
      formId,
      handleSubmitHSForm,
      hasClientErrors,
      hsError,
      onSuccessCallback,
      query?.sales,
      reset,
    ],
  )

  const cn = clsx(
    'grid content-start gap-2',
    box && 'mood-white border p-4 sm:py-8',
    formId === HubSpotFormIDs.Contact && 'w-full sm:w-3/4',
    formId === HubSpotFormIDs.Expert && 'w-full sm:w-3/4 sm:justify-self-end',
  )

  const { ref: contactSubjectRef, ...contactSubjectProps } = register(
    'contact_subject',
    formId === HubSpotFormIDs.Contact ? validations.generic : undefined,
  )

  const { ref: roleRef, ...roleProps } = register(
    'role',
    formId === HubSpotFormIDs.Report ? validations.generic : undefined,
  )

  return (
    <form
      className={cn}
      id={id || `hubspot-form-${formId}`}
      onSubmit={handleSubmit(submit)}
    >
      {title && <h6 className="mb-4 text-center text-lg">{title}</h6>}
      <div className="flow-y-sm">
        <fieldset disabled={disableFields}>
          <Input
            id={`${formId}-firstname`}
            error={errors?.firstname?.message}
            label="first name"
            {...register('firstname', validations.generic)}
          />
          <Input
            id={`${formId}-lastname`}
            error={errors?.lastname?.message}
            label="last name"
            {...register('lastname', validations.generic)}
          />
          <Input
            error={errors?.email?.message}
            label="email"
            {...register('email', validations.email)}
            id={`${formId}-email`}
          />
          {formId === HubSpotFormIDs.Contact && (
            <>
              <StateSelect
                error={errors?.state?.message}
                label="state"
                {...register('state', validations.state)}
                id={`${formId}-state`}
              />
              <Select
                error={errors?.contact_subject?.message}
                hideLabel
                label="Subject"
                reference={contactSubjectRef}
                {...contactSubjectProps}
                options={SUBJECT_OPTIONS}
                id={`${formId}-contact_subject`}
              />

              <TextareaCounter
                error={errors?.contact_notes?.message}
                label="Message"
                len={messageLen}
                max={1200}
                placeholder="How can we help?"
                required={true}
                {...register('contact_notes', validations.message)}
              />
            </>
          )}
          {formId === HubSpotFormIDs.ContactSales && (
            <>
              <Input
                error={errors?.phone?.message}
                label="phone number"
                {...register('phone', validations.phone)}
                id={`${formId}-phone`}
              />
              <Input
                error={errors?.company?.message}
                label="company name"
                {...register('company', validations.generic)}
                id={`${formId}-company`}
              />
            </>
          )}
          {formId === HubSpotFormIDs.Report && (
            <Select
              error={errors?.role?.message}
              label="role"
              reference={roleRef}
              {...roleProps}
              options={ROLE_OPTIONS}
              id={`${formId}-role`}
            />
          )}
        </fieldset>
        <div className="flex gap-4">
          {handleCancel && (
            <ClickyBtn className="mood-white" type="reset">
              Cancel
            </ClickyBtn>
          )}
          {!disableFields && (
            <ClickyBtn
              id={`submit-${id}`}
              className="mood-orange"
              disabled={saving}
              type="submit"
            >
              {saving ? 'Sending' : 'Submit'}
            </ClickyBtn>
          )}
        </div>
        <div className="flow-y-xs">
          {hasClientErrors && (
            <AlertText
              aria-live="assertive"
              className="text-red"
              icon={MdError}
            >
              A validation error occurred.
            </AlertText>
          )}

          {hsError && <QueryErrorFallback error={new HttpError(500)} />}
          {scene === 2 && (
            <AlertText
              className="text-sm italic text-green"
              icon={MdCheckCircleOutline}
            >
              Form submitted successfully!
            </AlertText>
          )}
        </div>
      </div>
    </form>
  )
}

export { HubSpotFormIDs }
export default React.memo(HubSpotForm)
