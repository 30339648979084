import clsx from '@vangst/lib/clsx'
import { IconType } from 'react-icons'
import Busy from './Busy'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly icon?: IconType
  readonly loading?: boolean
}

/**
 * Display filler content during a components empty or zero state.
 *
 * @example
 * <EmptyState icon={MdBadge}>No existing content</EmptyState>
 * <EmptyState icon={MdBadge}>
 *   <span className="flex max-w-prose justify-center text-center text-sm">
 *     No existing content
 *   </span>
 *   <GoContactSupport />
 * </EmptyState>
 */
function EmptyState(props: PropsType) {
  const { icon, children, className, loading = false } = props
  const Icon = icon
  return (
    <div
      className={clsx(
        'mood-grey-light flex w-full flex-col items-center justify-center gap-2 rounded p-6',
        className,
      )}
    >
      {loading && <Busy />}
      {!loading && Icon != null && <Icon className="mx-auto mb-4 size-12" />}
      {children != null && (
        <div className="text-center text-sm">{children}</div>
      )}
    </div>
  )
}

export default EmptyState
