import { QueryClient, UseMutationOptions } from '@tanstack/react-query'
import Routes from '../../routes'
import { getSessionRedirect, setSessionRedirect } from '../../storage'
import {
  FetcherError,
  SignUpContextEnum,
  SignUpUserMutation,
  SignUpUserMutationVariables,
  useSignUpUserMutation,
} from '../types'
import { initializeViewer, invalidateViewer } from './useViewer'

type TOptions = UseMutationOptions<
  SignUpUserMutation,
  FetcherError,
  SignUpUserMutationVariables
>

function useSignUpUser(
  queryClient: QueryClient,
  options?: TOptions,
  skipRedirect?: boolean,
) {
  return useSignUpUserMutation({
    onSuccess: (data, { input }) => {
      invalidateViewer(queryClient)
      queryClient.clear()

      const { from } = input
      const username = data.signUpUser.username

      const profileRoute = Routes.MEMBERS_DETAIL.replace(':username', username)
      const existing = getSessionRedirect()
      const redirectPath =
        existing != null
          ? existing
          : from === SignUpContextEnum.SalaryGuide
          ? Routes.REPORTS_SALARY_GUIDE
          : from === SignUpContextEnum.LinkedInLandingPage
          ? profileRoute
          : from === SignUpContextEnum.MemberLandingPage
          ? profileRoute
          : undefined
      if (redirectPath != null && !skipRedirect) {
        setSessionRedirect(redirectPath)
      }
      initializeViewer(data.signUpUser, skipRedirect)
    },
    ...options,
  })
}

export default useSignUpUser
