import { GoogleLogin } from '@react-oauth/google'
import { useQueryClient } from '@tanstack/react-query'
import clsx from '@vangst/lib/clsx'
import useLogin from '@vangst/services/oogst/viewer/useLogin'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdError } from 'react-icons/md'
import AlertText from '../../components/feedback/AlertText'
import Input from '../../components/forms/Input'
import ClickyBtn from '../../components/navigation/ClickyBtn'
import QueryErrorBoundary from '../application/feedback/QueryErrorBoundary'
import QueryErrorFallback from '../application/feedback/QueryErrorFallback'
import validations from '../application/validations'
import { GoForgotPassword } from './navigation/GoDoThings'

export const defValues = { email: '', password: '' }
type FormInput = typeof defValues

type PropsType = {
  readonly as?: 'page' | 'dialog'
  readonly handleClose?: () => void
  readonly isApplicationView?: boolean
  readonly defaultValues?: FormInput
}

function SignInForm(props: PropsType) {
  const { as = 'page', handleClose, isApplicationView, defaultValues } = props
  const [oauthError, setOauthError] = useState(false)
  const skipRedirect = isApplicationView

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ defaultValues })

  const queryClient = useQueryClient()

  const {
    error: serverError,
    isLoading,
    mutate,
  } = useLogin(
    queryClient,
    {
      onSettled: (response) => {
        // if profile is already complete, hide the modal
        if (
          response?.loginUser?.candidate?.isAbleToApply &&
          isApplicationView
        ) {
          queryClient.invalidateQueries(['JobPosting'])
          handleClose ? handleClose() : undefined
        }
      },
    },
    skipRedirect,
  )

  const submit = React.useCallback(
    (fd: FormInput) => {
      mutate(
        { input: { ...fd } },
        {
          onSuccess:
            handleClose != null && !isApplicationView ? handleClose : undefined,
        },
      )
    },
    [mutate, handleClose, isApplicationView],
  )

  const send = React.useMemo(() => handleSubmit(submit), [handleSubmit, submit])

  return (
    <div>
      <div className="mb-6">
        <QueryErrorBoundary kind="status">
          <GoogleLogin
            onSuccess={(credentialResponse: any) => {
              const obj = {
                token: credentialResponse.credential,
              }
              mutate({ input: obj })
            }}
            onError={() => {
              setOauthError(true)
            }}
            theme="filled_blue"
            context="signin"
            width={200}
          />
          {oauthError && (
            <AlertText
              className="mt-4 text-center text-sm text-red"
              icon={MdError}
            >
              <span>There was an error siging in with Google.</span>
            </AlertText>
          )}
        </QueryErrorBoundary>
      </div>
      <form id="sign-in-form" onSubmit={send} className="flow-y-sm">
        <Input
          error={errors?.email?.message}
          label="email"
          required
          {...register('email', validations.email)}
        />
        <Input
          error={errors?.password?.message}
          label="password"
          type="password"
          required
          {...register('password', validations.password)}
        />
        {as === 'dialog' && <GoForgotPassword handleClose={handleClose} />}
        <div className={clsx(as === 'page' ? 'w-full' : 'flex justify-end')}>
          <ClickyBtn
            as="text-icon"
            className="mood-orange-medium-inverted"
            disabled={isLoading}
            type="submit"
          >
            Sign In
          </ClickyBtn>
        </div>
        {serverError != null && <QueryErrorFallback error={serverError} />}
      </form>
    </div>
  )
}

export default React.memo(SignInForm)
