/**
 * An immutable add or remove on an item from an array using an indexOf lookup
 *
 * @example
 * addOrRemove([1,2,3], 4) // [1,2,3,4]
 * addOrRemove([1,2,3], 2) // [1,3]
 */
function addOrRemove<T>(arr: T[], item: T) {
  const index = arr.indexOf(item)
  return index != null && index >= 0
    ? [...arr.slice(0, index), ...arr.slice(index + 1)]
    : [...arr, item]
}

export default addOrRemove
