import clsx from 'clsx'
import { forwardRef, memo } from 'react'
import { IconType } from 'react-icons'
import {
  MdArrowBack,
  MdArrowDownward,
  MdArrowForward,
  MdArrowUpward,
} from 'react-icons/md'
import AnchorButton, { AnchorButtonType } from './internal/AnchorButton'

export type ClickyType = AnchorButtonType & {
  readonly as?: 'text' | 'icon' | 'icon-text' | 'text-icon'
  readonly icon?: IconType | (() => JSX.Element)
}

const ClickyLiner = forwardRef<HTMLElement, ClickyType>(
  function ClickyLiner(props, ref) {
    const { as = 'text', children, className, icon, ...rezt } = props
    const rest = { ref, ...rezt }

    const Icon = icon
      ? icon
      : className?.includes('motion-north')
      ? MdArrowUpward
      : className?.includes('motion-south')
      ? MdArrowDownward
      : className?.includes('motion-west')
      ? MdArrowBack
      : MdArrowForward

    return (
      <AnchorButton
        className={clsx(
          'group/clicky liner',
          'aria-[current=true]:text-orange hocus:text-orange',
          className,
        )}
        {...rest}
      >
        <span
          className={clsx(
            'liner whitespace-nowrap bg-gradient-to-r from-current to-current bg-[length:0_0.25em] bg-bottom bg-no-repeat py-1 transition-[background] group-hover/clicky:bg-[length:100%_0.25em] group-aria-[current=true]/clicky:bg-[length:100%_0.25em]',
            as === 'icon' && 'sr-only',
            as === 'icon-text' && 'order-2',
          )}
        >
          {children}
        </span>
        {Icon && as !== 'text' && (
          <Icon
            className={clsx(as === 'icon-text' ? 'order-1' : undefined)}
            role="presentation"
          />
        )}
      </AnchorButton>
    )
  },
)

/**
 * ClickyLiner is a wrapper around `AnchorButton` that is context aware to provide
 * predefined layouts, states, motions with icons and classes.
 *
 * Clicky can be rendered `as: text | icon | icon-text | text-icon`. It adjusts
 * layouts handled by the `liner` css class. It's the option you want when looking for a navigation link with an animated bottom border on hover.
 *
 * @see [AnchorButton](https://github.com/vangst/oogmerk/blob/main/apps/web/components/navigation/internal/AnchorButton.tsx)
 * @see [Clicky Story](https://uat.vangst.com/oranje/stories?id=Components_Navigation_Clicky)
 *
 * @example
 * <ClickyLiner as="text-icon" className="hocus:text-orange motion-west">
 *   An orange text link  with an (optional) icon to the right that animates west and has an animated bottom border on hover
 * </ClickyLiner>
 *
 */
export default memo(ClickyLiner)
