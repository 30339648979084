import clsx from 'clsx'
import { forwardRef, memo } from 'react'
import { IconType } from 'react-icons'
import {
  MdArrowBack,
  MdArrowDownward,
  MdArrowForward,
  MdArrowUpward,
} from 'react-icons/md'
import AnchorButton, { AnchorButtonType } from './internal/AnchorButton'

export type ClickyType = AnchorButtonType & {
  readonly as?: 'text' | 'icon' | 'icon-text' | 'text-icon'
  readonly icon?: IconType | (() => JSX.Element)
}

const ClickyLink = forwardRef<HTMLElement, ClickyType>(
  function ClickyLink(props, ref) {
    const { as = 'text', children, className, icon, ...rezt } = props
    const rest = { ref, ...rezt }

    const Icon = icon
      ? icon
      : className?.includes('motion-north')
      ? MdArrowUpward
      : className?.includes('motion-south')
      ? MdArrowDownward
      : className?.includes('motion-west')
      ? MdArrowBack
      : MdArrowForward

    return (
      <AnchorButton
        className={clsx(
          'group/clicky inline-flex !cursor-pointer items-center  gap-1',
          className,
        )}
        {...rest}
      >
        <span
          className={clsx(
            // TODO: Need to rethink clikcies (again).
            // TODO: This is bad. Clickies all now have an internal span, which is messing everything up.
            className?.includes('truncate') && 'truncate',
            className?.includes('w-full') && 'w-full text-left',
            as === 'icon' && 'sr-only',
            as === 'icon-text' && 'order-2',
          )}
        >
          {children}
        </span>
        {Icon && as !== 'text' && (
          <Icon
            className={clsx(as === 'icon-text' ? 'order-1' : undefined)}
            role="presentation"
          />
        )}
      </AnchorButton>
    )
  },
)

/**
 * ClickyLink is a wrapper around `AnchorButton` that is context aware to provide
 * predefined layouts, states, motions with icons and classes.
 * ClickyLink  can be rendered `as: text | icon | icon-text | text-icon`. It adjusts
 * layouts handled by the `link-*` css class. It's the option you want when looking for a basic navigation link.
 *
 * @see [AnchorButton](https://github.com/vangst/oogmerk/blob/main/apps/web/components/navigation/internal/AnchorButton.tsx)
 * @see [Clicky Story](https://uat.vangst.com/oranje/stories?id=Components_Navigation_Clicky)
 *
 * @example
 * <ClickyLink as="text-icon" className="link-orange motion-west">
 *   An orange text link  with an (optional) icon to the right that animates west and has an animated bottom border on hover
 * </ClickyLink>
 *
 */
export default memo(ClickyLink)
