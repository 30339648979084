import clsx from '@vangst/lib/clsx'
import { getDynamicTimestamp } from '@vangst/lib/formatDates'
import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import useMarkAlertRead from '@vangst/services/oogst/member/useMarkAlertRead'
import {
  JobPostingActivitiesQueryVariables,
  MemberActivitiesQueryVariables,
  MemberFragment,
} from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import useActivitiesAlerts, {
  ActivitiesAlertsEndpoints,
  ActivitiesAlertsQueryVariables,
} from '@vangst/services/oogst/wtf/useActivitiesAlerts'
import Routes from '@vangst/services/routes'
import { useRouter } from 'next/dist/client/router'
import { useCallback, useState } from 'react'
import { IconType } from 'react-icons'
import {
  MdAddCircle,
  MdCancel,
  MdMoreHoriz,
  MdOutlineMessage,
  MdToggleOff,
  MdToggleOn,
} from 'react-icons/md'
import EmptyState from '../../components/feedback/EmptyState'
import ClickyLink from '../../components/navigation/ClickyLink'
import Avatar from '../../components/views/Avatar'
import { serializeJsonToReact } from '../editor/serializers'
import useMessages from '../messaging/useMessages'
import FormCreateActivity from './forms/FormCreateActivity'
import { emptyText } from './TableRow'

type PropsType = React.HTMLAttributes<HTMLElement> & {
  readonly classNameList?: string
  readonly endpoint: ActivitiesAlertsEndpoints
  readonly relayId?: string
  readonly variables: ActivitiesAlertsQueryVariables
}

export function ActivitiesRenderer(props: PropsType) {
  const [showCreateActivity, setShowCreateActivity] = useState(false)
  const [toggleNotes, setToggleNotes] = useState(false)
  const { className, classNameList, endpoint, relayId, variables } = props
  const { viewer } = useViewer()
  const { push } = useRouter()
  const enabled =
    // @ts-ignore
    !isEmptyOrNil(variables?.username as MemberActivitiesQueryVariables) ||
    // @ts-ignore
    !isEmptyOrNil(variables?.id as JobPostingActivitiesQueryVariables)

  const { data, isLoading, isRefetching, refetch } = useActivitiesAlerts(
    endpoint,
    {
      ...variables,
      notes: toggleNotes,
    },
    { enabled },
  )
  const { mutate } = useMarkAlertRead(endpoint)

  const items = data?.activities

  const { handleMessageUser } = useMessages({
    isWidget: true,
  })

  const handleMarkRead = useCallback(
    (id?: string, destination?: string) => {
      mutate({ input: { id: id ?? '' } })
      destination && push(destination)
    },
    [mutate, push],
  )

  const handleToggle = useCallback(() => {
    setToggleNotes(!toggleNotes)
    refetch()
  }, [refetch, toggleNotes])

  const isBusy = data == null && (isLoading || isRefetching)

  return (
    <div className={clsx('flow-y-sm w-full', className)}>
      {relayId && !isBusy && (
        <div className="flex justify-between">
          <div className="flow-x-xs items-center">
            <span>All</span>
            {toggleNotes ? (
              <ClickyLink
                disabled={isRefetching}
                as="icon"
                className="text-orange"
                icon={MdToggleOn}
                onClick={handleToggle}
              />
            ) : (
              <ClickyLink
                disabled={isRefetching}
                as="icon"
                className="text-orange"
                icon={MdToggleOff}
                onClick={handleToggle}
              />
            )}
            <span>Notes</span>
          </div>
          {!showCreateActivity ? (
            <ClickyLink
              as="text-icon"
              icon={MdAddCircle}
              className="link-orange ml-auto justify-self-end text-sm"
              onClick={() => setShowCreateActivity(true)}
            >
              Create Activity
            </ClickyLink>
          ) : (
            <ClickyLink
              as="text-icon"
              icon={MdCancel}
              className="link-orange ml-auto justify-self-end text-sm"
              onClick={() => setShowCreateActivity(false)}
            >
              Cancel
            </ClickyLink>
          )}
        </div>
      )}
      {showCreateActivity && (
        <FormCreateActivity
          endpoint={endpoint}
          handleCancel={() => setShowCreateActivity(false)}
          relayId={relayId ?? ''}
          variables={variables}
        />
      )}
      <div
        className={clsx(
          'grid w-full overflow-y-auto',
          relayId && 'border-y border-y-grey-light pt-2',
        )}
      >
        <ul className={clsx('relative z-20', classNameList)}>
          {isBusy ? (
            <MdMoreHoriz />
          ) : items && items.length === 0 ? (
            <li>
              <EmptyState>
                No {endpoint === 'getMemberAlerts' ? 'Alerts' : 'Activities'}
              </EmptyState>
            </li>
          ) : (
            items &&
            items.map((item, i) => {
              const showMarkRead =
                viewer?.username !== item?.author?.username &&
                endpoint === 'getMemberAlerts' &&
                !item?.recipientRead
              const ContextIcon = item?.details?.context?.icon as IconType
              return (
                <li
                  key={`alert-${item?.updatedAt}-${i}`}
                  className={clsx(
                    'flow-y-xs mood-white relative rounded-sm border border-grey-light p-2 pb-4 text-sm',
                    item?.details?.context?.uri != null &&
                      'hover:bg-grey-lightest',
                  )}
                >
                  {/* TOP ROW */}
                  <span className="flex items-start justify-between">
                    <span className="flow-x-xs z-20 items-center">
                      <ContextIcon />

                      <span className="truncate">
                        {item?.details?.context?.id}
                      </span>
                    </span>
                    <span className="flow-y-xs">
                      <span
                        className={clsx(
                          'flow-x-xs items-center text-[12px] sm:ml-auto',
                          !item?.recipientRead && 'font-600',
                        )}
                      >
                        {getDynamicTimestamp(
                          new Date(Date.now()),
                          new Date(item?.createdAt),
                        )}
                        {showMarkRead && (
                          <ClickyLink
                            as="text"
                            className="link-orange z-10 rounded-sm bg-green px-1 text-[12px]"
                            onClick={() => handleMarkRead(item?.id ?? '')}
                          >
                            Mark Read
                          </ClickyLink>
                        )}
                      </span>
                      <span className="z-10 text-[12px]">
                        <span className="flow-x-xs items-center">
                          <Avatar
                            alt={item?.author?.firstName[0] ?? ''}
                            className="mood-grey size-4 shrink-0"
                            src={
                              item?.author?.avatarUrl ||
                              Routes.GENERIC_USER_AVATAR
                            }
                          />
                          {item?.details?.authorUri ? (
                            <ClickyLink
                              href={item?.details?.authorUri}
                              className="link-orange underline"
                            >
                              <span>{item?.details?.from}</span>
                            </ClickyLink>
                          ) : (
                            <span>Unknown Author</span>
                          )}
                          <ClickyLink
                            disabled={item?.author?.username == null}
                            onClick={() =>
                              handleMessageUser(item?.author as MemberFragment)
                            }
                            className={clsx(
                              item?.author
                                ? 'link-orange'
                                : 'pointer-events-none text-grey',
                            )}
                          >
                            <span className="flow-x-xs items-center text-[12px]">
                              {' '}
                              <MdOutlineMessage className="size-4" />
                            </span>
                          </ClickyLink>
                        </span>
                      </span>
                    </span>
                  </span>
                  {/* DETAILS */}
                  <span className="flow-y-xs">
                    <span className="flow-x-xs items-start">
                      <ClickyLink
                        disabled={item?.details?.context?.uri == null}
                        className="hitarea"
                        onClick={() =>
                          handleMarkRead(item?.id, item?.details?.context?.uri)
                        }
                      >
                        <span className="max-w-8/10">
                          <span>
                            {item?.jsonBody
                              ? serializeJsonToReact(item?.jsonBody)
                              : emptyText}
                          </span>
                        </span>
                      </ClickyLink>
                    </span>
                  </span>
                </li>
              )
            })
          )}
        </ul>
      </div>
    </div>
  )
}

export default ActivitiesRenderer
