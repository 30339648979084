import clsx from '@vangst/lib/clsx'
import useMember, {
  MemberOptions,
  MemberVars,
} from '@vangst/services/oogst/member/useMember'
import { memo } from 'react'
import { AsTypeUnion } from '../nodes/RendererFactory'
import MemberTableRow from './MemberTableRow'
import MemberAvatar from './views/MemberAvatar'
import MemberCard from './views/MemberCard'
import MemberDetail from './views/MemberDetail'
import MemberFaceCard from './views/MemberFaceCard'
import MemberHeader from './views/MemberHeader'
import MemberMenuItem from './views/MemberMenuItem'
import MemberSlip from './views/MemberSlip'

type PropsType = React.HTMLAttributes<HTMLElement> & {
  readonly as: AsTypeUnion
  readonly isFeatured?: boolean
  readonly jobPostingId?: string
  readonly label?: string
  readonly options?: MemberOptions
  readonly popoverClassName?: string
  readonly url?: string
  readonly variables: MemberVars
}

export function LabelPercentComplete({
  as,
  percent,
}: {
  as: 'text' | 'tag'
  percent?: number
}) {
  if (percent == null) return null

  const mood =
    percent <= 44
      ? 'mood-red'
      : percent > 44 && percent <= 74
      ? 'mood-orange-medium'
      : 'mood-green'

  const text =
    percent <= 44
      ? 'text-red'
      : percent > 44 && percent <= 74
      ? 'text-orange-medium'
      : 'text-green-dark'

  return (
    <span
      className={clsx(
        as === 'tag' && mood,
        as === 'text' && text,
        'mr-4 block self-center rounded-sm px-2 py-1 text-[10px] font-400 uppercase',
      )}
    >
      Profile {percent}% complete
    </span>
  )
}

function MemberRenderer(props: PropsType) {
  const {
    as,
    isFeatured,
    jobPostingId,
    label,
    variables,
    options,
    popoverClassName,
    url,
    ...rest
  } = props

  const username = variables.username
  const { data, uri } = useMember({ username }, options)
  const member = data?.getUser

  const title = member ? member.fullName || '' : label ? label : '...'

  if (as === 'avatar') {
    return <MemberAvatar member={member} title={title} uri={url ?? uri} />
  }
  if (as === 'chavatar') {
    return (
      <MemberAvatar isChat member={member} title={title} uri={url ?? uri} />
    )
  }

  if (as === 'detail') {
    return (
      <MemberDetail
        jobPostingId={jobPostingId}
        member={member}
        title={title}
        variables={variables}
        {...rest}
      />
    )
  }

  if (as === 'header') {
    return (
      <MemberHeader
        jobPostingId={jobPostingId}
        member={member}
        title={title}
        variables={variables}
        {...rest}
      />
    )
  }

  if (as === 'slip') {
    return (
      <MemberSlip
        jobPostingId={jobPostingId}
        member={member}
        title={title}
        uri={url ?? uri}
        variables={variables}
        {...rest}
      />
    )
  }

  if (as === 'face-card') {
    return (
      <MemberFaceCard
        jobPostingId={jobPostingId}
        member={member}
        popoverClassName={popoverClassName}
        title={title}
        uri={url ?? uri}
        variables={variables}
        {...rest}
      />
    )
  }

  if (as === 'menuitem') {
    return (
      <MemberMenuItem
        jobPostingId={jobPostingId}
        member={member}
        title={title}
        uri={url ?? uri}
        variables={variables}
        {...rest}
      />
    )
  }

  if (as === 'table-row') {
    return (
      <MemberTableRow
        jobPostingId={jobPostingId}
        member={member}
        title={title}
        uri={url ?? uri}
        variables={variables}
        {...rest}
      />
    )
  }

  // as card
  return (
    <MemberCard
      isFeatured={isFeatured}
      jobPostingId={jobPostingId}
      member={member}
      options={options}
      title={title}
      uri={url ?? uri}
      variables={variables}
      {...rest}
    />
  )
}

export default memo(MemberRenderer)
