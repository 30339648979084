import { useRouter } from 'next/router'
import { memo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdCheckCircleOutline, MdErrorOutline } from 'react-icons/md'
import AlertText from '../../components/feedback/AlertText'
import Input from '../../components/forms/Input'
import ClickyExplore from '../../components/navigation/ClickyExplore'
import SelectTypeOfLead from './forms/SelectTypeOfLead'
import { HubSpotFormIDs } from './HubSpotForm'
import useHubSpotForm from './useHubSpotForm'
import validations from './validations'

type PropsType = React.HTMLAttributes<HTMLFormElement> & {
  readonly onSuccessCallback?: () => void
  readonly id: string
}

function NewsletterForm(props: PropsType) {
  const { asPath } = useRouter()
  const { id, onSuccessCallback } = props
  const [success, setSuccess] = useState(false)
  const defaultValues = { email: '', type_of_lead: '' }

  const {
    handleSubmitHSForm,
    saving,
    error: hsError,
  } = useHubSpotForm(HubSpotFormIDs.Newsletter)

  type UserInput = typeof defaultValues
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<UserInput>()

  const submit = (userInput: UserInput) => {
    const input = {
      submittedAt: Date.now(),
      fields: [
        { name: 'email', value: userInput.email },
        { name: 'type_of_lead', value: userInput['type_of_lead'] },
      ],
      context: {
        pageName: asPath,
      },
    }
    handleSubmitHSForm(input)
    if (Object.keys(errors).length === 0 && hsError === false) {
      setSuccess(true)
      onSuccessCallback != null && onSuccessCallback()
      reset(defaultValues)
    }
  }

  return (
    <form
      id={`${id}-form`}
      className="relative flex flex-col content-end gap-y-4"
      onReset={() => reset(defaultValues)}
      onSubmit={handleSubmit(submit)}
    >
      <Input
        className="w-full"
        error={errors?.email?.message}
        id={`${id}-email`}
        label="Email"
        {...register('email', validations.email)}
      />
      <fieldset>
        <SelectTypeOfLead
          id={`${id}-type-of-lead`}
          label="What is your role?"
          error={errors?.type_of_lead?.message}
          className="text-black"
          {...register('type_of_lead', validations.generic)}
        />
      </fieldset>
      <ClickyExplore
        id={`submit-${id}`}
        disabled={saving}
        className="w-48"
        type="submit"
      >
        Submit
      </ClickyExplore>
      {success && (
        <AlertText
          className="text-sm italic text-green"
          icon={MdCheckCircleOutline}
        >
          Successfully submitted!
        </AlertText>
      )}
      {(Object.keys(errors).length > 0 || hsError === true) && (
        <AlertText className="text-sm italic text-red" icon={MdErrorOutline}>
          Error submitting form.
        </AlertText>
      )}
    </form>
  )
}

export default memo(NewsletterForm)
