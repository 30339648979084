import { QueryClient, UseMutationOptions } from '@tanstack/react-query'
import {
  FetcherError,
  UpdateJobApplicationMutation,
  UpdateJobApplicationMutationVariables,
  useUpdateJobApplicationMutation,
} from '../types'
import { invalidateCompanyJobApplications } from './useCompanyJobApplications'

type UpdateJobApplicationOptions = UseMutationOptions<
  UpdateJobApplicationMutation,
  FetcherError,
  UpdateJobApplicationMutationVariables
>

function useUpdateJobApplication(
  queryClient: QueryClient,
  options?: UpdateJobApplicationOptions,
) {
  return useUpdateJobApplicationMutation({
    onSettled: () => {
      invalidateCompanyJobApplications(queryClient)
    },
    ...options,
  })
}

export default useUpdateJobApplication
