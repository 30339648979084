// TODO: Maybe there's a better name for this or place to stick it?
// Don't mean to confuse it with useQueryParams from search :shrug:

/**
 * Toggle a query parameter on and off on a click event provided a key and value
 *
 * Example: () => toggleParams({ key: 'open', value: member?.username })
 * `https://example.com?open=memberUsername`
 * Will look for the query parameter `open` and if it doesnt exist,
 * will set it to the value of `member?.username`
 * If it exists, it will remove the query parameter `open`
 */
export default function useParams() {
  const toggleParams = ({ key, value }: { key: string; value?: string }) => {
    if (globalThis.sessionStorage?.getItem(key) === value) {
      globalThis.sessionStorage.removeItem(key)
      return
    }
    value != null && globalThis.sessionStorage.setItem(key, value)
    return
  }

  return { toggleParams }
}
