import { ActivitiesAlertsEndpoints } from '@vangst/services/oogst/wtf/useActivitiesAlerts'
import { useState } from 'react'
import DialogCreateActivity from '../../viewer/feedback/DialogCreateActivity'

type PropsType = {
  readonly endpoint: ActivitiesAlertsEndpoints
  readonly className?: string
  readonly relayId: string
  readonly username: string
}

function AddNote(props: PropsType) {
  const { endpoint, username, relayId, className } = props
  const [shown, setShown] = useState(false)

  return (
    <>
      <button className={className} onClick={() => setShown(true)}>
        Add Note
      </button>
      {shown && (
        <DialogCreateActivity
          endpoint={endpoint}
          handleCancel={() => setShown(false)}
          relayId={relayId}
          variables={{ username }}
        />
      )}
    </>
  )
}

export default AddNote
