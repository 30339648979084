import { STATE_LIST } from './constants/locations'
import unionizeAll from './unionizeAll'

const stateSlugToAbbreviation = (slug: string) => {
  return Object.keys(STATE_LIST).find((key: string) => {
    const state = STATE_LIST[key as keyof typeof STATE_LIST].toLowerCase()
    if (unionizeAll(state, '-') === slug) {
      return key
    }
    return undefined
  })
}

export default stateSlugToAbbreviation
