import { useQueryClient } from '@tanstack/react-query'
import { orderedJobApplicationStatus } from '@vangst/services/oogst/company/statuses'
import useUpdateJobApplication from '@vangst/services/oogst/company/useUpdateJobApplication'
import { JobApplicationStatusEnum } from '@vangst/services/oogst/types'
import React from 'react'
import Select, { SelectType } from '../../components/forms/Select'

type PropsType = SelectType & {
  readonly applicationId: string
}

const options = Object.keys(orderedJobApplicationStatus).map((status) => ({
  label: orderedJobApplicationStatus[status as JobApplicationStatusEnum],
  value: status,
}))

function SelectJobApplicationStatus(props: PropsType) {
  const { error, hint, label, name, applicationId, reference, ...rest } = props
  const id = props?.id ?? name
  const queryClient = useQueryClient()
  const { mutate } = useUpdateJobApplication(queryClient)

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const status = e.target.value as JobApplicationStatusEnum
    mutate({ input: { id: applicationId, status } })
  }

  return (
    <Select
      id={id}
      disabled={props.disabled}
      error={error}
      hint={hint}
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      reference={reference}
      {...rest}
    />
  )
}

export default React.memo(SelectJobApplicationStatus)
