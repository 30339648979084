import { MemberFragment } from '@vangst/services/oogst/types'
import React, { useMemo } from 'react'
import { Control } from 'react-hook-form'
import ComboBoxMulti from '../../../components/forms/ComboBoxMulti'
import { SelectType } from '../../../components/forms/Select'

type PropsType = SelectType

function ChooseClientUsersCombo(
  props: PropsType & {
    readonly control: Control
    readonly fieldLabel?: string
    readonly handleValueChange: (name: string, value: string[]) => void
    readonly users: MemberFragment[]
  },
) {
  const {
    error,
    hideLabel,
    hint,
    label,
    name,
    reference,
    handleValueChange,
    users,
    value,
    ...rest
  } = props

  const data = useMemo(() => {
    return users?.map((member) => ({
      label: member.firstName + ' ' + member.lastName,
      value: member.id ?? '',
    }))
  }, [users])

  const listOfUsers = useMemo(() => {
    return data?.map((user) => ({
      label: `${user.label}`,
      value: user.value ?? '',
    }))
  }, [data])

  const employeeOptions = [...(listOfUsers ?? [])]

  return (
    <div className="mt-4 w-72">
      <ComboBoxMulti
        id={name}
        handleValueChange={handleValueChange}
        value={value as string[]}
        error={error}
        hideLabel={hideLabel}
        label={label}
        name={name}
        options={employeeOptions}
        {...rest}
      />
    </div>
  )
}

export default React.memo(ChooseClientUsersCombo)
