import { GoogleLogin } from '@react-oauth/google'
import { useQueryClient } from '@tanstack/react-query'
import clsx from '@vangst/lib/clsx'
import {
  ProviderEnum,
  SignUpContextEnum,
  UserRoleEnum,
} from '@vangst/services/oogst/types'
import useSignUpUser from '@vangst/services/oogst/viewer/useSignUpUser'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdError, MdErrorOutline } from 'react-icons/md'
import AlertText from '../../components/feedback/AlertText'
import Input from '../../components/forms/Input'
import ClickyBtn from '../../components/navigation/ClickyBtn'
import QueryErrorBoundary from '../application/feedback/QueryErrorBoundary'
import QueryErrorFallback from '../application/feedback/QueryErrorFallback'
import validations from '../application/validations'
import { GoSignIn, GoTermsAgreement } from './navigation/GoDoThings'

type FormInput = typeof defValues

export const defValues = {
  email: '',
  firstName: '',
  lastName: '',
  postalCode: '',
  password: '',
  passwordConfirmation: '',
}

type PropsType = {
  readonly as?: 'page' | 'dialog'
  readonly defaultValues?: FormInput
  readonly from: SignUpContextEnum
  readonly handleClose?: () => void
  readonly isApplicationView?: boolean
  readonly userType?: UserRoleEnum
}

function SignUpForm(props: PropsType) {
  const { as = 'page', from, handleClose, isApplicationView } = props
  const { defaultValues = defValues, userType = UserRoleEnum.Candidate } = props
  const skipRedirect = isApplicationView

  const [oauthError, setOauthError] = useState(false)
  const [pwMismatchError, setPwMismatchError] = React.useState(false)

  const queryClient = useQueryClient()

  const {
    error: serverError,
    isLoading,
    mutate,
  } = useSignUpUser(queryClient, {}, skipRedirect)

  const submit = React.useCallback(
    (fd: FormInput) => {
      const fields: FormInput = {
        email: fd.email,
        firstName: fd.firstName,
        lastName: fd.lastName,
        password: fd.password,
        passwordConfirmation: fd.passwordConfirmation,
        postalCode: fd.postalCode,
      }

      const input = {
        ...fields,
        from,
        termsAccepted: true,
        userType,
      }

      if (fd.password !== fd.passwordConfirmation) {
        setPwMismatchError(true)
        return
      }

      mutate(
        { input },
        {
          onSuccess:
            handleClose != null && !isApplicationView ? handleClose : undefined,
        },
      )
    },
    [userType, from, handleClose, isApplicationView, mutate],
  )

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({ defaultValues })

  const send = React.useMemo(() => handleSubmit(submit), [handleSubmit, submit])

  return (
    <div>
      <div className="mb-6">
        <QueryErrorBoundary kind="status">
          <GoogleLogin
            onSuccess={(credentialResponse: any) => {
              const obj = {
                termsAccepted: true,
                userType: UserRoleEnum.Candidate,
                from: SignUpContextEnum.MemberSignUpForm,
                token: credentialResponse.credential,
                provider: ProviderEnum.Google,
              }
              mutate({ input: obj })
            }}
            text="signup_with"
            theme="filled_blue"
            context="signup"
            width={200}
            onError={() => {
              setOauthError(true)
            }}
          />
          {oauthError && (
            <AlertText
              className="mt-4 text-center text-sm text-red"
              icon={MdError}
            >
              <span>There was an error siging in with Google.</span>
            </AlertText>
          )}
        </QueryErrorBoundary>
      </div>
      <form className="grid gap-8" id="sign-up-form" onSubmit={send}>
        <div
          className={clsx(
            as === 'page' && 'grid gap-4',
            as === 'dialog' && 'grid gap-4 sm:grid-cols-2',
          )}
        >
          <Input
            error={errors?.firstName?.message}
            label="First Name"
            required
            {...register('firstName', validations.firstName)}
          />
          <Input
            error={errors?.lastName?.message}
            label="Last Name"
            required
            {...register('lastName', validations.lastName)}
          />
          <Input
            error={errors?.postalCode?.message}
            label="Zip Code"
            required
            {...register('postalCode', validations.zip)}
          />
          <Input
            error={errors?.email?.message}
            label="email"
            readOnly={from === SignUpContextEnum.ClaimInvitationLink}
            {...register('email', validations.email)}
            required
          />
          <Input
            error={errors?.password?.message}
            label="password"
            type="password"
            required
            {...register('password', validations.password)}
          />
          <Input
            error={errors?.password?.message}
            label="password confirmation"
            type="password"
            required
            {...register('passwordConfirmation', validations.password)}
          />
          {pwMismatchError && (
            <AlertText
              className="text-sm italic text-red"
              icon={MdErrorOutline}
            >
              Passwords do not match.
            </AlertText>
          )}
        </div>
        <div className="flow-y-xs">
          <GoTermsAgreement />
          <div className={clsx(as === 'page' ? 'w-full' : 'flex justify-end')}>
            <ClickyBtn
              className="mood-orange-medium-inverted"
              disabled={isLoading}
              type="submit"
            >
              Sign Up
            </ClickyBtn>
          </div>

          {from !== SignUpContextEnum.ClaimInvitationLink &&
            as !== 'dialog' && <GoSignIn />}
        </div>
        {serverError != null && <QueryErrorFallback error={serverError} />}
      </form>
    </div>
  )
}

export default React.memo(SignUpForm)
