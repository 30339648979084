import { useCallback, useState } from 'react'

export type useCountType = {
  readonly len: number
  readonly max: number
  readonly min?: number
}

/**
 * Return meta information around a character count.
 *
 * - Supplying a min/max will return a string message when the count is below
 * or above the restrictions
 * - Used by `InputCounter` and `TextareaCounter`
 *
 * @example
 * const { handleChange, count } = useCount({ len, max })
 */
export default function useCount({ len, max, min }: useCountType) {
  const [cur, setCur] = useState(len)
  const percent = cur / max
  const count = percent > 0.8 ? `${cur}/${max}` : undefined
  const underMin =
    min != null && cur < min
      ? `Must submit at least ${min} characters.`
      : undefined

  const overMax =
    max != null && cur > max
      ? `Must submit under ${max} characters.`
      : undefined

  const handleChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCur(e.currentTarget.value.length)
    },
    [],
  )

  return { count, handleChange, overMax, underMin }
}
