import { QueryClient } from '@tanstack/react-query'
import Router from 'next/router'
import { setHeader } from '../../headers'
import { clearSessionRedirect, getSessionRedirect } from '../../storage'
import { getDerived as getDerivedCompany } from '../company/useCompany'
import { getDerived as getDerivedMember } from '../member/useMember'
import { useViewerQuery, Viewer_UserFragment } from '../types'

/**
 * @private
 * Retrieve the stringified `Viewer` query key used in the internal cache.
 */
const getKey = useViewerQuery.getKey

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateViewer(queryClient)
 */
function invalidateViewer(queryClient: QueryClient) {
  queryClient.invalidateQueries(getKey())
}

function useViewer() {
  const query = useViewerQuery(undefined, {
    // TODO: Once invalidations are in place for any affecting queries
    // staleTime: Infinity,
    staleTime: 10000,
    // refetchOnMount: false,
    select: ({ currentUser: user }) =>
      user != null ? select(user) : undefined,
    useErrorBoundary: false,
  })
  const { data: viewer, ...rest } = query
  const authenticating = rest.isLoading
  const authenticated = !authenticating && viewer != null
  return { authenticated, authenticating, viewer, ...rest }
}

// -------------------------------------

const select = (user: Viewer_UserFragment) => {
  const nameOrId =
    user.client?.displayname ||
    user.client?.id ||
    user.username ||
    user.id ||
    '#'

  const isCompany = !!user.client?.id
  const isMember = user && !isCompany

  const companyWithDerived = {
    client: user.client
      ? { ...user.client, ...getDerivedCompany(user.client) }
      : user.client,
  }

  return {
    ...user,
    ...getDerivedMember(user),
    ...companyWithDerived,
    isCompany,
    isMember,
    nameOrId: nameOrId,
  }
}

export type ViewerType = ReturnType<typeof select>

function initializeViewer(viewer: Viewer_UserFragment, skipRedirect?: boolean) {
  if (typeof window === 'undefined') return
  if (viewer?.jwt != null) setHeader('access', viewer.jwt)
  const stored = getSessionRedirect()

  if (!skipRedirect) {
    const redirect = stored || '/dashboard'
    clearSessionRedirect()
    Router.query?.redirect
      ? Router.replace(Router.query.redirect as string)
      : Router.replace(`${globalThis.location?.origin || '/'}${redirect}`)
  }
}

export { initializeViewer, invalidateViewer }
export default useViewer
