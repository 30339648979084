import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  formatDistanceToNowStrict,
  parse,
} from 'date-fns'
import formatISO from 'date-fns/formatISO'
import dateIsThisYear from 'date-fns/isThisYear'
import dateIsToday from 'date-fns/isToday'
import { MONTH_NAMES } from './constants/dates'

// TODO started with this in en.ts but it wasn't having it
const dateLocales = (count: number) => ({
  xSeconds: 'now',
  halfAMinute: '30s',
  xMinutes: `${count}m`,
  xHours: `${count}h`,
  xDays: `${count}d`,
  xWeeks: `${count}w`,
  xMonths: `${count}mo`,
  xYears: `${count}y`,
})

const locale = {
  formatDistance: (token: string, payload: number) => {
    return dateLocales(payload)[token as keyof typeof dateLocales]
  },
}

export const abbreviatedDateToNow = (date: Date) => {
  return formatDistanceToNowStrict(new Date(date), { locale })
}

export const isToday = (date: Date) => dateIsToday(new Date(date))
export const isThisYear = (date: Date) => dateIsThisYear(new Date(date))

const isTest = process.env.NODE_ENV === 'test'

const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
} as const

/**
 * Returns either the distance in time (ie: '5m ago, 'in 5m')
 * or a timestamp in the format 'MMM dd, yy' (of the second argument) if more than 7 days prior
 *
 * In the pseudocode example getDynamicTimestamp(June 28, June 27)
 * It's like saying "From June 28, when was June 27?" (you'd expect "1 day ago")
 * the difference in days will be a negative number (27 - 28 = -1) and the function will indicate past
 *
 * @param time1 - The date you are starting from
 * @param time2 - The date from whence (🤓) you'd like the know the difference
 * @returns the date formatted in words as a string
 *
 * Example:
 * const time1 = new Date('2023-06-22T17:27:41.526Z')
 * const time2 = new Date('2023-06-22T17:28:18.687Z')
 * getDynamicTimestamp(time1, time2)
 * @returns: '37s ago'
 *
 *
 */
export const getDynamicTimestamp = (time1: Date, time2: Date) => {
  const seconds = differenceInSeconds(time2, time1)
  const minutes = differenceInMinutes(time2, time1)
  const hours = differenceInHours(time2, time1)
  const days = differenceInDays(time2, time1)

  const deets =
    Math.abs(seconds) <= 59
      ? {
          word: 's',
          num: seconds,
          direction: seconds < 0 ? 'past' : seconds > 0 ? 'future' : null,
        }
      : Math.abs(minutes) <= 59
      ? {
          word: 'm',
          num: minutes,
          direction: minutes < 0 ? 'past' : minutes > 0 ? 'future' : null,
        }
      : Math.abs(hours) <= 24
      ? {
          word: 'h',
          num: hours,
          direction: hours < 0 ? 'past' : hours > 0 ? 'future' : null,
        }
      : Math.abs(days) <= 7
      ? {
          word: 'd',
          num: days,
          direction: days < 0 ? 'past' : days > 0 ? 'future' : null,
        }
      : null

  if (deets != null)
    return `${
      deets?.direction != null && deets?.direction === 'future' ? 'in ' : ''
    }${Math.abs(deets.num)}${deets.word}${
      deets?.direction != null && deets?.direction === 'past' ? ' ago' : ''
    }`

  return formatReadableDateWords(new Date(time2), 'MMM dd, yyyy')
}

// Incoming date must be in the format "2021-01-06T22:00:00+00:00"
export type DateTimeType = {
  date: string
  time: string
}
export const dateAndTimeToWords = (dateAndTime: string) => {
  const date = new Date(dateAndTime).toLocaleDateString('default', options)
  const time = new Date(dateAndTime).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })
  return {
    date: isTest ? 'Thursday, December 17, 2020' : date,
    time: isTest ? '10:00 AM' : time,
  }
}

// Incoming date must be in format 'YYYY-MM-DD'
export const dateToWords = (date: string) => {
  if (!date) return
  const datified = parse(date, 'yyyy-MM-dd', new Date())
  return format(datified, 'MMM dd, yyyy')
}

// Incoming ds argument here is a DateTime format like 2007-12-03T10:15:30Z
export const getMonth = (ds: Date) => {
  return format(new Date(ds), 'LL')
}

export const getTime = (ds: Date) => {
  return format(new Date(ds), 'p')
}
// Incoming ds argument here is a DateTime format like 2007-12-03T10:15:30Z
export const getYear = (ds: Date) => format(new Date(ds), 'yyyy')

/**
 * Converts an ISO type string or Date object to a range of common formats
 * Incoming values should be strings like 2007-12-03T10:15:30Z or a Date object
 *
 * The returned values match the date-fns patterns
 * MMMM: January, February, March...
 * yyyy: 2020, 2021, 2022...
 * words: Dec 03, 2007
 *
 * @see {@link https://date-fns.org/docs/format|date-fns/format}
 * @TODO: Add isToday fallback for any of these?
 */
export const fromISO = (date: Date | string) => {
  const d = typeof date === 'string' ? new Date(date) : date
  return {
    MMMM: format(d, 'MMMM'),
    MMddyy: format(d, 'MM/dd/yy'),
    words: isTest ? 'Dec 03, 2007' : format(d, 'MMM dd, yyyy'),
    yyyy: format(d, 'yyyy'),
  }
}

/**
 * Converts string values into an ISO DateTime format like 2007-12-03T10:15:30Z
 * Incoming values should be strings from the form, like 'January' and '2020'
 *
 * @see {@link https://date-fns.org/docs/formatISO|date-fns/formatISO}
 */
export const toISO = ({ month, year }: { month: string; year: string }) => {
  const m = (MONTH_NAMES as string[]).indexOf(month)
  return formatISO(new Date(Number(year), m))
}

/**
 * Returns a formatted date in words depending on options provided, ie: October 2007
 *
 * @param {Date} date - The date to format, as a Date object (new Date())
 * @param {string} opts? - The string of options to format the words
 * @returns 'October, 2007' (unless other options are provided)
 *
 * @see {@link https://date-fns.org/v2.29.3/docs/format}
 *
 * example:
 * const now = new Date()
 * const opts = 'MMM dd, yyyy'
 * formatReadableDateWords(now, opts)
 * @returns 'Jun 22, 2023'
 */
export const formatReadableDateWords = (date: Date, opts?: string) => {
  const options = opts != null ? opts : 'MMMM yyyy'
  return `${format(date, options)}`
}

/**
 * Returns date formatted like MM/YYYY
 * Expecting a DateTime format like 2007-12-03T10:15:30Z
 */
export const formatReadableDate = (date: Date) => {
  return `${getMonth(date)}/${getYear(date)}`
}
export { formatISO }
