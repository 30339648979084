import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { invalidateMemberConnections } from '../member/useMemberConnections'
import { invalidateNodes } from '../nodes/useNodes'
import {
  ConnectionStatusEnum,
  useFollowCompanyMutation as useFollowCompany,
  useUnfollowCompanyMutation as useUnfollowCompany,
} from '../types'
import {
  CompanyFragment,
  invalidateCompany,
  optimisticUpdateCompany,
} from './useCompany'
import { invalidateCompanyFollowers } from './useCompanyFollowers'

/**
 * Follow or Unfollow a `Company`.
 *
 * When overriding `onMutate` or `onSettled` be sure to provide necessary invalidations.
 *
 * @example
 * const { followCompany, unfollowCompany } = useFollow(company)
 * const onClickFollow = () => followCompany()
 * const onClickUnfollow = () => unfollowCompany()
 *
 * @TODO Return the whole mutation instead of just the mutation function
 * @TODO Tune invalidations
 */
function useFollow(company?: CompanyFragment) {
  const queryClient = useQueryClient()
  const { Accepted, DisconnectedReceived, DisconnectedSent, Unconnected } =
    ConnectionStatusEnum

  const isUnconnected =
    company?.connectionStatus === Unconnected ||
    company?.connectionStatus === DisconnectedSent ||
    company?.connectionStatus === DisconnectedReceived
  const isAccepted = company?.connectionStatus === Accepted

  const options = useCallback(
    (cs: ConnectionStatusEnum) => {
      const data = { connectionStatus: cs }
      return {
        onMutate: () => {
          if (company) {
            const { displayname } = company
            optimisticUpdateCompany(queryClient, { displayname }, data)
          }
        },
        onSettled: () => {
          if (company) {
            const { displayname } = company
            invalidateCompany(queryClient)
            invalidateCompanyFollowers(queryClient, { displayname })
          }
          invalidateNodes(queryClient)
          invalidateMemberConnections(queryClient)
        },
      }
    },
    [company, queryClient],
  )

  const { mutate: follow } = useFollowCompany(
    options(ConnectionStatusEnum.Accepted),
  )
  const { mutate: unfollow } = useUnfollowCompany(
    options(ConnectionStatusEnum.Unconnected),
  )

  const changeMutation = useCallback(async () => {
    company == null
      ? undefined
      : isAccepted
      ? unfollow({ input: { displayname: company.displayname } })
      : follow({ input: { displayname: company.displayname } })
  }, [company, follow, isAccepted, unfollow])

  return {
    followCompany: isUnconnected ? changeMutation : undefined,
    isFollowing: isAccepted,
    unfollowCompany: isAccepted ? changeMutation : undefined,
  }
}

export default useFollow
