import { Popover } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import { IconType } from 'react-icons'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly classNamePanel?: string
  readonly icon?: IconType
  readonly label: string
}

function ButtonPopover(props: PropsType) {
  const { children, className, classNamePanel, icon, label, ...rest } = props
  const Icon = icon
  return (
    <Popover {...rest}>
      <Popover.Button
        className={clsx(
          'group/popoverbutton',
          'flex items-center justify-center',
          className,
        )}
        title={label}
      >
        <span className="sr-only">{label}</span>
        {Icon != null ? <Icon /> : label}
      </Popover.Button>
      <Popover.Panel className={classNamePanel}>{children}</Popover.Panel>
    </Popover>
  )
}

export default ButtonPopover
