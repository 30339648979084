type PropsType = React.SVGAttributes<SVGElement> & {
  readonly size?: string | number
}

export default function SocialTwitter(props: PropsType) {
  const { size = '2em' } = props
  return (
    <svg height={size} width={size} viewBox="0 0 32 32" {...props}>
      <g fill="currentColor" fillRule="evenodd" stroke="none" strokeWidth="0">
        <path d="M22.2325316,13.4657323 C22.2381369,13.6054339 22.2409396,13.7470106 22.2409396,13.889525 C22.2409396,18.2212116 18.9571616,23.2139023 12.9501367,23.2139023 C11.1069236,23.2139023 9.38983003,22.6710351 7.94459403,21.741879 C8.19963568,21.7728196 8.46028264,21.7868835 8.72373225,21.7868835 C10.2539821,21.7868835 11.6618494,21.2637058 12.7791747,20.3842421 C11.3507546,20.3589271 10.1446786,19.411019 9.72988556,18.1096379 C9.92793988,18.1480792 10.133468,18.1677687 10.3427329,18.1677687 C10.6416828,18.1677687 10.9294221,18.1293273 11.2040823,18.0524446 C9.70933276,17.7514768 8.5845337,16.4275933 8.5845337,14.8383706 L8.5845337,14.7980541 C9.02455061,15.0427662 9.52809437,15.1909062 10.0643358,15.2077829 C9.18803884,14.6189735 8.61069181,13.6157474 8.61069181,12.4803205 C8.61069181,11.8793224 8.77231161,11.3167657 9.05351138,10.8310917 C10.6650383,12.8150417 13.0697167,14.1182981 15.7836214,14.2551869 C15.7275683,14.0161003 15.6995417,13.7667001 15.6995417,13.5088616 C15.6995417,11.7002418 17.1615936,10.2319688 18.9637011,10.2319688 C19.9035249,10.2319688 20.7536637,10.6304465 21.3478267,11.2670732 C22.0923988,11.1208084 22.79026,10.8479684 23.4208575,10.4729306 C23.1788949,11.2380077 22.6604037,11.8793224 21.9858979,12.2853008 C22.6463904,12.2056053 23.2760537,12.0293375 23.8608744,11.7686862 C23.4245944,12.42594 22.8706028,13.0034982 22.2325316,13.4657323 M15.8901223,0 C7.11407379,0 3.63797881e-13,7.13978216 3.63797881e-13,15.9466073 C3.63797881e-13,24.7543701 7.11407379,31.8932147 15.8901223,31.8932147 C24.6652366,31.8932147 31.7793103,24.7543701 31.7793103,15.9466073 C31.7793103,7.13978216 24.6652366,0 15.8901223,0" />
      </g>
    </svg>
  )
}
