import clsx from '@vangst/lib/clsx'
import React from 'react'

type PropsType = {
  readonly small?: boolean
  readonly label?: string
}

function UnpublishedTag(props: PropsType) {
  const { small, label } = props
  return (
    <span
      className={clsx(
        'inline-flex h-8 items-center rounded bg-red/50 px-2',
        small ? 'text-[12px] uppercase' : 'ml-2',
      )}
      title="Pending approval of your account."
    >
      {label || 'Unpublished'}
    </span>
  )
}

export default React.memo(UnpublishedTag)
