import { UserPromptIdentifierEnum } from '@vangst/services/oogst/types'
import useCreateUserPromptResponse from '@vangst/services/oogst/viewer/useCreateUserPromptResponse'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import React, { useCallback, useRef, useState } from 'react'
import { MdFavoriteBorder } from 'react-icons/md'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import QueryErrorFallback from '../../application/feedback/QueryErrorFallback'

function DialogIndispensableSurvey() {
  const { viewer } = useViewer()
  const [showModal, setShowModal] = useState(true)
  const { mutate, isLoading, error } = useCreateUserPromptResponse()
  const focusRef = useRef<HTMLAnchorElement>(null)

  const handleCancel = useCallback(() => {
    mutate({
      input: {
        identifier: UserPromptIdentifierEnum.IndispensableSurvey,
        response: false,
      },
    })
    setShowModal(false)
  }, [mutate])

  const handleConfirm = useCallback(() => {
    mutate({
      input: {
        identifier: UserPromptIdentifierEnum.IndispensableSurvey,
        response: true,
      },
    })
    setShowModal(false)
  }, [mutate])

  return (
    <DialogModal
      initialFocus={focusRef}
      onClose={handleCancel}
      open={showModal}
    >
      <DialogModal.Panel icon={MdFavoriteBorder} title="Feedback. We love it.">
        <DialogModal.Description className="flow-y-sm my-8 text-center">
          <span>
            Feedback is the single biggest driver of change for our team at
            Vangst.
          </span>
          <span>
            So c'mon - lay it on thick and don't be shy. Brutal honesty is what
            we're looking for (although no need to be crass about it).
          </span>
          <span>
            <span role="img" aria-label="heart emoji" className="mr-2">
              🧡
            </span>
            Vangst
          </span>
        </DialogModal.Description>
        <div className="flex w-full items-center justify-center gap-4">
          <ClickyBtn
            className="mood-white w-full"
            disabled={isLoading}
            onClick={handleCancel}
          >
            Maybe Later
          </ClickyBtn>
          {viewer?.indispensableSurveyLink != null && (
            <ClickyBtn
              className="mood-orange w-full"
              href={viewer?.indispensableSurveyLink}
              onClick={handleConfirm}
              ref={focusRef}
              rel="noopener noreferrer"
              target="_blank"
            >
              {isLoading ? 'Redirecting' : 'Continue'}
            </ClickyBtn>
          )}
        </div>
        {error != null && <QueryErrorFallback error={error} />}
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default React.memo(DialogIndispensableSurvey)
