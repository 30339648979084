import clsx from '@vangst/lib/clsx'

type PropsType = React.HTMLAttributes<HTMLSpanElement> & {
  border?: boolean
  title?: string
  subText?: string
  children: React.ReactNode
}

export default function ZeroState(props: PropsType) {
  const { border, subText, title, children, ...rest } = props
  return (
    <div
      className={clsx(
        'flow-y-sm py-4',
        border && 'border-b border-b-grey pb-4',
      )}
      {...rest}
    >
      {title && <span className="text-lg text-orange-medium">{title}</span>}
      {children}
    </div>
  )
}
