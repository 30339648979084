import {
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import Routes from '../../routes'
import fetcher from '../fetcher'
import {
  FetcherError,
  MemberConnectionsDocument,
  MemberConnectionsQuery,
  MemberConnectionsQueryVariables as MemberConnectionsVars,
} from '../types'
import { getPageInfoInfinitePagination } from '../wtf/pagination'

type MemberConnectionsOptions = UseInfiniteQueryOptions<
  MemberConnectionsQuery,
  FetcherError,
  MemberConnectionsQuery
>

/**
 * @private
 * Retrieve the stringified `MemberConnectionsInfinite` query key used in the internal cache.
 */
function getKey(variables?: MemberConnectionsVars) {
  const name = 'MemberConnectionsInfinite'
  return variables ? [name, variables] : [name]
}

/**
 * @private
 * Retrieve the `Member.connections` from the queryClient's cache.
function getCache(queryClient: QueryClient, variables?: MemberConnectionsVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<MemberConnectionsQuery>(key)
}
 */

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function. If no
 * variables are passed all `MemberConnectionsInfinite` queries will be
 * invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateMemberConnections(queryClient, { username: 'ted-lasso', first: 4 })
 * invalidateMemberConnections(queryClient)
 */
function invalidateMemberConnections(
  queryClient: QueryClient,
  variables?: MemberConnectionsVars,
) {
  queryClient.invalidateQueries(
    variables ? getKey(variables) : ['MemberConnectionsInfinite'],
  )
}

/**
 * Fetch an infinite query of the paginated `Connections` field on a `Member`.
 *
 * @example
 * const variables = { username: slug, first: 24 }
 * const options = { enabled: authenticated }
 * const { data, isZero, pagination, uri } = useMemberConnections(variables, options)
 *
 * @TODO: Add optimistic Create|Update|Delete?
 * @TODO: Compute data in select?
 * @TODO: Tune options
 * @TODO: Add prefetching once profiles become public
 */

function useMemberConnections(
  variables: MemberConnectionsVars,
  options?: MemberConnectionsOptions,
) {
  const query = useInfiniteQuery<
    MemberConnectionsQuery,
    FetcherError,
    MemberConnectionsQuery
  >(
    getKey(variables),
    ({ pageParam }) =>
      fetcher<MemberConnectionsQuery, MemberConnectionsVars>(
        MemberConnectionsDocument,
        {
          ...variables,
          ...pageParam,
        },
      )(),
    {
      getNextPageParam: (lastPage) => {
        const edges = lastPage.getUser.connections.edges
        const after = edges ? edges[edges.length - 1]?.cursor : undefined
        return { after }
      },
      ...options,
    },
  )

  const pages = query?.data?.pages
  const pageInfo = getPageInfoInfinitePagination(
    pages?.[0]?.getUser.connectionCount || 0,
    pages?.map((p) => p.getUser.connections.pageInfo) || [],
    variables?.first || variables?.last || 24,
  )

  const nextPage = pageInfo?.nextHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchNextPage()
      }
    : undefined

  const prevPage = pageInfo?.prevHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchPreviousPage()
      }
    : undefined

  const pagination = { ...pageInfo, nextPage, prevPage }
  const isZero = query.data && pagination?.totalCount === 0
  const uri = Routes.MEMBERS_CONNECTIONS.replace(
    ':username',
    variables.username,
  )
  return { ...query, isZero, pagination, uri }
}

// -------------------------------------

export type { MemberConnectionsOptions, MemberConnectionsVars }
export { invalidateMemberConnections }
export default useMemberConnections
