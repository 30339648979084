import { forwardRef } from 'react'

type PropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  readonly reference?: React.Ref<HTMLInputElement>
}

/**
 * A generic input that is `hidden` and set to `readonly`
 *
 * @example
 * <HiddenInput name="hidden-input" />
 */

const HiddenInput = forwardRef<HTMLInputElement, PropsType>(
  function HiddenInput(props, ref) {
    const { reference, ...rest } = props
    return (
      <input
        hidden
        readOnly
        className="hidden"
        ref={ref || reference}
        {...rest}
      />
    )
  },
)

export default HiddenInput
