import clsx from '@vangst/lib/clsx'
import { JobPostingComputed } from '@vangst/services/oogst/jobPosting/useJobPosting'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly jobPosting?: JobPostingComputed
  readonly title: string
  readonly uri?: string
}

function JobPostingAvatar(props: PropsType) {
  const { className, jobPosting, title, uri } = props
  const loading = jobPosting == null ? '•••' : undefined
  const alt = loading ?? jobPosting?.client?.initials ?? ''

  if (uri) {
    return (
      <ClickyLink
        className={clsx('flex size-12 ease-bounce hocus:scale-101', className)}
        href={uri}
        title={title}
      >
        <Avatar
          alt={alt}
          className="mood-grey min-w-12 shrink-0"
          src={jobPosting?.client?.avatarUrl ?? undefined}
          role="presentation"
        />
      </ClickyLink>
    )
  }

  return (
    <Avatar
      alt={alt}
      className={clsx('mood-grey min-w-12', className)}
      src={jobPosting?.client?.avatarUrl ?? undefined}
      title={title}
      role="presentation"
    />
  )
}

export default JobPostingAvatar
