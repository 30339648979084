import useCompanyCreateUser from '@vangst/services/oogst/company/useCompanyCreateUser'
import {
  CreateCompanyUserInput,
  MemberFragment,
} from '@vangst/services/oogst/types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import AlertText from '../../../components/feedback/AlertText'
import Checkbox from '../../../components/forms/Checkbox'
import ControlGroup from '../../../components/forms/ControlGroup'
import Input from '../../../components/forms/Input'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import validations from '../../application/validations'

export const defValues = {
  clientDisplayname: '',
  confirmation: null,
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
}

type PropsType = {
  readonly defaultValues?: FormInput
  readonly displayname: string
  readonly handleCancel: () => void
  readonly setMember: (member?: MemberFragment) => void
}

type FormInput = CreateCompanyUserInput & { confirmation: boolean | null }
function FormAddUserToCompany(props: PropsType) {
  const {
    defaultValues = defValues,
    displayname,
    handleCancel,
    setMember,
  } = props
  const [serverError, setServerError] = useState<string>()

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<FormInput>({
    defaultValues,
  })

  const watchedConfirmation = watch('confirmation')
  const { mutate, isLoading } = useCompanyCreateUser({
    onError: (error) => setServerError(error.message),
    onSettled: (data) => setMember(data?.createCompanyUser as MemberFragment),
  })

  const submit = React.useCallback(
    (fd: FormInput) => {
      const input = {
        clientDisplayname: displayname,
        email: fd.email,
        firstName: fd.firstName,
        lastName: fd.lastName,
        phoneNumber: fd.phoneNumber,
      }
      mutate({ input })
    },
    [displayname, mutate],
  )

  const send = React.useMemo(() => handleSubmit(submit), [handleSubmit, submit])

  return (
    <form className="flow-y-sm w-full" id="add-user-to-company" onSubmit={send}>
      <div className="flow-y-xs">
        <Input
          error={errors?.firstName?.message}
          label="First Name"
          required
          {...register('firstName', validations.firstName)}
        />
        <Input
          error={errors?.lastName?.message}
          label="Last Name"
          required
          {...register('lastName', validations.lastName)}
        />
        <Input
          error={errors?.email?.message}
          label="email"
          {...register('email', validations.email)}
          required
        />
        <Input
          error={errors?.phoneNumber?.message}
          label="phone"
          {...register('phoneNumber', validations.phone)}
          required
        />
        <ControlGroup
          label="Adding this user to the company will allow that user to update this company's profile."
          type="checkbox"
          required
        >
          <Checkbox
            label="I Understand"
            {...register('confirmation', validations.generic)}
          />
        </ControlGroup>
      </div>

      <div className="flex w-full items-center justify-center gap-4">
        <ClickyBtn
          type="submit"
          className="mood-orange w-full"
          disabled={watchedConfirmation !== true || isLoading}
        >
          Submit
        </ClickyBtn>
        <ClickyBtn type="Cancel" className="w-full" onClick={handleCancel}>
          Cancel
        </ClickyBtn>
      </div>
      {serverError && (
        <AlertText className="text-sm italic">{serverError}</AlertText>
      )}
    </form>
  )
}

export default React.memo(FormAddUserToCompany)
