import { Transforms } from 'slate'
import { BlockFormatType, CustomEditor } from './customTypes'
import { deserializeDoc, deserializeHtml } from './deserializers'

export const withHtml = (editor: CustomEditor) => {
  const { insertData, isInline, isVoid } = editor

  editor.isInline = (element: any) => {
    return element.type === BlockFormatType.link ? true : isInline(element)
  }

  editor.isVoid = (element: any) => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = (data: DataTransfer) => {
    const html = data.getData('text/html')
    const parsed = new DOMParser().parseFromString(html, 'text/html')
    const isDoc =
      // @ts-ignore
      data.types.includes('text/rtf') && !parsed.head.childNodes[3].text
    const isHtml = !data.types.includes('text/rtf')
    if (html && (isDoc || isHtml)) {
      if (isDoc) {
        const fragment: any = deserializeDoc(parsed.body)
        Transforms.insertFragment(editor, fragment)
      }
      if (isHtml) {
        const fragment: any = deserializeHtml(parsed.body)
        Transforms.insertFragment(editor, fragment)
      }
      return
    }
    insertData(data)
  }

  return editor
}
