import { useQueryClient } from '@tanstack/react-query'
import { invalidateCompanyJobApplication } from '@vangst/services/oogst/company/useCompanyJobApplication'
import { invalidateJobPosting } from '@vangst/services/oogst/jobPosting/useJobPosting'
import { invalidateMember } from '@vangst/services/oogst/member/useMember'
import { invalidateNodes } from '@vangst/services/oogst/nodes/useNodes'
import {
  JobPostingQueryVariables,
  MemberQueryVariables,
  useCreateActivityMutation,
} from '@vangst/services/oogst/types'
import {
  ActivitiesAlertsEndpoints,
  ActivitiesAlertsQueryVariables,
  invalidateActivitiesAlerts,
} from '@vangst/services/oogst/wtf/useActivitiesAlerts'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { extractMentions, isEmptyOrNilEditor } from '../editor/serializers'

type PropsType = {
  readonly endpoint: ActivitiesAlertsEndpoints
  readonly relayId: string
  readonly handleCancel: () => void
  readonly variables: ActivitiesAlertsQueryVariables
}

function useCreateActivity(props: PropsType) {
  const { endpoint, variables, relayId, handleCancel } = props
  const queryClient = useQueryClient()

  const methods = useForm<{ notes: string }>({ defaultValues: { notes: '' } })
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError: setFormError,
  } = methods

  const { mutate } = useCreateActivityMutation({
    onError: (error) => {
      setFormError('notes', { type: 'custom', message: error.message })
    },
    onSettled: (data) => {
      invalidateNodes(queryClient)
      invalidateActivitiesAlerts(queryClient, endpoint, variables)
      if (data?.createActivity?.resource?.__typename === 'User') {
        invalidateMember(queryClient, {
          username: data?.createActivity?.resource?.username,
        } as MemberQueryVariables)
      }
      if (data?.createActivity?.resource?.__typename === 'JobPosting') {
        invalidateJobPosting(queryClient, {
          id: data?.createActivity?.resource?.id,
        } as JobPostingQueryVariables)
      }
      if (data?.createActivity?.resource?.__typename === 'JobApplication') {
        invalidateCompanyJobApplication(queryClient, {
          id: data?.createActivity?.resource?.id,
        })
      }
    },
  })

  const submitActivity = useCallback(
    (fd: { notes: string }) => {
      const input = {
        jsonBody: fd.notes,
        resourceId: relayId ?? '',
        contextId: relayId ?? '',
        mentionedIds: extractMentions(JSON.stringify(fd.notes)) || [],
      }
      if (isEmptyOrNilEditor(fd.notes)) {
        setFormError('notes', {
          type: 'custom',
          message: 'Note must be filled in.',
        })
        return
      }
      mutate({ input })
      handleCancel()
    },
    [handleCancel, relayId, mutate, setFormError],
  )

  return { control, handleSubmit, errors, submitActivity }
}

export default useCreateActivity
