import clsx from '@vangst/lib/clsx'
import ClickyLink, {
  ClickyType,
} from '../../../components/navigation/ClickyLink'

/**
 * Tertiary text links used in a `Disclosure.Panel`
 *
 * @example
 * <NavbarClickyTextLink>About Us</NavbarClickyTextLink>
 * <NavbarClickyTextLink>Contact Us</NavbarClickyTextLink>
 * <NavbarClickyTextLink>Help</NavbarClickyTextLink>
 */
export default function NavbarClickyTextLink(props: ClickyType) {
  const { className, ...rest } = props
  return (
    <ClickyLink
      activate
      className={clsx(
        'link-grey-dark flex justify-between px-4 py-2 text-[0.75rem] font-400 uppercase underline',
        className,
      )}
      {...rest}
    />
  )
}
