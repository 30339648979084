import React from 'react'
import { MdDelete } from 'react-icons/md'
import useDeletePlacement from '../../../../../packages/services/oogst/jobPosting/useDeletePlacement'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyBtn from '../../../components/navigation/ClickyBtn'

type PropsType = {
  readonly placementId: string
  readonly handleCancel: () => void
}

function DialogDeletePlacement({ placementId, handleCancel }: PropsType) {
  const { mutate } = useDeletePlacement({ onSuccess: () => handleCancel() })

  const submit = () => {
    mutate({ input: { id: placementId } })
  }
  return (
    <DialogModal onClose={handleCancel} open>
      <DialogModal.Panel icon={MdDelete} title="Delete Placement?">
        <span className="space-y-10 text-lg">
          Are you sure you want to delete this placement?
        </span>
        <div className="flex space-x-4">
          <ClickyBtn className="mood-orange w-40" onClick={submit}>
            Delete
          </ClickyBtn>
          <ClickyBtn className="w-40" onClick={handleCancel}>
            Cancel
          </ClickyBtn>
        </div>
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default React.memo(DialogDeletePlacement)
