import titleize from '@vangst/lib/titleize'
import { JobPostingComputed } from '@vangst/services/oogst/jobPosting/useJobPosting'
import { PlacementStatusEnum } from '@vangst/services/oogst/types'
import ClickyLink from '../../../components/navigation/ClickyLink'
import { TableBodyDetailList } from '../../application/TableRow'

export default function TableRowPlacements({
  jobPosting,
}: {
  jobPosting: JobPostingComputed
}) {
  const numPlaced =
    jobPosting?.placementStatusCounts?.find(
      (p) => p.status === PlacementStatusEnum.Active,
    )?.count ?? 0
  const totalPlacements = jobPosting?.quantity

  const counts = jobPosting?.placementStatusCounts ?? []
  const stats = [
    ...counts.map((status) => ({
      title: titleize(status?.status || '')
        .split('_')
        .join(' '),
      value: status.count,
    })),
    {
      title: 'All Placements',
      value: (
        <ClickyLink
          className="link-orange"
          href={jobPosting?.routes?.jobsPlacement}
        >
          View All Placements
        </ClickyLink>
      ),
    },
  ]
  return (
    <>
      <span className="text-sm font-600 underline">
        Roles Filled{' '}
        <span className="relative -top-1 text-[12px]">
          ({numPlaced}/{totalPlacements || '0'})
        </span>
      </span>
      <div className="grid gap-2 gap-y-4 sm:grid-cols-6">
        {stats.map((stat) => (
          <TableBodyDetailList key={stat.title} title={stat.title}>
            <span title={stat.title} className="text-[12px]">
              {stat.value}
            </span>
          </TableBodyDetailList>
        ))}
      </div>
    </>
  )
}
