import { JobPostingComputed } from '@vangst/services/oogst/jobPosting/useJobPosting'
import { useRouter } from 'next/router'
import TableRowDetails from '../../application/TableRow'
import JobPostingTableRowBody from './JobPostingTableRowBody'
import JobPostingTableRowSummary from './JobPostingTableRowSummary'

type PropsType = React.HTMLAttributes<HTMLDetailsElement> & {
  readonly jobPosting?: JobPostingComputed
  readonly title: string
  readonly uri: string
}

export function randomizer(items: number[]) {
  const num = items[Math.floor(Math.random() * items.length)]
  return num
}

function JobPostingTableRow(props: PropsType) {
  const { jobPosting, title, uri } = props
  const { query } = useRouter()
  const initialOpen = query?.open != null && query?.open === jobPosting?.title
  return (
    <TableRowDetails initialOpen={initialOpen}>
      <JobPostingTableRowSummary
        jobPosting={jobPosting}
        title={title}
        uri={uri}
      />
      <JobPostingTableRowBody jobPosting={jobPosting} title={title} uri={uri} />
    </TableRowDetails>
  )
}

export default JobPostingTableRow
