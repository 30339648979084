import isUrl from 'is-url'
function stripHost(val: string) {
  if (!isUrl(val)) {
    return val
  }
  const url = new URL(val)
  return url.pathname
}

export default stripHost
