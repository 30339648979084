import escapeHtml from 'escape-html'
import HTMLReactParser from 'html-react-parser'
import { Node, Text } from 'slate'
import { BlockFormatType, CustomLeafElement } from './customTypes'

export const isEmptyOrNilEditor = (editorValue: any) => {
  return (
    serializeJsonToText(editorValue).trim() === '' ||
    editorValue == null ||
    editorValue === ''
  )
}

export const serializeJsonToHtml = (node: any) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if ((node as CustomLeafElement).strong) {
      string = `<strong>${string}</strong>`
    }
    if ((node as CustomLeafElement).emphasis) {
      string = `<em>${string}</em>`
    }
    if ((node as CustomLeafElement).header) {
      string = `<strong className="text-lg">${string}</strong>`
    }
    return string
  }

  const data = node.children ? node.children : node
  const parsed = typeof data === 'string' ? tryToParse(data) : data
  const children = parsed?.[0]
    ? parsed.map((n: any) => serializeJsonToHtml(n)).join('')
    : '<span>(No Information)</span>'

  switch (node.type) {
    case 'blockquote':
      return `<blockquote class="pl-4 my-4 text-grey border-l-2"><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p class="my-2">${children}</p>`
    case 'link':
      return `<a class="link-orange underline" href="${escapeHtml(
        node.url,
      )}" target="_blank" rel="noopener noreferrer">${children}</a>`
    case 'mention':
      return `<a class="link link-orange underline" href="${escapeHtml(
        node.url,
      )}" target="_blank" rel="noopener noreferrer">${children}</a>`
    case 'list':
    case 'unorderedList':
      return `<ul class="my-4 ml-6 list-disc">${children}</ul>`
    case 'orderedList':
      return `<ol class="my-4 ml-6 list-decimal">${children}</ol>`
    case 'listItem':
      return `<li>${children}</li>`
    default:
      return children
  }
}

export const serializeJsonToReact = (val: string) => {
  // handling some possible weird backend data from parsing ingested jobs here
  if (val === 'null') {
    return '(No Information)'
  }
  const parsed = typeof val === 'string' ? tryToParse(val) : val
  const html = serializeJsonToHtml(parsed)
  return HTMLReactParser(html)
}

export const serializeJsonToText = (nodes: any[]) => {
  const string = nodes.map((n) => Node.string(n)).join('\n')
  return string
}

export const serializeSlateForForm = (val: string) => {
  return val ? tryToParse(val) : ''
}

export const tryToParse = (val: string) => {
  try {
    return JSON.parse(val.replaceAll('=>', ':'))
  } catch (e) {
    return [
      {
        type: BlockFormatType.paragraph,
        children: [
          {
            text: '(No Information)',
          },
        ],
      },
    ]
  }
}

export const extractMentions = (node: string) => {
  const result: any = []
  node.split('"type":"mention","id":"').map((text, i) => {
    if (i > 0) {
      result.push(text.split('",')[0])
    }
  })
  return result
}
