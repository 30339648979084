import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import {
  FetcherError,
  UpdateMemberUserMutation,
  UpdateMemberUserMutationVariables,
  useUpdateMemberUserMutation,
} from '../types'
import { invalidateViewer } from '../viewer/useViewer'
import { invalidateMember } from './useMember'
import { invalidateMemberEdit } from './useMemberEdit'

type UpdateMemberUserOptions = UseMutationOptions<
  UpdateMemberUserMutation,
  FetcherError,
  UpdateMemberUserMutationVariables
>

/**
 * Updates an existing `User.Member`.
 *
 * When overriding `onSettled` be sure to provide necessary invalidations.
 *
 * @example
 * const updateMemberUser = useUpdateMemberUser({onSuccess: () => push(url)})
 * const input = {firstName: 'Coach'', phoneNumber: '716-867-5309'}
 * const updateMemberUser.mutate({ input })
 *
 * @TODO Invalidate searchMembers?
 * @TODO Call optimistic Updates?
 * @TODO Tune options
 */
function useUpdateMemberUser(options?: UpdateMemberUserOptions) {
  const queryClient = useQueryClient()
  return useUpdateMemberUserMutation({
    onSettled: (data: any) => {
      const username = data?.updateUser?.username
      if (username) {
        invalidateMember(queryClient, { username })
      }
      invalidateMemberEdit(queryClient)
      invalidateViewer(queryClient)
    },
    ...options,
  })
}

export default useUpdateMemberUser
