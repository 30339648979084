import {
  CompanyComputed,
  CompanyOptions,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import { useRouter } from 'next/router'
import TableRowDetails from '../../application/TableRow'
import CompanyTableRowBody from './CompanyTableRowBody'
import CompanyTableRowSummary from './CompanyTableRowSummary'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly company?: CompanyComputed
  readonly options?: CompanyOptions
  readonly uri: string
  readonly variables: CompanyVars
}

function CompanyTableRow(props: PropsType) {
  const { company, options, uri, variables } = props
  const { query } = useRouter()
  const initialOpen =
    query?.open != null && query?.open === company?.displayname

  return (
    <TableRowDetails initialOpen={initialOpen}>
      <CompanyTableRowSummary
        uri={uri}
        company={company}
        variables={variables}
        options={options}
      />

      <CompanyTableRowBody company={company} />
    </TableRowDetails>
  )
}

export default CompanyTableRow
