import clsx from '@vangst/lib/clsx'
import useConnect from '@vangst/services/oogst/member/useConnect'
import useMember, {
  MemberFragment,
  MemberOptions,
  MemberVars,
} from '@vangst/services/oogst/member/useMember'
import { Viewer_UserFragment } from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { useRouter } from 'next/router'
import { memo, useCallback, useState } from 'react'
import { MdErrorOutline, MdOutlineMessage } from 'react-icons/md'
import Busy from '../../components/feedback/Busy'
import { DialogModal } from '../../components/feedback/DialogModal'
import ClickyBtn from '../../components/navigation/ClickyBtn'
import ClickyLink from '../../components/navigation/ClickyLink'
import useMessages from '../messaging/useMessages'

type CustomType = {
  readonly at: 'detail' | 'menu'
  readonly className?: string
  readonly variables: MemberVars
  readonly options?: MemberOptions
}

type PropsType = React.HTMLAttributes<HTMLElement> & CustomType

// TODO: This is NOT dry but at least its all in one place now
function ConnectWrapper(props: PropsType) {
  const { at, className, variables, options } = props
  const { viewer } = useViewer()
  const { data } = useMember(variables, options)

  const canConnect =
    data?.getUser != null &&
    (viewer?.permissions?.updateConnectionStatus || viewer?.isCompany)

  // const viewerIsRecruiter = viewer?.permissions?.hasRecruiterLabel

  if (data?.getUser != null && viewer != null && canConnect) {
    if (at === 'menu') {
      return <MenuConnect className={className} member={data.getUser} />
    }

    return <Connect member={data.getUser} viewer={viewer} />
  }

  return <Busy />
}

function MenuConnect({
  className,
  member,
}: {
  className?: string
  member: MemberFragment
}) {
  const [disconnectModal, setDisconnectModal] = useState(false)
  const {
    acceptMemberConnection,
    connectWithMember,
    declineMemberConnection,
    disconnectWithMember,
    isPending,
  } = useConnect(member)

  return (
    <>
      {isPending === true && (
        <span className={clsx(className, 'pointer-events-none text-grey-mid')}>
          Connection Pending
        </span>
      )}
      {!isPending && connectWithMember != null && (
        <button className={className} onClick={() => connectWithMember()}>
          Connect
        </button>
      )}
      {disconnectWithMember && (
        <button className={className} onClick={() => setDisconnectModal(true)}>
          Disconnect
        </button>
      )}
      {acceptMemberConnection && (
        <button className={className} onClick={acceptMemberConnection}>
          Accept Connection
        </button>
      )}
      {declineMemberConnection && (
        <button className={className} onClick={declineMemberConnection}>
          Decline Connection
        </button>
      )}

      {disconnectModal && disconnectWithMember && (
        <DialogModal onClose={() => setDisconnectModal(false)} open>
          <DialogModal.Panel icon={MdErrorOutline} title="Disconnect?">
            <DialogModal.Description className="my-8">
              Click okay to remove this connection from your network. We won't
              notify the other party of the disconnection. This profile will no
              longer display as a connection on your profile.
            </DialogModal.Description>
            <div className="flex w-full items-center justify-center gap-4">
              <ClickyBtn
                className="mood-white w-full"
                onClick={() => setDisconnectModal(false)}
              >
                Cancel
              </ClickyBtn>
              <ClickyBtn
                className="mood-orange w-full"
                onClick={() => {
                  disconnectWithMember()
                  setDisconnectModal(false)
                }}
              >
                OK
              </ClickyBtn>
            </div>
          </DialogModal.Panel>
        </DialogModal>
      )}
    </>
  )
}

function Connect({
  member,
  viewer,
}: {
  member: MemberFragment
  viewer: Viewer_UserFragment
}) {
  const [showAlert, setShowAlert] = useState(false)
  const { pathname } = useRouter()

  const name = member?.firstName
  const phone = member?.phoneNumber

  const {
    acceptMemberConnection,
    connectWithMember,
    declineMemberConnection,
    disconnectWithMember,
    isPending,
  } = useConnect(member)

  const show = useCallback(() => setShowAlert(true), [])
  const hide = useCallback(() => setShowAlert(false), [])

  const { handleMessageUser } = useMessages({
    isWidget: true,
  })

  const handleClickConnect = useCallback(() => {
    connectWithMember?.()
  }, [connectWithMember])

  const handleClickDisconnect = useCallback(() => {
    disconnectWithMember?.()
    hide()
  }, [hide, disconnectWithMember])

  const showConnect =
    viewer?.permissions.updateConnectionStatus &&
    !isPending &&
    connectWithMember != null &&
    !/applications/gi.test(pathname)

  return (
    <div className="flow-y-xs">
      {isPending === true && viewer?.permissions.updateConnectionStatus && (
        <p className="flow-y-xs">
          <span className="text-lg">Connection Pending</span>
          <span className="text-sm">
            Your request has been sent but{' '}
            <span className="capitalize">{name}</span> has not responded yet.
          </span>
        </p>
      )}
      {showConnect && (
        <ClickyBtn
          as="text-icon"
          className="w-full bg-white text-orange hocus:border-orange hocus:bg-orange hocus:text-white"
          onClick={handleClickConnect}
          title={`Connect with ${name}`}
        >
          Connect with <span className="capitalize">{name}</span>
        </ClickyBtn>
      )}
      <div className="flex">
        {member?.permissions.sendMessage && (
          <ClickyBtn
            className={
              'flex w-full items-center justify-center bg-white text-orange hocus:border-orange hocus:bg-orange hocus:text-white'
            }
            onClick={() => handleMessageUser(member)}
            title={`Send message to ${name}`}
          >
            <MdOutlineMessage />
          </ClickyBtn>
        )}
      </div>

      {!member?.permissions.sendMessage && (
        <span className="text-sm">
          (messaging will be availalbe once you connect)
        </span>
      )}
      {acceptMemberConnection && viewer?.permissions.updateConnectionStatus && (
        <ClickyBtn
          className="w-full bg-white text-orange hocus:border-orange hocus:bg-orange hocus:text-white"
          onClick={acceptMemberConnection}
        >
          Accept Invitation to Connect
        </ClickyBtn>
      )}

      {phone && member?.permissions?.readPhone && (
        <ClickyLink
          href={`tel:${phone}`}
          className="link-orange text-left text-sm underline"
        >
          Phone: {phone}
        </ClickyLink>
      )}

      {disconnectWithMember && viewer?.permissions.updateConnectionStatus && (
        <ClickyLink
          className="link-orange text-left text-sm underline"
          onClick={show}
          title="Disconnect"
        >
          Disconnect with <span className="capitalize">{name}</span>
        </ClickyLink>
      )}
      {declineMemberConnection &&
        viewer?.permissions.updateConnectionStatus && (
          <ClickyLink
            className="text-left text-sm underline hocus:text-grey"
            onClick={declineMemberConnection}
            title="Decline"
          >
            Decline Invitation from <span className="capitalize"> {name}</span>
          </ClickyLink>
        )}
      {showAlert && (
        <DialogModal onClose={hide} open>
          <DialogModal.Panel icon={MdErrorOutline} title="Disconnect?">
            <DialogModal.Description className="my-10">
              {declineMemberConnection ? (
                <span>
                  Are you sure you want to decline the invitiation from{' '}
                  <span className="capitalize">{name}</span>?
                </span>
              ) : (
                <span>
                  Are you sure you want to disconnect from{' '}
                  <span className="capitalize">{name}</span>?
                </span>
              )}
            </DialogModal.Description>
            <div className="flex w-full items-center justify-center gap-4">
              <ClickyBtn className="mood-white w-full" onClick={hide}>
                Cancel
              </ClickyBtn>
              <ClickyBtn
                className="mood-red w-full"
                onClick={handleClickDisconnect}
              >
                {declineMemberConnection
                  ? 'Decline Invitation'
                  : 'Disconnect with Member'}
              </ClickyBtn>
            </div>
          </DialogModal.Panel>
        </DialogModal>
      )}
    </div>
  )
}

export default memo(ConnectWrapper)
