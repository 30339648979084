// TODO: Replace with: use-aws-s3
// @ts-ignore
import S3FileUpload from 'aws-s3'

// TODO: Should come from the aws-s3 package :(
export type S3SuccessType = {
  readonly bucket: string
  readonly key: string
  readonly location: string
  readonly status: number
}

// TODO: Should come from the aws-s3 package :(
export type S3ErrorType = {
  readonly body: ReadableStream
  readonly bodyUsed: boolean
  readonly headers: Headers
  readonly ok: boolean
  readonly redirected: boolean
  readonly status: number // 400
  readonly statusText: string // 'Bad Request'
  readonly type: string // 'cors'
  readonly url: string
}

type BucketType = {
  readonly bucketName?: string
  readonly dirName?: string
  readonly accessKeyId?: string
  readonly secretAccessKey?: string
}

export type BucketIdentifierUnion = 'defaultBucket' | 'csvApplicants'

function getBucketByIdentifier(type: 'defaultBucket' | 'csvApplicants') {
  if (type === 'csvApplicants') {
    return s3CSVUploadBucket
  }
  return s3Bucket
}

function newBucket(props: BucketType) {
  const { bucketName, dirName = '', accessKeyId, secretAccessKey } = props
  return new S3FileUpload({
    bucketName,
    dirName,
    accessKeyId,
    secretAccessKey,
    region: 'us-west-2',
    s3Url: `https://${bucketName}.s3-accelerate.amazonaws.com`,
  })
}

// -------------------------------------
// DEFAULT BUCKET

let memoizedS3Bucket: any

function s3Bucket() {
  if (memoizedS3Bucket == null) {
    memoizedS3Bucket = newBucket({
      bucketName: 'vangst-direct-upload',
      accessKeyId: process.env.NEXT_PUBLIC_AWS_DIRECT_UPLOAD_ACCESS_KEY_ID,
      secretAccessKey:
        process.env.NEXT_PUBLIC_AWS_DIRECT_UPLOAD_SECRET_ACCESS_KEY,
    })
  }
  return memoizedS3Bucket
}
// -------------------------------------
// CSV UPLOAD BUCKET

let memoizedS3CSVUploadBucket: any

function s3CSVUploadBucket() {
  if (memoizedS3CSVUploadBucket == null) {
    memoizedS3CSVUploadBucket = newBucket({
      bucketName: 'vangst-direct-upload',
      dirName: 'applicants',
      accessKeyId: process.env.NEXT_PUBLIC_AWS_DIRECT_UPLOAD_ACCESS_KEY_ID,
      secretAccessKey:
        process.env.NEXT_PUBLIC_AWS_DIRECT_UPLOAD_SECRET_ACCESS_KEY,
    })
  }
  return memoizedS3CSVUploadBucket
}
// -------------------------------------

export { s3Bucket, s3CSVUploadBucket, getBucketByIdentifier }
