import {
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import Routes from '../../routes'
import fetcher from '../fetcher'
import {
  CompanyJobPostingsDocument,
  CompanyJobPostingsQuery,
  CompanyJobPostingsQueryVariables as CompanyJobPostingsVars,
  FetcherError,
} from '../types'
import { getBasePageInfoInfinitePagination } from '../wtf/pagination'

type CompanyJobPostingsOptions = UseInfiniteQueryOptions<
  CompanyJobPostingsQuery,
  FetcherError,
  CompanyJobPostingsQuery
>

/**
 * @private
 * Retrieve the stringified `CompaniesJobPostingsInfinite` query key used in the internal cache.
 */
function getKey(variables?: CompanyJobPostingsVars) {
  const name = 'CompaniesJobPostingsInfinite'
  return variables ? [name, variables] : [name]
}

/**
 * @private
 * Retrieve the `Company.jobPostings` from the queryClient's cache.
 */
function getCache(queryClient: QueryClient, variables: CompanyJobPostingsVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<CompanyJobPostingsQuery>(key)
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function. If no
 * variables are passed all `CompaniesJobPostingsInfinite` queries will be
 * invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateCompanyJobPostings(queryClient, { displayname: 'moontower', first: 4 })
 * invalidateCompanyJobPostings(queryClient)
 */
function invalidateCompanyJobPostings(
  queryClient: QueryClient,
  variables?: CompanyJobPostingsVars,
) {
  queryClient.invalidateQueries(
    variables ? getKey(variables) : ['CompaniesJobPostingsInfinite'],
  )
}

/**
 * Prefetch an infinite query of paginated JobPostings through a Company.
 *
 * @example
 * const jobPostings = await prefetchCompanyJobPostings(queryClient, { displayname: 'moontower', first: 4 })
 */
async function prefetchCompanyJobPostings(
  queryClient: QueryClient,
  variables: CompanyJobPostingsVars,
) {
  const key = getKey(variables)
  const fn = fetcher<CompanyJobPostingsQuery, CompanyJobPostingsVars>(
    CompanyJobPostingsDocument,
    variables,
  )
  await queryClient.prefetchInfiniteQuery(key, fn)
  return getCache(queryClient, variables)
}

/**
 * Fetch an infinite query of the paginated `jobPostings` field on a `Company`.
 *
 * @example
 * const variables = { displayname: slug, first: 24, atsRecordType: AtsRecordTypeEnum.PlatformFree }
 * const options = { enabled: true }
 * const { data, isZero, pagination, uri } = useCompanyJobPostings(variables, options)
 *
 * @TODO Add optimistic Create|Update|Delete?
 * @TODO Compute data in select?
 * @TODO Tune options
 */
function useCompanyJobPostings(
  variables: CompanyJobPostingsVars,
  options?: CompanyJobPostingsOptions,
) {
  const query = useInfiniteQuery<
    CompanyJobPostingsQuery,
    FetcherError,
    CompanyJobPostingsQuery
  >(
    getKey(variables),
    ({ pageParam }) =>
      fetcher<CompanyJobPostingsQuery, CompanyJobPostingsVars>(
        CompanyJobPostingsDocument,
        { ...variables, ...pageParam },
      )(),
    {
      getNextPageParam: (lastPage) => {
        const edges = lastPage.getClient?.jobPostings.edges
        const after = edges ? edges[edges.length - 1]?.cursor : undefined
        return { after }
      },
      getPreviousPageParam: (lastPage) => {
        const edges = lastPage.getClient?.jobPostings.edges
        const before = edges ? edges[0]?.cursor : undefined
        return { before }
      },
      keepPreviousData: true,
      ...options,
    },
  )
  const pages = query?.data?.pages
  const pageInfo = getBasePageInfoInfinitePagination(
    pages?.map((p) => p.getClient?.jobPostings.pageInfo) || [],
    variables?.first || variables?.last || 24,
  )

  const nextPage = pageInfo?.nextHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchNextPage()
      }
    : undefined

  const prevPage = pageInfo?.prevHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchPreviousPage()
      }
    : undefined

  const pagination = { ...pageInfo, nextPage, prevPage }
  const isZero = query.data && pagination?.totalCount === 0
  const uri =
    Routes.COMPANIES_DETAIL.replace(':displayname', variables.displayname) +
    '#open-positions'
  return { ...query, isZero, pagination, uri }
}

// -------------------------------------

export type { CompanyJobPostingsOptions, CompanyJobPostingsVars }
export { invalidateCompanyJobPostings, prefetchCompanyJobPostings }
export default useCompanyJobPostings
