import clsx from '@vangst/lib/clsx'
import { forwardRef } from 'react'
import ClickyLink, {
  ClickyType,
} from '../../../components/navigation/ClickyLink'

/**
 * A `Disclosure` like link that appears as a sibling to other `Disclosures` in a Navbar Menu
 *
 * @example
 * <NavbarClickyDisclosure href="#">
 *   The Disclosure Like Link
 * </NavbarClickyDisclosure>
 * <NavbarClickyDisclosure aria-current="true">
 *   The Disclosure Active Like Link
 * </NavbarClickyDisclosure>
 */
const NavbarClickyDisclosure = forwardRef<HTMLElement, ClickyType>(
  function NavbarClickyDisclosure(props: ClickyType, ref) {
    const { children, className, ...rezt } = props
    const rest = { ...rezt, ref }
    return (
      <ClickyLink
        activate
        className={clsx(
          'flex w-full cursor-pointer list-none items-center justify-between p-4 pr-2 text-sm uppercase transition',
          'border-b border-grey-light',
          'hocus:bg-orange hocus:text-white',
          'aria-[current=true]:bg-orange aria-[current=true]:font-700 aria-[current=true]:text-white',
          className,
        )}
        {...rest}
      >
        {children}
      </ClickyLink>
    )
  },
)

export default NavbarClickyDisclosure
