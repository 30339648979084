import useGenerateChargebeePortal from '@vangst/services/oogst/member/useGenerateChargebeePortal'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { useCallback } from 'react'

/**
 * Get the Chargebee url for the navigation
 */
export default function useChargebeeUrl() {
  const { viewer } = useViewer()

  const mutation = useGenerateChargebeePortal({
    onSuccess: (data) => {
      const url = data?.generateChargebeePortal?.accessUrl
      url != null && globalThis?.window?.open(url)
    },
  })

  const label = mutation.isLoading ? 'Opening...' : 'Billing Options'

  const callback = useCallback(
    (event: React.FormEvent) => {
      event?.preventDefault()
      mutation.mutate({
        input: {
          redirectUrl: viewer?.isMember
            ? `${origin}/members/${viewer?.username}`
            : `${origin}`,
        },
      })
    },
    [mutation, viewer?.isMember, viewer?.username],
  )

  return { callback, label }
}
