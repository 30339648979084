import useCompanyPhotos, {
  CompanyPhotosOptions,
  CompanyPhotosVars,
} from '@vangst/services/oogst/company/useCompanyPhotos'
import {
  CompanyPhotoDisplayRoleEnum,
  CompanyPhotoMediaTypeEnum,
} from '@vangst/services/oogst/types'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { memo, useMemo, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { DialogModal } from '../../components/feedback/DialogModal'
import ClickyLink from '../../components/navigation/ClickyLink'

type PropsType = React.HTMLAttributes<HTMLUListElement> & {
  readonly at: 'content' | 'sidebar' // Not using yet
  readonly variables: CompanyPhotosVars
  readonly options?: CompanyPhotosOptions
}

type StateType = {
  alt?: string
  src: string
}

function CompanyPhotosRenderer(props: PropsType) {
  const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

  const { at, variables, options, ...rest } = props
  const [selectedPhoto, setSelectedPhoto] = useState<StateType>()
  const { data } = useCompanyPhotos(variables, options)
  const company = data?.getClient
  const standardPhotos = company?.companyPhotos?.filter(
    (photo) => photo.displayRole === CompanyPhotoDisplayRoleEnum.Standard,
  )
  // TODO Move to derive data?
  const gallery = useMemo(
    () => Array.from({ length: 4 }, (_, i) => standardPhotos?.[i]),
    [standardPhotos],
  )
  const photosToRender = gallery.filter((img) => img)
  if (!photosToRender.length) {
    return null
  }

  return (
    <section id="media" className="mt-8 sm:mt-16">
      <ul
        className="flex w-full flex-wrap justify-center xl:justify-start"
        {...rest}
      >
        <li className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {photosToRender?.map((pic, i) => (
            <div key={pic?.id || `cophoto-${i}`} className="sm:mb-2 sm:mr-2">
              {pic &&
                (pic.mediaType === CompanyPhotoMediaTypeEnum.Video ? (
                  <div className="mood-black h-60 min-w-80 overflow-hidden rounded-lg sm:min-w-100 lg:h-72">
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      url={pic.url}
                      light
                    />
                  </div>
                ) : (
                  <ClickyLink
                    style={{
                      borderRadius: 10,
                      overflow: 'hidden',
                      width: '100%',
                      position: 'relative',
                    }}
                    className="relative flex h-60 min-w-80 transition-opacity duration-200 hocus:opacity-75 sm:min-w-100 lg:h-72"
                    onClick={() =>
                      setSelectedPhoto({ alt: pic.alt || pic.id, src: pic.url })
                    }
                  >
                    {pic?.url && (
                      <Image
                        alt={pic?.alt || pic.id}
                        className="object-cover"
                        fill
                        sizes="(min-width: 86em) 64em, 50vw"
                        src={pic?.url}
                      />
                    )}
                  </ClickyLink>
                ))}
            </div>
          ))}
          {selectedPhoto != null && (
            <DialogModal onClose={() => setSelectedPhoto(undefined)} open>
              <DialogModal.Panel>
                <DialogModal.Title className="sr-only">
                  Selected Photo
                </DialogModal.Title>
                <div className="relative p-8">
                  <ClickyLink
                    as="icon"
                    icon={MdClose}
                    className="link-grey absolute right-0 top-0 hidden sm:block"
                    onClick={() => setSelectedPhoto(undefined)}
                  >
                    Delete
                  </ClickyLink>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img alt={selectedPhoto.alt} src={selectedPhoto.src} />
                </div>
              </DialogModal.Panel>
            </DialogModal>
          )}
        </li>
      </ul>
    </section>
  )
}

export default memo(CompanyPhotosRenderer)
