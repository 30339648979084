import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import { invalidateCompany } from '../company/useCompany'
import { invalidateCompanyJobPostings } from '../company/useCompanyJobPostings'
import {
  CreateJobPostingMutation,
  CreateJobPostingMutationVariables,
  FetcherError,
  useCreateJobPostingMutation,
} from '../types'
import { invalidateViewer } from '../viewer/useViewer'

type CreateOptions = UseMutationOptions<
  CreateJobPostingMutation,
  FetcherError,
  CreateJobPostingMutationVariables
>

/**
 * Creates a brand new `JobPosting`.
 *
 * When overriding `onSettled` be sure to provide necessary invalidations.
 *
 * @example
 * const createJobPosting = useCreateJobPosting({onSuccess: () => push(url)})
 * const input = {title: 'Job Title'}
 * const createJobPosting.mutate({ input })
 *
 * @TODO Invalidate searchJobPostings?
 * @TODO Call optimistic Creates?
 */
function useCreateJobPosting(options: CreateOptions) {
  const queryClient = useQueryClient()

  return useCreateJobPostingMutation({
    onSettled: (data) => {
      const displayname = data?.createJobPosting.client?.displayname

      if (displayname) {
        invalidateCompany(queryClient, { displayname })
        invalidateCompanyJobPostings(queryClient, { displayname })
      }

      invalidateViewer(queryClient)
    },
    ...options,
  })
}

export default useCreateJobPosting
