import { useQueryClient } from '@tanstack/react-query'
import clsx from '@vangst/lib/clsx'
import {
  allApplicationStatuses,
  orderedJobApplicationStatus,
} from '@vangst/services/oogst/company/statuses'
import useUpdateJobApplication from '@vangst/services/oogst/company/useUpdateJobApplication'
import { JobApplicationStatusEnum } from '@vangst/services/oogst/types'
import { memo } from 'react'
import { MdAdd, MdExpandLess, MdExpandMore, MdRemove } from 'react-icons/md'
import AnchorButton from '../../../components/navigation/internal/AnchorButton'

type PropsType = {
  readonly applicationId: string
  readonly className?: string
  readonly defaultValue?: JobApplicationStatusEnum
  readonly isGrouped?: boolean
  readonly level?: number
}

function ApplicationStatusDisclosure(props: PropsType) {
  const { applicationId, className, defaultValue, isGrouped, level, ...rest } =
    props
  const queryClient = useQueryClient()

  const { mutate } = useUpdateJobApplication(queryClient)

  const onChange = (status: JobApplicationStatusEnum) => {
    mutate({ input: { id: applicationId, status } })
  }

  const defaultLabel =
    orderedJobApplicationStatus[defaultValue as JobApplicationStatusEnum]

  return (
    <details className="group/disc" {...rest}>
      <summary className={clsx('cursor-pointer', className)}>
        <span className="truncate">
          {isGrouped
            ? `${defaultLabel ?? 'Status'}`
            : `Application: ${defaultLabel ?? 'Status'}`}
        </span>
        <span className="ml-auto">
          {isGrouped ? (
            <>
              <MdExpandMore className="group-open/disc:hidden" />
              <MdExpandLess className="hidden group-open/disc:inline" />
            </>
          ) : (
            <>
              <MdAdd className="group-open/disc:hidden" />
              <MdRemove className="hidden group-open/disc:inline" />
            </>
          )}
        </span>
      </summary>
      <ul>
        {allApplicationStatuses.map((status) => (
          <li key={status}>
            <AnchorButton
              aria-selected={defaultValue === status}
              className={clsx('pl-6', className)}
              onClick={() => onChange(status as JobApplicationStatusEnum)}
            >
              {defaultValue === status && '✓ '}
              {orderedJobApplicationStatus[status as JobApplicationStatusEnum]}
            </AnchorButton>
          </li>
        ))}
      </ul>
    </details>
  )
}

export default memo(ApplicationStatusDisclosure)
