import { AtsRecordTypeEnum } from '@vangst/services/oogst/types'
import { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import ControlGroup from '../../../components/forms/ControlGroup'
import Radio from '../../../components/forms/Radio'

type PropsType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly defaultValue?: AtsRecordTypeEnum[]
  readonly hint?: string | null
  readonly label: string
  readonly name: string
  readonly recruitingJob?: boolean
}

function ChooseAtsRecordTypeRadio(props: PropsType) {
  const { hint, label, name, required, recruitingJob } = props
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const { ref, ...formProps } = register(name)
  const splitName = name.split('.')
  const errorMessage =
    splitName.length === 2
      ? errors?.[splitName[0]]?.[splitName[1]]?.message
      : errors?.[name]?.message
  return (
    <ControlGroup
      disabled={props.disabled}
      error={errorMessage}
      hint={hint}
      label={label}
      name={name}
      required={required}
      type="radio"
    >
      <Radio
        label="GIGS"
        reference={ref}
        disabled={!recruitingJob}
        value={AtsRecordTypeEnum.BoutiqueGigs}
        {...formProps}
      />
      <Radio
        label="Direct Hire"
        reference={ref}
        disabled={!recruitingJob}
        value={AtsRecordTypeEnum.BoutiqueDh}
        {...formProps}
      />
      <Radio
        label="Self Service (Platform Free)"
        reference={ref}
        disabled={recruitingJob}
        value={AtsRecordTypeEnum.PlatformFree}
        {...formProps}
      />
    </ControlGroup>
  )
}

export default memo(ChooseAtsRecordTypeRadio)
