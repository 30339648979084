import { useQueryClient } from '@tanstack/react-query'
import {
  MemberFragment,
  useMarkChatMessageReadMutation,
} from '@vangst/services/oogst/types'
import { useCallback } from 'react'
import { useMessagingStore } from '../application/useZustand'

type useMessagesType = {
  isWidget: boolean
}

export default function useMessages({ isWidget }: useMessagesType) {
  const queryClient = useQueryClient()

  const [
    handleCloseWidget,
    messagingWidgetIsVisible,
    recipient,
    handleOpenWidget,
    setRecipient,
    setWidgetView,
    widgetView,
  ] = useMessagingStore((state) => [
    state.hideMessagingWidget,
    state.messagingWidgetIsVisible,
    state.recipient,
    state.showMessagingWidget,
    state.setRecipient,
    state.setWidgetView,
    state.widgetView,
  ])

  const { mutate: mutateMarkRead } = useMarkChatMessageReadMutation({
    onSettled: () => {
      queryClient.invalidateQueries(['AllChatMessages', 'Notifications'])
    },
  })

  const markMessageAsRead = useCallback(
    (id?: string) => {
      if (!id) return
      mutateMarkRead({ input: { id } })
    },
    [mutateMarkRead],
  )

  const handleSelectThread = useCallback(
    (recipient: MemberFragment) => {
      if (isWidget) {
        setWidgetView('detail')
        handleOpenWidget()
      }
      setRecipient(recipient)
    },
    [isWidget, handleOpenWidget, setRecipient, setWidgetView],
  )

  const handleSelectAllMessages = useCallback(() => {
    if (isWidget) {
      setWidgetView('list')
    }
    setRecipient()
  }, [isWidget, setRecipient, setWidgetView])

  const handleToggleWidget = useCallback(
    (state?: 'hide' | 'show') => {
      if (state != null) {
        if (state === 'hide') {
          handleCloseWidget()
          return
        }
        handleOpenWidget()
        return
      }

      if (messagingWidgetIsVisible) {
        handleCloseWidget()
        return
      }

      handleOpenWidget()
    },
    [handleCloseWidget, handleOpenWidget, messagingWidgetIsVisible],
  )

  const handleMessageUser = useCallback(
    (member: MemberFragment, state?: 'hide' | 'show') => {
      setRecipient(member)
      setWidgetView('detail')
      handleToggleWidget(state)
    },
    [handleToggleWidget, setRecipient, setWidgetView],
  )

  const exitWidget = useCallback(() => {
    setRecipient()
    handleToggleWidget('hide')
  }, [handleToggleWidget, setRecipient])

  return {
    exitWidget,
    handleMessageUser,
    handleSelectAllMessages,
    handleSelectThread,
    handleToggleWidget,
    markMessageAsRead,
    messagingWidgetIsVisible,
    recipient,
    setRecipient,
    setWidgetView,
    widgetView,
  }
}
