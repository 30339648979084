/**
 * Attempt to convert common values into a Boolean
 *
 * @example
 * toBoolean('true') // => true
 * toBoolean('no') // => false
 * toBoolean(undefined) // => undefined
 */
function toBoolean(val?: any) {
  if (typeof val === 'boolean' || val == null) return val
  if (typeof val === 'number') return Boolean(val)
  return /true|yes|on/gi.test(val)
    ? true
    : /false|no|off/gi.test(val)
    ? false
    : val
}

export default toBoolean
