import stateSlugToAbbreviation from '@vangst/lib/stateSlugToAbbreviation'

const geocodedStateToAbbreviation = (state: string) => {
  // in one edge case google returns a 2-char state code + a zip
  if (/\d/.test(state)) {
    return state.slice(0, 2).toUpperCase()
  }
  // sometimes google returns state code in CamelCase (like "Co")
  if (state.length === 2) {
    return state.toUpperCase()
  }
  // sometimes google returns full state name
  return stateSlugToAbbreviation(state.toLowerCase().replace(' ', '-'))
}

export default geocodedStateToAbbreviation
