import clsx from '@vangst/lib/clsx'
import { forwardRef } from 'react'

export type RadioType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly classNameLabel?: string
  readonly label: string | React.ReactNode
  readonly name: string
  readonly reference?: React.Ref<HTMLInputElement> | any
  readonly value: string
}

/**
 * The `li > label > input[type="radio"]` elements.
 *
 * Radio must be a direct descendant of a `ControlGroup` component or a
 * `ul/ol` element.
 *
 * @see [MDN Radio](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio)
 *
 * @example
 * <ControlGroup label="Radio Column" name="radio-1" type="radio">
 *   <Radio name="radio-1" label="Yes" value="yes" />
 *   <Radio name="radio-1" label="No" value="no" />
 * </ControlGroup>
 *
 * <ControlGroup
 *   className="text-blue ring-blue checked:bg-blue checked:bg-none focus:ring-blue hocus:bg-blue/30"
 *   classNameLabel="items-end text-magenta"
 *   error="Some radio thing happened."
 *   hint="This is a hint"
 *   label="Radio Row Hint Required"
 *   layout="row"
 *   name="radio-2"
 *   required
 *   type="radio"
 * >
 *   <Radio name="radio-2" label="Yes" value="yes" />
 *   <Radio name="radio-2" label="No" value="no" />
 * </ControlGroup>
 */
const Radio = forwardRef<HTMLInputElement, RadioType>(
  function Radio(props, ref) {
    const {
      className,
      classNameLabel,
      disabled,
      label,
      name,
      reference,
      value,
      ...rest
    } = props
    const id = props.id || props.name

    return (
      <li key={`${id}-${value}`} className="list-none">
        <label
          htmlFor={props.id || `${name}-${value}`}
          className={clsx('inline-flex cursor-pointer gap-3', classNameLabel)}
        >
          <input
            className={clsx(
              'size-4 cursor-pointer appearance-none border-none bg-none text-orange ring-2 ring-orange ring-offset-2 transition checked:bg-orange checked:bg-none focus:ring-2 focus:ring-orange hocus:bg-orange/30',
              className,
            )}
            disabled={disabled}
            id={props.id || `${name}-${value}`}
            name={name}
            ref={ref || reference}
            type="radio"
            value={value}
            {...rest}
          />
          <span>{label}</span>
        </label>
      </li>
    )
  },
)

export default Radio
