/**
 * Tests for empty (`''`, `[]`, `{}`) or nil (`undefined`, `null`) values
 * All other values will return false
 */
function isEmptyOrNil(obj: any, omitFalse = false) {
  if (obj == null) return true
  if (omitFalse && obj === false) return true
  if (
    typeof obj === 'boolean' ||
    typeof obj === 'number' ||
    typeof obj === 'function' ||
    typeof obj === 'symbol' ||
    typeof obj === 'bigint'
  )
    return false
  if (typeof obj === 'string' && obj.length === 0) return true
  if (Array.isArray(obj) && obj.length === 0) return true
  return Object.keys(obj).length === 0
}

export default isEmptyOrNil
