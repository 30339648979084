import clsx from '@vangst/lib/clsx'
import noop from '@vangst/lib/noop'
import { UseFormGetValues } from 'react-hook-form'
import PlacesAutocomplete, { Suggestion } from 'react-places-autocomplete'
import Label from '../../../components/forms/Label'
import ClickyLink from '../../../components/navigation/ClickyLink'
import { SearchTypeUnion } from './InputGeolocation'

type PropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  readonly address: string
  readonly error?: string | null
  readonly formType: SearchTypeUnion
  readonly getValues?: UseFormGetValues<any>
  readonly handleChange: (address: string) => void
  readonly handleSelect: (address: string) => void
  readonly label?: string
  readonly placeholder?: string
  readonly setSearchError: React.Dispatch<React.SetStateAction<string | null>>
}

type searchOptionsType = {
  readonly componentRestrictions: {
    readonly country: ['us' | 'ca']
  }
  readonly types: [string]
  readonly location?: any
  readonly radius?: number
}

function GooglePlacesAutocomplete(props: PropsType) {
  const {
    address,
    error,
    handleChange,
    handleSelect,
    setSearchError,
    formType,
    placeholder,
    required,
    label,
    ...rest
  } = props

  const formError =
    'No results, try to find the best available address suggestion'

  // GEO CODE OPTIONS
  let searchOptions: searchOptionsType = {
    componentRestrictions: { country: ['us'] },
    types: ['address'],
  }
  if (formType === 'search') {
    searchOptions = {
      componentRestrictions: { country: ['us'] },
      types: ['(cities)'],
    }
  } else if (formType === 'geocode') {
    searchOptions = {
      componentRestrictions: { country: ['us'] },
      types: ['geocode'],
    }
  }

  const selectSuggestion = (address: string, placeId: string) => {
    if (!placeId) {
      return
    }
    handleSelect(address)
  }

  return (
    // @ts-ignore: This seems to be upstream and related to the react 18 upgrade
    <PlacesAutocomplete
      googleCallbackName="Function.prototype"
      value={address}
      onChange={(e: string) => handleChange(e)}
      searchOptions={searchOptions}
      onError={() => setSearchError(formError)}
      onSelect={(address, placeId) => selectSuggestion(address, placeId)}
      highlightFirstSuggestion={true}
      {...rest}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <label htmlFor="geo" className="block">
          <span className="flow-x-xs mb-2">
            {label && <Label label={label} required={required} />}
          </span>
          <input
            data-id="geo"
            name="location"
            {...getInputProps({
              placeholder: placeholder || `Enter ${label}`,
              className: clsx(
                'peer/geo w-full rounded-md py-3 text-sm transition-all placeholder:text-sm placeholder:italic focus:border-orange focus:outline-none focus:ring-orange',
                error != null && 'border-2 border-red',
              ),
            })}
            onBlur={() => noop}
          />

          <div
            data-id="autocomplete-dropdown-container"
            className={clsx(
              'mood-white flex flex-col rounded-b border-x border-blue-dark shadow-lg',
              'peer-aria-[expanded=true]/geo:border-b',
            )}
          >
            {loading && <div>Loading...</div>}
            {formType === 'search'
              ? suggestions.map((suggestion: any, i: number) => {
                  return renderSuggestions(
                    getSuggestionItemProps,
                    suggestion,
                    i,
                  )
                })
              : suggestions.map((suggestion: any, i: number) => {
                  return renderSuggestions(
                    getSuggestionItemProps,
                    suggestion,
                    i,
                  )
                })}
          </div>
        </label>
      )}
    </PlacesAutocomplete>
  )
}

const renderSuggestions = (
  getSuggestionItemProps: any,
  suggestion: Suggestion,
  i: number,
) => {
  const suggestionText = suggestion.description
  return (
    <ClickyLink
      aria-selected={suggestion.active ? 'true' : undefined}
      className={clsx(
        'w-full border-t border-grey-light p-2 text-left first:border-t-0',
        'aria-[selected=true]:bg-blue aria-[selected=true]:font-600 hocus:bg-blue',
      )}
      key={`suggestion-option-${i}`}
      {...getSuggestionItemProps(suggestion, {})}
    >
      <span className="text-sm">{suggestionText}</span>
    </ClickyLink>
  )
}

export default GooglePlacesAutocomplete
