import { QueryClient, UseMutationOptions } from '@tanstack/react-query'
import { invalidateMember } from '../member/useMember'
import {
  AddToJobMutation,
  AddToJobMutationVariables,
  FetcherError,
  useAddToJobMutation,
} from '../types'
import { invalidateCompanyJobApplication } from './useCompanyJobApplication'
import { invalidateCompanyJobApplications } from './useCompanyJobApplications'

type AddToJobOptions = UseMutationOptions<
  AddToJobMutation,
  FetcherError,
  AddToJobMutationVariables
>

function useAddToJob(queryClient: QueryClient, options?: AddToJobOptions) {
  return useAddToJobMutation({
    onSettled: () => {
      invalidateCompanyJobApplications(queryClient)
      invalidateMember(queryClient)
      invalidateCompanyJobApplication(queryClient)
    },
    ...options,
  })
}

export default useAddToJob
