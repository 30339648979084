import clsx from '@vangst/lib/clsx'
import { forwardRef } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import AlertText from '../feedback/AlertText'
import Label, { LabelPropsType } from './Label'

export type TextareaType = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  LabelPropsType & {
    readonly classNameLabel?: string
    readonly count?: string
    readonly error?: string
    readonly hint?: string | null
    readonly labelSubtext?: string
    readonly name: string
    readonly overMax?: string
    readonly reference?: React.Ref<TextareaType> | any
    readonly underMin?: string
  }

/**
 * The `label > text + textarea + error/hint` elements.
 *
 * @see [MDN textarea](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea)
 *
 * @example
 * <Textarea
 *   label="Tell us some stuff"
 *   labelSubtext="(Optional)"
 *   name="title"
 *   rows={4}
 * />
 * <Textarea
 *   className="max-w-prose border-dashed border-magenta"
 *   classNameLabel="ml-24"
 *   label="Tell us more stuff"
 *   name="stuff"
 *   rows={4}
 * />
 */
const Textarea = forwardRef(function Textarea(
  props: TextareaType,
  ref: React.Ref<TextareaType>,
) {
  const {
    className,
    classNameLabel,
    count,
    error,
    hideLabel,
    hint,
    label,
    labelSubtext,
    name,
    overMax,
    reference,
    required,
    underMin,
    ...rest
  } = props
  const id = props.id || props.name
  const showDetails =
    error != null || hint != null || count != null || underMin != null
  return (
    <label
      className={clsx('flex w-full flex-col gap-2', classNameLabel)}
      htmlFor={name}
    >
      <Label
        hideLabel={hideLabel}
        label={label}
        labelSubtext={labelSubtext}
        required={required}
      />
      <textarea
        className={clsx(
          'w-full rounded-md text-sm transition placeholder:text-sm placeholder:italic focus:border-orange focus:outline-none focus:ring-orange',
          error != null && 'border-2 border-red',
          className,
        )}
        id={id}
        name={name}
        ref={ref || reference}
        {...rest}
      />
      {showDetails && (
        <span className="ml-auto grid gap-1">
          {(error != null || hint != null) && (
            <AlertText
              className={clsx('text-sm italic', error != null && 'text-red')}
              icon={MdErrorOutline}
              role={error ? 'alert' : 'note'}
            >
              {error || hint}
            </AlertText>
          )}
          {count != null && (
            <span
              className={clsx(
                'flex justify-end text-sm italic',
                overMax && 'text-red',
              )}
            >
              {count}
            </span>
          )}
          {underMin != null && (
            <span className="flex justify-end text-sm italic text-orange">
              {underMin}
            </span>
          )}
        </span>
      )}
    </label>
  )
})

export default Textarea
