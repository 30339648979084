import {
  InfiniteData,
  QueryClient,
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from '@tanstack/react-query'
import fetcher from '../fetcher'
import {
  fetchResource,
  useInfiniteQueryResource,
  useQueryResource,
} from '../resource'
import {
  ClientTrainingsDocument,
  ClientTrainingsQuery,
  ClientTrainingsQueryVariables,
  FetcherError,
  TrainingDocument,
  TrainingMembersDocument,
  TrainingMembersQuery,
  TrainingMembersQueryVariables,
  TrainingQuery,
  TrainingQueryVariables,
} from '../types'
import { getBasePageInfoInfinitePagination } from '../wtf/pagination'

type TrainingOptions = UseQueryOptions<
  TrainingQuery,
  FetcherError,
  TrainingQuery
>

type ClientTrainingsQueryOptions = UseInfiniteQueryOptions<
  ClientTrainingsQuery,
  FetcherError,
  ClientTrainingsQuery
>

type TrainingMembersQueryOptions = UseInfiniteQueryOptions<
  TrainingMembersQuery,
  FetcherError,
  TrainingMembersQuery
>

/**
 * @private
 * The cache key name utilized for the `Quiz` Resource.
 */

const KEY_NAME = 'Training'

/**
 * @private
 * Retrieve the stringified `CompaniesTrainingsInfinite` query key used in the internal cache.
 */
function getKey(variables?: ClientTrainingsQueryVariables) {
  const name = 'CompaniesTrainingsInfinite'
  return variables ? [name, variables] : [name]
}

/**
 * @private
 * Retrieve the `Company.trainings` from the queryClient's cache.
 */
function getCache(
  queryClient: QueryClient,
  variables: ClientTrainingsQueryVariables,
) {
  const key = getKey(variables)
  return queryClient.getQueryData<ClientTrainingsQuery>(key)
}

// TODO: Ready For Use
/**
 * @private
 * Derives data not contained in the API.
 */
// function deriveTraining(training?: TrainingFragment) {
//   if (training == null) return training
//   const displayname = training?.creatorClient?.displayname
//   const trainingClientUri = Routes?.COMPANIES_DETAIL?.replace(
//     ':displayname',
//     displayname,
//   )
//   const trainingQuizUri = Routes?.COMPANIES_QUIZ?.replace(
//     ':displayname',
//     displayname ?? '',
//   )
//     ?.replace(':tid', training?.id ?? '')
//     ?.replace(':id', training?.quizzes[0]?.id ?? '')
//   const trainingMembersUri = Routes?.COMPANIES_TRAINING_MEMBERS?.replace(
//     ':displayname',
//     displayname,
//   ).replace(':tid', training.id)

//   return {
//     ...training,
//     routes: {
//       trainingClientUri,
//       trainingMembersUri,
//       trainingQuizUri,
//     },
//   }
// }

/**
 * @public
 * A fetcher for the current `` WITHOUT derived data.
 *
 * @example
 * const trainings = await fetchTrainings(queryClient, { displayname: 'vangst' })
 * const pageInfo = trainings.getClient.trainings.pageInfo
 */
export async function fetchTrainings(
  queryClient: QueryClient,
  variables?: ClientTrainingsQueryVariables,
) {
  const result = await fetchResource<
    ClientTrainingsQuery,
    ClientTrainingsQueryVariables
  >(queryClient, KEY_NAME + 'Infinite', ClientTrainingsDocument, variables)
  return result
}

/**
 * Prefetch an infinite query of paginated Trainings through a Company.
 *
 * @example
 * const trainings = await prefetchClientTrainings(queryClient, { displayname: 'moontower', first: 4 })
 */
export async function prefetchClientTrainings(
  queryClient: QueryClient,
  variables: ClientTrainingsQueryVariables,
) {
  const key = getKey(variables)
  const fn = fetcher<ClientTrainingsQuery, ClientTrainingsQueryVariables>(
    ClientTrainingsDocument,
    variables,
  )
  await queryClient.prefetchInfiniteQuery(key, fn)
  return getCache(queryClient, variables)
}

/**
 * @public
 * A fetching hook for the current `Company.client_trainings` WITHOUT derived data.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data, isZero, pagination } = useClientTrainings({ displayname: 'vangst' })
 * const pages = data?.pages
 */
export function useClientTrainings(
  variables?: ClientTrainingsQueryVariables,
  options?: ClientTrainingsQueryOptions,
) {
  const query = useInfiniteQueryResource<
    ClientTrainingsQuery,
    ClientTrainingsQueryVariables
  >(KEY_NAME + 'Infinite', ClientTrainingsDocument, variables, {
    getNextPageParam: (lastPage) => {
      const edges = lastPage.getClient?.clientTrainings.edges
      const after = edges ? edges[edges.length - 1]?.cursor : undefined
      return { after }
    },
    getPreviousPageParam: (lastPage) => {
      const edges = lastPage.getClient?.clientTrainings.edges
      const before = edges ? edges[0]?.cursor : undefined
      return { before }
    },
    ...options,
  })
  const pages = (query.data as InfiniteData<ClientTrainingsQuery> | undefined)
    ?.pages

  const pageInfo = getBasePageInfoInfinitePagination(
    pages?.map((p) => p.getClient?.clientTrainings.pageInfo) || [],
    variables?.first || variables?.last || 24,
  )

  const nextPage = pageInfo?.nextHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchNextPage()
      }
    : undefined

  const prevPage = pageInfo?.prevHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchPreviousPage()
      }
    : undefined

  const pagination = { ...pageInfo, nextPage, prevPage }
  const isZero = query.data && pagination?.totalCount === 0
  return {
    ...query,
    data: query.data as InfiniteData<ClientTrainingsQuery> | undefined,
    isZero,
    pagination,
  }
}

/**
 * @public
 * A fetching hook for the current `???` WITHOUT derived data.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data, isZero, pagination } = useTrainingMembers({ trainingId: '123' })
 * const pages = data?.pages
 */
export function useTrainingMembers(
  variables?: TrainingMembersQueryVariables,
  options?: TrainingMembersQueryOptions,
) {
  const query = useInfiniteQueryResource<
    TrainingMembersQuery,
    TrainingMembersQueryVariables
  >('TrainingMembers' + 'Infinite', TrainingMembersDocument, variables, {
    getNextPageParam: (lastPage) => {
      const edges = lastPage.getTrainingMembers?.edges
      const after = edges ? edges[edges.length - 1]?.cursor : undefined
      return { after }
    },
    getPreviousPageParam: (lastPage) => {
      const edges = lastPage.getTrainingMembers?.edges
      const before = edges ? edges[0]?.cursor : undefined
      return { before }
    },
    ...options,
  })
  const pages = (query.data as InfiniteData<TrainingMembersQuery> | undefined)
    ?.pages

  const pageInfo = getBasePageInfoInfinitePagination(
    // @ts-ignore getTrainingMember is returning undefined
    pages?.map((p) => p?.getTrainingMembers?.pageInfo) || [],
    variables?.first || variables?.last || 24,
  )

  const nextPage = pageInfo?.nextHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchNextPage()
      }
    : undefined

  const prevPage = pageInfo?.prevHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchPreviousPage()
      }
    : undefined

  const pagination = { ...pageInfo, nextPage, prevPage }
  const isZero = query.data && pagination?.totalCount === 0
  return {
    ...query,
    data: query.data as InfiniteData<TrainingMembersQuery> | undefined,
    isZero,
    pagination,
  }
}
/**
 * @public
 * A fetching hook for the current `Training` with derived data.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data } = useTraining()
 * const client = data?.getTraining?.creatorClient
 */
export function useTraining(
  variables?: TrainingQueryVariables,
  options?: TrainingOptions,
) {
  const query = useQueryResource<TrainingQuery, TrainingQueryVariables>(
    'Training',
    TrainingDocument,
    variables,
    { ...options },
  )
  const data = query.data?.getTraining
  return { ...query, data }
}

/**
 * @public
 * A fetcher for the current `Training` .
 *
 * @example
 * const training = await fetchTraining(queryClient)
 */
export async function fetchTraining(
  queryClient: QueryClient,
  variables?: TrainingQueryVariables,
) {
  const result = await fetchResource<TrainingQuery, TrainingQueryVariables>(
    queryClient,
    KEY_NAME,
    TrainingDocument,
    variables,
  )
  return result ? { getTraining: result.getTraining } : result
}
