import clsx from '@vangst/lib/clsx'
import Image from 'next/image'
import React from 'react'
import {
  MdCheckCircleOutline,
  MdDelete,
  MdDescription,
  MdErrorOutline,
  MdWarningAmber,
} from 'react-icons/md'
import ClickyLink from '../navigation/ClickyLink'

// TODO: This may need more defined states rather than
// inferring what to render based on the existing props

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly as?: 'hero' | 'companyPhoto' | 'avatar' | 'file'
  readonly avatar?: boolean
  readonly preview?: boolean
  readonly remove?: () => void
  readonly theme?: 'default' | 'failure' | 'pending' | 'success' | 'warning'
  readonly url?: string | null
}

function Attachment(props: PropsType) {
  const { as, preview = false, theme = 'default', url } = props
  const { children, remove } = props

  const cnDiv = clsx(
    'flex items-center rounded-lg text-sm uppercase',
    as === 'hero' && 'relative h-60 bg-grey-dark sm:h-78',
    as === 'companyPhoto' && 'relative',
    as === 'file' && 'border border-dashed p-2',
  )
  const cnImg = clsx(
    as === 'avatar' && 'mr-4 size-32 rounded-full object-cover',
  )

  const link = clsx(
    'flex h-full items-center',
    as === 'hero' && 'opacity-60',
    as === 'companyPhoto' && '!h-56 opacity-60',
    as === 'file' && 'flex',
    as === 'avatar' && 'grid',
  )

  const trash = clsx(
    'right-4 top-4 mt-4',
    as !== 'avatar' && as !== 'file' && 'absolute text-white',
    as === 'avatar' && '-ml-4 -mt-4 pr-6 text-orange',
    as === 'file' && 'ml-6 mt-2',
  )

  const renderPhoto = () => {
    if (url && (as === 'hero' || as === 'companyPhoto')) {
      return (
        <Image
          alt="Preview"
          className="h-auto max-w-full rounded-lg object-cover"
          fill
          sizes="(min-width: 86em) 86em, 100vw"
          src={url}
        />
      )
    } else {
      // eslint-disable-next-line @next/next/no-img-element
      return <img alt="Preview" className={cnImg} src={url || undefined} />
    }
  }

  return (
    <div className={cnDiv}>
      {(theme === 'failure' ||
        theme === 'pending' ||
        theme === 'success' ||
        theme === 'warning') && (
        <span
          className={clsx(
            'flex items-center gap-2 text-sm italic',
            theme === 'warning'
              ? 'text-orange'
              : theme === 'success'
              ? 'text-green'
              : theme === 'failure'
              ? 'text-red'
              : undefined,
          )}
        >
          {theme === 'warning' ? (
            <MdWarningAmber />
          ) : theme === 'success' ? (
            <MdCheckCircleOutline />
          ) : (
            <MdErrorOutline />
          )}
          <span>{children}</span>
        </span>
      )}
      {theme === 'default' && url != null && (
        <div className="flex w-full items-center justify-center">
          <a
            className={link}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {preview || as === 'avatar' ? (
              renderPhoto()
            ) : (
              <MdDescription className="mr-2" />
            )}
            <div>{children}</div>
          </a>
          {remove != null && (
            <ClickyLink
              as="icon"
              className={clsx(trash)}
              icon={MdDelete}
              onClick={remove}
              title="Remove"
            />
          )}
        </div>
      )}
      {theme === 'default' && url == null && children}
    </div>
  )
}

export default React.memo(Attachment)
