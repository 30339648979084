import { useQueryClient } from '@tanstack/react-query'
import { orderedPlacementStatus } from '@vangst/services/oogst/company/statuses'
import useUpdatePlacement from '@vangst/services/oogst/company/useUpdatePlacement'
import { PlacementStatusEnum } from '@vangst/services/oogst/types'
import React from 'react'
import Select, { SelectType } from '../../components/forms/Select'

type PropsType = SelectType & {
  readonly placementId: string
}

const options = Object.keys(orderedPlacementStatus).map((status) => ({
  label: orderedPlacementStatus[status as PlacementStatusEnum],
  value: status,
}))

function SelectPlacementStatus(props: PropsType) {
  const { error, hint, label, name, placementId, reference, ...rest } = props
  const id = props?.id ?? name
  const queryClient = useQueryClient()
  const { mutate } = useUpdatePlacement(queryClient)

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const status = e.target.value as PlacementStatusEnum
    mutate({ input: { id: placementId, status } })
  }

  return (
    <Select
      id={id}
      disabled={props.disabled}
      error={error}
      hint={hint}
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      reference={reference}
      {...rest}
    />
  )
}

export default React.memo(SelectPlacementStatus)
