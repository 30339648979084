import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import {
  FetcherError,
  MarkAlertReadMutation,
  MarkAlertReadMutationVariables,
  MemberFragment,
  useMarkAlertReadMutation,
} from '../types'
import {
  ActivitiesAlertsEndpoints,
  invalidateActivitiesAlerts,
} from '../wtf/useActivitiesAlerts'

type MarkAlertReadOptions = UseMutationOptions<
  MarkAlertReadMutation,
  FetcherError,
  MarkAlertReadMutationVariables
>

/**
 * Marks an alert as read by the recipient.
 *
 * When overriding `onSettled` be sure to provide necessary invalidations.
 *
 * @example
 * const apply = useMarkAlertRead({onSuccess: () => push(url)})
 * const apply.mutate({ input: { id: id } })
 */
function useMarkAlertRead(
  endpoint: ActivitiesAlertsEndpoints,
  options?: MarkAlertReadOptions,
) {
  const queryClient = useQueryClient()
  return useMarkAlertReadMutation({
    onSettled: (data) => {
      if (data) {
        invalidateActivitiesAlerts(queryClient, endpoint, {
          username: (data?.markAlertRead?.resource as MemberFragment).username,
        })
      }
    },
    ...options,
  })
}

export default useMarkAlertRead
