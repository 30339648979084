import { BasePageInfoFragment, PageInfoFragment } from '../types'

/**
 * @private
 * Builds a full path utilized by next / prev hrefs.
 *
 * @TODO Possible to pass in uri?
 */
function getPath() {
  if (!globalThis?.location) return undefined
  const pathname = globalThis.location.pathname || ''
  const search = globalThis.location.search || ''
  return `${pathname}${search}${search.length ? '&' : '?'}`
}

// -------------------------------------

/**
 * @protected
 *
 * Typically used by infinite paginated service hooks.
 * Derives infinite pagination properties off a `BasePageInfoFragment`.
 *
 * @TODO Memoize
 */
function getBasePageInfoInfinitePagination(
  basePageInfos: BasePageInfoFragment[],
  limit = 24,
) {
  const visiblePages = basePageInfos.length
  const visibleCount = visiblePages * limit

  const totalCount = basePageInfos[0]?.totalCount
  const totalPages =
    totalCount && limit ? Math.ceil(totalCount / limit) : undefined

  const nextCursor = basePageInfos[basePageInfos.length - 1]?.endCursor
  const prevCursor = basePageInfos[0]?.startCursor

  const hasNextPage =
    nextCursor && totalPages && totalPages > 1 && visiblePages < totalPages
  const hasPrevPage =
    prevCursor && totalPages && totalPages > 1 && visiblePages < totalPages

  const path = getPath()
  const nextHref =
    hasNextPage && path ? `${path}after=${nextCursor}` : undefined
  const prevHref =
    hasPrevPage && path ? `${path}before=${prevCursor}` : undefined

  return {
    nextCursor,
    nextHref,
    prevCursor,
    prevHref,
    totalCount,
    totalPages,
    visibleCount,
    visiblePages,
  }
}

// -------------------------------------

/**
 * @protected
 *
 * Typically used by infinite paginated service hooks.
 * Derives infinite pagination properties off a `PageInfoFragment`.
 *
 * _Note `totalCount` must be provided and should be fetched in the parent entity_
 *
 * @TODO Memoize
 */
function getPageInfoInfinitePagination(
  totalCount: number,
  pageInfos: PageInfoFragment[],
  limit = 24,
) {
  const visiblePages = pageInfos.length
  const visibleCount = visiblePages * limit

  const totalPages =
    totalCount && limit ? Math.ceil(totalCount / limit) : undefined

  const nextCursor = pageInfos[pageInfos.length - 1]?.endCursor
  const prevCursor = pageInfos[0]?.startCursor

  const hasNextPage =
    nextCursor &&
    visibleCount > 0 &&
    !(Math.ceil(visibleCount / limit) >= Math.ceil(totalCount / limit))
  const hasPrevPage =
    prevCursor && visibleCount > 0 && pageInfos[0]?.hasPreviousPage

  const path = getPath()
  const nextHref =
    hasNextPage && path ? `${path}after=${nextCursor}` : undefined
  const prevHref =
    hasPrevPage && path ? `${path}before=${prevCursor}` : undefined

  return {
    nextCursor,
    nextHref,
    prevCursor,
    prevHref,
    totalCount,
    totalPages,
    visibleCount,
    visiblePages,
  }
}

// -------------------------------------

export { getBasePageInfoInfinitePagination, getPageInfoInfinitePagination }
