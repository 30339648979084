import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import { dateToWords } from '@vangst/lib/formatDates'
import unionize from '@vangst/lib/unionize'
import Routes from '../../routes'
import { getDerived as getDerivedCompany } from '../company/useCompany'
import fetcher from '../fetcher'
import {
  CompanyFragment,
  EmploymentTypesEnum,
  FetcherError,
  JobPostingDocument,
  JobPostingFragment,
  JobPostingQuery,
  JobPostingQueryVariables as JobPostingVars,
  useJobPostingQuery as useEndpoint,
} from '../types'

type JobPostingOptions = UseQueryOptions<
  JobPostingQuery,
  FetcherError,
  JobPostingQuery
>

/**
 * @private
 * Retrieve the stringified `JobPosting` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

/**
 * @private
 * Retrieve the `JobPosting` from the queryClient's cache.
 */
function getCache(queryClient: QueryClient, variables: JobPostingVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<JobPostingQuery>(key)
}

// -------------------------------------

export const employmentTypeMappings = {
  [EmploymentTypesEnum.FullTime]: 'Full Time',
  [EmploymentTypesEnum.Temp]: 'Temporary',
  [EmploymentTypesEnum.TempToHire]: 'Temp to Hire',
  [EmploymentTypesEnum.Contract]: 'Contract',
  [EmploymentTypesEnum.PartTime]: 'Part Time',
  [EmploymentTypesEnum.Perm]: 'Permanent Position',
}

export const employmentTypeMappingsSERP = {
  [EmploymentTypesEnum.FullTime]: 'FULL_TIME',
  [EmploymentTypesEnum.Temp]: 'TEMPORARY',
  [EmploymentTypesEnum.TempToHire]: 'TEMPORARY',
  [EmploymentTypesEnum.Contract]: 'CONTRACTOR',
  [EmploymentTypesEnum.PartTime]: 'PART_TIME',
  [EmploymentTypesEnum.Perm]: 'FULL_TIME',
}

/**
 * @private
 * Derives data not contained in the API.
 */
function getDerived(jobPosting?: JobPostingFragment) {
  if (jobPosting == null) return jobPosting
  const displayname = jobPosting.client?.displayname || ''
  const formattedBenefits = jobPosting.employmentBenefits
    ?.map((b) => b.employmentBenefit.name)
    .join(', ')
  return {
    citystate: unionize(
      jobPosting.location?.city,
      jobPosting.location?.state,
      ', ',
    ),
    employmentTypeMappingsSERP: jobPosting.employmentTypes
      ? jobPosting.employmentTypes.map(
          (type: EmploymentTypesEnum) => employmentTypeMappingsSERP[type],
        )
      : undefined,
    employmentTypesFormatted: jobPosting.employmentTypes
      ? jobPosting.employmentTypes
          .map((type: EmploymentTypesEnum) => employmentTypeMappings[type])
          .join(', ')
      : undefined,
    formattedBenefits: formattedBenefits,
    startDateWords: jobPosting.startDate
      ? dateToWords(jobPosting.startDate)
      : undefined,
    endDateWords: jobPosting.endDate
      ? dateToWords(jobPosting.endDate)
      : undefined,
    postedAtWords: jobPosting.postedAt
      ? dateToWords(jobPosting.postedAt)
      : undefined,
    routes: {
      jobsApplications: jobPosting.permissions.readJobApps
        ? Routes.COMPANIES_JOBS_APPLICATIONS.replace(
            ':displayname',
            displayname,
          ).replace(':id', jobPosting.slug)
        : undefined,
      jobsDetail: Routes.JOBS_DETAIL.replace(':id', jobPosting.slug),
      jobsEdit: Routes.COMPANIES_JOBS_EDIT.replace(
        ':displayname',
        displayname,
      ).replace(':id', jobPosting.slug),
      jobsLeads: jobPosting.permissions.readJobApps
        ? Routes.COMPANIES_JOBS_LEADS.replace(
            ':displayname',
            displayname,
          ).replace(':id', jobPosting.slug)
        : undefined,
      jobsPlacement: jobPosting.permissions.readPlacements
        ? Routes.COMPANIES_JOBS_PLACEMENTS.replace(
            ':displayname',
            displayname,
          ).replace(':id', jobPosting.slug)
        : undefined,
      jobsPlacementManage: jobPosting.permissions.readPlacements
        ? Routes.COMPANIES_JOBS_PLACEMENTS_MANAGE.replace(
            ':displayname',
            displayname,
          ).replace(':id', jobPosting.slug)
        : undefined,
    },
    showReceived:
      !jobPosting.permissions.readJobApps && jobPosting.applicationReceived,
    url: Routes.JOBS_DETAIL.replace(':id', jobPosting.slug),
  }
}

/**
 * @private
 * Merge a `JobPostingFragment` with derived data from `JobPosting` and `Company`.
 */
function getComputed(jobPosting?: JobPostingFragment) {
  if (jobPosting == null) return jobPosting
  return {
    ...jobPosting,
    ...getDerived(jobPosting),
    client: {
      ...jobPosting.client,
      ...getDerivedCompany(jobPosting.client as CompanyFragment),
    },
  }
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 * If no variables are passed all `JobPosting` queries will be invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateJobPosting(queryClient, { id: 'Sx8675309' })
 * invalidateJobPosting(queryClient)
 */
function invalidateJobPosting(
  queryClient: QueryClient,
  variables?: JobPostingVars,
) {
  queryClient.invalidateQueries(variables ? getKey(variables) : ['JobPosting'])
}

/**
 * Create a new `JobPosting` within the internal cache's result set.
 *
 * @example
 * optimisticCreateJobPosting(queryClient, { id: 'Sx8675309' }, { title: 'New Gig' })
 */
function optimisticCreateJobPosting(
  queryClient: QueryClient,
  variables: JobPostingVars,
  data: Partial<JobPostingFragment>,
) {
  const key = getKey(variables)
  queryClient.setQueryData(key, data)
}

/**
 * Find and update a `JobPosting` within the internal cache's result set. If an
 * existing result is not found a new one will be optimistically created.
 *
 * @example
 * optimisticUpdateJobPosting(queryClient, { id: 'Sx8675309' }, { title: 'Gig Updated' })
 */
function optimisticUpdateJobPosting(
  queryClient: QueryClient,
  variables: JobPostingVars,
  data: Partial<JobPostingFragment>,
) {
  const key = getKey(variables)
  const prev = queryClient.getQueryData<JobPostingQuery>(key)
  if (!prev) {
    optimisticCreateJobPosting(queryClient, variables, data)
    return
  }
  const next = { getJobPosting: { ...prev.getJobPosting, ...data } }
  queryClient.setQueryData(key, () => next)
}

/**
 * Removes the `JobPosting` from the internal cache's result set.
 *
 * @example
 * optimisticDeleteJobPosting(queryClient, { id: 'Sx8675309' })
 */
function optimisticDeleteJobPosting(
  queryClient: QueryClient,
  variables: JobPostingVars,
) {
  const key = getKey(variables)
  queryClient.removeQueries(key, { exact: true })
}

/**
 * Prefetch and return the computed `JobPosting` from an `id`.
 *
 * @TODO: The shape of the data from the API is awkward and we need to test out https://github.com/vangst/oogst/pull/1317
 * @TODO: Instead of sending a `is403` we should move to `http-status-codes`
 * @TODO: Revisit the try/catch block and see how we can automatically incorporate that in the fetcher
 *
 * @example
 * const jobPosting = await prefetchJobPosting(queryClient, { id: 'Sx8675309' })
 */
async function prefetchJobPosting(
  queryClient: QueryClient,
  variables: JobPostingVars,
) {
  const key = getKey(variables)
  const fn = fetcher<JobPostingQuery | FetcherError, JobPostingVars>(
    JobPostingDocument,
    variables,
  )

  let data

  try {
    data = await queryClient.fetchQuery(key, fn)
  } catch (e) {
    // Silently catching this for now :|
  }

  const is403 = !!(data as FetcherError)?.response?.errors?.some(
    (e) =>
      e.extensions?.code === 'UNAUTHORIZED' ||
      e.extensions?.code === 'ACTION_UNAUTHORIZED',
  )

  const cache = getCache(queryClient, variables)
  return cache?.getJobPosting
    ? { getJobPosting: getComputed(cache.getJobPosting), is403 }
    : { ...cache, is403 }
}

/**
 * Fetch a `JobPostingFragment` with derived data.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data } = useJobPosting({ id: 'Sx8675309' }, options)
 * const { data } = useJobPosting({ id: node.id }, { initialData: node })
 *
 * @TODO Compute data in `select`
 * @TODO Tune options
 */
function useJobPosting(variables: JobPostingVars, options?: JobPostingOptions) {
  const query = useEndpoint(variables, {
    enabled: options?.initialData == null,
    // staleTime: 10000,
    ...options,
  })
  const d = (options?.initialData as JobPostingQuery) || query?.data
  const data = d ? { getJobPosting: getComputed(d.getJobPosting) } : d
  const uri = Routes.JOBS_DETAIL.replace(
    ':id',
    data?.getJobPosting?.slug || variables.id,
  )
  return { ...query, data, uri }
}

// -------------------------------------

export type JobPostingComputed = ReturnType<typeof getComputed>

export type { JobPostingFragment, JobPostingOptions, JobPostingVars }
export {
  invalidateJobPosting,
  optimisticCreateJobPosting,
  optimisticDeleteJobPosting,
  optimisticUpdateJobPosting,
  prefetchJobPosting,
}
export default useJobPosting
