type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly color?: string
  readonly plane?: 'both' | 'horizontal' | 'vertical'
  readonly size?: number
}

const grid = (props: PropsType) => {
  const { color, plane, size } = props
  const cl =
    plane === 'horizontal'
      ? ''
      : `<line x1='${size}' y1='0' x2='${size}' y2='${size}'/>`
  const rl =
    plane === 'vertical'
      ? ''
      : `<line x1='0' y1='${size}' x2='${size}' y2='${size}'/>`
  return `data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg'><g stroke='${color}'>${cl}${rl}</g></svg>`
}

/**
 * An debug grid overlay used for aligning elements.
 *
 * @example
 * <Xray className="h-40 w-full border border-magenta" />
 * <Xray
 *   className="h-40 w-full border border-magenta"
 *   color="magenta"
 *   plane="both"
 *   size={32}
 * />
 */
function Xray(props: PropsType) {
  const {
    color = 'rgba(255, 0, 255, 0.25)',
    size = 16,
    plane = 'both',
    ...rest
  } = props

  const style = {
    backgroundImage: `url("${grid({ color, plane, size })}")`,
    backgroundPosition: '0 0',
    backgroundSize: `${size}px ${size}px`,
  }
  return <div style={style} {...rest} />
}

export default Xray
