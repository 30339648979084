import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import { MemberSummaryFragment } from '@vangst/services/oogst/types'
import ClickyLink from '../../../components/navigation/ClickyLink'
import ZeroState from './ZeroState'

type PropsType = {
  href: string
  isCurrentUser?: boolean
  summary: MemberSummaryFragment
  showEmptyState?: boolean
}

export default function Summary(props: PropsType) {
  const { href, isCurrentUser, summary, showEmptyState } = props

  return (
    <>
      {!showEmptyState ? (
        <div key={summary?.id} className="grid gap-4">
          {!isEmptyOrNil(summary?.title) && (
            <h2 className="heading-sm">{summary?.title}</h2>
          )}
          <span>
            {summary?.entry.split(/\n/).map((line, i) => (
              <span className="block" key={line + i}>
                {line}&nbsp;
              </span>
            ))}
          </span>
        </div>
      ) : (
        <ZeroState
          title={
            isCurrentUser
              ? "! Let employers know why you're a can't-miss candidate:"
              : undefined
          }
        >
          {isCurrentUser ? (
            <p className="flow-y-sm italic text-grey-dark">
              <span>
                Provide the accomplishments you are most proud of, things you
                know, and what you’re looking to do next. Confidence is key, but
                bravado is a turn-off. At the end of the day, the more detailed
                your proof points, the better your chances. Add your “about me”
                overview info.
              </span>
              <ClickyLink href={href} className="link-orange underline">
                Add your "about me" overview info.
              </ClickyLink>
            </p>
          ) : (
            <p className="italic text-grey-dark">
              No information has been provided by this user yet. Feel free to
              connect with them to inquire personally if you’re interested.
            </p>
          )}
        </ZeroState>
      )}
    </>
  )
}
