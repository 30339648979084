import Routes from '@vangst/services/routes'
import { MdErrorOutline } from 'react-icons/md'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import ClickyLink from '../../../components/navigation/ClickyLink'

type PropsType = {
  handleCancel: () => void
}

function DialogDuplicateName({ handleCancel }: PropsType) {
  return (
    <DialogModal onClose={handleCancel} open>
      <DialogModal.Panel
        icon={MdErrorOutline}
        title="Company name already exists."
      >
        <DialogModal.Description className="flow-y-xs my-8">
          <span>
            Looks like the company name you entered is being used for another
            account.
          </span>
          <span>
            If you're a multi-state operator trying to add another location, you
            can do so on your existing company profile in the new "Additional
            Locations" area.
          </span>
          <span>
            If you are trying to create a new account for a dedicated location
            page, you'll need to reflect that in the company name, or better
            yet, contact us at{' '}
            <ClickyLink href={Routes.MAILTO_SUPPORT} className="link-orange">
              support@vangst.com
            </ClickyLink>{' '}
            so we can help you out.
          </span>
        </DialogModal.Description>
        <div className="flex w-full items-center justify-center gap-4">
          <ClickyBtn className="mood-orange w-full" onClick={handleCancel}>
            Close
          </ClickyBtn>
        </div>
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default DialogDuplicateName
