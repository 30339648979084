import { animate } from 'framer-motion'
import { useEffect, useRef } from 'react'

type PropsType = React.HTMLAttributes<HTMLSpanElement> & {
  readonly to: number
}

/**
 * Counter is a fancy `span` element which uses framer-motion's `animate` to
 * transition between a set of numbers.
 *
 * @see [framer.motions animate function](https://www.framer.com/motion/animate-function/)
 *
 * @example
 * <Counter to={666} />
 */
function Counter({ to, ...rest }: PropsType) {
  const ref = useRef<HTMLSpanElement>(null)
  useEffect(() => {
    const initialTextContent = ref?.current?.textContent || '0'
    const from = Number(initialTextContent.replace(/[^0-9.-]/g, '')) || 0
    const controls = animate(from, to, {
      ease: 'easeOut',
      onUpdate(value) {
        if (ref != null && ref.current != null)
          ref.current.textContent = value?.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
      },
    })
    return () => controls.stop()
  }, [to])
  return <span data-counter={to} ref={ref} {...rest} />
}

export default Counter
