import { QueryClient, UseMutationOptions } from '@tanstack/react-query'
import { invalidateMember } from '../member/useMember'
import {
  FetcherError,
  UpdatePlacementMutation,
  UpdatePlacementMutationVariables,
  useUpdatePlacementMutation,
} from '../types'
import { invalidateCompanyJobApplication } from './useCompanyJobApplication'
import { invalidateCompanyJobPlacements } from './useCompanyJobPlacements'

type UpdatePlacementOptions = UseMutationOptions<
  UpdatePlacementMutation,
  FetcherError,
  UpdatePlacementMutationVariables
>

function useUpdatePlacement(
  queryClient: QueryClient,
  options?: UpdatePlacementOptions,
) {
  return useUpdatePlacementMutation({
    onSettled: () => {
      invalidateCompanyJobPlacements(queryClient)
      invalidateMember(queryClient)
      invalidateCompanyJobApplication(queryClient)
    },
    ...options,
  })
}

export default useUpdatePlacement
