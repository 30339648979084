import { MemberLicenseFragment } from '@vangst/services/oogst/types'
import ClickyLink from '../../../components/navigation/ClickyLink'
import NonZeroState from './NonZeroState'
import ZeroState from './ZeroState'

type PropsType = {
  href: string
  showEmptyState?: boolean
  licenses?: MemberLicenseFragment[]
  readPermissions?: boolean
}

export default function Licenses(props: PropsType) {
  const { href, licenses, readPermissions, showEmptyState } = props

  return (
    <>
      {!showEmptyState ? (
        <NonZeroState title="Licenses:">
          {licenses?.map((license, index) => (
            <li key={license.id} className="bullet mb-2 ml-4 text-sm font-600">
              {readPermissions && license?.url ? (
                <ClickyLink
                  className="link-orange underline"
                  href={license?.url}
                >
                  {license?.license?.name ?? 'View License'}
                </ClickyLink>
              ) : (
                <span>{license?.license?.name ?? `License #${index + 1}`}</span>
              )}

              {license?.number != null && (
                <ul>
                  <li className="bullet ml-4 text-sm">No. {license?.number}</li>
                </ul>
              )}
            </li>
          ))}
        </NonZeroState>
      ) : (
        <ZeroState title="! Licenses:" border>
          <p className="flow-y-sm italic">
            <span>
              Employers can search for you by any license or badge you have
              acquired. Stand out and who off those credentials!
            </span>
            <ClickyLink href={href} className="link-orange underline">
              Add licenses by editing your profile.
            </ClickyLink>{' '}
          </p>
        </ZeroState>
      )}
    </>
  )
}

// --------------- Serializers ---------------------------
export const serializeForForm = (licenses: MemberLicenseFragment[]) => {
  return licenses?.map((license: MemberLicenseFragment) => ({
    date: license?.date,
    id: license?.id,
    licenseId: license?.license?.id,
    number: license?.number,
    url: license?.url,
  }))
}
