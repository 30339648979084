import { useCreatePage, useUpdatePage } from '@vangst/services/oogst/page'
import { PageInput, PageTypeEnum } from '@vangst/services/oogst/types'
import { memo } from 'react'
import { useForm } from 'react-hook-form'
import Input from '../../components/forms/Input'
import InputCounter from '../../components/forms/InputCounter'
import Textarea from '../../components/forms/Textarea'
import TextareaCounter from '../../components/forms/TextareaCounter'
import ClickyBtn from '../../components/navigation/ClickyBtn'
import AttachmentPhoto from '../company/forms/AttachmentPhoto'
import ChooseRobots from './forms/ChooseRobots'
import SelectPageType from './forms/SelectPageType'

type PropsType = {
  readonly close: () => void
  readonly page?: PageInput
}

function PageEditForm(props: PropsType) {
  const { close, page } = props
  const create = useCreatePage()
  const update = useUpdatePage()
  const { handleSubmit, register, formState, setValue } = useForm<PageInput>({
    defaultValues: {
      ...page,
      pageType: (page?.pageType as PageTypeEnum) || PageTypeEnum.LandingPage,
    },
  })

  const handleOnSubmit = async (page: PageInput) => {
    if (page.pagePhotos?.[0]) {
      if (!page.pagePhotos?.[0].url) {
        // @ts-ignore
        page.pagePhotos = []
      } else {
        // @ts-ignore - this value is added by AttachmentPhoto, not needed here
        delete page.pagePhotos[0].displayRole
        // @ts-ignore - sending photo id messes up the backend logic
        delete page.pagePhotos[0].id
        // @ts-ignore
        page.pagePhotos[0].width = Number(page.pagePhotos[0].width)
        // @ts-ignore
        page.pagePhotos[0].height = Number(page.pagePhotos[0].height)
      }
    }
    const variables = { input: { page } }
    const options = {
      onSuccess: () => {
        close()
        // TODO: Hammer it. The Page request happens server-side
        globalThis.location?.reload()
      },
    }
    const result =
      props.page == null
        ? create.mutate(variables, options)
        : update.mutate(variables, options)
    return result
  }

  const pagePhoto = page?.pagePhotos?.[0]

  const { ref: pageTypeRef, ...pageTypeProps } = register('pageType')
  const { ref: robotsRef, ...robotsProps } = register('metaInfo.robots')

  return (
    <form
      className="relative flex w-full flex-col gap-8"
      onSubmit={handleSubmit(handleOnSubmit)}
      onReset={close}
    >
      <fieldset>
        <section>
          <h2
            id="training-courses"
            className="mood-orange flex h-16 items-center pl-6 font-600"
          >
            Meta Info
          </h2>

          <div className="mood-grey-lightest mt-4 flex w-full flex-col gap-8 p-4">
            <InputCounter
              error={formState.errors?.metaInfo?.title?.message}
              label="Meta Title"
              len={page?.metaInfo.title?.length || 0}
              max={160}
              {...register('metaInfo.title')}
            />
            <TextareaCounter
              error={formState.errors?.metaInfo?.description?.message}
              len={page?.metaInfo.description?.length || 0}
              max={160}
              label="Meta Description"
              placeholder="Add a meta description about this page."
              {...register('metaInfo.description')}
            />
            <InputCounter
              error={formState.errors?.metaInfo?.canonicalUrl?.message}
              label="Canonical URL"
              len={page?.metaInfo.canonicalUrl?.length || 0}
              max={2048}
              {...register('metaInfo.canonicalUrl')}
            />
            <ChooseRobots
              error={formState.errors?.metaInfo?.robots?.message}
              label="Robots"
              layout="row"
              reference={robotsRef}
              restProps={robotsProps}
              name="robots"
            />
          </div>
          {/* Image Url? */}
        </section>
      </fieldset>

      <fieldset>
        <section>
          <h2
            id="training-courses"
            className="mood-orange flex h-16 items-center pl-6 font-600"
          >
            Page Info
          </h2>
          <div className="mood-grey-lightest mt-4 flex w-full flex-col gap-8 p-4">
            {/* We'll eventually want this but not quite yet. */}
            {false && (
              <SelectPageType
                hideLabel
                error={formState.errors?.pageType?.message}
                label="Select size of company"
                reference={pageTypeRef}
                {...pageTypeProps}
              />
            )}
            <InputCounter
              error={formState.errors?.title?.message}
              label="Page Title"
              len={page?.title?.length || 0}
              max={160}
              {...register('title')}
            />
            <InputCounter
              error={formState.errors?.subtitle?.message}
              label="Page Subtitle"
              len={page?.subtitle?.length || 0}
              max={160}
              {...register('subtitle')}
            />
            <Textarea
              className="min-h-64"
              error={formState.errors?.description?.message}
              label="Page Description"
              placeholder="Add a description about this page."
              {...register('description')}
            />
            <Textarea
              error={formState.errors?.markdownContent?.message}
              label="Markdown Content"
              placeholder="Add markdown content."
              {...register('markdownContent')}
            />
            <span className="-mb-6 text-lg font-600">Page Photo:</span>
            <div className="flow-x-sm">
              <Input
                error={formState.errors?.pagePhotos?.message}
                label="Height"
                min={1}
                type="number"
                step="0.01"
                // @ts-ignore
                {...register('pagePhotos[0].height')}
              />
              <Input
                error={formState.errors?.pagePhotos?.message}
                label="Width"
                min={1}
                type="number"
                step="0.01"
                // @ts-ignore
                {...register('pagePhotos[0].width')}
              />
            </div>
            <Input
              error={formState.errors?.pagePhotos?.message}
              label="Alt"
              // @ts-ignore
              {...register('pagePhotos[0].alt')}
            />
            <AttachmentPhoto
              register={register}
              setValue={setValue}
              defaultValue={pagePhoto}
              as="hero"
              name={`pagePhotos[0]`}
            />
          </div>
        </section>
      </fieldset>

      <div className="mt-8 flex justify-evenly gap-4">
        <ClickyBtn
          className="mood-white w-5/10"
          disabled={update.isLoading}
          type="reset"
        >
          Cancel
        </ClickyBtn>
        <ClickyBtn
          className="mood-orange w-5/10"
          disabled={update.isLoading}
          type="submit"
        >
          {update.isLoading ? 'Sending' : 'Submit'}
        </ClickyBtn>
      </div>
    </form>
  )
}

export default memo(PageEditForm)
