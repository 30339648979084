import Routes from '@vangst/services/routes'
import SocialFacebook from '../../../components/assets/social/SocialFacebook'
import SocialInstagram from '../../../components/assets/social/SocialInstagram'
import SocialLinkedIn from '../../../components/assets/social/SocialLinkedIn'
import SocialTwitter from '../../../components/assets/social/SocialTwitter'
import ClickyLink from '../../../components/navigation/ClickyLink'

/**
 * The social icon links within the `Footer`
 */
export default function FooterSocialLinks() {
  return (
    <div aria-label="Social Links" className="flow-x-sm">
      <ClickyLink
        as="icon"
        className="text-blue hover:text-orange"
        href={Routes.INSTAGRAM}
        icon={SocialInstagram}
        title="Instagram"
      >
        Instagram
      </ClickyLink>
      <ClickyLink
        as="icon"
        className="text-blue hover:text-orange"
        href={Routes.TWITTER}
        icon={SocialTwitter}
        title="Twitter"
      >
        Twitter
      </ClickyLink>
      <ClickyLink
        as="icon"
        className="text-blue hover:text-orange"
        href={Routes.FACEBOOK}
        icon={SocialFacebook}
        title="Facebook"
      >
        Facebook
      </ClickyLink>
      <ClickyLink
        as="icon"
        className="text-blue hover:text-orange"
        href={Routes.LINKED_IN}
        icon={SocialLinkedIn}
        title="LinkedIn"
      >
        LinkedIn
      </ClickyLink>
    </div>
  )
}
