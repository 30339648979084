import { useState } from 'react'
import DialogScreeningAnswers from '../../job/feedback/DialogScreeningAnswers'

type PropsType = {
  readonly applicationId: string
  readonly className?: string
}

function ViewScreening(props: PropsType) {
  const { applicationId, className } = props
  const [shown, setShown] = useState(false)

  return (
    <>
      <button className={className} onClick={() => setShown(true)}>
        View Screening
      </button>
      {shown && (
        <DialogScreeningAnswers
          applicationId={applicationId}
          handleClose={() => setShown(false)}
        />
      )}
    </>
  )
}

export default ViewScreening
