import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import fetcher from '../fetcher'
import {
  FetcherError,
  ScreeningsDocument,
  ScreeningsQuery,
  ScreeningsQueryVariables as ScreeningsVars,
  useScreeningsQuery as useEndpoint,
} from '../types'

type ScreeningsOptions = UseQueryOptions<
  ScreeningsQuery,
  FetcherError,
  ScreeningsQuery
>

/**
 * @private
 * Retrieve the stringified `Screenings` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

/**
 * @private
 * Retrieve the `Screenings` from the queryClient's cache.
 */
function getCache(queryClient: QueryClient, variables?: ScreeningsVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<ScreeningsQuery>(key)
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateScreenings(queryClient)
 */
function invalidateScreenings(
  queryClient: QueryClient,
  variables?: ScreeningsVars,
) {
  queryClient.invalidateQueries(variables ? getKey(variables) : ['Screenings'])
}

/**
 * Prefetch and return the list of `Screenings`.
 *
 * @example
 * const screenings = await prefetchScreenings(queryClient)
 */
async function prefetchScreenings(
  queryClient: QueryClient,
  variables?: ScreeningsVars,
) {
  const key = getKey(variables)
  const fn = fetcher<ScreeningsQuery, ScreeningsVars>(
    ScreeningsDocument,
    variables,
  )
  await queryClient.prefetchQuery(key, fn)
  return getCache(queryClient, variables)
}

/**
 * Fetch a `ScreeningsFragment` and derive a `ScreeningsComputed` data set.
 * Passing `initialData` will hydrate the cache.
 *
 * The staleTime is set to Infinity for a single fetch.
 *
 * @example
 * const { data, uri } = useScreenings()
 * const { data, uri } = useScreenings(_, { initialData: node })
 */

function useScreenings(
  variables?: ScreeningsVars,
  options?: ScreeningsOptions,
) {
  const query = useEndpoint(variables, {
    staleTime: Infinity,
    ...options,
  })
  return query
}

// -------------------------------------

export { invalidateScreenings, prefetchScreenings }
export default useScreenings
