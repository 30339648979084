import { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import ControlGroup from '../../../components/forms/ControlGroup'
import Radio from '../../../components/forms/Radio'
import validations from '../validations'

type PropsType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly hint?: string | null
  readonly label: string
  readonly layout?: 'col' | 'row'
  readonly name: string
}

function ChooseBoolean(props: PropsType) {
  const { hint, label, layout, name, required } = props

  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { ref, ...formProps } = register(
    name,
    required ? validations.generic : {},
  )

  const splitName = name.split('.')
  const formErrorMessage =
    splitName.length === 2
      ? errors?.[splitName[0]]?.[splitName[1]]?.message
      : errors?.[name]?.message

  return (
    <ControlGroup
      error={formErrorMessage}
      hint={hint}
      label={label}
      layout={layout}
      name={name}
      required={required}
      type="radio"
    >
      <Radio
        id={`${name}-true`}
        label="Yes"
        reference={ref}
        value="true"
        {...formProps}
      />
      <Radio
        id={`${name}-false`}
        label="No"
        reference={ref}
        value="false"
        {...formProps}
      />
    </ControlGroup>
  )
}

export default memo(ChooseBoolean)
