import { RobotsEnum } from '@vangst/services/oogst/types'
import ControlGroup from '../../../components/forms/ControlGroup'
import Radio from '../../../components/forms/Radio'

type PropsType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly error?: string
  readonly hint?: string | null
  readonly label: string
  readonly layout?: 'col' | 'row'
  readonly name: string
  readonly reference?: React.Ref<HTMLInputElement>
  readonly restProps?: object
}

function ChooseRobots(props: PropsType) {
  const { error, hint, label, layout, name, reference, restProps } = props
  return (
    <ControlGroup
      error={error}
      hint={hint}
      label={label}
      layout={layout}
      name={name}
      type="radio"
    >
      <Radio
        id={`${name}-${RobotsEnum.NoindexNofollow}`}
        label="noindex, nofollow"
        name={name}
        reference={reference}
        value={RobotsEnum.NoindexNofollow}
        {...restProps}
      />
      <Radio
        id={`${name}-${RobotsEnum.IndexFollow}`}
        label="index, follow"
        name={name}
        reference={reference}
        value={RobotsEnum.IndexFollow}
        {...restProps}
      />
    </ControlGroup>
  )
}

export default ChooseRobots
