import useJobPosting from '@vangst/services/oogst/jobPosting/useJobPosting'
import {
  JobPosting_ScreeningFragment,
  useCreateScreeningSubmissionMutation,
} from '@vangst/services/oogst/types'
import { useRouter } from 'next/router'
import { HTMLAttributes } from 'react'
import { useForm } from 'react-hook-form'
import { MdNotes } from 'react-icons/md'
import { ScreeningQuestionFragment } from '../../../../../packages/services/oogst/types'
import Busy from '../../../components/feedback/Busy'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ControlGroup from '../../../components/forms/ControlGroup'
import Input from '../../../components/forms/Input'
import Radio from '../../../components/forms/Radio'
import Textarea from '../../../components/forms/Textarea'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import validations from '../../application/validations'

type PropsType = {
  readonly handleCancel: () => void
  readonly applicationId: string
  readonly jobPostingScreening?: JobPosting_ScreeningFragment
} & HTMLAttributes<HTMLDivElement>

type DynamicScreeningForm = {
  [key: string]: string
}

function DialogScreenApplicant(props: PropsType) {
  const { handleCancel, applicationId, jobPostingScreening } = props
  const { register, handleSubmit, formState } = useForm()
  const { query } = useRouter()

  const id = query?.id ?? null
  const { data, isLoading } = useJobPosting(
    { id: id as string },
    // @gross: rely on the cached job posting, othewise we trigger an
    // invalidation on the useJobPosting hook
    { enabled: false },
    // { enabled: !jobPostingScreening && id != null },
  )
  const jobPostingScreeningData =
    jobPostingScreening || data?.getJobPosting?.jobPostingScreening
  const { mutate } = useCreateScreeningSubmissionMutation()

  if (isLoading) return <Busy />

  const questions = jobPostingScreeningData?.screening?.screeningQuestions || []

  const screeningId = jobPostingScreeningData?.id || ''

  const onSubmit = (fd: DynamicScreeningForm) => {
    const input = {
      screeningQResponsesAttributes: formatScreenQuestions(fd),
      jobApplicationId: applicationId,
      jobPostingScreeningId: screeningId,
    }
    mutate({ input }, { onSuccess: () => globalThis.location?.reload() })
  }

  const formatScreenQuestions = (questions: DynamicScreeningForm) => {
    return Object.keys(questions).map((key) => {
      return {
        screeningQuestionId: key,
        answer: questions[key],
      }
    })
  }

  return (
    <DialogModal onClose={handleCancel} open>
      <DialogModal.Panel icon={MdNotes} title="Screen Applicant">
        <form className="min-w-100" onSubmit={handleSubmit(onSubmit)}>
          {questions.map((question: ScreeningQuestionFragment) => (
            <div key={question.id}>
              {question.typeOf === 'input' && (
                <Input
                  className="my-4"
                  error={formState?.errors?.[question.id]?.message}
                  label={question.question || ''}
                  type="text"
                  required
                  {...register(question.id, validations.generic)}
                />
              )}

              {question.typeOf === 'textarea' && (
                <Textarea
                  className="my-4"
                  error={formState?.errors?.[question.id]?.message}
                  label={question.question || ''}
                  required
                  {...register(question.id, validations.generic)}
                />
              )}
              {question.typeOf === 'boolean' && (
                <div className="my-4">
                  <ControlGroup
                    error={formState?.errors?.[question.id]?.message}
                    label={question.question || ''}
                    name={question.id}
                    type="radio"
                  >
                    <Radio
                      id={`${question.id}-true`}
                      label="Yes"
                      value="true"
                      {...register(question.id, validations.generic)}
                    />
                    <Radio
                      id={`${question.id}-false`}
                      label="No"
                      value="false"
                      {...register(question.id, validations.generic)}
                    />
                  </ControlGroup>
                </div>
              )}
              {question.typeOf === 'date' && (
                <Input
                  error={formState?.errors?.[question.id]?.message}
                  label={question.question || ''}
                  type="date"
                  required
                  {...register(question.id, validations.generic)}
                />
              )}
            </div>
          ))}
          <span className="flow-x-sm mt-4 w-full">
            <ClickyBtn type="submit" className="mood-orange w-full">
              Submit
            </ClickyBtn>
            <ClickyBtn onClick={handleCancel} type="reset" className="w-full">
              Cancel
            </ClickyBtn>
          </span>{' '}
        </form>
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default DialogScreenApplicant
