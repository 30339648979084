import { PageTypeEnum } from '@vangst/services/oogst/types'
import Select, { SelectType } from '../../../components/forms/Select'

const options = () => [
  { label: 'Select Page Type', value: '' },
  ...Object.values(PageTypeEnum).map((k) => ({ label: k, value: k })),
]

function SelectPageType(props: SelectType) {
  const { error, hint, label, name, reference, ...rest } = props
  const id = props?.id ?? name
  return (
    <Select
      id={id}
      disabled={props.disabled}
      error={error}
      hint={hint}
      label={label}
      name={name}
      options={options()}
      reference={reference}
      {...rest}
    />
  )
}

export default SelectPageType
