import { JobPostingLicenseInput } from '@vangst/services/oogst/types'
import { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import HiddenInput from '../../../components/forms/HiddenInput'
import ClickyLink from '../../../components/navigation/ClickyLink'
import SelectLicense from '../../member/forms/SelectLicense'

type PropsType = {
  readonly defaultValue?: JobPostingLicenseInput
  readonly index: number
  readonly remove: () => void
}

function FieldsLicenses(props: PropsType) {
  const { defaultValue, index, remove } = props
  const { formState, register } = useFormContext()

  const { ref: licenseIdRef, ...licenseIdRefProps } = register(
    `requiredLicenses.${index}.licenseId`,
  )

  return (
    <div className="flex w-full items-center gap-2">
      <SelectLicense
        defaultValue={defaultValue?.licenseId || undefined}
        error={formState?.errors?.licenseId?.message}
        hideLabel
        label="Choose License"
        reference={licenseIdRef}
        {...licenseIdRefProps}
      />
      <HiddenInput
        defaultValue={''}
        {...register(`requiredLicenses.${index}.id`)}
      />
      <ClickyLink
        as="icon"
        className="link-orange"
        icon={MdClose}
        onClick={remove}
        title="Remove"
      >
        Remove
      </ClickyLink>
    </div>
  )
}

// -------------------------------------

export default memo(FieldsLicenses)
