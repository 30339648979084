import useCompany, {
  CompanyOptions,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import { memo } from 'react'
import { MdFormatListBulleted } from 'react-icons/md'
import GroupHeader from '../../components/views/GroupHeader'

type PropsType = React.HTMLAttributes<HTMLUListElement> & {
  readonly at: 'content' | 'disclosure'
  readonly variables: CompanyVars
  readonly options?: CompanyOptions
}

function CompanyBenefitsRenderer(props: PropsType) {
  const { at, variables, options } = props
  const { data } = useCompany(variables, options)
  const company = data?.getClient

  const benefits = company?.companyEmploymentBenefits || []
  if (!benefits.length) {
    return null
  }

  if (at === 'disclosure') {
    return (
      <section>
        <h2 className="mood-orange flex h-16 items-center pl-6 font-600">
          Benefits & Perks
        </h2>
        <div className="mood-grey-lightest grid grid-cols-4 gap-4 p-4">
          {benefits.map((benefit, i: number) => (
            <div key={i} className="text-left text-sm">
              {benefit.employmentBenefit.name}
            </div>
          ))}
        </div>
      </section>
    )
  }

  return (
    <section id="benefits" className="mt-20">
      <GroupHeader
        className="items-center justify-between"
        icon={MdFormatListBulleted}
        title="Benefits & Perks"
      ></GroupHeader>
      <div className="mt-10 grid grid-cols-2 gap-8 sm:-ml-8 sm:grid-cols-5">
        {benefits.map((benefit, i: number) => (
          <div
            key={i}
            className="flex flex-col items-center justify-items-start"
          >
            <div className="text-center text-sm">
              {benefit.employmentBenefit.name}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default memo(CompanyBenefitsRenderer)
