import { forwardRef } from 'react'
import Textarea, { TextareaType } from './Textarea'
import useCount, { useCountType } from './useCount'

type PropsType = useCountType &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  TextareaType & {
    readonly ref?: React.Ref<TextareaType>
    readonly name: string
  }

/**
 * The `Textarea` component displayed with a character counter.
 *
 * @example
 * <TextareaCounter
 *   len={10}
 *   max={20}
 *   label="TextareaCounter"
 *   name="TextareaCounter"
 *   defaultValue="Type more things"
 * />
 */
const TextareaCounter = forwardRef<HTMLTextAreaElement, PropsType>(
  function TextareaCounter(props, ref) {
    const { len, max, min, name, required, ...rest } = props

    const { handleChange, count, overMax, underMin } = useCount({
      len,
      max,
      min,
    })

    return (
      <Textarea
        count={count}
        overMax={overMax}
        name={name}
        underMin={underMin}
        reference={ref}
        required={required}
        {...rest}
        onChange={handleChange}
      />
    )
  },
)

export default TextareaCounter
