import PageEditDialog from '../admin/feedback/PageEditDialog'
import MessagesWidgetToggle from '../messaging/MessagesWidgetToggle'

function FloatControls() {
  return (
    <div className="pointer-events-none fixed bottom-0 right-0 z-40 w-full pb-4 pr-4">
      <div className="contain-c flex justify-end gap-2 [&_*]:pointer-events-auto">
        <PageEditDialog />
        <MessagesWidgetToggle isWidget />
      </div>
    </div>
  )
}

export default FloatControls
