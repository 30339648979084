import clsx from '@vangst/lib/clsx'
import {
  MemberComputed,
  MemberOptions,
  MemberVars,
} from '@vangst/services/oogst/member/useMember'
import { MdMoreHoriz } from 'react-icons/md'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'
import MemberLabel from '../MemberLabel'
import MemberActionsMenu from '../navigation/MemberActionsMenu'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly isFeatured?: boolean
  readonly jobPostingId?: string
  readonly member?: MemberComputed
  readonly options?: MemberOptions
  readonly title: string
  readonly uri: string
  readonly variables: MemberVars
}

function MemberCard(props: PropsType) {
  const {
    className,
    isFeatured,
    jobPostingId,
    member,
    options,
    title,
    uri,
    variables,
    ...rest
  } = props

  const loading = member == null ? <MdMoreHoriz /> : undefined
  const skills = member?.candidate?.skillRelations
  const experiences = member?.candidate?.experiences

  return (
    <div
      className={clsx(
        'boxshadow boxlink mood-white relative flex h-80 w-full flex-col gap-4 rounded-md pt-4',
        className,
      )}
      {...rest}
    >
      {/* ... */}
      {/* Header */}
      <div className="grid grid-cols-[3rem,1fr] items-center gap-4 px-4">
        <Avatar
          alt={member?.initials ?? '...'}
          className="mood-grey shrink-0"
          src={member?.avatarUrl || undefined}
        />
        <div className="z-20 flex justify-end">
          <MemberLabel variables={variables} />
          <MemberActionsMenu
            jobPostingId={jobPostingId}
            variables={variables}
            options={options}
            url={uri}
          />
        </div>
      </div>

      {/* Body */}
      <div className="px-4">
        <div>
          <h2 className="line-clamp-1 text-sm" title={`@${variables.username}`}>
            <ClickyLink href={uri} className="hitarea">
              {title}
            </ClickyLink>
          </h2>
          <p className="line-clamp-1 text-[12px] font-400">
            {loading ?? member?.citystate ?? ''}
          </p>
        </div>
        <ul
          className={clsx(
            'mt-4 flex h-18 flex-wrap gap-2 overflow-hidden',
            skills != null && skills.length ? 'mb-4' : 'hidden',
          )}
        >
          {skills?.map((skill, index) => (
            <li
              className="mood-blue-dark flex h-8 items-center rounded-full px-4 py-2 text-[12px] font-400 leading-none text-white"
              key={`${variables.username}-skill-${index}`}
            >
              <span className="line-clamp-1">
                {(skill as any)?.name || skill?.skill.name}
              </span>
              <span className="ml-2">{skill?.years}</span>
            </li>
          ))}
        </ul>

        <p className="line-clamp-4 text-[12px] font-400">
          <span className="line-clamp-4">
            {loading ?? member?.candidateSummary ?? ''}
          </span>
        </p>

        {(!skills || !skills.length) &&
          !member?.candidateSummary &&
          experiences &&
          experiences.length > 0 && (
            <div className="overflow-hidden">
              <p className="mt-6 text-sm">
                <span className="font-700">Work Experience:</span>
              </p>
              <ul className="ml-4 mt-2 list-disc gap-2">
                {experiences?.slice(0, 3).map((experience, index) => (
                  <li
                    className="mt-1 text-sm"
                    key={`${variables.username}-experience-${index}`}
                  >
                    <span className="">{experience?.jobTitle}</span>
                    {experience?.companyName && (
                      <span className="ml-2">({experience?.companyName})</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
      </div>
    </div>
  )
}

export default MemberCard
