import isUrl from '@vangst/lib/isUrl'
import normalizeUrl from '@vangst/lib/normalizeUrl'
import { Editor, Element as SlateElement, Node, Range, Transforms } from 'slate'
import { BlockFormatType, CustomEditor } from './customTypes'

export const withLinks = (editor: CustomEditor) => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = (element: SlateElement) => {
    // @ts-ignore
    return element.type === BlockFormatType.link ? true : isInline(element)
  }

  editor.insertText = (text: string) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data: DataTransfer) => {
    const text = data.getData('text/plain')
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

export const insertLink = (editor: CustomEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, normalizeUrl(url))
  }
}

export const isLinkActive = (editor: CustomEditor) => {
  // @ts-ignore TODO downline iteration?
  const [link] = Editor.nodes(editor, {
    match: (n: Node) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      // @ts-ignore
      n.type === BlockFormatType.link,
  })
  return !!link
}

export const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      // @ts-ignore
      n.type === BlockFormatType.link,
  })
}

export const wrapLink = (editor: CustomEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: BlockFormatType.link,
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }
  if (isCollapsed) {
    // @ts-ignore
    Transforms.insertNodes(editor, link)
  } else {
    // @ts-ignore
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
    Transforms.move(editor, {
      distance: 9999,
      unit: 'offset',
    })
  }
}
