import { Combobox } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import {
  CompanyComputed,
  CompanyOptions,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import Avatar from '../../../components/views/Avatar'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly company?: CompanyComputed
  readonly title: string
  readonly uri: string
  readonly variables: CompanyVars
  readonly options?: CompanyOptions
}

function CompanyMenuItem(props: PropsType) {
  const { className, company, title, uri, variables, options, ...rest } = props
  return (
    <Combobox.Option
      as="div"
      key={company?.id ?? uri}
      value={company?.displayname ?? uri}
      className={clsx(
        'group/comboboxoption flow-x-xs mood-white cursor-pointer items-center p-2',
        'aria-selected:bg-green aria-selected:font-600 hocus:bg-green',
        className,
      )}
      title={title}
      {...rest}
    >
      <span className="text-orange">
        <MdCheckBox
          aria-hidden="true"
          className="hidden group-aria-selected/comboboxoption:inline"
        />
        <MdCheckBoxOutlineBlank
          aria-hidden="true"
          className="group-aria-selected/comboboxoption:hidden"
        />
      </span>
      <Avatar
        alt={company?.initials ?? '...'}
        className="size-8"
        src={company?.avatarUrl}
      />
      <span>{company?.name ?? title}</span>
    </Combobox.Option>
  )
}

export default CompanyMenuItem
