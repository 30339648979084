import { memo, useState } from 'react'
import { MdSearch } from 'react-icons/md'
import NavbarClickyDisclosure from './NavbarClickyDisclosure'
import NavbarClickyIcon from './NavbarClickyIcon'
import NavbarDialog from './NavbarDialog'

type RouteType = ReadonlyArray<{ href?: string; label: string }>

type PropsType = {
  readonly routes: RouteType
}

function NavbarMenuDialogSearch(props: PropsType) {
  const { routes } = props
  const [expanded, setExpanded] = useState(false)
  const hide = () => setExpanded(false)
  const toggle = () => setExpanded(!expanded)

  return (
    <>
      <NavbarClickyIcon
        aria-expanded={expanded || undefined}
        icon={MdSearch}
        id="navbar-search-toggle"
        onClick={toggle}
        title={`${expanded ? 'Close' : 'Open'} Search Menu`}
      >
        Toggle Navbar Menu
      </NavbarClickyIcon>

      <NavbarDialog open={expanded} onClose={hide} title="Navigation">
        {routes.map((route) => (
          <NavbarClickyDisclosure
            className="text-orange"
            key={route.href}
            href={route.href}
          >
            {route.label}
          </NavbarClickyDisclosure>
        ))}
      </NavbarDialog>
    </>
  )
}

/**
 * Search menu utilized by mobile layouts and screenreaders
 *
 * @example
 * <NavbarMenuDialogSearch routes={routesSearch} />
 */
export default memo(NavbarMenuDialogSearch)
