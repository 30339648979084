import {
  ActivitiesAlertsEndpoints,
  ActivitiesAlertsQueryVariables,
} from '@vangst/services/oogst/wtf/useActivitiesAlerts'
import { HTMLAttributes } from 'react'
import { MdNotes } from 'react-icons/md'
import { DialogModal } from '../../../components/feedback/DialogModal'
import FormCreateActivity from '../../application/forms/FormCreateActivity'

type PropsType = {
  readonly endpoint: ActivitiesAlertsEndpoints
  readonly relayId: string
  readonly handleCancel: () => void
  readonly variables: ActivitiesAlertsQueryVariables
} & HTMLAttributes<HTMLDivElement>

function DialogCreateActivity(props: PropsType) {
  const { endpoint, handleCancel, relayId, variables } = props

  return (
    <DialogModal onClose={handleCancel} open>
      <DialogModal.Panel icon={MdNotes} title="Add A Note">
        <FormCreateActivity
          endpoint={endpoint}
          handleCancel={handleCancel}
          relayId={relayId ?? ''}
          variables={variables}
        />
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default DialogCreateActivity
