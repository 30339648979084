import { useState } from 'react'
import DialogScreenApplicant from '../../viewer/feedback/DialogScreenApplicant'

type PropsType = {
  readonly applicationId: string
  readonly className?: string
}

function ScreenApplicant(props: PropsType) {
  const { applicationId, className } = props
  const [shown, setShown] = useState(false)

  return (
    <>
      <button className={className} onClick={() => setShown(true)}>
        Screen Applicant
      </button>
      {shown && (
        <DialogScreenApplicant
          applicationId={applicationId}
          handleCancel={() => setShown(false)}
        />
      )}
    </>
  )
}

export default ScreenApplicant
