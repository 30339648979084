import {
  EmploymentTypesEnum,
  HiringStatusEnum,
} from '@vangst/services/oogst/types'
import React from 'react'
import Checkbox from '../../../components/forms/Checkbox'
import ControlGroup from '../../../components/forms/ControlGroup'

type PropsType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly defaultValue?: HiringStatusEnum[]
  readonly error?: string
  readonly hideLabel?: boolean
  readonly hint?: string | null
  readonly label: string
  readonly layout?: 'col' | 'row'
  readonly name: string
  readonly reference?: React.Ref<HTMLInputElement>
  readonly restProps?: object
}

function ChooseEmploymentTypes(props: PropsType) {
  const {
    error,
    hint,
    hideLabel = false,
    label,
    layout = 'col',
    name,
    required,
    reference,
    restProps,
  } = props

  return (
    <ControlGroup
      disabled={props.disabled}
      error={error}
      hideLabel={hideLabel}
      hint={hint}
      label={label}
      layout={layout}
      name={name}
      required={required}
      type="checkbox"
    >
      <Checkbox
        id={'temporary'}
        name={name}
        label="Temporary"
        reference={reference}
        value={EmploymentTypesEnum.Temp}
        {...restProps}
      />
      <Checkbox
        id={'contract'}
        name={name}
        label="Contract"
        reference={reference}
        value={EmploymentTypesEnum.Contract}
        {...restProps}
      />
      <Checkbox
        id={'fullTime'}
        name={name}
        label="Full Time"
        reference={reference}
        value={EmploymentTypesEnum.FullTime}
        {...restProps}
      />
      <Checkbox
        id={'partTime'}
        name={name}
        label="Part Time"
        reference={reference}
        value={EmploymentTypesEnum.PartTime}
        {...restProps}
      />
      <Checkbox
        id={'perm'}
        name={name}
        label="Permanent"
        reference={reference}
        value={EmploymentTypesEnum.Perm}
        {...restProps}
      />
    </ControlGroup>
  )
}

export default React.memo(ChooseEmploymentTypes)
