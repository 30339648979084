import {
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import fetcher from '../fetcher'
import {
  CompanyJobApplicationsDocument,
  CompanyJobApplicationsQuery,
  CompanyJobApplicationsQueryVariables as CompanyJobApplicationsVars,
  FetcherError,
} from '../types'
import { getBasePageInfoInfinitePagination } from '../wtf/pagination'

type CompanyJobApplicationsOptions = UseInfiniteQueryOptions<
  CompanyJobApplicationsQuery,
  FetcherError,
  CompanyJobApplicationsQuery
>

/**
 * @private
 * Retrieve the stringified `CompaniesJobApplicationsInfinite` query key used in the internal cache.
 */
function getKey(variables?: CompanyJobApplicationsVars) {
  const name = 'CompaniesJobApplicationsInfinite'
  return variables ? [name, variables] : [name]
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function. If no
 * variables are passed all `CompanyJobApplications` queries will be
 * invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateCompanyJobApplications(queryClient, { displayname: 'moontower' })
 * invalidateCompanyJobApplications(queryClient)
 */
function invalidateCompanyJobApplications(
  queryClient: QueryClient,
  variables?: CompanyJobApplicationsVars,
) {
  queryClient.invalidateQueries(
    variables ? getKey(variables) : ['CompaniesJobApplicationsInfinite'],
  )
}

/**
 * Fetch a `CompanyJobApplicationsFragment`.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data, uri } = useCompanyJobApplications({ id: jobPosting.id })
 * const { data, uri } = useCompanyJobApplications({ id: node.id }, { initialData: node })
 *
 * @TODO Add `optimisticCreate|Update|Delete`
 * @TODO Support `prefetchCompanyJobApplications`?
 * @TODO Compute data in select?
 * @TODO Tune options
 */
function useCompanyJobApplications(
  variables: CompanyJobApplicationsVars,
  options?: CompanyJobApplicationsOptions,
) {
  const query = useInfiniteQuery<
    CompanyJobApplicationsQuery,
    FetcherError,
    CompanyJobApplicationsQuery
  >(
    getKey(variables),
    ({ pageParam }) =>
      fetcher<CompanyJobApplicationsQuery, CompanyJobApplicationsVars>(
        CompanyJobApplicationsDocument,
        { ...variables, ...pageParam },
      )(),
    {
      getNextPageParam: (lastPage) => {
        const edges = lastPage.getJobApplications.edges
        const after = edges ? edges[edges.length - 1]?.cursor : undefined
        return { after }
      },
      getPreviousPageParam: (lastPage) => {
        const edges = lastPage.getJobApplications.edges
        const before = edges ? edges[0]?.cursor : undefined
        return { before }
      },
      keepPreviousData: true,
      ...options,
    },
  )
  const pages = query?.data?.pages
  const pageInfo = getBasePageInfoInfinitePagination(
    pages?.map((p) => p.getJobApplications.pageInfo) || [],
    variables?.first || variables?.last || 24,
  )

  const nextPage = pageInfo?.nextHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchNextPage()
      }
    : undefined

  const prevPage = pageInfo?.prevHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchPreviousPage()
      }
    : undefined

  const pagination = { ...pageInfo, nextPage, prevPage }
  const isZero = query.data && pagination?.totalCount === 0
  // TODO...
  // const uri = Routes.COMPANIES_JOBS_APPLICATIONS.replace(':id', variables.id)
  return { ...query, isZero, pagination /*, uri*/ }
}
// -------------------------------------

export type { CompanyJobApplicationsOptions, CompanyJobApplicationsVars }
export { getKey, invalidateCompanyJobApplications }
export default useCompanyJobApplications
