import { Combobox } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import {
  ElasticSearchEndpoints,
  ElasticSearchOptions,
  ElasticSearchVars,
} from '@vangst/services/oogst/search/useElasticSearch'
import { memo, useState } from 'react'
import { MdExpandMore, MdSearch } from 'react-icons/md'
import SearchRenderer from '../SearchRenderer'

type PropsType = {
  readonly children?: React.ReactNode
  readonly endpoint: ElasticSearchEndpoints
  readonly onChange?: (value: string) => void
  readonly variables?: ElasticSearchVars
  readonly options?: ElasticSearchOptions
  readonly className?: string
}

function ChooseElasticSearch(props: PropsType) {
  const { className, children, endpoint, onChange, variables, options } = props

  const [term, setTheTerm] = useState<string | undefined>(undefined)
  const setTerm = (term: string) => {
    setTheTerm(term === '' ? undefined : term)
  }

  const vars = { ...variables, term, first: variables?.first ?? 12 }

  return (
    <Combobox onChange={onChange}>
      {({ open }) => (
        <div className="relative">
          <Combobox.Button
            className={clsx(
              'group/comboboxbutton',
              'flex h-8 w-full min-w-24 items-center justify-between rounded border border-grey-mid pl-2 pr-1 text-left text-sm outline-none',
              open ? 'bg-orange text-white' : 'bg-white text-grey',
              open
                ? 'hocus:border-orange hocus:bg-orange'
                : 'hocus:border-orange hocus:text-orange',
              open
                ? null
                : 'aria-expanded:border-orange aria-expanded:text-orange',
              className,
            )}
          >
            {children}
            <MdExpandMore className="ml-4 transition-transform group-aria-expanded/comboboxbutton:rotate-180" />
          </Combobox.Button>

          <div className="mood-grey-lightest absolute inset-x-0 z-40 shadow-lg">
            {open && (
              <div className="p-4">
                <Combobox.Label className="mood-white flex h-11 w-full items-center gap-2 border border-grey-light pl-4">
                  <Combobox.Input
                    className="peer/searchinput w-full truncate border-0 p-0 transition-colors placeholder:italic focus:ring-0"
                    autoComplete="off"
                    onChange={(event) => setTerm(event.target.value)}
                    displayValue={() => term ?? ''}
                  />
                  <span className="flex h-full min-w-11 items-center justify-center bg-orange text-white">
                    <MdSearch size="1.75em" />
                  </span>
                </Combobox.Label>
              </div>
            )}
            <Combobox.Options as="div" className="max-h-120 overflow-y-auto">
              <SearchRenderer
                as="menuitem"
                at="options"
                endpoint={endpoint}
                variables={vars}
                options={options}
              />
            </Combobox.Options>
          </div>
        </div>
      )}
    </Combobox>
  )
}

export default memo(ChooseElasticSearch)
