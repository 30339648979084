import { useQueryClient } from '@tanstack/react-query'
import useAddToJob from '@vangst/services/oogst/company/useAddToJob'
import ChooseElasticSearch from '../../search/forms/ChooseElasticSearch'

type PropsType = {
  readonly candidateId?: string
  readonly className?: string
  readonly slug?: string
}

function AddToJob(props: PropsType) {
  const { candidateId, className, slug } = props
  const queryClient = useQueryClient()

  const { mutate: addToJob } = useAddToJob(queryClient)

  const handleAddToJob = () => {
    if (!candidateId || !slug) return
    const input = { slug, candidateId }
    addToJob({ input })
  }

  const handleAddJobToMember = (value: string) => {
    if (!candidateId) return
    const input = { slug: value, candidateId }
    addToJob({ input })
  }

  const handleAddMemberToJob = (value: string) => {
    if (!slug) return
    const input = { slug, candidateId: value }
    addToJob({ input })
  }

  return (
    <>
      {!slug && (
        <ChooseElasticSearch
          className={className}
          endpoint="searchJobPostingsByRecruiters"
          onChange={handleAddJobToMember}
        >
          Assign to Job
        </ChooseElasticSearch>
      )}
      {!candidateId && (
        <ChooseElasticSearch
          className={className}
          endpoint="searchCandidatesByRecruiters"
          onChange={handleAddMemberToJob}
        >
          Assign to Member
        </ChooseElasticSearch>
      )}
      {slug && candidateId && (
        <button className={className} onClick={handleAddToJob}>
          Assign to Job
        </button>
      )}
    </>
  )
}

export default AddToJob
