import { QueryClient } from '@tanstack/react-query'
import { dateAndTimeToWords } from '@vangst/lib/formatDates'
import omitEmptyOrNil from '@vangst/lib/omitEmptyOrNil'
import unionize from '@vangst/lib/unionize'
import {
  fetchResource,
  MutationOptions,
  QueryOptions,
  useMutateResource,
  useQueryResource,
} from '../resource'
import {
  CreatePageDocument,
  CreatePageMutation,
  CreatePageMutationVariables,
  PageDocument,
  PageFragment,
  PageInput,
  PageQuery,
  PageQueryVariables,
  UpdatePageDocument,
  UpdatePageMutation,
  UpdatePageMutationVariables,
} from '../types'

type CreatePageOptions = MutationOptions<
  CreatePageMutation,
  CreatePageMutationVariables
>

type PageQueryOptions = QueryOptions<PageQuery>

type UpdatePageOptions = MutationOptions<
  UpdatePageMutation,
  UpdatePageMutationVariables
>

export type PageType = ReturnType<typeof derivePage>

/**
 * @private
 * The cache key name utilized for the `Page` Resource.
 */
const KEY_NAME = 'Page'

/**
 * Derives data not contained in the API.
 */
export function derivePage(page?: PageFragment) {
  if (page == null) return page
  return {
    ...(omitEmptyOrNil(page) as PageFragment),
    author: {
      ...page.author,
      fullName: page.author
        ? unionize(page.author.firstName, page.author.lastName, ' ')
        : undefined,
    },
    createdAtWords: page.createdAt ? dateAndTimeToWords(page.createdAt) : null,
    updatedAtWords: page.updatedAt ? dateAndTimeToWords(page.updatedAt) : null,
    postedAtWords: page.postedAt ? dateAndTimeToWords(page.postedAt) : null,
  }
}

/**
 * @private
 * Serializes a PageFragment into a PageInput.
 */
function serializeFragmentToInput(page: PageFragment): PageInput {
  // const { author, createdAt, id, metaInfo, rawUrl, updatedAt, ...fields } = page
  // const { createdAt: _, updatedAt: __, id: ___, ...metas } = metaInfo
  // const input = { ...fields, metaInfo: metas }

  if (page == null) return page
  return {
    description: page.description,
    markdownContent: page.markdownContent,
    metaInfo: {
      canonicalUrl: page.metaInfo.canonicalUrl,
      description: page.metaInfo.description,
      robots: page.metaInfo.robots,
      title: page.metaInfo.title,
    },
    pagePhotos: page.pagePhotos,
    pageType: page.pageType,
    postedAt: page.postedAt,
    subtitle: page.subtitle,
    title: page.title,
  }
}

/**
 * @public
 * A fetcher for the current `Page` with derived data.
 *
 * @example
 * const page = await fetchPage(queryClient)
 * const author = page.getPage.author
 */
export async function fetchPage(
  queryClient: QueryClient,
  variables?: PageQueryVariables,
) {
  const result = await fetchResource<PageQuery, PageQueryVariables>(
    queryClient,
    KEY_NAME,
    PageDocument,
    variables,
  )
  return result ? { getPage: derivePage(result.getPage) } : result
}

/**
 * @public
 * A fetcher for the current `Page` which is serialized into a `PageInput`.
 *
 * @example
 * const page = await fetchPageInput(queryClient)
 * const defaultFormValues = page?.getPage
 */
export async function fetchPageInput(
  queryClient: QueryClient,
  variables?: PageQueryVariables,
) {
  const result = await fetchResource<PageQuery, PageQueryVariables>(
    queryClient,
    KEY_NAME,
    PageDocument,
    variables,
  )
  return result?.getPage
    ? { getPage: serializeFragmentToInput(result.getPage) }
    : result
}

/**
 * @public
 * A fetching hook for the current `Page` with derived data.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data } = usePage()
 * const author = data?.getPage?.author
 */
export function usePage(
  variables?: PageQueryVariables,
  options?: PageQueryOptions,
) {
  const query = useQueryResource<PageQuery, PageQueryVariables>(
    KEY_NAME,
    PageDocument,
    variables,
    { staleTime: Infinity, ...options },
  )
  const data = query?.data
    ? { getPage: derivePage(query.data.getPage) }
    : query.data
  return { ...query, data }
}

/**
 * @public
 * A fetching hook for the current `Page` which is serialized into a `PageInput`.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data } = usePageInput()
 * const { handleSubmit } = useForm<PageInput>({ defaultValues: data?.getPage })
 */
export function usePageInput(
  variables?: PageQueryVariables,
  options?: PageQueryOptions,
) {
  const query = useQueryResource<PageQuery, PageQueryVariables>(
    KEY_NAME,
    PageDocument,
    variables,
    { staleTime: Infinity, ...options },
  )
  const data = query?.data?.getPage
    ? { getPage: serializeFragmentToInput(query.data.getPage) }
    : query.data
  return { ...query, data }
}

/**
 * @public
 * A hook mutation to create a `Page` resource.
 *
 * @example
 * const createPage = useCreatePage()
 * const changePage = async () => {
 *   const input = { page: { title: 'Hey a moose.' } }
 *   createPage.mutate({input})
 * }
 */
export function useCreatePage(options?: CreatePageOptions) {
  const result = useMutateResource<
    CreatePageMutation,
    CreatePageMutationVariables
  >(KEY_NAME + 'Create', CreatePageDocument, options)
  return result
}

/**
 * @public
 * A mutation to update a `Page` resource.
 * The resource will be created if one doesn't exist.
 *
 * @example
 * const updatePage = useUpdatePage()
 * const changePage = async () => {
 *   const input = { page: { title: 'Hey a moose.' } }
 *   updatePage.mutate({input})
 * }
 */
export function useUpdatePage(options?: UpdatePageOptions) {
  const result = useMutateResource<
    UpdatePageMutation,
    UpdatePageMutationVariables
  >(KEY_NAME + 'Update', UpdatePageDocument, options)
  return result
}
