import { memo, useState } from 'react'
import { MdClose } from 'react-icons/md'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import DialogDeleteJobPosting from '../feedback/DialogDeleteJobPosting'

type PropsType = {
  readonly id: string
}

function DeleteJobPosting({ id }: PropsType) {
  const [prompt, setPrompt] = useState(false)
  const show = () => setPrompt(true)
  const hide = () => setPrompt(false)
  return (
    <>
      <ClickyBtn as="text-icon" className="mood-red" onClick={show}>
        <span className="flow-x-xs items-center truncate">
          <MdClose />
          Delete Job Posting
        </span>
      </ClickyBtn>
      {prompt && <DialogDeleteJobPosting id={id} handleCancel={hide} />}
    </>
  )
}

export default memo(DeleteJobPosting)
