import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import {
  CreateCompanyUserMutation,
  CreateCompanyUserMutationVariables,
  FetcherError,
  useCreateCompanyUserMutation,
} from '../types'
import { invalidateCompany } from './useCompany'

type CreateCompanyUserOptions = UseMutationOptions<
  CreateCompanyUserMutation,
  FetcherError,
  CreateCompanyUserMutationVariables
>

/**
 * Updates an existing `User.Company`.
 *
 * When overriding `onSettled` be sure to provide necessary invalidations.
 *
 * @example
 * const updateCompany = useCreateCompanyUser({onSuccess: () => push(url)})
 * const input = {firstName: 'Bananas', lastName: 'Super', email: 'superbananas@gmail.com', displayname: 'vangsttalent', phone: '716-867-5309'}
 * const updateCompany.mutate({ input })
 *
 */
function useCreateCompanyUser(options?: CreateCompanyUserOptions) {
  const queryClient = useQueryClient()

  return useCreateCompanyUserMutation({
    onSettled: (data) => {
      const displayname = data?.createCompanyUser?.client?.displayname
      if (displayname) {
        invalidateCompany(queryClient, { displayname })
      }
    },
    ...options,
  })
}

export default useCreateCompanyUser
