type PropsType = React.SVGAttributes<SVGElement> & {
  readonly size?: string | number
}

export default function SocialInstagram(props: PropsType) {
  const { size = '2em' } = props
  return (
    <svg height={size} width={size} viewBox="0 0 32 32" {...props}>
      <g fill="currentColor" fillRule="evenodd" stroke="none" strokeWidth="0">
        <path d="M24.0016072,11.0917348 C24.0016072,9.38712517 22.6140249,7.99954286 20.9094153,7.99954286 L11.0937991,7.99954286 C9.3891895,7.99954286 8.00160718,9.38712517 8.00160718,11.0917348 L8.00160718,14.3579624 L8.00160718,20.9082917 C8.00160718,22.6129013 9.3891895,23.9995429 11.0937991,23.9995429 L20.9094153,23.9995429 C22.6140249,23.9995429 24.0016072,22.6129013 24.0016072,20.9082917 L24.0016072,14.3579624 L24.0016072,11.0917348 Z M31.9997257,15.9995429 C31.9997257,24.8358552 24.8369788,31.9995429 15.9997257,31.9995429 C7.16247266,31.9995429 -0.000274285714,24.8358552 -0.000274285714,15.9995429 C-0.000274285714,7.16323053 7.16247266,-0.000457142857 15.9997257,-0.000457142857 C24.8369788,-0.000457142857 31.9997257,7.16323053 31.9997257,15.9995429 Z M16.0016072,18.815159 C17.5538179,18.815159 18.8181641,17.5526943 18.8181641,15.9995429 C18.8181641,15.3861844 18.6187285,14.8208034 18.284768,14.3579624 C17.7730089,13.6486492 16.9414002,13.1839267 16.0016072,13.1839267 C15.0618141,13.1839267 14.2302055,13.6486492 13.7184463,14.3579624 C13.3854266,14.8208034 13.185991,15.3861844 13.185991,15.9995429 C13.185991,17.5526943 14.4493965,18.815159 16.0016072,18.815159 Z M22.1502431,12.5545758 L22.1502431,10.1952155 L22.1502431,9.84244032 L21.7965272,9.84432178 L19.4371669,9.85184765 L19.4465743,12.5630424 L22.1502431,12.5545758 Z M20.0542883,14.3579624 L22.4428113,14.3579624 L22.4428113,20.9082917 C22.4428113,21.7530706 21.7551349,22.440747 20.9094153,22.440747 L11.0937991,22.440747 C10.2480794,22.440747 9.56040304,21.7530706 9.56040304,20.9082917 L9.56040304,14.3579624 L11.9489261,14.3579624 C11.7429054,14.8650179 11.6271951,15.4191101 11.6271951,15.9995429 C11.6271951,18.4115842 13.5895658,20.3748956 16.0016072,20.3748956 C18.4145893,20.3748956 20.3760192,18.4115842 20.3760192,15.9995429 C20.3760192,15.4191101 20.2593682,14.8650179 20.0542883,14.3579624 Z" />
      </g>
    </svg>
  )
}
