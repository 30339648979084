import { Menu } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import { forwardRef } from 'react'
import { IconType } from 'react-icons'
import ClickyLink, { ClickyType } from './ClickyLink'

/**
 * A floating white box with a orange border container for Menu items.
 * The parent container should have a `className` of `relative`.
 *
 * @see [headlessui Menu component](https://headlessui.com/react/menu)
 *
 * @example
 * <Menu.Items as={MenuItems}>
 *   <MenuItemClicky>Item One</MenuItemClicky>
 *   <MenuItemClicky>Item Two</MenuItemClicky>
 *   <MenuItemClicky>Item Three</MenuItemClicky>
 * </Menu.Items>
 **/
export const MenuItems = forwardRef<HTMLDivElement, any>(function MenuItems(
  props: React.HTMLAttributes<HTMLDivElement>,
  ref,
) {
  const { className, ...rest } = props
  return (
    <div
      className={clsx(
        'mood-white absolute right-1 top-full z-40 mt-4 flex w-48 animate-fadeIn flex-col rounded border border-t-4 border-orange text-sm shadow-lg outline-none',
        'before:absolute before:-top-3 before:right-3 before:border-x-[9px] before:border-b-[12px] before:border-x-transparent before:border-b-orange',
        className,
      )}
      ref={ref}
      {...rest}
    />
  )
})

// -------------------------------------

/**
 * A `Clicky` used within a `Menu` as a child of `Menu.Item`, bordered with a blue tint for hocused states.
 *
 * @see [headlessui Menu component](https://headlessui.com/react/menu)
 *
 * @example
 * <Menu.Items as={MenuItems}>
 *   <MenuItemClicky>Item One</MenuItemClicky>
 *   <MenuItemClicky>Item Two</MenuItemClicky>
 *   <MenuItemClicky>Item Three</MenuItemClicky>
 * </Menu.Items>
 **/
export const MenuItemClicky = forwardRef<HTMLElement, ClickyType>(
  function MenuItemClicky(props: ClickyType, ref) {
    const { className, ...rezt } = props
    const rest = { ...rezt, ref }
    return (
      <Menu.Item>
        <ClickyLink
          className={clsx(
            'relative inline-flex w-full items-center border-t border-grey-light first:border-t-0',
            'hocus:bg-green',
            'aria-[selected=true]:bg-green aria-[selected=true]:font-600',
            'aria-[current=true]:bg-green aria-[current=true]:font-600',
            className,
          )}
          {...rest}
        />
      </Menu.Item>
    )
  },
)

type KidProps = {
  readonly label: string
  readonly icon?: IconType
  readonly notificationCounts?: { [key: string]: number }
}

export const MenuItemClickyKid = (props: KidProps) => {
  const { label, icon, notificationCounts } = props

  const Icon = icon

  const Dot = (
    <span className="absolute right-2 top-3 size-2 rounded-full bg-blue" />
  )
  const showDot =
    notificationCounts != null &&
    ((label.toLowerCase().includes('messages') &&
      notificationCounts.chatMessage > 0) ||
      (label.toLowerCase().includes('connections') &&
        notificationCounts.connections > 0))
  return (
    <span className="flow-x-xs items-center p-2">
      {Icon && <Icon className="motion-west" />}
      {label}
      {showDot && Dot}
    </span>
  )
}
// -------------------------------------

/**
 * A `Clicky` used typically as the last level action of a `Menu`, `Tree` or
 * `Disclosure` that is prefixed with a bullet.
 *
 * Note that this doesn't require Menu but it acts like a menut
 *
 * @example
 * <div className="mood-orange flex flex-col">
 *   <MenuBulletClicky>MenuBulletClicky 1</MenuBulletClicky>
 *   <MenuBulletClicky>MenuBulletClicky 2</MenuBulletClicky>
 *   <MenuBulletClicky>MenuBulletClicky 3</MenuBulletClicky>
 *   <MenuBulletClicky>MenuBulletClicky 4</MenuBulletClicky>
 * </div>
 **/
export const MenuBulletClicky = forwardRef<HTMLElement, ClickyType>(
  function BulletLink(props: ClickyType, ref) {
    const { className, ...rezt } = props
    const rest = { ...rezt, ref }
    return (
      <ClickyLink
        className={clsx(
          'inline-flex w-full gap-2 p-4 text-sm text-white',
          'before:text-[1.25em] before:font-700 before:content-["•"]',
          'before:transition before:ease-bounce',
          'hover:before:scale-150 hocus:bg-green hocus:text-black',
          'aria-[current=true]:bg-green aria-[current=true]:before:scale-150',
          className,
        )}
        {...rest}
      />
    )
  },
)
