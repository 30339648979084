type PropsType = React.SVGAttributes<SVGElement> & {
  readonly size?: string | number
}

export default function SocialLinkedIn(props: PropsType) {
  const { size = '2em' } = props
  return (
    <svg height={size} width={size} viewBox="0 0 32 32" {...props}>
      <g fill="currentColor" fillRule="evenodd" stroke="none" strokeWidth="0">
        <path d="M23.919911,22.6157171 L20.3954933,22.6157171 L20.3954933,17.4683232 C20.3954933,16.1209999 19.8441877,15.2012197 18.631878,15.2012197 C17.704597,15.2012197 17.1889201,15.8219073 16.9488959,16.4200926 C16.8588868,16.6348017 16.8729507,16.9329568 16.8729507,17.232987 L16.8729507,22.6157171 L13.3813488,22.6157171 C13.3813488,22.6157171 13.4263533,13.4976105 13.3813488,12.668777 L16.8729507,12.668777 L16.8729507,14.2298718 C17.0792215,13.547303 18.194959,12.5731423 19.9754509,12.5731423 C22.1844236,12.5731423 23.919911,14.0039115 23.919911,17.0848471 L23.919911,22.6157171 Z M9.85036787,11.4236514 L9.8278656,11.4236514 C8.7027522,11.4236514 7.97330367,10.6632623 7.97330367,9.70035275 C7.97330367,8.71775371 8.72431687,7.97330367 9.87193254,7.97330367 C11.0186106,7.97330367 11.7236817,8.71587852 11.746184,9.69753997 C11.746184,10.6613871 11.0186106,11.4236514 9.85036787,11.4236514 Z M8.37553171,22.6157171 L11.4836575,22.6157171 L11.4836575,12.668777 L8.37553171,12.668777 L8.37553171,22.6157171 Z M15.9466073,0 C7.13978216,0 0,7.13978216 0,15.9456697 C0,24.7524949 7.13978216,31.8932147 15.9466073,31.8932147 C24.7553077,31.8932147 31.8932147,24.7524949 31.8932147,15.9456697 C31.8932147,7.13978216 24.7553077,0 15.9466073,0 Z" />
      </g>
    </svg>
  )
}
