import useCompanyJobApplication from '@vangst/services/oogst/company/useCompanyJobApplication'
import { memo } from 'react'
import { MdCheckCircleOutline } from 'react-icons/md'
import { ScreeningSubmissionFragment } from '../../../../../packages/services/oogst/types'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyBtn from '../../../components/navigation/ClickyBtn'

type PropsType = {
  readonly handleClose: () => void
  readonly applicationId: string
}

type SerializedAnswer = {
  question: string
  answer: string
}

function DialogScreeningAnswers({ applicationId, handleClose }: PropsType) {
  const { data } = useCompanyJobApplication({ id: applicationId })
  const application = data?.getJobApplication

  const serializeAnswers = (submission?: ScreeningSubmissionFragment) => {
    return submission?.screeningQResponses?.map((response) => {
      return {
        question: response.screeningQuestion?.question,
        answer: response.answer,
      } as SerializedAnswer
    })
  }
  const serializedAnswers =
    serializeAnswers(application?.screeningSubmissions?.[0]) || []

  return (
    <>
      <DialogModal onClose={handleClose} open>
        <DialogModal.Panel
          icon={MdCheckCircleOutline}
          title={'Screening: ' + application?.jobPosting?.title}
        >
          <DialogModal.Description className="flow-y-sm my-8 text-center">
            <div>
              {serializedAnswers.map((item, index) => (
                <div className="mb-2 text-left" key={index}>
                  <span className="font-700">{item.question}:</span>{' '}
                  <span>{item.answer}</span>
                </div>
              ))}
            </div>
          </DialogModal.Description>
          <div className="flex w-full items-center justify-center gap-4">
            <ClickyBtn className="mood-orange w-full" onClick={handleClose}>
              Close
            </ClickyBtn>
          </div>
        </DialogModal.Panel>
      </DialogModal>
    </>
  )
}

export default memo(DialogScreeningAnswers)
