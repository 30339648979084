import { QueryErrorResetBoundary } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { ErrorBoundary } from 'react-error-boundary'
import {
  QueryErrorPageFallback as Page,
  QueryErrorStatusFallback as Status,
} from './QueryErrorFallback'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly kind?: 'page' | 'status'
}

function QueryErrorBoundary(props: PropsType) {
  const fb = props.kind === 'page' ? Page : Status
  const { asPath } = useRouter()
  return (
    <QueryErrorResetBoundary>
      <ErrorBoundary resetKeys={[asPath]} FallbackComponent={fb}>
        {props.children}
      </ErrorBoundary>
    </QueryErrorResetBoundary>
  )
}

export default QueryErrorBoundary
