import {
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import fetcher from './fetcher'
import { FetcherError } from './types'

/**
 * Wraps the query options object with types for data and error.
 *
 * @example
 * type JobPostingQueryOptions = QueryOptions<JobPostingQuery>
 */
export type QueryOptions<TQuery> = UseQueryOptions<TQuery, FetcherError, TQuery>

/**
 * Wraps the mutation options object with types for variables, data and error.
 *
 * @example
 * type JobPostingMutationOptions = MutationOptions<JobPostingMutation, JobPostingMutationVariables>
 */
export type MutationOptions<TMutation, TVars> = UseMutationOptions<
  TMutation,
  FetcherError,
  TVars
>

// -------------------------------------

/**
 * @private
 * Retrieve the stringified `Resource` query key used in the internal cache store.
 */
function getKey<TVars>(name: string, variables?: TVars) {
  return variables ? [name, variables] : [name]
}

/**
 * @private
 * Retrieve the `Resource` from the queryClient's internal cache store.
 */
function getCache<TQuery, TVars>(
  queryClient: QueryClient,
  name: string,
  variables?: TVars,
) {
  const key = getKey<TVars>(name, variables)
  return queryClient.getQueryData<TQuery>(key)
}

/**
 * @private
 * Create a new `Resource` within the internal cache's result set.
 *
 * @example
 * optimisticCreateResource<JobPostingInput, CreateJobPostingMutationVariables>(
 *   queryClient, 'JobPosting', { title: 'New Resource' }, { id: 'Sx8675309' }
 * )
 */
function optimisticCreateResource<TInput, TVars>(
  queryClient: QueryClient,
  name: string,
  data: Partial<TInput>,
  variables?: TVars,
) {
  const key = getKey<TVars>(name, variables)
  queryClient.setQueryData(key, data)
}

/**
 * @TODO: __!!! THIS IS NOT WORKING CORRECTLY !!!__
 *
 * @private
 * Update a new `Resource` within the internal cache's result set.
 * If the `Resource` does not exist, one will be created.
 *
 * @example
 * optimisticUpdateResource<JobPostingInput, UpdateJobPostingMutationVariables>(
 *   queryClient, 'JobPosting', { title: 'Update Resource' }, { id: 'Sx8675309' }
 * )
 */
function optimisticUpdateResource<TInput, TVars, TQuery>(
  queryClient: QueryClient,
  name: string,
  data: Partial<TInput>,
  variables?: TVars,
) {
  const key = getKey<TVars>(name, variables)
  const prev = queryClient.getQueryData<TQuery>(key)
  if (!prev) {
    optimisticCreateResource<TInput, TVars>(queryClient, name, data, variables)
    return
  }
  // TODO: Would have to include the operationName or become a deep copy merge?
  // { getJobPosting: { ...prev.getJobPosting, ...data } }
  queryClient.setQueryData(key, { ...prev, data })
}

/**
 * @private
 * Removes the `Resource` from the internal cache's result set.
 *
 * @example
 * optimisticDeleteResource<JobPostingMutationVariables>(
 *   queryClient, 'JobPosting', { id: 'Sx8675309' }
 * )
 */
function optimisticDeleteResource<TVars>(
  queryClient: QueryClient,
  name: string,
  variables?: TVars,
) {
  const key = getKey<TVars>(name, variables)
  queryClient.removeQueries(key, { exact: true })
}

// -------------------------------------

/**
 * @public
 * Convenience wrapper around react-query's `invalidateQueries` function.
 * If no variables are passed all `Resource` queries will be invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateResource<JobPostingQueryVariables>(queryClient, 'JobPosting', { id: 'Sx8675309'})
 * invalidateResource(queryClient, 'JobPosting')
 */
export function invalidateResource<TVars>(
  queryClient: QueryClient,
  name: string,
  variables?: TVars,
) {
  const key = getKey<TVars>(name, variables)
  queryClient.invalidateQueries(key)
}

/**
 * @public
 * A fetcher which wires up types and tackles error handling.
 *
 * @example
 * const jobPosting = await fetchResource<JobPostingQueryVariables, JobPostingQuery>(
 *   queryClient, 'JobPosting', JobPostingDocument, { id: 'Sx8675309' },
 * )
 */
export async function fetchResource<TQuery, TVars>(
  queryClient: QueryClient,
  name: string,
  operation: string,
  variables?: TVars,
) {
  const key = getKey<TVars>(name, variables)
  const fn = fetcher<TQuery | FetcherError, TVars>(operation, variables)

  let data
  try {
    data = name.includes('Infinite')
      ? await queryClient.prefetchInfiniteQuery(key, fn)
      : await queryClient.fetchQuery(key, fn)
  } catch (e) {
    // TODO: Silently catching this for now :| ...... ?
    console.warn('Resource error caught', e)
    // throw e
  }

  // TODO: Handle 404s and better error handling in general?
  const is403 = !!(data as FetcherError)?.response?.errors?.some(
    (e) =>
      e.extensions?.code === 'UNAUTHORIZED' ||
      e.extensions?.code === 'ACTION_UNAUTHORIZED',
  )
  const status = is403 ? 403 : 200
  const cache = getCache(queryClient, name, variables) as TQuery
  return { ...cache, status }
}

/**
 * @public
 * A fetching hook which wires up types and tackles error handling.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data } = useQueryResource<JobPostingQuery, JobPostingQueryVariables>(
 *   'JobPostingName', JobPostingDocument, { id: 'Sx8675309' }, options
 * )
 * const { data } = useQueryResource<JobPostingQuery, JobPostingQueryVariables>(
 *   'JobPostingName', JobPostingDocument, { id: 'Sx8675309' }, { initialData: node }
 * )
 */
export function useQueryResource<TQuery, TVars>(
  name: string,
  operation: string,
  variables?: TVars,
  options?: UseQueryOptions<TQuery, FetcherError, TQuery>,
) {
  const query = useQuery<TQuery, FetcherError, TQuery>(
    getKey<TVars>(name, variables),
    fetcher<TQuery, TVars>(operation, variables),
    {
      enabled: options?.initialData == null,
      ...options,
    },
  )
  const data = (options?.initialData as TQuery) || query?.data
  return { ...query, data }
}

/**
 * WIP
 */
export function useInfiniteQueryResource<TQuery, TVars>(
  name: string,
  operation: string,
  variables?: TVars,
  options?: UseInfiniteQueryOptions<TQuery, FetcherError, TQuery>,
) {
  const query = useInfiniteQuery<TQuery, FetcherError, TQuery>(
    getKey<TVars>(name, variables),
    ({ pageParam }) =>
      fetcher<TQuery, TVars>(operation, { ...variables, ...pageParam })(),
    {
      enabled: options?.initialData == null,
      keepPreviousData: true,
      ...options,
    },
  )
  const data = (options?.initialData as TQuery) || query?.data
  return { ...query, data }
}

/**
 * @public
 * Utilizing a hook, mutate a `Resource`.
 *
 * @TODO: Connect optimistic(Create|Update)Resource
 *
 * @example
 * const result = useMutateResource<UpdatePageMutation, UpdatePageMutationVariables>(
 *   'UpdateResource', ResourceDocument, { onSettled: () => { invalidateResource(queryClient) } }
 * )
 */
export function useMutateResource<TMutation, TMutationVars>(
  name: string,
  operation: string,
  options?: MutationOptions<TMutation, TMutationVars>,
) {
  const queryClient = useQueryClient()
  const result = useMutation<TMutation, FetcherError, TMutationVars>(
    getKey(name),
    (variables?: TMutationVars) =>
      fetcher<TMutation, TMutationVars>(operation, variables)(),
    {
      onMutate: () => {
        const keyName = name.replace(/Create|Update|Delete/, '')
        if (/Create$/g.test(name)) {
          console.warn('Not connected:', typeof optimisticCreateResource)
          // optimisticCreateResource<TInput, TMutationVariables>( queryClient, keyName, input.page,)
        }
        if (/Update$/g.test(name)) {
          console.warn('Not connected:', typeof optimisticUpdateResource)
          // optimisticUpdateResource<TInput, TMutationVariables>( queryClient, keyName, input.page,)
        }
        if (/Delete$/g.test(name)) {
          optimisticDeleteResource<TMutationVars>(queryClient, keyName)
        }
      },
      onSettled: () => {
        if (/(Create|Update|Delete)$/g.test(name)) {
          const keyName = name.replace(/Create|Update/, '')
          invalidateResource<TMutationVars>(queryClient, keyName)
        }
      },
      ...options,
    },
  )
  return result
}
