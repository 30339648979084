// TODO: Should these objects be lazily instantiated?
// TODO: Add tests!

/**
 * A frozen object with state abbreviation as the keys and state name for values
 *
 * @TODO Can this be an enum?
 * @TODO How does this fit in with the API's STATE enum?
 */
export const STATE_LIST = Object.freeze({
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
})

export const STATE_LIST_CANADA = Object.freeze({
  AB: 'Alberta',
  BC: 'British Columbia',
  ON: 'Ontario',
  NS: 'Novia Scotia',
  NB: 'New Brunswick',
  MB: 'Manitoba',
  NL: 'Newfoundland & Labrador',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
})

/**
 * A frozen array of state abbreviations
 * AL, AK, AZ, AR, CA, CO...
 */
export const STATE_ABBREVIATIONS = Object.freeze(Object.keys(STATE_LIST))
export const STATE_ABBREVIATIONS_CANADA = Object.freeze(
  Object.keys(STATE_LIST_CANADA),
)

/**
 * A frozen array of state names
 * Alabama, Alaska, Arizona, Arkansas, California, Colorado...
 */
export const STATE_NAMES = Object.freeze(Object.values(STATE_LIST))

/**
 * An array of state options used with Comboboxes and Selects
 * { label: 'CO', value: 'Colorado' },...
 *
 * @TODO How to freeze this without blowing up consumers
 */
export const STATE_OPTIONS = [
  { label: 'Select a State', value: '' },
  ...STATE_ABBREVIATIONS.map((stateAbbr: string) => ({
    label: STATE_LIST[stateAbbr as keyof typeof STATE_LIST],
    value: stateAbbr,
  })),
]

export const STATE_OPTIONS_CANADA = [
  { label: 'Select a Province/Territory', value: '' },
  ...STATE_ABBREVIATIONS_CANADA.map((stateAbbr: string) => ({
    label: STATE_LIST_CANADA[stateAbbr as keyof typeof STATE_LIST_CANADA],
    value: stateAbbr,
  })),
]

export const COUNTRY_OPTIONS = [
  { label: 'Select a Country', value: '' },
  { label: 'United States', value: 'united_states' },
  { label: 'Canada', value: 'canada' },
]

export const COUNTRY_LIST = Object.freeze({
  united_states: 'USA',
  canada: 'Canada',
})
