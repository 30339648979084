import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import fetcher from '../fetcher'
import {
  AllChatMessagesDocument,
  AllChatMessagesQuery,
  AllChatMessagesQueryVariables as ChatVars,
  FetcherError,
  useAllChatMessagesQuery as useEndpoint,
} from '../types'

type AllChatMessagesOptions = UseQueryOptions<
  AllChatMessagesQuery,
  FetcherError,
  AllChatMessagesQuery
>

/**
 * @private
 * Retrieve the stringified `Skills` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

/**
 * @private
 * Retrieve the `Skills` from the queryClient's cache.
 */
function getCache(queryClient: QueryClient, variables?: ChatVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<AllChatMessagesQuery>(key)
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateSkills(queryClient)
 */
function invalidateAllChatMessages(
  queryClient: QueryClient,
  variables?: ChatVars,
) {
  queryClient.invalidateQueries(
    variables ? getKey(variables) : ['AllChatMessages'],
  )
}

/**
 * Prefetch and return the list of `Skills`.
 *
 * @example
 * const skills = await prefetchSkills(queryClient)
 */
async function prefetchAllChatMessages(
  queryClient: QueryClient,
  variables?: ChatVars,
) {
  const key = getKey(variables)
  const fn = fetcher<AllChatMessagesQuery, ChatVars>(
    AllChatMessagesDocument,
    variables,
  )
  await queryClient.prefetchQuery(key, fn)
  return getCache(queryClient, variables)
}

/**
 * Fetch a `AllChatMessagesFragment` and derive a `AllChatMessagesComputed` data set.
 * Passing `initialData` will hydrate the cache.
 *
 * The staleTime is set to Infinity for a single fetch.
 *
 * @example
 * const { data, uri } = useAllChatMessages()
 * const { data, uri } = useAllChatMessages(_, { initialData: node })
 */

function useAllChatMessages(
  variables?: ChatVars,
  options?: AllChatMessagesOptions,
) {
  const query = useEndpoint(variables, {
    staleTime: Infinity,
    ...options,
  })
  return query
}

// -------------------------------------

export { invalidateAllChatMessages, prefetchAllChatMessages }
export default useAllChatMessages
