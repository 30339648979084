import useNodes, { NodesOptions } from '@vangst/services/oogst/nodes/useNodes'
import { EsResultFragment } from '@vangst/services/oogst/types'
import { Fragment, memo } from 'react'
import SearchPromotion from '../search/views/SearchPromotion'
import RendererFactory, {
  AsTypeUnion,
  RendererFragments,
} from './RendererFactory'

type PropsType = {
  readonly as: AsTypeUnion
  readonly promote?: boolean
  readonly subjects: EsResultFragment[]
  readonly options?: NodesOptions
}

function NodesRenderer(props: PropsType) {
  const { as, options, promote, subjects } = props
  const ids = subjects.map((s) => s.id)
  const { data } = useNodes({ ids }, options)
  return (
    <>
      {subjects.map((subject, i) => {
        return (
          <Fragment key={`${subject.id}_${i}`}>
            <RendererFactory
              as={as}
              enabled={false}
              // TODO: Errors on a `EventListing_BaseFragment` without the
              // casting. Might be related to ongoing CMS work though?
              initialData={data?.nodes?.[i] as RendererFragments}
              label={subject.label}
              slug={subject.slug}
              type={subject.type}
            />
            {promote && i === 9 && <SearchPromotion as="one" />}
            {promote && i === 17 && <SearchPromotion as="two" />}
          </Fragment>
        )
      })}
    </>
  )
}

/**
 * Render a list of cards via the useNodes endpoint. NodesRenderer does not
 * support entity types for BlogPost|EventPost or other GraphCMS renderers.
 */
export default memo(NodesRenderer)
