import { getHeaders, removeHeaders } from '@vangst/services/headers'
import Routes from '../routes'
import client from './client'
import { FetcherError } from './types'

function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  const headers = getHeaders()
  return async (): Promise<TData> =>
    client.request<TData, TVariables>(query, variables, headers).catch((e) => {
      const response = (e as FetcherError)?.response

      const isTokenExpired = response?.errors?.some(
        ({ extensions }) =>
          extensions?.code === 'JWT_EXPIRED' ||
          extensions?.code === 'JWT_INVALID',
      )
      if (isTokenExpired === true) {
        removeHeaders()
        globalThis.location?.replace(Routes.SIGN_IN)
        return
      }

      // TODO: Remove this in favor of the error boundaries
      const filtered = response?.errors?.filter(
        ({ extensions }) => !extensions?.code.includes('UNAUTHORIZED'),
      )

      if (filtered?.length === 0) return e
      const firstError = response?.errors?.[0]
      const name = firstError?.extensions?.code || e.name || 'Oogst Error'
      const message = firstError?.message || e.message
      const group = firstError?.extensions?.group || 'UNKNOWN'

      e.origin = 'oogst'
      if (name) e.name = name
      if (message) e.message = message
      if (group) e.group = group
      throw e
    })
}

export { fetcher }
export default fetcher
