import clsx from '@vangst/lib/clsx'
import { COUNTRY_LIST } from '@vangst/lib/constants/locations'
import geocodedStateToAbbreviation from '@vangst/lib/geocodedStateToAbbreviation'
import { LocationInput } from '@vangst/services/oogst/types'
import { memo, useState } from 'react'
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import { MdCheckCircle, MdErrorOutline, MdWarningAmber } from 'react-icons/md'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import AlertText from '../../../components/feedback/AlertText'
import HiddenInput from '../../../components/forms/HiddenInput'
import GooglePlacesAutocomplete from './GooglePlacesAutocomplete'

export type SearchTypeUnion = 'fullAddress' | 'cityState' | 'geocode' | 'search'

export type InputType = React.InputHTMLAttributes<HTMLInputElement> & {
  readonly customPrefix?: string
  readonly error?: string | null
  readonly formType: SearchTypeUnion
  readonly getValues: UseFormGetValues<any>
  readonly isClient?: boolean
  readonly label: string
  readonly location?: any
  readonly placeholder?: string
  readonly register: UseFormRegister<any>
  readonly setValue: UseFormSetValue<any>
}

function InputGeolocation(props: InputType) {
  const {
    className,
    customPrefix,
    error = null,
    formType,
    register,
    location,
    setValue,
    getValues,
    label,
    placeholder,
    required,
  } = props

  // HELPERS FOR DIFFERENT TYPES OF FORMS THIS COMPONENT IS USED ON
  const formPrefix = customPrefix || 'location.'
  // converts google places responses to what the api wants
  const countries = {
    USA: 'united_states',
    Canada: 'canada',
  } as const

  const formattedLocation = formatLocation(location, formType)

  // value of the input:
  const [address, setAddress] = useState(formattedLocation)
  // used to trigger re-renders after some form updates:
  const [toggle, setToggle] = useState(true)
  const [searchError, setSearchError] = useState<string | null>(error || null)
  const handleChange = (address: string) => {
    setSearchError(null)
    setAddress(address)
    setValue(`${formPrefix}rawLocation`, '')
  }

  // when selecting an auto-suggested option in member/company/job forms
  const handleSelect = (address: string) => {
    setAddress(address)
    const addressParts = address.split(', ')
    if (formType === 'geocode') {
      setValue(`${formPrefix}city`, addressParts[0])
      setValue(`${formPrefix}state`, addressParts[1])
      setValue(`${formPrefix}country`, 'united_states')
    } else {
      // address/city
      if (formType === 'fullAddress') {
        setValue(`${formPrefix}address`, addressParts[0])
        setValue(`${formPrefix}city`, addressParts[1])
      } else {
        setValue(`${formPrefix}city`, addressParts[0])
      }
      // state
      setValue(
        `${formPrefix}state`,
        geocodedStateToAbbreviation(addressParts[addressParts.length - 2]),
      )
      // country
      const country =
        countries[addressParts[addressParts.length - 1] as 'USA' | 'Canada'] ||
        'united_states'
      setValue(`${formPrefix}country`, country)
    }

    try {
      geocodeByAddress(address)
        .then((results: any) => {
          if (formType === 'fullAddress') {
            const postalCode = results[0].address_components.find(
              (c: any) => c.types[0] === 'postal_code',
            )?.short_name
            setValue(`${formPrefix}.postalCode`, postalCode)
          }
          return getLatLng(results[0])
        })
        .then((latLng) => {
          setValue(`${formPrefix}latitude`, latLng.lat)
          setValue(`${formPrefix}longitude`, latLng.lng)
          setValue(`${formPrefix}rawLocation`, address)
          // NOTE: this toggle value is just used to trigger a render after from data has been updated
          // (setValue does not trigger a re-render)
          setToggle(!toggle)
        })
    } catch (e) {
      setSearchError(
        'Unable to validate location, please refresh and try again.',
      )
    }
  }

  // For initial rendering of the helper text below the input
  const geoLocatedValue = getValues(`${formPrefix}rawLocation`)
  const streetAddress = location?.address || getValues(`${formPrefix}address`)
  if (formType !== 'geocode' && formattedLocation && !geoLocatedValue) {
    setValue(`${formPrefix}rawLocation`, formattedLocation)
    setToggle(!toggle)
  }
  return (
    <div>
      <div className={clsx('mb-4', className)}>
        <GooglePlacesAutocomplete
          address={address || ''}
          error={error}
          formType={formType}
          handleChange={handleChange}
          handleSelect={handleSelect}
          label={label}
          placeholder={placeholder}
          required={required}
          setSearchError={setSearchError}
        />

        {formType !== 'geocode' &&
          location?.address == null &&
          location?.city != null && (
            <AlertText
              className="mt-4 text-sm italic text-red"
              icon={MdErrorOutline}
            >
              <span>
                Your saved address is{' '}
                <strong>
                  {location?.city}, {location?.state}
                </strong>
                . Please update this to be a full street address.
              </span>
            </AlertText>
          )}

        <RenderFormInputs
          register={register}
          formType={formType}
          location={location}
          formPrefix={formPrefix}
        />
      </div>

      {searchError != null && (
        <AlertText className="text-sm italic text-red" icon={MdErrorOutline}>
          {searchError}
        </AlertText>
      )}
      {streetAddress && (
        <AlertText
          className="text-sm italic text-green"
          icon={geoLocatedValue ? MdCheckCircle : MdErrorOutline}
        >
          Selected Location: {geoLocatedValue || 'NONE'}
        </AlertText>
      )}
      {formType !== 'geocode' && !streetAddress && geoLocatedValue && (
        <AlertText className="text-sm italic text-red" icon={MdWarningAmber}>
          Please update your location to a full street address...
        </AlertText>
      )}
    </div>
  )
}

type renderInputType = {
  readonly location: any
  readonly register: UseFormRegister<any>
  readonly formPrefix: string
  readonly formType: SearchTypeUnion
}

const formatLocation = (
  location: LocationInput | undefined,
  formType: SearchTypeUnion,
) => {
  if (location?.rawLocation) {
    return location?.rawLocation
  }
  if (formType === 'fullAddress') {
    if (
      location?.address &&
      location?.city &&
      location?.state &&
      location?.country
    ) {
      const country = COUNTRY_LIST[location.country]
      return `${location?.address}, ${location?.city}, ${location?.state}, ${country}`
    }
  } else {
    if (location?.city && location?.state && location?.country) {
      const country = COUNTRY_LIST[location.country]
      return `${location?.city}, ${location?.state}, ${country}`
    }
  }
  return ''
}

const RenderFormInputs = memo((props: renderInputType) => {
  const { location, register, formPrefix, formType } = props

  // REFS
  const { ref: rawLocationRef, ...rawLocationProps } = register(
    `${formPrefix}rawLocation`,
  )
  const { ref: cityRef, ...cityProps } = register(`${formPrefix}city`)
  const { ref: stateRef, ...stateProps } = register(`${formPrefix}state`)
  const { ref: latitudeRef, ...latitudeProps } = register(
    `${formPrefix}latitude`,
  )
  const { ref: longitudeRef, ...longitudeProps } = register(
    `${formPrefix}longitude`,
  )
  const { ref: countryRef, ...countryProps } = register(`${formPrefix}country`)
  const { ref: addressRef, ...addressProps } = register(`${formPrefix}address`)
  const { ref: addressExtendedRef, ...addressExtendedProps } = register(
    `${formPrefix}addressExtended`,
  )
  const { ref: postalCodeRef, ...postalCodeProps } = register(
    `${formPrefix}postalCode`,
  )

  return (
    <div>
      <HiddenInput
        defaultValue={location?.city}
        reference={cityRef}
        {...cityProps}
      />
      <HiddenInput
        defaultValue={location?.state}
        reference={stateRef}
        {...stateProps}
      />
      <HiddenInput
        defaultValue={location?.latitude}
        reference={latitudeRef}
        {...latitudeProps}
      />
      <HiddenInput
        defaultValue={location?.longitude}
        reference={longitudeRef}
        {...longitudeProps}
      />
      <HiddenInput
        defaultValue={location?.country}
        reference={countryRef}
        {...countryProps}
      />
      <HiddenInput
        defaultValue={location?.rawLocation}
        reference={rawLocationRef}
        {...rawLocationProps}
      />
      {formType === 'fullAddress' && (
        <>
          <HiddenInput
            defaultValue={location?.address}
            reference={addressRef}
            {...addressProps}
          />
          <HiddenInput
            defaultValue={location?.addressExtended}
            reference={addressExtendedRef}
            {...addressExtendedProps}
          />
          <HiddenInput
            defaultValue={location?.postalCode}
            reference={postalCodeRef}
            {...postalCodeProps}
          />
        </>
      )}
    </div>
  )
})
RenderFormInputs.displayName = 'RenderFormInputs'

// -------------------------------------

export default memo(InputGeolocation)
