import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import Routes from '../../routes'
import {
  FetcherError,
  MemberEditQuery,
  MemberEditQueryVariables as MemberEditVars,
  UpdateUserInput,
  useMemberEditQuery as useEndpoint,
} from '../types'

type MemberEditOptions = UseQueryOptions<
  MemberEditQuery,
  FetcherError,
  MemberEditQuery
>

/**
 * @private
 * Retrieve the stringified `MemberEdit` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 * If no variables are passed all `MemberEdit` queries will be invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateMemberEdit(queryClient)
 */
function invalidateMemberEdit(
  queryClient: QueryClient,
  variables?: MemberEditVars,
) {
  queryClient.invalidateQueries(variables ? getKey(variables) : ['MemberEdit'])
}

/**
 * Fetch a `MemberEditFragment` through `currentUser` for `updateUser`
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data, uri } = useMemberEdit()
 * const { data, uri } = useMemberEdit(_, { initialData: node })
 *
 * @TODO Add `optimisticCreate|Update|Delete`?
 * @TODO Handle serialization/deserialization
 * @TODO Tune options
 */
function useMemberEdit(variables: MemberEditVars, options?: MemberEditOptions) {
  const query = useEndpoint(variables, options)
  const d = (options?.initialData as MemberEditQuery) || query?.data
  const data = d ? { getUser: d.getUser as UpdateUserInput } : d
  const uri = Routes.MEMBERS_EDIT.replace(':username', variables.username)
  return { ...query, data, uri }
}

export type { MemberEditOptions, MemberEditVars }
export { invalidateMemberEdit }
export default useMemberEdit
