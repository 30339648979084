import isEmptyOrNil from './isEmptyOrNil'

/**
 * Recursively omit an object or an array through an `isEmptyOrNil` check.
 * Pass `true` for `onlyNil` to only remove nil values.
 *
 * TODO: Generate returned types! For the moment, cast it on the result:
 *
 * @example
 * omitEmptyOrNil(input) as UserInput
 */
function omitEmptyOrNil(obj: any, onlyNil = false, omitFalse = false): any {
  if (Array.isArray(obj)) {
    return obj
      .map((v) =>
        v && typeof v === 'object' ? omitEmptyOrNil(v, onlyNil, omitFalse) : v,
      )
      .filter((v) => (onlyNil ? v != null : !isEmptyOrNil(v, omitFalse)))
  }
  return Object.entries(obj)
    .map(([k, v]) => [
      k,
      v && typeof v === 'object' ? omitEmptyOrNil(v, onlyNil, omitFalse) : v,
    ])
    .reduce(
      (a: any, [k, v]) =>
        (onlyNil ? v == null : isEmptyOrNil(v, omitFalse))
          ? a
          : ((a[k] = v), a),
      {},
    )
}

export default omitEmptyOrNil
