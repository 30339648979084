import { MemberFragment } from '@vangst/services/oogst/types'
import React, { useMemo } from 'react'
import Select, { SelectType } from '../../../components/forms/Select'

type PropsType = SelectType & {
  readonly fieldLabel?: string
  readonly users: MemberFragment[]
  readonly valueField?: string
}

function SelectClientUser(props: PropsType & { fieldLabel?: string }) {
  const {
    users,
    error,
    hideLabel,
    hint,
    label,
    name,
    reference,
    valueField = 'id',
    ...rest
  } = props

  const listOfUsers = useMemo(() => {
    return users?.map((user: MemberFragment) => ({
      label: user?.firstName + ' ' + user?.lastName ?? '',
      // @ts-ignore
      value: user?.[valueField] ?? '',
    }))
  }, [users, valueField])

  const userOptions = [
    { label: 'Select User', value: '' },
    ...(listOfUsers ?? []),
  ]

  return (
    <Select
      id="Users"
      disabled={props.disabled}
      error={error}
      hideLabel={hideLabel}
      hint={hint}
      label={label}
      name={name}
      options={userOptions}
      reference={reference}
      {...rest}
    />
  )
}

export default React.memo(SelectClientUser)

// ----------------------------------------------------
