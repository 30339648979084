import { GoogleOAuthProvider } from '@react-oauth/google'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import '@vangst/tailwind/main.css'
import type { AppProps } from 'next/app'
import { Montserrat } from 'next/font/google'
import Head from 'next/head'
import React from 'react'
import { IconContext } from 'react-icons'
import DebugTools from '../components/views/DebugTools'
import Beacons from '../domains/application/Beacons'
import QueryErrorBoundary from '../domains/application/feedback/QueryErrorBoundary'
import FloatControls from '../domains/application/FloatControls'
import Footer from '../domains/application/Footer'
import Navbar from '../domains/application/Navbar'
import SkipLinks from '../domains/application/SkipLinks'

export const montserrat = Montserrat({
  subsets: ['latin'],
})

export default function App({
  Component,
  pageProps,
}: AppProps<{ dehydratedState: unknown }>) {
  const queryClientRef = React.useRef<QueryClient>()

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          useErrorBoundary: true,
        },
      },
    })
  }
  return (
    <GoogleOAuthProvider
      clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || ''}
    >
      <QueryClientProvider client={queryClientRef.current}>
        <style jsx global>{`
          :root {
            --font-montserrat: ${montserrat.style.fontFamily};
          }
        `}</style>
        <ReactQueryDevtools />
        <Hydrate state={pageProps.dehydratedState}>
          <Head key="head">
            <meta
              key="meta:viewport"
              content="width=device-width, initial-scale=1, maximum-scale=5"
              name="viewport"
            />
          </Head>
          <QueryErrorBoundary kind="page">
            <IconContext.Provider value={{ size: '1.5em' }}>
              <SkipLinks />
              <Beacons />
              <Navbar />
              <main id="main" className="z-10 min-h-screen" role="main">
                <QueryErrorBoundary kind="page">
                  <Component {...pageProps} />
                </QueryErrorBoundary>
              </main>
              <Footer />
              <FloatControls />
            </IconContext.Provider>
          </QueryErrorBoundary>
        </Hydrate>
        {process.env.NEXT_PUBLIC_OOGST_UAT != null && <DebugTools />}
      </QueryClientProvider>
    </GoogleOAuthProvider>
  )
}
