import { PayPeriodEnum } from '../services/oogst/types'
import formatDollars from './formatDollars'
import isEmptyOrNil from './isEmptyOrNil'

type PropsType = {
  maxValue?: number
  minValue?: number
  payPeriod?: PayPeriodEnum
}

/**
 * Formats salary min, max, and pay period into 1 field
 *
 * @example
 * formatSalary({ maxValue: 12, minValue: 10, payPeriod: 'hour' }) // '$10.00 - $12.00 / hour'
 */
export default function formatSalary(props: PropsType) {
  const { maxValue = null, minValue = null, payPeriod } = props
  const min = formatDollars(minValue)
  const max = formatDollars(maxValue)
  const period = payPeriod?.toLowerCase()
  const hasNone = isEmptyOrNil(min) && isEmptyOrNil(max) && isEmptyOrNil(period)
  if (hasNone) return null

  const hasBoth = !isEmptyOrNil(minValue) && !isEmptyOrNil(maxValue)
  const salary = `${min}${hasBoth ? ` - ${max}` : ''} / ${period}`
  return salary
}
