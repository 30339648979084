import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import Routes from '../../routes'
import { fetcher } from '../fetcher'
import { prefetchNodes } from '../nodes/useNodes'
import {
  EsResultFragment,
  FetcherError,
  SearchCandidatesByClientsDocument,
  SearchCandidatesByClientsQuery,
  SearchCandidatesByClientsQueryVariables,
  SearchCandidatesByRecruitersDocument,
  SearchCandidatesByRecruitersQuery,
  SearchCandidatesByRecruitersQueryVariables,
  SearchCompaniesByRecruitersDocument,
  SearchCompaniesByRecruitersQuery,
  SearchCompaniesByRecruitersQueryVariables,
  SearchCompaniesDocument,
  SearchCompaniesQuery,
  SearchCompaniesQueryVariables,
  SearchCredentialsDocument,
  SearchCredentialsQuery,
  SearchCredentialsQueryVariables,
  SearchJobPostingsByRecruitersDocument,
  SearchJobPostingsByRecruitersQuery,
  SearchJobPostingsByRecruitersQueryVariables,
  SearchJobPostingsDocument,
  SearchJobPostingsQuery,
  SearchJobPostingsQueryVariables,
  SearchMembersDocument,
  SearchMembersQuery,
  SearchMembersQueryVariables,
  SearchSlugNamesDocument,
  SearchSlugNamesQuery,
  SearchSlugNamesQueryVariables,
} from '../types'
import { getBasePageInfoInfinitePagination } from '../wtf/pagination'

/**
 * @private
 * List of available endpoints to query for elastic search.
 * Endpoints are treated similar to filters.
 */
const endpoints = Object.freeze([
  'searchCandidates',
  'searchCandidatesByClients',
  'searchCandidatesByRecruiters',
  'searchClients',
  'searchClientsByRecruiters',
  'searchCredentials',
  'searchJobPostings',
  'searchJobPostingsByRecruiters',
  'searchSlugNames',
] as const)

type ElasticSearchEndpoints = (typeof endpoints)[number]

type ElasticSearchQuery =
  | SearchCandidatesByClientsQuery
  | SearchCandidatesByRecruitersQuery
  | SearchCompaniesQuery
  | SearchCompaniesByRecruitersQuery
  | SearchCredentialsQuery
  | SearchJobPostingsQuery
  | SearchJobPostingsQuery
  | SearchJobPostingsByRecruitersQuery
  | SearchMembersQuery
  | SearchSlugNamesQuery

type ElasticSearchVars =
  | SearchCandidatesByClientsQueryVariables
  | SearchCandidatesByRecruitersQueryVariables
  | SearchCompaniesQueryVariables
  | SearchCompaniesByRecruitersQueryVariables
  | SearchCredentialsQueryVariables
  | SearchJobPostingsQueryVariables
  | SearchJobPostingsQueryVariables
  | SearchJobPostingsByRecruitersQueryVariables
  | SearchMembersQueryVariables
  | SearchSlugNamesQueryVariables

type ElasticSearchOptions = UseInfiniteQueryOptions<
  ElasticSearchQuery,
  FetcherError,
  ElasticSearchQuery
>

/**
 * @private
 * Locate and return the result query casted to the right type.
 */
function castedResult(q: ElasticSearchQuery) {
  return (
    (q as SearchCompaniesQuery)?.searchClients ||
    (q as SearchCompaniesByRecruitersQuery)?.searchClientsByRecruiters ||
    (q as SearchCredentialsQuery)?.searchCredentials ||
    (q as SearchMembersQuery)?.searchCandidates ||
    (q as SearchCandidatesByClientsQuery)?.searchCandidatesByClients ||
    (q as SearchCandidatesByRecruitersQuery)?.searchCandidatesByRecruiters ||
    (q as SearchJobPostingsByRecruitersQuery)?.searchJobPostingsByRecruiters ||
    (q as SearchJobPostingsQuery)?.searchJobPostings ||
    (q as SearchSlugNamesQuery)?.searchSlugNames
  )
}

/**
 * @private
 * Retrieve the cached `EsResult.id` list to prefetch the `nodes` endpoint.
 */
function getCachedIds(
  queryClient: QueryClient,
  endpoint: ElasticSearchEndpoints,
  variables?: ElasticSearchVars,
) {
  const key = getKey(endpoint, variables)
  const data = queryClient.getQueryData<InfiniteData<ElasticSearchQuery>>(key)
  const ids = data?.pages.flatMap((p) => {
    return castedResult(p).edges?.map((edge) => edge?.node?.id)
  })
  // TODO Sort of a bailout instead of (string | undefined)[] | []
  return ids as string[] | undefined
}

/**
 * @private
 * Retrieve the document fragment used in the request.
 */
function getDoc(endpoint: ElasticSearchEndpoints) {
  return endpoint === 'searchCandidates'
    ? SearchMembersDocument
    : endpoint === 'searchClients'
    ? SearchCompaniesDocument
    : endpoint === 'searchClientsByRecruiters'
    ? SearchCompaniesByRecruitersDocument
    : endpoint === 'searchCandidatesByClients'
    ? SearchCandidatesByClientsDocument
    : endpoint === 'searchCredentials'
    ? SearchCredentialsDocument
    : endpoint === 'searchSlugNames'
    ? SearchSlugNamesDocument
    : endpoint === 'searchCandidatesByRecruiters'
    ? SearchCandidatesByRecruitersDocument
    : endpoint === 'searchJobPostingsByRecruiters'
    ? SearchJobPostingsByRecruitersDocument
    : SearchJobPostingsDocument
}

/**
 * @private
 * Retrieve the stringified query cache key used in the internal cache.
 *
 * @example
 * const key = getKey('searchJobPostings', { first: 1, term: "maple" })
 * -> ["searchJobPostingsInfinite",{"first": 1,"term": "maple"}]
 */
function getKey(
  endpoint: ElasticSearchEndpoints,
  variables?: ElasticSearchVars,
) {
  const name = endpoint + 'Infinite'
  return variables ? [name, variables] : [name]
}

/**
 * @private
 * Retrieve the probable uri for the endpoint.
 */
function getUri(endpoint: ElasticSearchEndpoints) {
  return endpoint === 'searchCandidates'
    ? Routes.MEMBERS
    : endpoint === 'searchClients'
    ? Routes.MEMBERS
    : endpoint === 'searchClientsByRecruiters'
    ? Routes.COMPANIES
    : endpoint === 'searchCandidatesByClients'
    ? Routes.MEMBERS
    : endpoint === 'searchCandidatesByRecruiters'
    ? Routes.MEMBERS
    : endpoint === 'searchJobPostingsByRecruiters'
    ? Routes.MEMBERS
    : endpoint === 'searchJobPostings'
    ? Routes.JOBS
    : Routes.SEARCH
}

/**
 * @private
 * Prefetch and return the list of Elastic Search ids.
 *
 * @example
 * const ids = await prefetchElasticSearch({ queryClient, endpoint: 'searchJobPostings', variables: { first: 3 } })
 * await queryClient.prefetchInfiniteQuery(key, fn)
 */
async function prefetchElasticSearchIds(
  queryClient: QueryClient,
  endpoint: ElasticSearchEndpoints,
  variables?: ElasticSearchVars,
) {
  const key = getKey(endpoint, variables)
  const fn = fetcher<ElasticSearchQuery, ElasticSearchVars>(
    getDoc(endpoint),
    variables,
  )
  await queryClient.prefetchInfiniteQuery(key, fn)
  const ids = getCachedIds(queryClient, endpoint, variables)
  return ids
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function. If no
 * variables are passed all `ElasticSearch` queries will be invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateElasticSearch(queryClient, 'searchClients', { first: 24 } })
 * invalidateElasticSearch(queryClient)
 */
function invalidateElasticSearch(
  queryClient: QueryClient,
  endpoint: ElasticSearchEndpoints,
  variables?: ElasticSearchVars,
) {
  queryClient.invalidateQueries(getKey(endpoint, variables))
}

/**
 * Prefetch and return the list of `Nodes` from an Elastic Search query.
 *
 * @example
 * const companies = await prefetchElasticSearch(queryClient, 'searchClients', { first: 24 } })
 */
async function prefetchElasticSearch(
  queryClient: QueryClient,
  endpoint: ElasticSearchEndpoints,
  variables?: ElasticSearchVars,
) {
  const ids = await prefetchElasticSearchIds(queryClient, endpoint, variables)
  const data = ids ? await prefetchNodes(queryClient, { ids }) : undefined

  return data
}

/**
 * Fetch an infinite query of paginated Elastic Search result subjects.
 *
 * Yo! Generally use `subjects` instead of `data` on the return from the hook.
 * It has the `ElasticSearchQuery` union deconstructed to an
 * `EsResultFragment`.
 *
 * @example
 * const { isFetching, isZero, pagination, subjects, uri } =
 *   useElasticSearch('searchJobPostings', { term: 'budtender' }, options)
 *
 * @TODO Add optimistic Create|Update|Delete?
 * @TODO Move `subjects` into `data`?
 * @TODO Compute data in select?
 * @TODO Tune options
 */
function useElasticSearch(
  endpoint: ElasticSearchEndpoints,
  variables?: ElasticSearchVars,
  options?: ElasticSearchOptions,
) {
  const query = useInfiniteQuery<
    ElasticSearchQuery,
    FetcherError,
    ElasticSearchQuery
  >(
    getKey(endpoint, variables),
    ({ pageParam }) =>
      fetcher<ElasticSearchQuery, ElasticSearchVars>(getDoc(endpoint), {
        ...variables,
        ...pageParam,
      })(),
    {
      getNextPageParam: (lastPage) => {
        const s = castedResult(lastPage)
        const after = s?.pageInfo?.hasNextPage
          ? s?.pageInfo?.endCursor
          : undefined
        return { after }
      },
      keepPreviousData: true,
      ...options,
    },
  )
  const pages = query?.data?.pages
  const pageInfo = getBasePageInfoInfinitePagination(
    pages?.map((p) => castedResult(p)?.pageInfo) || [],
    variables?.first || variables?.last || 24,
  )
  const subjects = pages?.map((p) => {
    const casted = castedResult(p)
    if (casted == null) {
      return []
    }
    return casted.edges?.map((edge) => edge?.node as EsResultFragment)
  })

  const nextPage = pageInfo?.nextHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchNextPage()
      }
    : undefined

  const prevPage = pageInfo?.prevHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchPreviousPage()
      }
    : undefined

  const pagination = { ...pageInfo, nextPage, prevPage }
  const isZero = query.data && pagination?.totalCount === 0
  const uri = getUri(endpoint)
  return { ...query, isZero, pagination, subjects, uri }
}

// -------------------------------------

export type {
  ElasticSearchEndpoints,
  ElasticSearchOptions,
  ElasticSearchQuery,
  ElasticSearchVars,
}
export { invalidateElasticSearch, prefetchElasticSearch }
export default useElasticSearch
