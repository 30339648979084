/**
 * Attempt to convert common values into a Float
 *
 * @example
 * toNumber(0) // => 0
 * toNumber('100') // => 100
 * toNumber('666 will it convert?') // => 666
 * toNumber(undefined) // => undefined
 */
function toNumber(val?: any) {
  if (typeof val === 'number' || val == null) return val
  const float = parseFloat(val)
  return isNaN(float) ? undefined : float
}

export default toNumber
