import {
  MemberComputed,
  MemberOptions,
  MemberVars,
} from '@vangst/services/oogst/member/useMember'
import { useRouter } from 'next/router'
import TableRowDetails from '../application/TableRow'
import MemberTableRowBody from './views/MemberTableRowBody'
import MemberTableRowSummary from './views/MemberTableRowSummmary'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly jobPostingId?: string
  readonly isFeatured?: boolean
  readonly member?: MemberComputed
  readonly options?: MemberOptions
  readonly uri: string
  readonly variables: MemberVars
}

function MemberTableRow(props: PropsType) {
  const { jobPostingId, member, uri, variables, options, isFeatured } = props
  const { query } = useRouter()
  const initialOpen = query?.open != null && query?.open === member?.username

  return (
    <TableRowDetails initialOpen={initialOpen}>
      <MemberTableRowSummary
        uri={uri}
        member={member}
        isFeatured={isFeatured}
        jobPostingId={jobPostingId}
        options={options}
        variables={variables}
      />
      <MemberTableRowBody member={member} variables={variables} />
    </TableRowDetails>
  )
}

export default MemberTableRow
