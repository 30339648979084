import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import { invalidateCompany } from '../company/useCompany'
import { invalidateCompanyJobApplications } from '../company/useCompanyJobApplications'
import { invalidateCompanyJobPostings } from '../company/useCompanyJobPostings'
import {
  FetcherError,
  UpdateJobPostingMutation,
  UpdateJobPostingMutationVariables,
  useUpdateJobPostingMutation,
} from '../types'
import { invalidateViewer } from '../viewer/useViewer'
import { invalidateJobPosting } from './useJobPosting'
import { invalidateJobPostingEdit } from './useJobPostingEdit'

type UpdateOptions = UseMutationOptions<
  UpdateJobPostingMutation,
  FetcherError,
  UpdateJobPostingMutationVariables
>

/**
 * Updates an existing `JobPosting`.
 *
 * When overriding `onSettled` be sure to provide necessary invalidations.
 *
 * @example
 * const updateJobPosting = useUpdateJobPosting({onSuccess: () => push(url)})
 * const input = {title: 'Job Title Updated'}
 * const updateJobPosting.mutate({ input })
 *
 * @TODO Invalidate searchJobPostings?
 * @TODO Call optimistic Updates?
 */
function useUpdateJobPosting(options: UpdateOptions) {
  const queryClient = useQueryClient()

  return useUpdateJobPostingMutation({
    onSettled: (data) => {
      const slug = data?.updateJobPosting.slug
      const displayname = data?.updateJobPosting.client?.displayname

      if (slug) {
        invalidateJobPosting(queryClient, { id: slug })
        invalidateJobPostingEdit(queryClient, { id: slug })
        invalidateCompanyJobApplications(queryClient)
      }

      if (displayname) {
        invalidateCompany(queryClient, { displayname })
        invalidateCompanyJobPostings(queryClient, { displayname })
      }

      invalidateViewer(queryClient)
    },
    ...options,
  })
}

export default useUpdateJobPosting
