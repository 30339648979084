import { useQueryClient } from '@tanstack/react-query'
import useUpdatePlacement from '@vangst/services/oogst/company/useUpdatePlacement'
import { Company_PlacementFragment } from '@vangst/services/oogst/types'
import React from 'react'
import { useForm } from 'react-hook-form'
import { MdCalendarMonth } from 'react-icons/md'
import Busy from '../../../components/feedback/Busy'
import { DialogModal } from '../../../components/feedback/DialogModal'
import Input from '../../../components/forms/Input'
import ClickyBtn from '../../../components/navigation/ClickyBtn'

type PropsType = {
  readonly placement: Company_PlacementFragment
  readonly handleCancel: () => void
}

function DialogEditPlacement({ placement, handleCancel }: PropsType) {
  const defaultValues = {
    interviewDate: placement?.interviewDate,
    startDate: placement?.startDate,
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ defaultValues })

  const queryClient = useQueryClient()
  const { mutate, isLoading } = useUpdatePlacement(queryClient, {
    onSuccess: () => handleCancel(),
  })

  const submit = React.useCallback(
    (fd: any) => {
      const fields: any = {
        interviewDate: fd?.interviewDate
          ? new Date(fd?.interviewDate).toISOString()
          : null,
        startDate: fd?.startDate ? new Date(fd?.startDate).toISOString() : null,
      }
      const input = {
        ...fields,
        id: placement.id,
      }
      mutate({ input })
    },
    [mutate, placement.id],
  )

  const send = React.useMemo(() => handleSubmit(submit), [handleSubmit, submit])

  return (
    <DialogModal onClose={handleCancel} open>
      <DialogModal.Panel icon={MdCalendarMonth} title="Edit Placement Details">
        {placement && (
          <div className="max-w-100">
            <Input
              error={errors?.interviewDate?.message}
              label="Interview Date"
              type="datetime-local"
              defaultValue={placement?.interviewDate}
              {...register('interviewDate')}
            />
            <Input
              error={errors?.startDate?.message}
              label="Start Date"
              type="datetime-local"
              defaultValue={placement?.startDate}
              {...register('startDate')}
            />
          </div>
        )}
        <div className="flex w-full items-center justify-center gap-4">
          <ClickyBtn className="mood-orange w-40" onClick={send}>
            {!isLoading ? 'Save' : <Busy />}
          </ClickyBtn>
          <ClickyBtn className="w-40" onClick={handleCancel}>
            Cancel
          </ClickyBtn>
        </div>
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default React.memo(DialogEditPlacement)
