import { Dialog, DialogPanelProps, DialogProps } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import { ElementType, forwardRef } from 'react'
import { IconType } from 'react-icons'

type DialogRootType = DialogProps<ElementType>

const DialogModalRoot = forwardRef<HTMLDivElement, DialogRootType>(
  function DialogModalRoot(props: DialogRootType, ref) {
    const { children, className, ...rezt } = props
    const rest = { ...rezt, ref }
    return (
      <Dialog
        className={clsx(
          'fixed inset-0 z-50 transform-gpu animate-fadeIn overflow-y-auto p-8',
          'bg-grey-darkest/50 backdrop-blur-sm',
          'sm:py-16',
          'md:py-24',
          className,
        )}
        {...rest}
      >
        {children}
      </Dialog>
    )
  },
)

type DialogPanelType = DialogPanelProps<ElementType> & {
  icon?: IconType
  title?: string
}

const DialogModalPanel = forwardRef<HTMLDivElement, DialogPanelType>(
  function DialogModalPanel(props: DialogPanelType, ref) {
    const { children, className, icon, title, ...rezt } = props
    const rest = { ...rezt, ref }
    const Icon = icon

    return (
      <Dialog.Panel
        className={clsx(
          'mood-white mx-auto flex w-full max-w-prose flex-col items-center justify-center gap-4 overflow-hidden rounded-md border p-4 shadow-xl',
          'md:p-8',
          className,
        )}
        {...rest}
      >
        <>
          {Icon && <Icon className="text-orange" size="3em" />}
          {title && <Dialog.Title className="heading-sm">{title}</Dialog.Title>}
          {children}
        </>
      </Dialog.Panel>
    )
  },
)

// -------------------------------------

/**
 * The DialogModal is a styled wrapper around `@headlessui/Dialog`
 *
 * The modal is a fixed, scrollable container on a blurred out white
 * translucent backdrop. It accepts a `className` property so any of these
 * attributes can be overriden.
 *
 * - Setup similar to `Dialog` with pass through properties as
 * `DialogModal.Backdrop|Description|Overlay|Title`
 * - Utilizes a custom `DialogModal.Panel`
 *
 * @TODO
 * - Add headlessui.Transition - Right now its a css fade-in animation
 *
 * @see [@headlessui/Dialog](https://headlessui.com/dialog)
 *
 * @example
 * <DialogModal initialFocus={focusRef} onClose={onClose} open={isOpen}>
 *   <DialogModal.Panel icon={MdGrass} title="Oh Hey There.">
 *     <DialogModal.Description className="my-8">
 *       ...
 *     </DialogModal.Description>
 *     <div className="flex w-full items-center justify-center gap-4">
 *       ...
 *     </div>
 *   </DialogModal.Panel>
 * </DialogModal>
 *
 * ☝️ -- These are equivalent -- 👇
 *
 * <DialogModal initialFocus={focusRef} onClose={onClose} open={isOpen}>
 *   <DialogModal.Panel>
 *     <MdErrorOutline className="text-orange" size="3em" />
 *     <Dialog.Title className="heading-sm">Oh Hey There.</Dialog.Title>
 *     <DialogModal.Description className="my-8">
 *       ...
 *     </DialogModal.Description>
 *     <div className="flex w-full items-center justify-center gap-4">
 *       ...
 *     </div>
 *   </DialogModal.Panel>
 * </DialogModal>
 */
export const DialogModal = Object.assign(DialogModalRoot, {
  Backdrop: Dialog.Backdrop,
  Description: Dialog.Description,
  Overlay: Dialog.Overlay,
  /**
   * The DialogModal.Panel is a styled wrapper around `@headlessui/Dialog.Panel`
   *
   * - Pass an optional `icon?: IconType` property to render a styled icon in
   * the header
   * - Pass an optional `title?: string` property to render a styled
   * `DialogModal.Title` in the header
   * - A `DialogModal.Description` should be used at the instance level
   * - It is not possible to style predefined `Icon` + `Title`s using these
   * attributes
   * - See the story for custom examples
   *
   * @see DialogModal for examples
   */
  Panel: DialogModalPanel,
  Title: Dialog.Title,
})
