import { useQueryClient } from '@tanstack/react-query'
import clsx from '@vangst/lib/clsx'
import { orderedPlacementStatus } from '@vangst/services/oogst/company/statuses'
import useUpdatePlacement from '@vangst/services/oogst/company/useUpdatePlacement'
import { PlacementStatusEnum } from '@vangst/services/oogst/types'
import { memo } from 'react'
import { MdAdd, MdExpandLess, MdExpandMore, MdRemove } from 'react-icons/md'
import AnchorButton from '../../../components/navigation/internal/AnchorButton'

type PropsType = {
  readonly className?: string
  readonly defaultValue?: PlacementStatusEnum
  readonly isGrouped?: boolean
  readonly placementId: string
}

function PlacementStatusDisclosure(props: PropsType) {
  const { className, defaultValue, isGrouped, placementId, ...rest } = props
  const queryClient = useQueryClient()
  const { mutate } = useUpdatePlacement(queryClient)

  const onChange = (status: PlacementStatusEnum) => {
    mutate({ input: { id: placementId, status } })
  }

  const defaultLabel =
    orderedPlacementStatus[defaultValue as PlacementStatusEnum]

  return (
    <details className="group/disc" {...rest}>
      <summary className={clsx('cursor-pointer', className)}>
        <span className="truncate">
          {isGrouped
            ? `${defaultLabel ?? 'Status'}`
            : `Placement: ${defaultLabel ?? 'Status'}`}
        </span>
        <span className="ml-auto">
          {isGrouped ? (
            <>
              <MdExpandMore className="group-open/disc:hidden" />
              <MdExpandLess className="hidden group-open/disc:inline" />
            </>
          ) : (
            <>
              <MdAdd className="group-open/disc:hidden" />
              <MdRemove className="hidden group-open/disc:inline" />
            </>
          )}
        </span>
      </summary>
      <ul>
        {Object.keys(orderedPlacementStatus).map((status) => (
          <li key={status}>
            <AnchorButton
              aria-selected={defaultValue === status}
              className={clsx('pl-6', className)}
              onClick={() => onChange(status as PlacementStatusEnum)}
            >
              {defaultValue === status && '✓ '}
              {orderedPlacementStatus[status as PlacementStatusEnum]}
            </AnchorButton>
          </li>
        ))}
      </ul>
    </details>
  )
}

export default memo(PlacementStatusDisclosure)
