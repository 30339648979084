import { LicenseFragment } from '@vangst/services/oogst/types'
import useLicenses from '@vangst/services/oogst/wtf/useLicenses'
import React, { useMemo } from 'react'
import Busy from '../../../components/feedback/Busy'
import Select, { SelectType } from '../../../components/forms/Select'

type PropsType = SelectType

function SelectLicense(props: PropsType & { fieldLabel?: string }) {
  const { error, hideLabel, hint, label, name, reference, ...rest } = props

  const { data: ld, isLoading: isLicensesLoading } = useLicenses()
  const licenses = ld?.licenses

  const id = props?.id ?? name

  const listOfLicenses = useMemo(() => {
    return licenses?.map((license: LicenseFragment) => ({
      label: `${license.state} - ${license.name}`,
      value: license.id ?? '',
    }))
  }, [licenses])

  const licenseOptions = [
    { label: 'Select License', value: '' },
    ...(listOfLicenses ?? []),
  ]

  if (isLicensesLoading) return <Busy />

  return (
    <Select
      id={id}
      disabled={props.disabled}
      error={error}
      hideLabel={hideLabel}
      hint={hint}
      label={label}
      name={name}
      options={licenseOptions}
      reference={reference}
      {...rest}
    />
  )
}

export default React.memo(SelectLicense)
