import { BaseEditor, Element, Text } from 'slate'
import { HistoryEditor } from 'slate-history'
import { ReactEditor } from 'slate-react'
type Descendant = Element | Text

export enum BlockFormatType {
  'paragraph' = 'paragraph',
  'blockquote' = 'blockquote',
  'link' = 'link',
  'listItem' = 'listItem',
  'list' = 'list',
  'orderedList' = 'orderedList',
  'mention' = 'mention',
}

export enum MarkFormatType {
  'emphasis' = 'emphasis',
  'strong' = 'strong',
  'header' = 'header',
}

export type BaseElement = {
  children: Descendant[]
}

type HeaderText = {
  header: boolean
  text: string
} & Text

type StrongText = {
  strong: boolean
  text: string
} & Text

type EmphasisText = {
  emphasis: boolean
  text: string
} & Text

type LinkElement = {
  type: BlockFormatType.link
  url: string
} & BaseElement

type ListElement = {
  type: BlockFormatType.list
} & BaseElement

type OrderedListElement = {
  type: BlockFormatType.orderedList
} & BaseElement

type ListItemElement = {
  type: BlockFormatType.listItem
} & BaseElement

type ParagraphElement = {
  type: BlockFormatType.paragraph
} & BaseElement

type QuoteElement = {
  type: BlockFormatType.blockquote
} & BaseElement

type MentionElement = {
  type: BlockFormatType.mention
  id: string
  displayname: string
  url: string
} & BaseElement

export type BlockFormat = BlockFormatType
export type MarkFormat = MarkFormatType

// https://github.com/ianstormtaylor/slate/blob/228f4fa94f61f42ca41feae2b3029ebb570e0480/packages/slate-react/src/components/element.tsx
export type ElementAttributes = {
  'data-slate-node': 'element'
  'data-slate-void'?: true
  'data-slate-inline'?: true
  contentEditable?: false
  dir?: 'rtl'
  ref: any
}

// https://github.com/ianstormtaylor/slate/blob/228f4fa94f61f42ca41feae2b3029ebb570e0480/packages/slate-react/src/components/leaf.tsx
export type LeafAttributes = {
  'data-slate-leaf': true
}

export type CustomElement =
  | LinkElement
  | ParagraphElement
  | ListElement
  | OrderedListElement
  | ListItemElement
  | QuoteElement
  | MentionElement

export type CustomLeafElement = StrongText & EmphasisText & HeaderText
export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor

export type CustomText = {
  bold?: boolean
  italic?: boolean
  text: string
}

export type EmptyText = {
  text: string
}
// https://github.com/ianstormtaylor/slate/blob/main/site/examples/custom-types.d.ts
declare module 'slate' {
  // @ts-ignore
  type CustomTypes = {
    Editor: CustomEditor
    Element: CustomElement
    Text: CustomText | EmptyText
  }
}
