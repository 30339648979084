// TODO: Should these objects be lazily instantiated?
// TODO: Add tests!

/**
 * A frozen object with month abbreviation as the keys and month name for values
 *
 * @TODO Can this be an enum?
 */
export const MONTH_LIST = Object.freeze({
  '1': 'January',
  '2': 'February',
  '3': 'March',
  '4': 'April',
  '5': 'May',
  '6': 'June',
  '7': 'July',
  '8': 'August',
  '9': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
})

/**
 * A frozen array of month abbreviations
 * 01, 02, 03, 04, ... 12
 */
export const MONTH_ABBREVIATIONS = Object.freeze(Object.keys(MONTH_LIST))

/**
 * A frozen array of month names
 * January, February, March, April...
 */
export const MONTH_NAMES = Object.freeze(Object.values(MONTH_LIST))

/**
 * An array of month options used with Comboboxes and Selects
 * { label: 'January', value: 'January' },...
 *
 * @TODO How to freeze this without blowing up consumers
 */
export const MONTH_OPTIONS = [
  { label: 'Month', value: '' },
  ...MONTH_NAMES.map((m: string) => ({ label: m, value: m })),
]

/**
 * The current year as `yyyy`
 */
export const CURRENT_YEAR = Object.freeze(new Date().getUTCFullYear())

/**
 * A frozen array of year names as strings
 * '1999', '2000', '2001', '2002'
 */
export const YEAR_NAMES = Array(CURRENT_YEAR - (CURRENT_YEAR - 65))
  .fill(null)
  .map((_, i) => String(CURRENT_YEAR - i))

/**
 * An array of year options used with Comboboxes and Selects
 * { label: '1992', value: '1992' },...
 *
 * @TODO How to freeze this without blowing up consumers
 */
export const YEAR_OPTIONS = [
  { label: 'Year', value: '' },
  ...YEAR_NAMES.map((y: string) => ({ label: y, value: y })),
]
