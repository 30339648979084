import Routes from './routes'

enum StorageKeys {
  ACCESS = 'com.vangst.accessToken',
  ANONYMOUS = 'com.vangst.anonymousToken',
  APPLY_SOON = 'com.vangst.applySoon',
  REDIRECT = 'com.vangst.redirect',
}

/**
 * Get and remove a redirect url from `sessionStorage`.
 * Typically used during sign in|up|out.
 */
function getSessionRedirect() {
  const redirect = globalThis.sessionStorage?.getItem(StorageKeys.REDIRECT)
  if (redirect) globalThis.sessionStorage?.removeItem(StorageKeys.REDIRECT)
  return redirect
}

function clearSessionRedirect() {
  globalThis.sessionStorage?.removeItem(StorageKeys.REDIRECT)
  return
}

/**
 * Set a path for an upcoming redirect after signing in|up
 * Defaults to `location.pathname + location.search`
 */
function setSessionRedirect(redirect?: string) {
  if (redirect != null) {
    globalThis.sessionStorage?.setItem(StorageKeys.REDIRECT, redirect)
    return
  }
  const pathname = globalThis.location?.pathname as Routes
  if (
    pathname === Routes.ADMIN_IMPOSTOR ||
    pathname === Routes.INDEX ||
    pathname === Routes.MEMBER_FORGOT_PASSWORD ||
    pathname === Routes.MEMBER_RESET_PASSWORD ||
    pathname === Routes.SIGN_IN ||
    pathname === Routes.SIGN_OUT ||
    pathname === Routes.SIGN_UP
  )
    return
  globalThis.sessionStorage?.setItem(
    StorageKeys.REDIRECT,
    pathname + globalThis.location?.search,
  )
}

let hasLocalStorageMemoized: boolean | undefined

/**
 * Runs a test to see if the consuming client supports localStorage
 */
function hasLocalStorage() {
  if (hasLocalStorageMemoized != null) return hasLocalStorageMemoized
  try {
    globalThis.localStorage.setItem('hasls', 'hasls')
    globalThis.localStorage.removeItem('hasls')
    hasLocalStorageMemoized = true
    return true
  } catch (e) {
    hasLocalStorageMemoized = false
    return false
  }
}

/**
 * Uses `localStorage` with a fallback for `sessionStorage`.
 * Typically needed for Incognito windows in Safari
 */
function storage() {
  if (typeof window === 'undefined') return
  return hasLocalStorage() ? globalThis.localStorage : globalThis.sessionStorage
}

export {
  clearSessionRedirect,
  getSessionRedirect,
  setSessionRedirect,
  StorageKeys,
}
export default storage
