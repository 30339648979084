import {
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { getDynamicTimestamp } from '@vangst/lib/formatDates'
import Routes from '../../routes'
import { fetcher } from '../fetcher'
import useNodesActivityFactory from '../member/useNodesActivityFactory'
import {
  ActivityFragment,
  BasePageInfoFragment,
  CompanyFragment,
  FetcherError,
  JobPostingActivitiesDocument,
  JobPostingActivitiesQuery,
  JobPostingActivitiesQueryVariables,
  JobPostingFragment,
  MemberActivitiesDocument,
  MemberActivitiesQuery,
  MemberActivitiesQueryVariables,
  MemberAlertsDocument,
  MemberAlertsQuery,
  MemberAlertsQueryVariables,
  MemberFragment,
} from '../types'
import { getBasePageInfoInfinitePagination } from './pagination'

/**
 * @private
 * List of available endpoints to query for elastic search.
 * Endpoints are treated similar to filters.
 */
const endpoints = Object.freeze([
  'getMemberActivities',
  'getMemberAlerts',
  'getJobPostingActivities',
] as const)

const { generateDetails } = useNodesActivityFactory()

type ActivitiesAlertsEndpoints = (typeof endpoints)[number]

type ActivitiesAlertsQuery =
  | MemberActivitiesQuery
  | MemberAlertsQuery
  | JobPostingActivitiesQuery

type ActivitiesAlertsQueryVariables =
  | MemberActivitiesQueryVariables
  | MemberAlertsQueryVariables
  | JobPostingActivitiesQueryVariables

type ActivitiesAlertsOptions = UseInfiniteQueryOptions<
  ActivitiesAlertsQuery,
  FetcherError,
  ActivitiesAlertsQuery
>

export type EntityType = JobPostingFragment | MemberFragment | CompanyFragment

/**
 * @private
 * Locate and return the result query casted to the right type.
 */
function castedResult(q: ActivitiesAlertsQuery) {
  return (
    (q as MemberActivitiesQuery)?.getUser?.activities ||
    (q as MemberAlertsQuery)?.getUser?.alerts ||
    (q as JobPostingActivitiesQuery)?.getJobPosting?.activities
  )
}

/**
 * @private
 * Retrieve the stringified `MemberActivitiesInfinite` query key used in the internal cache.
 * Retrieve the `Member.activities` from the queryClient's cache.
 */
// TODO: Is this only necessary if we are prefetching?
// function getCache(
//   queryClient: QueryClient,
//   endpoint: MemberActivitiesAlertsEndpoints,
//   variables?: MemberActivitiesAlertsVars,
// ) {
//   const key = getKey(endpoint, variables)
//   return queryClient.getQueryData<MemberActivitiesAlertsQuery>(key)
// }
/**
 * @private
 * Retrieve the document fragment used in the request.
 */
function getDoc(endpoint: ActivitiesAlertsEndpoints) {
  return endpoint === 'getMemberActivities'
    ? MemberActivitiesDocument
    : endpoint === 'getMemberAlerts'
    ? MemberAlertsDocument
    : endpoint === 'getJobPostingActivities'
    ? JobPostingActivitiesDocument
    : ''
}

/**
 * @private
 * Retrieve the stringified query cache key used in the internal cache.
 *
 * @example
 * const key = getKey('searchJobPostings', { first: 1, term: "maple" })
 * -> ["searchJobPostingsInfinite",{"first": 1,"term": "maple"}]
 */
function getKey(
  endpoint: ActivitiesAlertsEndpoints,
  variables?: ActivitiesAlertsQueryVariables,
) {
  const name = endpoint + 'Infinite'
  return variables ? [name, variables] : [name]
}

/**
 * @private
 * Retrieve the probable uri for the endpoint.
 */
// TODO: update this to something real
function getUri(endpoint: ActivitiesAlertsEndpoints) {
  return endpoint === 'getMemberActivities'
    ? Routes.DASHBOARD
    : endpoint === 'getMemberAlerts'
    ? Routes.DASHBOARD
    : endpoint === 'getJobPostingActivities'
    ? Routes.JOBS
    : Routes.SEARCH
}

// TODO: Dont think we need this bc its  an authenticated experience
// /**
//  * @private
//  * Prefetch and return the list of Elastic Search ids.
//  *
//  * @example
//  * const ids = await prefetchElasticSearch({ queryClient, endpoint: 'searchJobPostings', variables: { first: 3 } })
//  * await queryClient.prefetchInfiniteQuery(key, fn)
//  */
// async function prefetchElasticSearchIds(
//   queryClient: QueryClient,
//   endpoint: ElasticSearchEndpoints,
//   variables?: ElasticSearchVars,
// ) {
//   const key = getKey(endpoint, variables)
//   const fn = fetcher<ElasticSearchQuery, ElasticSearchVars>(
//     getDoc(endpoint),
//     variables,
//   )
//   await queryClient.prefetchInfiniteQuery(key, fn)
//   const ids = getCachedIds(queryClient, endpoint, variables)
//   return ids
// }

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function. If no
 * variables are passed all `MemberActivitiesAlerts` queries will be invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateElasticSearch(queryClient, 'getActivities', { first: 24 } })
 * invalidateElasticSearch(queryClient)
 */
function invalidateActivitiesAlerts(
  queryClient: QueryClient,
  endpoint: ActivitiesAlertsEndpoints,
  variables?: ActivitiesAlertsQueryVariables,
) {
  queryClient.invalidateQueries(getKey(endpoint, variables))
}

// TODO: Dont think we need this bc its  an authenticated experience
// /**
//  * Prefetch and return the list of items from the query.
//  *
//  * @example
//  * const companies = await prefetchElasticSearch(queryClient, 'searchClients', { first: 24 } })
//  */
// async function prefetchElasticSearch(
//   queryClient: QueryClient,
//   endpoint: ElasticSearchEndpoints,
//   variables?: ElasticSearchVars,
// ) {
//   const ids = await prefetchElasticSearchIds(queryClient, endpoint, variables)
//   const data = ids ? await prefetchNodes(queryClient, { ids }) : undefined

//   return data
// }

/**
 * @protected
 * Derives data not contained in the API.
 */
function getDerived(activity?: ActivityFragment | null) {
  if (activity == null) return activity

  return {
    details: {
      body: activity?.jsonBody,
      from: `${activity.author?.firstName} ${activity.author?.lastName}`,
      authorUri: activity.author?.uri,
      date: getDynamicTimestamp(
        new Date(Date.now()),
        new Date(activity.createdAt),
      ),
      context: generateDetails(
        (activity?.kontext as EntityType)?.__typename,
        activity?.kontext,
      ),
      resource: generateDetails(
        (activity?.resource as EntityType)?.__typename,
        activity?.resource,
      ),
    },
  }
}

/**
 * @private
 * Merge the `ActivityFragment` with derived `Activity` data.
 *
 * @TODO Memoize
 */
function getComputed(activity?: ActivityFragment) {
  if (activity == null) return activity
  return { ...activity, ...getDerived(activity) }
}

/**
 * Fetch an infinite query of paginated result subjects.
 *
 * @example
 * const { isFetching, isZero, pagination, subjects, uri } =
 *   useMemberActivitiesAlerts('getActivities', { id: id }, options)
 */
function useActivitiesAlerts(
  endpoint: ActivitiesAlertsEndpoints,
  variables?: ActivitiesAlertsQueryVariables,
  options?: ActivitiesAlertsOptions,
) {
  const query = useInfiniteQuery<
    ActivitiesAlertsQuery,
    FetcherError,
    ActivitiesAlertsQuery
  >(
    getKey(endpoint, variables),
    ({ pageParam }) =>
      fetcher<ActivitiesAlertsQuery, ActivitiesAlertsQueryVariables>(
        getDoc(endpoint),
        {
          ...variables,
          ...pageParam,
        },
      )(),
    {
      getNextPageParam: (lastPage) => {
        const edges = castedResult(lastPage)?.edges
        const after = edges ? edges[edges.length - 1]?.cursor : undefined
        return { after }
      },
      ...options,
    },
  )

  const pages = query?.data?.pages
  const pageInfo = getBasePageInfoInfinitePagination(
    (pages
      ?.map((p) => castedResult(p)?.pageInfo)
      // !!!!!  NOTE: THIS IS THE UNDEFINED HACK !!!!!
      .filter(Boolean) as BasePageInfoFragment[]) || [],
    variables?.first || variables?.last || 24,
  )

  const nextPage = pageInfo?.nextHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchNextPage()
      }
    : undefined

  const prevPage = pageInfo?.prevHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchPreviousPage()
      }
    : undefined

  const pagination = { ...pageInfo, nextPage, prevPage }
  const isZero = query.data && pagination?.totalCount === 0
  const uri = getUri(endpoint)

  const subjects = pages?.map((p) => {
    const casted = castedResult(p)
    if (casted == null) {
      return []
    }
    return casted.edges?.map((edge) => edge?.node as ActivityFragment)
  })?.[0]

  // TODO:  Why do I have to grab the 0 index in the line above?!?!?!
  const d = subjects?.map((s) => getComputed(s))
  const data = d ? { activities: d } : d
  return { ...query, isZero, pagination, data, uri }
}

// -------------------------------------

export type {
  ActivitiesAlertsEndpoints,
  ActivitiesAlertsOptions,
  ActivitiesAlertsQuery,
  ActivitiesAlertsQueryVariables,
}
export { getDerived, invalidateActivitiesAlerts }
export default useActivitiesAlerts
