import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import letterize from '@vangst/lib/letterize'
import unionize from '@vangst/lib/unionize'
import Routes from '../../routes'
import fetcher from '../fetcher'
import {
  FetcherError,
  MemberDocument,
  MemberFragment,
  MemberQuery,
  MemberQueryVariables as MemberVars,
  useMemberQuery as useEndpoint,
} from '../types'

type MemberOptions = UseQueryOptions<MemberQuery, FetcherError, MemberQuery>

/**
 * @private
 * Retrieve the stringified `Member` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

/**
 * @private
 * Retrieve the `Member` from the queryClient's cache.
 */
function getCache(queryClient: QueryClient, variables: MemberVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<MemberQuery>(key)
}

// -------------------------------------

/**
 * @protected
 * Derives data not contained in the API.
 */
function getDerived(member?: MemberFragment | null) {
  if (member == null) return member
  const username = member.username

  return {
    candidateSummary: member.candidate?.summary?.entry,
    citystate: unionize(member.location?.city, member.location?.state, ', '),
    fullName: unionize(member.firstName, member.lastName, ' '),
    initials: letterize(member.firstName, member.lastName),
    routes: {
      connections: Routes.MEMBERS_CONNECTIONS.replace(':username', username),
      detail: Routes.MEMBERS_DETAIL.replace(':username', username),
      edit: Routes.MEMBERS_EDIT.replace(':username', username),
      jobApplications: Routes.MEMBERS_APPLICATIONS.replace(
        ':username',
        username,
      ),
      messages: Routes.MEMBERS_MESSAGES.replace(':username', username),
      searchReadyToPublishGigs: `${Routes.JOBS}?atsRecordTypes=Boutique_GIGS&atsStatus=READY_TO_PUBLISH`,
      searchPendingGigs: `${Routes.JOBS}?atsRecordTypes=Boutique_GIGS&atsStatus=PENDING`,
      searchGigs: `${Routes.JOBS}?atsRecordTypes=Boutique_GIGS`,
      searchrecruiterAssignedGigs: `${Routes.JOBS}?atsRecordTypes=Boutique_GIGS&recruiterUsername=${username}`,
    },
    showCustomButton: false,
    shouldShowBillingOptions: true,
  }
}

/**
 * @private
 * Merge the `MemberFragment` with derived `Member` data.
 *
 * @TODO Memoize
 */
function getComputed(member?: MemberFragment) {
  if (member == null) return member
  return { ...member, ...getDerived(member) }
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 * If no variables are passed all `Member` queries will be invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateMember(queryClient, { username: 'ted-lasso' })
 * invalidateMember(queryClient)
 */
function invalidateMember(queryClient: QueryClient, variables?: MemberVars) {
  queryClient.invalidateQueries(variables ? getKey(variables) : ['Member'])
}

/**
 * Create a new `Member` within the internal cache's result set.
 *
 * @example
 * optimisticCreateMember(queryClient, { username: 'ted-lasso'}, { firstName: 'Ted' lastName: 'Lasso' })
 */
function optimisticCreateMember(
  queryClient: QueryClient,
  variables: MemberVars,
  data: Partial<MemberFragment>,
) {
  const key = getKey(variables)
  queryClient.setQueryData(key, data)
}

/**
 * Find and update a `Member` within the internal cache's result set. If an
 * existing result is not found a new one will be optimistically created.
 *
 * @example
 * optimisticUpdateMember(queryClient, { username: 'ted-lasso'}, { firstName: 'Coach Ted' })
 */
function optimisticUpdateMember(
  queryClient: QueryClient,
  variables: MemberVars,
  data: Partial<MemberFragment>,
) {
  const key = getKey(variables)
  const prev = queryClient.getQueryData<MemberQuery>(key)
  if (!prev) {
    optimisticCreateMember(queryClient, variables, data)
    return
  }
  const next = { getUser: { ...prev.getUser, ...data } }
  queryClient.setQueryData(key, () => next)
}

/**
 * Removes the `Member` from the internal cache's result set.
 *
 * @example
 * optimisticDeleteMember(queryClient, { username: 'ted-lasso'})
 */
function optimisticDeleteMember(
  queryClient: QueryClient,
  variables: MemberVars,
) {
  const key = getKey(variables)
  queryClient.removeQueries(key, { exact: true })
}

/**
 * Prefetch and return the computed `Member` from a `username`.
 *
 * @example
 * const member = await prefetchMember(queryClient, { username: 'ted-lasso' })
 */
async function prefetchMember(queryClient: QueryClient, variables: MemberVars) {
  const key = getKey(variables)
  const fn = fetcher<MemberQuery, MemberVars>(MemberDocument, variables)
  await queryClient.prefetchQuery(key, fn)
  const cache = getCache(queryClient, variables)
  return cache ? { getUser: getComputed(cache.getUser) } : cache
}

/**
 * Fetch a `MemberFragment` with derived data.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data, uri } = useMember({ displayname: 'moontower' })
 * const { data, uri } = useMember({ displayname: node.displayname }, { initialData: node })
 *
 * @TODO Compute data in `select`
 * @TODO Tune options
 */
function useMember(variables: MemberVars, options?: MemberOptions) {
  const query = useEndpoint(variables, {
    enabled: options?.initialData == null,
    // staleTime: 10000,
    ...options,
  })
  const uri = Routes.MEMBERS_DETAIL.replace(
    ':username',
    variables?.username ?? '',
  )
  const d = (options?.initialData as MemberQuery) || query?.data
  const data = d ? { getUser: getComputed(d.getUser) } : d
  return { ...query, data, uri }
}

// -------------------------------------
export type MemberComputed = ReturnType<typeof getComputed>

export type { MemberFragment, MemberOptions, MemberVars }
export {
  getDerived,
  invalidateMember,
  optimisticCreateMember,
  optimisticDeleteMember,
  optimisticUpdateMember,
  prefetchMember,
}
export default useMember
