import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import { CompanyFragment } from '@vangst/services/oogst/company/useCompany'
import Routes from '../../routes'
import {
  CompanyEditQuery,
  CompanyEditQueryVariables as CompanyEditVars,
  CompanyPhotoDisplayRoleEnum,
  FetcherError,
  MemberFragment,
  useCompanyEditQuery as useEndpoint,
} from '../types'

type CompanyEditOptions = UseQueryOptions<
  CompanyEditQuery,
  FetcherError,
  CompanyEditQuery
>

/**
 * @private
 * Retrieve the stringified `CompanyEdit` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

// -------------------------------------

/**
 * @protected
 * Derives data not contained in the API.
 */
function getDerivedCompany(
  company?: (CompanyFragment & { users: MemberFragment[] }) | null,
) {
  const standardPhotos = company?.companyPhotos?.filter(
    (photo) => photo?.displayRole === CompanyPhotoDisplayRoleEnum.Standard,
  )

  const gallery = Array.from({ length: 4 }, (_, i) => standardPhotos?.[i])

  return {
    gallery,
    ...company,
  }
}

function getComputed(company?: CompanyFragment & { users: MemberFragment[] }) {
  if (company == null) return company
  return {
    ...company,
    ...getDerivedCompany(company),
  }
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 * If no variables are passed all `CompanyEdit` queries will be invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateCompanyEdit(queryClient)
 */
function invalidateCompanyEdit(
  queryClient: QueryClient,
  variables?: CompanyEditVars,
) {
  queryClient.invalidateQueries(variables ? getKey(variables) : ['CompanyEdit'])
}

/**
 * Fetch a `CompanyEditFragment` through `currentUser` for `updateUser`
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data, uri } = useCompanyEdit()
 * const { data, uri } = useCompanyEdit(_, { initialData: node })
 *
 * @TODO Add `optimisticCreate|Update|Delete`?
 * @TODO Handle serialization/deserialization
 * @TODO Tune options
 */
function useCompanyEdit(
  variables: CompanyEditVars,
  options?: CompanyEditOptions,
) {
  const query = useEndpoint(variables, options)
  const d = (options?.initialData as CompanyEditQuery) || query?.data
  const data = d
    ? {
        getClient: getComputed(
          d.getClient as CompanyFragment & { users: MemberFragment[] },
        ),
      }
    : d
  const uri = Routes.COMPANIES_EDIT.replace(
    ':displayname',
    variables.displayname,
  )
  return { ...query, data, uri }
}

// -------------------------------------

export type { CompanyEditOptions, CompanyEditVars }
export { invalidateCompanyEdit }
export default useCompanyEdit
