import clsx from '@vangst/lib/clsx'
import { MdErrorOutline } from 'react-icons/md'
import AlertText from '../feedback/AlertText'
import Label, { LabelPropsType } from './Label'

type PropsType = React.FieldsetHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> &
  LabelPropsType & {
    readonly classNameList?: string
    readonly error?: string
    readonly hint?: string | null
    readonly layout?: 'col' | 'row'
    readonly required?: boolean
    readonly type: 'radio' | 'checkbox'
  }

/**
 * The `fieldset > legend + ul + hint/error` elements used with the `Checkbox`
 * or `Radio` components.
 *
 * @example
 * <ControlGroup label="Checkbox Column" name="checkbox-1" type="checkbox">
 *   <Checkbox name="checkbox-1.Yes" label="Yes" />
 *   <Checkbox name="checkbox-1.No" label="No" />
 * </ControlGroup>
 *
 * <ControlGroup label="Radio Column" name="radio-1" type="radio">
 *   <Radio name="radio-1" label="Yes" value="yes" />
 *   <Radio name="radio-1" label="No" value="no" />
 * </ControlGroup>
 */
function ControlGroup(props: PropsType) {
  const {
    children,
    className,
    classNameList,
    error,
    hideLabel,
    hint,
    label,
    labelSubtext,
    layout = 'col',
    required,
    type,
  } = props

  return (
    <fieldset
      disabled={props?.disabled}
      className={clsx('block', error != null && 'text-red', className)}
    >
      <legend className="flex flex-col" id={label}>
        <Label
          hideLabel={hideLabel}
          label={label}
          labelSubtext={labelSubtext}
          required={required}
        />
      </legend>
      <ul
        aria-labelledby={label}
        className={clsx(
          !hideLabel && 'mt-2',
          layout === 'col' && 'flow-y-sm',
          layout === 'row' && 'flow-y-sm sm:flow-x-sm sm:flex-wrap',
          classNameList,
        )}
      >
        {children}
      </ul>
      {(error != null || hint != null) && (
        <AlertText
          className={clsx(
            'mt-2 text-sm italic',
            type === 'radio' ? '-ml-0.5' : 'ml-0.5',
            error != null && 'text-red',
          )}
          icon={MdErrorOutline}
          role={error ? 'alert' : 'note'}
        >
          {error || hint}
        </AlertText>
      )}
    </fieldset>
  )
}

export default ControlGroup
