import useElasticSearch, {
  ElasticSearchVars,
} from '@vangst/services/oogst/search/useElasticSearch'
import { EsResultFragment } from '@vangst/services/oogst/types'
import React, { useMemo } from 'react'
import { Control } from 'react-hook-form'
import Busy from '../../../components/feedback/Busy'
import ComboBoxMulti from '../../../components/forms/ComboBoxMulti'
import { SelectType } from '../../../components/forms/Select'

type PropsType = SelectType

function ChooseByRoleCombo(
  props: PropsType & {
    readonly control?: Control
    handleValueChange: (name: string, value: string[]) => void
    vars: ElasticSearchVars
  },
) {
  const {
    error,
    hideLabel,
    hint,
    label,
    name,
    reference,
    handleValueChange,
    value,
    vars,
    ...rest
  } = props

  const { isFetching, subjects } = useElasticSearch(
    'searchCandidatesByRecruiters',
    vars,
  )

  const data = useMemo(() => {
    const pages = subjects ? [subjects[0]][0] : []
    return pages?.map((page: EsResultFragment) => ({
      label: page.label,
      value: page.id ?? '',
    }))
  }, [subjects])

  const listOfRecruiters = useMemo(() => {
    return data?.map((user) => ({
      label: `${user.label}`,
      value: user.value ?? '',
    }))
  }, [data])

  const recruiterOptions = [...(listOfRecruiters ?? [])]

  if (isFetching) return <Busy />

  return (
    <div className="mt-4">
      <ComboBoxMulti
        id={name}
        handleValueChange={handleValueChange}
        value={value as string[]}
        error={error}
        hideLabel={hideLabel}
        label={label}
        name={name}
        options={recruiterOptions}
        {...rest}
      />
    </div>
  )
}

export default React.memo(ChooseByRoleCombo)
