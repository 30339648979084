import { Menu } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import { Fragment, memo } from 'react'
import {
  MdArrowBack,
  MdBusiness,
  MdPerson,
  MdPersonOutline,
} from 'react-icons/md'
import {
  MenuItemClicky,
  MenuItemClickyKid,
  MenuItems,
} from '../../../components/navigation/Menus'
import useChargebeeUrl from '../useChargebeeUrl'
import NavbarClickyIcon from './NavbarClickyIcon'

type PropsType = {
  readonly hasBilling?: boolean
  readonly notificationCounts: { [key: string]: number }
  readonly routesProfile: { href?: string; label: string }[]
  readonly routesProfileClient?: { href?: string; label: string }[] | null
  readonly signOut: () => void
  readonly username?: string
}

function NavbarMenuProfile(props: PropsType) {
  const {
    hasBilling,
    notificationCounts,
    routesProfile,
    routesProfileClient,
    signOut,
    username,
  } = props
  const chargebee = useChargebeeUrl()

  return (
    <Menu as="div" className="relative">
      <Menu.Button as={Fragment}>
        <NavbarClickyIcon
          className="md:border-l"
          icon={username ? MdPerson : MdPersonOutline}
        >
          {username ?? 'Profile'}
        </NavbarClickyIcon>
      </Menu.Button>
      <Menu.Items
        as={MenuItems}
        className={clsx(routesProfileClient && 'w-96')}
      >
        {routesProfileClient ? (
          <div className="mood-grey-lightest flex flex-row">
            <div className="w-48">
              <div className="my-2 ml-2 mt-4 flex text-md font-700">
                <MdBusiness className="mr-2" />
                Company
              </div>
              {routesProfileClient.map(({ href, label }) => (
                <MenuItemClicky activate key={href} href={href}>
                  <MenuItemClickyKid
                    label={label}
                    notificationCounts={notificationCounts}
                  />
                </MenuItemClicky>
              ))}
            </div>
            <div className="w-48  border-l-2 border-l-grey-light">
              <div className="my-2 ml-2 mt-4 flex text-md font-700">
                <MdPerson className="mr-2" />
                Member
              </div>

              {routesProfile.map(({ href, label }) => (
                <MenuItemClicky activate key={href} href={href}>
                  <MenuItemClickyKid
                    label={label}
                    notificationCounts={notificationCounts}
                  />
                </MenuItemClicky>
              ))}
            </div>
          </div>
        ) : (
          routesProfile.map(({ href, label }) => (
            <MenuItemClicky activate key={href} href={href}>
              <MenuItemClickyKid
                label={label}
                notificationCounts={notificationCounts}
              />
            </MenuItemClicky>
          ))
        )}
        {hasBilling && (
          <MenuItemClicky onClick={chargebee.callback}>
            <MenuItemClickyKid label={chargebee.label} />
          </MenuItemClicky>
        )}
        {username && (
          <MenuItemClicky
            className="mood-grey-lightest flex items-center"
            onClick={signOut}
          >
            <MenuItemClickyKid label="Sign Out" icon={MdArrowBack} />
          </MenuItemClicky>
        )}
      </Menu.Items>
    </Menu>
  )
}

/**
 * The toolbar menu displaying links for various profiles
 *
 * @example
 * <NavbarMenuProfile
 *   routes={routesProfile}
 *   signOut={signOut}
 *   username={username}
 * />
 */
export default memo(NavbarMenuProfile)
