import { OrderByEnum } from '@vangst/services/oogst/types'
import React from 'react'
import Select, { SelectType } from '../../../components/forms/Select'

function SelectSort(props: SelectType) {
  const { error, hint, label, name, reference, ...rest } = props
  const id = props?.id ?? name

  const sortOptions = [
    { label: 'Sort By: Recommended', value: '' },
    { label: 'Date - Newest First', value: OrderByEnum.Desc },
    { label: 'Date - Oldest First', value: OrderByEnum.Asc },
  ]
  return (
    <Select
      id={id}
      disabled={props.disabled}
      error={error}
      hideLabel
      hint={hint}
      label={label}
      name={name}
      options={sortOptions}
      reference={reference}
      {...rest}
    />
  )
}

export default React.memo(SelectSort)
