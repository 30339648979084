import clsx from '@vangst/lib/clsx'
import { HUBSPOT_SUBJECTS } from '@vangst/services/hubspot'
import useCompanyJobPostings, {
  CompanyJobPostingsOptions,
  CompanyJobPostingsVars,
} from '@vangst/services/oogst/company/useCompanyJobPostings'
import { AtsRecordTypeEnum } from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import React, { Fragment, memo, useState } from 'react'
import { MdArrowDownward, MdDescription } from 'react-icons/md'
import Spinner from '../../components/assets/Spinner'
import EmptyState from '../../components/feedback/EmptyState'
import ClickyExplore from '../../components/navigation/ClickyExplore'
import ClickyLink from '../../components/navigation/ClickyLink'
import Counter from '../../components/views/Counter'
import DialogContact from '../job/feedback/DialogContact'
import JobPostingRenderer from '../job/JobPostingRenderer'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly as: 'avatar' | 'card' | 'header' | 'slip'
  readonly at: 'infinite' | 'preview' | 'sidebar'
  readonly classNameList?: string
  readonly take?: number
  readonly variables: CompanyJobPostingsVars
  readonly options?: CompanyJobPostingsOptions
}

function CompanyJobPostingsRenderer(props: PropsType) {
  const { as, at, classNameList, take, variables, options, ...rest } = props
  const { viewer } = useViewer()
  const [showServicesModal, setShowServicesModal] = useState(false)
  const { data, isFetching, isZero, pagination, uri } = useCompanyJobPostings(
    variables,
    options,
  )

  const canTake = take != null && (at === 'preview' || at === 'sidebar')
  const pages = canTake && data?.pages ? [data.pages[0]] : data?.pages
  const isOwner = viewer?.nameOrId === variables.displayname

  return (
    <div {...rest}>
      {showServicesModal && (
        <DialogContact
          email={viewer?.email ?? ''}
          phone={viewer?.phoneNumber ?? ''}
          state={viewer?.location?.state ?? ''}
          firstName={viewer?.firstName ?? ''}
          lastName={viewer?.lastName ?? ''}
          handleCancel={() => setShowServicesModal(false)}
          hubspotContactSubject={HUBSPOT_SUBJECTS.COMPANY_SUPPORT}
        />
      )}
      {at === 'sidebar' && (
        <p className="relative mb-4 text-sm">
          <ClickyLink className="link-blue-dark mr-2 text-sm" href={uri}>
            View All Positions
          </ClickyLink>
          <Counter to={pagination.totalCount} />
          {isFetching && (
            <Spinner
              className="absolute right-0 top-0"
              width={16}
              height={16}
            />
          )}
        </p>
      )}

      {at === 'infinite' && (
        <div className="flex items-center justify-between">
          <p className="relative mb-4 text-lg text-orange-medium">
            <span className="mr-2">Job Results:</span>
            <Counter to={pagination.totalCount} />
            {':'}
            {isFetching && (
              <Spinner
                className="absolute right-0 top-0"
                width={16}
                height={16}
              />
            )}
          </p>
        </div>
      )}

      <div className={clsx(!isZero && classNameList)}>
        {isZero && (
          <div>
            <EmptyState icon={MdDescription} className="sm:h-60">
              <span className="block max-w-xl">
                {isOwner &&
                variables.atsRecordTypes === AtsRecordTypeEnum.BoutiqueGigs ? (
                  <span>
                    Temp Staffing: Vangst Gigs Service is the cannabis
                    industry’s largest network of temporary talent.{' '}
                    <ClickyLink
                      onClick={() => setShowServicesModal(true)}
                      className="underline"
                    >
                      Contact Us
                    </ClickyLink>{' '}
                    today to help you staff up with ready-to-work, credentialed,
                    W-2 employees, available on-demand.
                  </span>
                ) : (
                  <span>There are currently no open positions.</span>
                )}
              </span>
            </EmptyState>
          </div>
        )}

        {pages?.map((page, i) => {
          const list = page.getClient?.jobPostings.edges
          const edges = canTake ? list?.slice(0, take) : list
          return (
            <Fragment key={`page-${i}`}>
              {edges?.map((edge) => {
                return edge?.node ? (
                  <JobPostingRenderer
                    as={as}
                    key={edge.node.id}
                    variables={{ id: edge.node.id }}
                    options={{ initialData: { getJobPosting: edge.node } }}
                  />
                ) : null
              })}
              {!isZero && at === 'infinite' && (
                <p className="col-span-full my-4 border-b pb-2 text-right text-sm">
                  Page {i + 1} of {pagination?.totalPages}
                </p>
              )}
            </Fragment>
          )
        })}
      </div>
      {at === 'infinite' && pagination.nextHref && pagination.nextPage && (
        <div className="flex justify-center">
          <ClickyExplore
            as="icon-text"
            className={clsx('mt-4', !isFetching && 'motion-south')}
            href={pagination.nextHref}
            icon={isFetching ? Spinner : MdArrowDownward}
            onClick={pagination.nextPage}
          >
            {isFetching ? 'Loading...' : 'Load Next Page'}
          </ClickyExplore>
        </div>
      )}
    </div>
  )
}

export default memo(CompanyJobPostingsRenderer)
