import { CompanyComputed } from '@vangst/services/oogst/company/useCompany'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Markdown from '../../../components/views/Markdown'
import {
  emptyText,
  TableBodyDetailList,
  TableRowBody,
} from '../../application/TableRow'

type PropsType = React.HTMLAttributes<HTMLDetailsElement> & {
  readonly company?: CompanyComputed
}

function CompanyTableRowBody(props: PropsType) {
  const { company } = props
  const address =
    company?.location?.address || company?.citystatezip !== ''
      ? `${company?.location.address} ${company?.citystatezip}`
      : null
  const locations =
    company?.companyLocations != null
      ? company.companyLocations.map(
          (loc) =>
            `${loc?.address} ${loc?.city} ${loc?.state} ${loc?.postalCode}`,
        )
      : []

  const allLocations = [address, ...locations]

  return (
    <TableRowBody>
      <div className="grid grid-cols-5 gap-4">
        <TableBodyDetailList title="Address">
          <ul>
            {allLocations?.length > 0 ? (
              allLocations.map((location, i) => (
                <li key={`location-${i}`}>
                  <ClickyLink
                    href={`http://maps.google.com/?q=${location}`}
                    className="link-orange"
                  >
                    <span>{location}</span>
                  </ClickyLink>
                </li>
              ))
            ) : (
              <li>{emptyText}</li>
            )}
          </ul>
        </TableBodyDetailList>
        <TableBodyDetailList title="Website">
          <ClickyLink
            className="link-orange w-32 truncate"
            href={company?.website}
            target="_blank"
            rel="noopener noreferrer"
          >
            {company?.webname}
          </ClickyLink>
        </TableBodyDetailList>
        <TableBodyDetailList title="Followers">
          <ClickyLink
            className="link-orange"
            href={company?.routes?.followers}
            target="_blank"
            rel="noopener noreferrer"
          >
            View All Followers
          </ClickyLink>
        </TableBodyDetailList>
        <TableBodyDetailList title="Socials">
          <ul className="">
            {company?.social?.linkedin && (
              <li>
                <ClickyLink
                  className="link-orange"
                  href={company.social?.linkedin}
                  target="_blank"
                  rel="noreferrer"
                  title={company.social?.linkedin}
                >
                  <span>Linked In</span>
                </ClickyLink>
              </li>
            )}
            {company?.social?.instagram && (
              <li>
                <ClickyLink
                  className="link-orange"
                  href={company.social?.instagram}
                  target="_blank"
                  rel="noreferrer"
                  title={company.social?.instagram}
                >
                  <span>Instagram</span>
                </ClickyLink>
              </li>
            )}
            {company?.social?.twitter && (
              <li>
                <ClickyLink
                  className="link-orange"
                  href={company.social?.twitter}
                  target="_blank"
                  rel="noreferrer"
                  title={company.social?.twitter}
                >
                  <span>Twitter</span>
                </ClickyLink>
              </li>
            )}
            {company?.social?.facebook && (
              <li>
                <ClickyLink
                  className="link-orange"
                  href={company.social?.facebook}
                  target="_blank"
                  rel="noreferrer"
                  title={company.social?.facebook}
                >
                  <span>Facebook</span>
                </ClickyLink>
              </li>
            )}
            {!company?.social?.linkedin &&
              !company?.social?.instagram &&
              !company?.social?.twitter &&
              !company?.social?.facebook && <li>{emptyText}</li>}
          </ul>
        </TableBodyDetailList>
      </div>
      <hr />
      <div className="grid grid-cols-4 gap-4">
        <TableBodyDetailList title="Description">
          <span className="prose">{company?.companyDescription}</span>
        </TableBodyDetailList>
        <TableBodyDetailList title="Values">
          <ul>
            {company?.companyValues == null ? (
              <li>{emptyText}</li>
            ) : (
              company?.companyValues?.map((value, i) => (
                <li key={i}>
                  <span className="text-md">{value.title}</span>
                  <Markdown className="text-sm" renderType="job">
                    {value.description}
                  </Markdown>
                </li>
              ))
            )}
          </ul>
        </TableBodyDetailList>
        <TableBodyDetailList title="Benefits">
          <ul>
            {company?.companyEmploymentBenefits == null ? (
              <li>{emptyText}</li>
            ) : (
              company.companyEmploymentBenefits.map((benefit, i: number) => (
                <li key={i} className="text-left text-sm">
                  {benefit.employmentBenefit.name}
                </li>
              ))
            )}
          </ul>
        </TableBodyDetailList>
      </div>
    </TableRowBody>
  )
}
export default CompanyTableRowBody
