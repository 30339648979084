import storage, { StorageKeys } from './storage'

// -------------------------------------
// private

let accessTokenMemoized: string | null | undefined

function getAccessToken() {
  if (accessTokenMemoized !== undefined) return accessTokenMemoized
  accessTokenMemoized = storage()?.getItem(StorageKeys.ACCESS)
  return accessTokenMemoized
}

function removeAccessToken() {
  storage()?.removeItem(StorageKeys.ACCESS)
  globalThis.sessionStorage?.removeItem('_jwt_token')
  accessTokenMemoized = undefined
}

function setAccessToken(token: string) {
  storage()?.setItem(StorageKeys.ACCESS, token)
  accessTokenMemoized = token
  return accessTokenMemoized
}


// -------------------------------------
// public

type KeysUnion = 'access' | 'anonymous'
type HeadersType = Record<string, string>

let headersMemoized: HeadersType

function refreshHeaders() {
  const accessToken = getAccessToken()
  headersMemoized = {
    ...(accessToken != null ? { authorization: `Bearer ${accessToken}` } : {}),
    ...(process.env.VERCEL ? { 'x-vercel-prerender': 'true' } : {}),
  }
}

function getHeader(key: KeysUnion) {
  if (key === 'access') return getAccessToken()
  return
}

function getHeaders() {
  if (!headersMemoized) refreshHeaders()
  return {
    ...headersMemoized,
    'x-referrer': globalThis?.document?.referrer,
    'x-url': globalThis?.location?.pathname + globalThis?.location?.search,
  }
}

// Yo! Does not remove the `anonymousId`
function removeHeaders() {
  removeAccessToken()
  refreshHeaders()
  return headersMemoized
}

function removeHeader(key: KeysUnion) {
  if (key === 'access') removeAccessToken()
  refreshHeaders()
  return headersMemoized
}

function setHeader(key: KeysUnion, value: string) {
  if (key === 'access') setAccessToken(value)
  refreshHeaders()
  return headersMemoized
}

// -------------------------------------

export {
  getAccessToken,
  getHeader,
  getHeaders,
  removeHeader,
  removeHeaders,
  setHeader,
}
