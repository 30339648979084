import { useState } from 'react'
import { MdCalendarMonth, MdEdit } from 'react-icons/md'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Progress from '../../../components/views/Progress'
import DialogRequestInterview from '../../company/feedback/DialogRequestInterview'

type PropsType = {
  readonly href: string
  readonly percent: number
}
export default function GuiltBox(props: PropsType) {
  const [showInterviewPrompt, setInterviewPrompt] = useState(false)
  const { href, percent } = props
  const width = Math.floor(percent / 10) * 10

  return (
    <div className="flow-y-xs">
      <Progress className="mood-orange" id="percent-complete" value={width} />
      <div className="heading-sm">
        {percent < 99
          ? `Profile ${percent < 85 ? 'only' : ''} ${percent}% complete`
          : `Profile Complete!`}
      </div>
      <div>
        {percent < 100
          ? 'Complete your profile and increase your chances of being contacted by recruiters!'
          : 'Complete profiles increase visibility to recruiters and interested companies.'}
      </div>
      <div className="flow-x-xs">
        <ClickyLink
          as="icon-text"
          className="link-orange text-sm underline"
          href={href}
          icon={MdEdit}
        >
          Edit Profile
        </ClickyLink>
        <ClickyLink
          as="icon-text"
          className="link-orange text-sm underline"
          icon={MdCalendarMonth}
          onClick={() => setInterviewPrompt(true)}
        >
          Edit Interview Availability
        </ClickyLink>
      </div>
      {showInterviewPrompt && (
        <DialogRequestInterview
          handleCancel={() => setInterviewPrompt(false)}
        />
      )}
    </div>
  )
}
