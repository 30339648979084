import { serializeJsonToText } from '../editor/serializers'

const validations = {
  city: {
    required: 'Please enter a city.',
  },
  // :metal: https://emailregex.com
  email: {
    pattern: {
      message: 'A valid email is required.',
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/im,
    },
    required: 'An email is required.',
  },
  secondaryEmail: {
    pattern: {
      message: 'A valid email is required.',
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/im,
    },
  },
  firstName: {
    required: 'Your first name is required.',
  },
  generic: {
    required: 'This field is required.',
  },
  lastName: {
    required: 'Your last name is required.',
  },
  message: {
    maxLength: {
      message: 'A maximum length of 1200 characters exceeded.',
      value: 1200,
    },
    required: 'Please include a message.',
  },
  maxLength255: {
    maxLength: {
      message: 'A maximum length of 255 characters exceeded.',
      value: 255,
    },
  },
  maxLength80: {
    maxLength: {
      message: 'A maximum length of 80 characters exceeded.',
      value: 80,
    },
    required: 'This field is required.',
  },
  maxLength160: {
    maxLength: {
      message: 'A maximum length of 160 characters exceeded.',
      value: 160,
    },
  },
  maxLength80Required: {
    maxLength: {
      message: 'A maximum length of 80 characters exceeded.',
      value: 80,
    },
    required: 'This field is required.',
  },
  maxLength255Required: {
    maxLength: {
      message: 'A maximum length of 255 characters exceeded.',
      value: 255,
    },
    required: 'This field is required.',
  },
  maxLength1200: {
    maxLength: {
      message: 'A maximum length of 1200 characters exceeded.',
      value: 1200,
    },
  },
  maxLength3600: {
    maxLength: {
      message: 'A maximum length of 3600 characters exceeded.',
      value: 3600,
    },
  },
  maxLength3600Temp: {
    maxLength: {
      message:
        'Oops! Since you were here last, we have combined some of these fields. Please reduce the number of characters used to 3600. Thank you for your understanding!',
      value: 3600,
    },
  },
  maxLength1200Required: {
    maxLength: {
      message: 'A maximum length of 1200 characters exceeded.',
      value: 1200,
    },
    required: 'This field is required.',
  },
  maxLength3600Required: {
    maxLength: {
      message: 'A maximum length of 3600 characters exceeded.',
      value: 3600,
    },
    required: 'This field is required.',
  },
  maxLength3600RequiredTemp: {
    maxLength: {
      message:
        'Oops! Since you were here last, we have combined some of these fields. Please reduce the number of characters used to 3600. Thank you for your understanding!',
      value: 3600,
    },
    required: 'This field is required.',
  },
  min0: {
    min: {
      message: 'Sorry, the value needs to be at least 0.',
      value: 0,
    },
  },
  minLength20: {
    min: {
      message: 'Please write at least 20 characters.',
      value: 20,
    },
  },
  password: {
    required: 'This field is required.',
  },
  // :metal: https://stackoverflow.com/a/29767609
  phone: {
    pattern: {
      message: 'A valid phone number is required.',
      value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
    },
  },
  skills: {
    required: 'At least one skill is required.',
  },
  slateMaxLength: (v: Node[]) =>
    !v ||
    (v && serializeJsonToText(v)?.length <= 3600) ||
    'Maximum character limit is 3600.',
  slateRequired: (v: any) =>
    (v && serializeJsonToText(v)?.length) || 'This field is required.',
  state: {
    required: 'Please enter a state.',
  },
  street: {
    required: 'Please enter a street address.',
  },
  // :metal: https://urlregex.com
  url: {
    pattern: {
      message: 'Please enter a valid url address, including "http" or "https".',
      value:
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/im,
    },
  },
  // :metal: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
  zip: {
    required: 'Please enter a zip code.',
    pattern: {
      message: 'A valid zip code is required.',
      value: /^[0-9]{5}(?:-[0-9]{4})?$/im,
    },
  },
}

export default validations
