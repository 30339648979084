import stripHost from '@vangst/lib/stripHost'
import { MemberCredentialFragment } from '@vangst/services/oogst/types'
import Routes from '@vangst/services/routes'
import Image from 'next/image'
import ClickyLink from '../../../components/navigation/ClickyLink'
import ZeroState from './ZeroState'

type PropsType = {
  as?: 'preview' | 'sidebar'
  showEmptyState?: boolean
  credentialCount?: number
  credentials?: MemberCredentialFragment[]
}

export default function Credentials(props: PropsType) {
  const { as, credentials, showEmptyState, credentialCount } = props

  if (as === 'sidebar') {
    const plural = credentialCount === 1 ? '' : 's'
    return (
      <div className="border-b pb-4">
        <p className="mb-2 text-lg">Training Credentials Earned:</p>
        <div className="grid grid-cols-[2fr_5fr] items-center gap-4">
          <span className="relative m-auto flex size-26">
            <Image
              fill
              className="h-auto max-w-full self-center object-cover"
              alt="Badge image"
              src={Routes.GENERIC_CREDENTIAL}
            />
          </span>
          <div className="flow-y-xs">
            <div className="text-blue-dark">
              {credentialCount} Credential{plural} Earned
            </div>
            <div className="text-sm">
              Members who take training courses are more likely to be seen by
              recruiters at those companies.
            </div>
            <ClickyLink
              className="link-blue-dark max-w-fit underline"
              href={Routes.COMPANIES + '/vangst#training-courses'}
            >
              View Training Courses
            </ClickyLink>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {showEmptyState ? (
        <ZeroState title="No training courses taken.">
          <span className="grid justify-between gap-6">
            <span className="italic">
              Members who take training courses are more likely to be seen by
              recruiters at those companies.
            </span>
            {/* Currently there is no general training landing page. Linking to Vangst courses for now */}
            <ClickyLink
              className="link-orange italic underline"
              href={Routes.COMPANIES + '/vangst#training-courses'}
            >
              Check out some courses here.
            </ClickyLink>
          </span>
        </ZeroState>
      ) : (
        <ul className="grid-four-up">
          {credentials?.map((cred: any) => {
            const training = cred.training
            if (!training) {
              return
            }
            return (
              <li key={cred.id} className="flex items-center justify-center">
                <ClickyLink
                  className="link-blue-dark flex max-w-fit flex-col items-center gap-2"
                  href={stripHost(training.trainingUrl)}
                >
                  <span className="relative flex size-26">
                    <Image
                      fill
                      className="h-auto max-w-full object-cover"
                      alt={`Badge image for ${cred.title}`}
                      src={cred.badgeUrl || Routes.GENERIC_CREDENTIAL}
                    />
                  </span>
                  <span className="text-center underline">{cred.title}</span>
                </ClickyLink>
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
}
