import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import { fromISO, isToday } from '@vangst/lib/formatDates'
import Routes from '../../routes'
import { getDerived as getDerivedMember } from '../member/useMember'
import {
  CompanyJobApplicationQuery,
  CompanyJobApplicationQueryVariables as CompanyJobApplicationVars,
  Company_JobApplicationFragment,
  FetcherError,
  useCompanyJobApplicationQuery as useEndpoint,
} from '../types'

type CompanyJobApplicationOptions = UseQueryOptions<
  CompanyJobApplicationQuery,
  FetcherError,
  CompanyJobApplicationQuery
>

/**
 * @private
 * Retrieve the `CompanyJobApplication` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

// -------------------------------------

/**
 * @private
 * Derives data not contained in the API.
 */
function getDerived(jobApplication?: Company_JobApplicationFragment) {
  if (jobApplication == null) return jobApplication
  const ca = jobApplication.createdAt
    ? new Date(jobApplication.createdAt)
    : undefined
  const appliedToday = ca ? isToday(ca) : undefined

  const displayname = jobApplication.jobPosting.client?.displayname

  return {
    appliedToday,
    createdAtFormatted: appliedToday
      ? 'Today'
      : ca
      ? fromISO(ca).MMddyy
      : undefined,
    routes: {
      detail: displayname
        ? Routes.COMPANIES_JOBS_APPLICATIONS_DETAIL.replace(
            ':displayname',
            displayname,
          )
            .replace(':jid', jobApplication.jobPosting.slug)
            .replace(':aid', jobApplication.id)
        : undefined,
      detailLead: displayname
        ? Routes.COMPANIES_JOBS_LEADS_DETAIL.replace(
            ':displayname',
            displayname,
          )
            .replace(':jid', jobApplication.jobPosting.slug)
            .replace(':lid', jobApplication.id)
        : undefined,
    },
  }
}

/**
 * @private
 * Merge a `JobApplication` with derived data from `JobApplication` and `Member/User`.
 */
function getComputedJobApplication(
  jobApplication?: Company_JobApplicationFragment,
) {
  if (jobApplication == null) return jobApplication
  return {
    ...jobApplication,
    ...getDerived(jobApplication),
    user: {
      ...jobApplication.user,
      ...getDerivedMember(jobApplication.user),
    },
  }
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function. If no
 * variables are passed all `CompanyJobApplication` queries will be
 * invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateCompanyJobApplication(queryClient, { id: 'Ax90210=' })
 * invalidateCompanyJobApplication(queryClient)
 */
function invalidateCompanyJobApplication(
  queryClient: QueryClient,
  variables?: CompanyJobApplicationVars,
) {
  queryClient.invalidateQueries(
    variables ? getKey(variables) : ['CompanyJobApplication'],
  )
}

/**
 * Fetch a `CompanyJobApplicationFragment` data set.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data } = useCompanyJobApplication({ id: 'Ax90210=' })
 * const { data } = useCompanyJobApplication({ id: node.id }, { initialData: node })
 *
 * @TODO Add uri (waiting till it's moved)
 * @TODO Add `optimisticCreate|Update|Delete`
 * @TODO Support `prefetchCompanyJobApplication`?
 * @TODO Compute data in `select`
 * @TODO Tune options
 */
function useCompanyJobApplication(
  variables: CompanyJobApplicationVars,
  options?: CompanyJobApplicationOptions,
) {
  const query = useEndpoint(variables, {
    enabled: options?.initialData == null,
    // staleTime: 10000,
    ...options,
  })
  const d = (options?.initialData as CompanyJobApplicationQuery) || query?.data
  const data = d
    ? { getJobApplication: getComputedJobApplication(d.getJobApplication) }
    : d
  return { ...query, data }
}

// -------------------------------------

export type { CompanyJobApplicationOptions, CompanyJobApplicationVars }
export { invalidateCompanyJobApplication, getComputedJobApplication }
export default useCompanyJobApplication
