import toBoolean from '@vangst/lib/toBoolean'
import useCompany, {
  CompanyOptions,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import useCompanyPhotos from '@vangst/services/oogst/company/useCompanyPhotos'
import { CompanyPhotoDisplayRoleEnum } from '@vangst/services/oogst/types'
import Image from 'next/image'
import React, { memo } from 'react'
import { MdPhotoCamera } from 'react-icons/md'
import ClickyLink from '../../components/navigation/ClickyLink'
import Markdown from '../../components/views/Markdown'
import UnpublishedTag from './UnpublishedTag'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly at: 'content' | 'sidebar' | 'jobs' | 'trainings'
  readonly variables: CompanyVars
  readonly options?: CompanyOptions
}

function CompanyBlockSummary(props: PropsType) {
  const { at, variables, options, ...rest } = props
  const { data } = useCompany(variables, options)
  const company = data?.getClient
  const { data: companyPhotosData } = useCompanyPhotos(variables)
  const photos = companyPhotosData?.getClient?.companyPhotos

  // todo: move to derived data?
  const heroPhoto = photos?.filter(
    (photo) => photo.displayRole === CompanyPhotoDisplayRoleEnum.Hero,
  )[0]

  const unpublished = toBoolean(company?.visibleOnPlatform) === false
  const isHeroZero = company != null && !heroPhoto
  return (
    <div {...rest}>
      <div>
        {unpublished && <UnpublishedTag small label="Unverified" />}
        <h1 id="about" className="heading-md">
          {at === 'jobs' || at === 'trainings' ? (
            <div>
              <span className="capitalize">{at}</span>
              <span> at {company?.name}</span>
            </div>
          ) : (
            company?.headline
          )}
        </h1>
        {at != 'jobs' && at != 'trainings' && (
          <Markdown className="text-sm">
            {company?.companyDescription || ''}
          </Markdown>
        )}
        {heroPhoto && !isHeroZero && (
          <div className="relative h-52 rounded-md sm:h-88">
            {heroPhoto.url && (
              <Image
                alt="Hero"
                className="h-auto max-w-full rounded-md object-cover"
                fill
                sizes="(min-width: 86em) 86em, 100vw"
                src={heroPhoto.url}
              />
            )}
          </div>
        )}
        {isHeroZero && company?.permissions.edit && company.routes && (
          <p className="mood-grey-light flex min-h-88 flex-col items-center justify-center rounded p-8 text-sm">
            <MdPhotoCamera className="size-12" />
            <ClickyLink
              className="underline hocus:text-orange"
              href={company.routes.edit}
            >
              Add a company header image
            </ClickyLink>
          </p>
        )}
      </div>
    </div>
  )
}

export default memo(CompanyBlockSummary)
