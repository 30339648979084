import clsx from '@vangst/lib/clsx'
import { getDynamicTimestamp } from '@vangst/lib/formatDates'
import { ActivityFragment, MemberFragment } from '@vangst/services/oogst/types'
import { HTMLAttributes } from 'react'
import { MdHorizontalRule, MdOutlineMessage } from 'react-icons/md'
import ClickyLink from '../../components/navigation/ClickyLink'
import { serializeJsonToReact } from '../editor/serializers'
import useMessages from '../messaging/useMessages'

// TODO: Where to put this so we can have a universal empty state?
export const emptyText = <MdHorizontalRule />
export const noData = 'N/A'

type TRDProps = React.HTMLAttributes<HTMLDetailsElement> & {
  readonly initialOpen?: boolean
}
export default function TableRowDetails(props: TRDProps) {
  const { children, className, initialOpen } = props
  return (
    <details
      className={clsx(
        'mood-white relative w-full cursor-pointer border border-grey-mid',
        'group/details',
        className,
      )}
      open={initialOpen ?? false}
    >
      {children}
    </details>
  )
}

type TRSProps = React.HTMLAttributes<HTMLDivElement> & {
  readonly toggleParams: () => void
}

export function TableRowSummary(props: TRSProps) {
  const { className, children, toggleParams } = props
  return (
    <summary
      onClick={toggleParams}
      className={clsx('flow-x-sm w-full list-none items-center p-2', className)}
    >
      {children}
    </summary>
  )
}

type TRBProps = React.HTMLAttributes<HTMLDivElement> & {
  readonly children: React.ReactNode
}
export function TableRowBody(props: TRBProps) {
  const { children } = props
  return (
    <div className="flow-y-sm w-full cursor-default border-t border-t-grey-mid bg-grey-lightest p-2 pt-4">
      {children}
    </div>
  )
}

export const Interactive = ({ item }: { item?: ActivityFragment }) => {
  const { handleMessageUser } = useMessages({
    isWidget: true,
  })
  return (
    <li className="flow-y-xs mood-white relative rounded-sm p-2">
      <span className="absolute right-2 top-2">
        {getDynamicTimestamp(new Date(Date.now()), new Date(item?.updatedAt))}
      </span>
      {item?.jsonBody ? serializeJsonToReact(item.jsonBody) : emptyText}
      <ClickyLink
        onClick={() => handleMessageUser(item?.author as MemberFragment)}
        className="link-orange"
      >
        <span className="flow-x-xs items-center">
          {' '}
          <MdOutlineMessage />
          <span>
            {item?.author?.firstName} {item?.author?.lastName}
          </span>
        </span>
      </ClickyLink>
    </li>
  )
}

export type TSDLType = {
  readonly title: string
} & React.HTMLAttributes<HTMLDListElement>

export function TableSummaryDetailList({
  className,
  title,
  children,
}: TSDLType) {
  return (
    <dl className={clsx('flex flex-col justify-between', className)}>
      <dt className="text-[10px]">{title}</dt>
      <dd className="flex text-[12px] font-600">{children}</dd>
    </dl>
  )
}

export type TBDLType = {
  readonly title: string
} & HTMLAttributes<HTMLDListElement>

export function TableBodyDetailList({ title, children }: TBDLType) {
  return (
    <dl className="flow-y-xs relative text-sm">
      <dt className="font-600 underline">{title}</dt>
      <dd className="text-sm">{children}</dd>
    </dl>
  )
}
