import { useRouter } from 'next/router'
import { forwardRef } from 'react'
import NextLink, { NextLinkType } from './NextLink'

type NextNavLinkType = NextLinkType & {
  readonly activate?:
    | true
    | 'contains'
    | 'exact'
    | 'start'
    | ((asPath: string) => boolean)
}

/**
 * A wrapper around `next/link` with router support for setting the
 * `aria-current` attribute
 *
 * The activate argument accepts:
 *
 * ```
 * true | 'contains' | 'exact' | 'start' | ((asPath: string) => boolean)
 * ```
 *
 * @see [next/link](https://nextjs.org/docs/api-reference/next/link)
 */

const NextNavLink = forwardRef(function NextNavLink(
  props: NextNavLinkType,
  ref: any,
) {
  const { activate, ...rest } = props
  const { asPath } = useRouter()

  const isActive =
    ((activate === 'exact' || activate === true) &&
      (asPath === rest.href || asPath === rest.as)) ||
    (activate === 'start' &&
      (asPath.startsWith(rest.href) || asPath.startsWith(String(rest.as)))) ||
    (activate === 'contains' &&
      (asPath.includes(rest.href) || asPath.includes(String(rest.as)))) ||
    (typeof activate === 'function' && activate(asPath))

  return (
    <NextLink aria-current={isActive ? true : undefined} ref={ref} {...rest} />
  )
})

export type { NextNavLinkType }
export default NextNavLink
