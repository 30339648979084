import { HUBSPOT_SUBJECTS } from '@vangst/services/hubspot'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { memo, useState } from 'react'
import VangstMark from '../../components/assets/marks/VangstMark'
import VangstType from '../../components/assets/marks/VangstType'
import ClickyLiner from '../../components/navigation/ClickyLiner'
import ClickyLink from '../../components/navigation/ClickyLink'
import ListPseudos from '../../components/views/ListPseudos'
import DialogContact from '../job/feedback/DialogContact'
import FooterLink from './navigation/FooterLink'
import FooterSocialLinks from './navigation/FooterSocialLinks'
import NewsletterForm from './NewsletterForm'

function Footer() {
  const { authenticated, viewer } = useViewer()
  const [showContactForm, setShowContactForm] = useState(false)

  return (
    <footer id="footer" className="mood-grey relative" role="contentinfo">
      <div>
        <div className="contain-xyc flex items-center justify-between">
          <ClickyLink
            id="footer-logo"
            className="inline-flex items-center"
            href={Routes.JOBS}
            title="vangst.com"
          >
            <span className="flow-x-xs items-center">
              <VangstMark width="33" height="32" className="text-orange" />
              <VangstType width="98" height="14" />
            </span>
          </ClickyLink>
          {/* Social Desktop */}
          <div className="hidden sm:block">
            <FooterSocialLinks />
          </div>
        </div>
      </div>

      {/* Cells */}
      <div className="contain-xyc grid gap-8 lg:grid-cols-[repeat(5,1fr)]">
        <div>
          <h2 className="mb-4 heading-xs">Search</h2>
          <ul aria-label="Search Links" className="flow-y-xs">
            <FooterLink href={Routes.JOBS}>Jobs</FooterLink>
            <FooterLink href={Routes.COMPANIES}>Companies</FooterLink>
            <FooterLink href={Routes.MEMBERS}>Members</FooterLink>
            <FooterLink href={Routes.TRAININGS}>Trainings</FooterLink>
            <FooterLink href={Routes.JOBS_BROWSE}>Jobs by Region</FooterLink>
          </ul>
        </div>
        <div>
          <h2 className="mb-4 heading-xs">Resources</h2>
          <ul aria-label="Resources Links" className="flow-y-xs">
            <FooterLink href="https://www.vangst.com/cannabis-regulations">
              How to work in Cannabis
            </FooterLink>
            <FooterLink href="https://www.vangst.com/blog">Blog</FooterLink>
            <FooterLink href={Routes.REPORTS}>Reports & Guides</FooterLink>
          </ul>
        </div>

        <div>
          <h2 className="mb-4 heading-xs">Vangst</h2>
          <ul aria-label="Vangst Company Links" className="flow-y-xs">
            <FooterLink href="https://www.vangst.com/about">
              About Vangst
            </FooterLink>
            <FooterLink href="https://share.hsforms.com/1s4ZV4VelSNOxUqDdV5evUw3eexj">
              Contact Us
            </FooterLink>
          </ul>
        </div>

        <div className="lg:col-span-2">
          {authenticated === false && (
            <div className="max-w-md">
              <h2 className="mb-8 heading-xs">Stay Connected</h2>
              <NewsletterForm id="newsletter" />
              <p className="mt-8">
                Sign up for our newsletter and get expert insights,
                executive-level conversations, the latest cannabis trends and
                more delivered directly to your inbox regularly.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Social Mobile */}
      <div className="contain-xyc sm:hidden">
        <FooterSocialLinks />
      </div>

      {/* Legal */}
      <div className="contain-xyc">
        <ListPseudos
          aria-label="Legal Links"
          as="•"
          at="after"
          className="flex flex-wrap text-sm"
        >
          <li>
            <ClickyLink className="hocus:text-orange" href={Routes.INDEX}>
              ©2023 Vangst
            </ClickyLink>
          </li>
          <li>
            <ClickyLiner
              activate
              className="hocus:text-orange"
              href={Routes.LEGAL_PRIVACY}
            >
              Privacy
            </ClickyLiner>
          </li>
          <li>
            <ClickyLink
              activate
              className="hocus:text-orange"
              href={Routes.LEGAL_TERMS}
            >
              Terms
            </ClickyLink>
          </li>
        </ListPseudos>
      </div>
      {showContactForm && (
        <DialogContact
          description="Are you a company interested in seeing what Vangst is all about? Shoot us a note to book a demo and we'll get in touch to show you around."
          email={viewer?.email ?? ''}
          firstName={viewer?.firstName ?? ''}
          handleCancel={() => setShowContactForm(false)}
          lastName={viewer?.lastName ?? ''}
          phone={viewer?.phoneNumber ?? ''}
          state={viewer?.location?.state ?? ''}
          hubspotContactSubject={HUBSPOT_SUBJECTS.COMPANY_DEMO_SUPPORT}
          title="Book A Demo"
        />
      )}
    </footer>
  )
}

export default memo(Footer)
