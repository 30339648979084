import { useQueryClient } from '@tanstack/react-query'
import { AllChatMessagesQueryVariables } from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import useAllChatMessages from '@vangst/services/oogst/wtf/useAllChatMessages'
import Routes from '@vangst/services/routes'
import { useRouter } from 'next/router'
import React, { HTMLAttributes, memo, useEffect } from 'react'
import Busy from '../../components/feedback/Busy'
import ClickyLink from '../../components/navigation/ClickyLink'
import CreateMessage from './CreateMessage'
import MessagesDetail from './MessagesDetail'
import MessagesList from './MessagesList'
import useMessages from './useMessages'

// TODO: Maybe leverage variables instead of list/detail
export type AsChatTypeUnion = 'avatar' | 'list' | 'detail'

type PropsType = {
  readonly as: AsChatTypeUnion
  readonly isWidget?: boolean
  readonly variables?: AllChatMessagesQueryVariables
} & HTMLAttributes<HTMLDivElement>

function MessagesRenderer(props: PropsType) {
  const { asPath } = useRouter()
  const queryClient = useQueryClient()

  const { as = 'detail', className, isWidget = false, variables = {} } = props
  const { viewer } = useViewer()
  const username = viewer?.username ?? ''
  const {
    exitWidget,
    messagingWidgetIsVisible,
    recipient,
    setRecipient,
    widgetView,
  } = useMessages({
    isWidget,
  })

  const dynamicAs = isWidget ? widgetView : as

  const { data: messagesData, isLoading } = useAllChatMessages(
    {
      recipient: recipient?.id,
      recentConversations:
        dynamicAs === 'list' || variables?.recentConversations === true,
      ...variables,
    },

    {
      enabled: messagingWidgetIsVisible || asPath.includes('messages'),
      onSettled: () => queryClient.invalidateQueries(['Notifications']),
      refetchInterval: 5000,
    },
  )

  useEffect(() => {
    if (
      viewer &&
      recipient == null &&
      messagesData != null &&
      messagesData.getChatMessages?.edges?.[0]?.node != null
    ) {
      const sender = messagesData?.getChatMessages?.edges?.[0]?.node.sender
      const recipient =
        messagesData?.getChatMessages?.edges?.[0]?.node.recipient
      setRecipient(sender.id === viewer.id ? recipient : sender)
    }
  }, [messagesData, recipient, setRecipient, viewer])

  return (
    <React.Fragment key={recipient?.id}>
      {isLoading && (messagingWidgetIsVisible || !isWidget) && (
        <Busy className="p-4" />
      )}
      {dynamicAs === 'list' && messagesData?.getChatMessages != null && (
        <div>
          <div className="my-4">
            <span className="ml-4 text-sm">
              Want to start a new conversation?
              <ClickyLink
                className="ml-2 text-orange"
                as="text"
                href={Routes.MEMBERS_CONNECTIONS.replace(':username', username)}
                onClick={() => exitWidget()}
              >
                View your connections
              </ClickyLink>
            </span>
          </div>
          <MessagesList
            className={className}
            isWidget={isWidget}
            messageConnection={messagesData?.getChatMessages}
            viewerId={viewer?.id ?? ''}
          />
        </div>
      )}
      {dynamicAs === 'detail' && messagesData?.getChatMessages != null && (
        <MessagesDetail
          className={className}
          isWidget={isWidget}
          messageConnection={messagesData?.getChatMessages}
          viewerId={viewer?.id ?? ''}
        />
      )}
      {dynamicAs === 'detail' && recipient != null && (
        <CreateMessage isWidget={isWidget} recipientId={recipient?.id || ''} />
      )}
    </React.Fragment>
  )
}

export default memo(MessagesRenderer)
