import { memo, useState } from 'react'
import useHotkeys from '../hooks/useHotkeys'
import ClickyBtn from '../navigation/ClickyBtn'
import Xray from './Xray'

function DebugTools() {
  const name = 'isXrayActive'

  const [active, setActive] = useState(
    JSON.parse(globalThis.sessionStorage?.getItem(name) || 'false'),
  )

  const toggle = () => {
    globalThis.sessionStorage?.setItem(name, JSON.stringify(!active))
    setActive(!active)
  }

  useHotkeys('`', toggle)

  return active === true ? (
    <>
      <Xray className="pointer-events-none fixed inset-0 z-[666]" />
      <ClickyBtn
        className="mood-red absolute right-4 top-4 z-[666] flex size-12 items-center justify-center rounded-full"
        href="/oranje/stories"
      >
        <span aria-label="story" role="img">
          💅
        </span>
      </ClickyBtn>
    </>
  ) : null
}

/**
 * A debug tool layer used in development, uat and preview apps
 *
 * - Viewport Xray triggered by the backtick key
 * - Link to Stories - 💅 button in the upper right corner
 */
export default memo(DebugTools)
