import { useQueryClient } from '@tanstack/react-query'
import { removeHeaders } from '@vangst/services/headers'
import { setSessionRedirect } from '../../storage'
import { useLogoutMutation } from '../types'

/**
 * redirectTo: The place you want the user to go after logging out
 * sessionRedirect: The place you want the user to go after logging back in, assuming they don't end the session. If this is left blank the user will be sent to their dashboard.
 *
 */

function useLogout(redirectTo?: string, sessionRedirect?: string) {
  if (sessionRedirect) {
    setSessionRedirect(sessionRedirect)
  }

  const queryClient = useQueryClient()
  const logout = useLogoutMutation({
    onError: (error) => {
      throw error
    },
    onSettled: () => {
      removeHeaders()
      globalThis.sessionStorage?.removeItem('showLoginDialog')
      queryClient.clear()
      // TODO: Just reload the page if we are on a public page?
      globalThis.location?.replace(
        redirectTo || `${globalThis.location?.origin || '/'}`,
      )
    },
  })
  return logout
}

export default useLogout
