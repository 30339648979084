import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import {
  FetcherError,
  JobPostingEditQuery,
  JobPostingEditQueryVariables as JobPostingEditVars,
  useJobPostingEditQuery as useEndpoint,
} from '../types'

type JobPostingEditOptions = UseQueryOptions<
  JobPostingEditQuery,
  FetcherError,
  JobPostingEditQuery
>

/**
 * @private
 * Retrieve the stringified `JobPostingEdit` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateJobPostingEdit(queryClient)
 */
function invalidateJobPostingEdit(
  queryClient: QueryClient,
  variables: JobPostingEditVars,
) {
  queryClient.invalidateQueries(
    variables ? getKey(variables) : ['JobPostingEdit'],
  )
}

/**
 * Fetch a `JobPostingEditFragment` through `currentUser` for `updateUser`
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data } = useJobPostingEdit()
 * const { data } = useJobPostingEdit(_, { initialData: node })
 *
 * @TODO Add uri in
 * @TODO Add `optimisticCreate|Update|Delete`?
 * @TODO Tune options
 */
function useJobPostingEdit(
  variables: JobPostingEditVars,
  options?: JobPostingEditOptions,
) {
  const query = useEndpoint(variables, options)
  const d = (options?.initialData as JobPostingEditQuery) || query?.data
  const data = d ? { getJobPosting: d.getJobPosting } : d
  return { ...query, data }
}

// -------------------------------------

export type { JobPostingEditOptions, JobPostingEditVars }
export { invalidateJobPostingEdit }
export default useJobPostingEdit
