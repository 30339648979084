import clsx from '@vangst/lib/clsx'
import Routes from '@vangst/services/routes'
import { memo, useState } from 'react'
import { MdClose, MdMenu } from 'react-icons/md'
import Spinner from '../../../components/assets/Spinner'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Disclosure from '../../../components/navigation/Disclosure'
import { MenuBulletClicky } from '../../../components/navigation/Menus'
import useChargebeeUrl from '../useChargebeeUrl'
import NavbarClickyIcon from './NavbarClickyIcon'
import NavbarClickyTextLink from './NavbarClickyTextLink'
import NavbarDialog from './NavbarDialog'

type RouteType = ReadonlyArray<{ href?: string; label: string }>

type PropsType = {
  readonly authenticated: boolean
  readonly authenticating: boolean
  readonly hasBilling?: boolean
  readonly hide: () => void
  readonly isExpanded: boolean
  readonly routesProfile?: RouteType
  readonly routesProfileClient?: RouteType | null
  readonly routesPrimary: RouteType
  readonly signOut: () => void
  readonly toggle: () => void
}

function NavbarMenuDialogMain(props: PropsType) {
  const {
    authenticated,
    authenticating,
    hasBilling,
    hide,
    isExpanded,
    routesProfile,
    routesProfileClient,
    routesPrimary,
    signOut,
    toggle,
  } = props

  const [expanded, setExpanded] = useState<string | undefined>()
  const toggleExpanded = (name: string) => (e: React.SyntheticEvent) => {
    e.preventDefault()
    setExpanded(expanded !== name ? name : undefined)
  }
  const chargebee = useChargebeeUrl()

  return (
    <>
      <NavbarClickyIcon
        aria-expanded={isExpanded || undefined}
        icon={authenticating ? Spinner : isExpanded ? MdClose : MdMenu}
        id="navbar-menu-toggle"
        onClick={toggle}
        title={`${isExpanded ? 'Close' : 'Open'} Navbar Menu`}
      >
        Toggle Navbar Menu
      </NavbarClickyIcon>

      <NavbarDialog open={isExpanded} onClose={hide} title="Navigation">
        {routesProfile && authenticated && (
          <Disclosure
            as="menuitem"
            className="text-orange"
            onClick={() => toggleExpanded('account')}
            open={expanded === 'account'}
            summary="Account"
          >
            <div className="mood-orange flex flex-col">
              {routesProfile.map(({ href, label }) => (
                <MenuBulletClicky activate key={href} href={href}>
                  {label}
                </MenuBulletClicky>
              ))}
              {hasBilling && (
                <MenuBulletClicky onClick={chargebee.callback}>
                  <span>{chargebee.label}</span>
                </MenuBulletClicky>
              )}
            </div>
          </Disclosure>
        )}

        {routesProfileClient && authenticated && (
          <Disclosure
            as="menuitem"
            className="text-orange"
            onClick={() => toggleExpanded('account')}
            open={expanded === 'account'}
            summary="Company Account"
          >
            <div className="mood-orange flex flex-col">
              {routesProfileClient.map(({ href, label }) => (
                <MenuBulletClicky activate key={href} href={href}>
                  {label}
                </MenuBulletClicky>
              ))}
            </div>
          </Disclosure>
        )}

        {routesPrimary.map(({ href, label }) => (
          <ClickyLink
            activate
            className={clsx(
              'flex w-full cursor-pointer list-none items-center justify-between p-4 pr-2 transition',
              'text-sm uppercase text-orange ',
              'border-b border-grey-light',
              'hocus:bg-orange hocus:text-white',
              'aria-[current=true]:bg-orange aria-[current=true]:text-white',
            )}
            key={'services-menu-' + href}
            href={href}
          >
            {label}
          </ClickyLink>
        ))}

        {/* Text links... */}
        <div className="border-b border-grey-light py-6">
          {/* <NavbarClickyTextLink href={Routes.ABOUT}> */}
          {/*   About Us */}
          {/* </NavbarClickyTextLink> */}
          <NavbarClickyTextLink href={Routes.ABOUT_CONTACT}>
            Contact Us
          </NavbarClickyTextLink>
          <NavbarClickyTextLink href={Routes.HELP}>Help</NavbarClickyTextLink>
        </div>

        {/* Auth links... */}
        <div className="flow-y-sm p-4">
          {!authenticated && (
            <>
              <ClickyBtn
                as="text-icon"
                className="mood-orange w-full"
                href={Routes.SIGN_UP}
              >
                Sign Up
              </ClickyBtn>
              <ClickyBtn
                as="text-icon"
                className="mood-white w-full"
                href={Routes.SIGN_IN}
              >
                Sign In
              </ClickyBtn>
            </>
          )}
          {authenticated && (
            <ClickyLink
              as="icon-text"
              className="motion-west link-grey max-w-fit text-[0.75rem] underline"
              onClick={signOut}
            >
              Sign Out
            </ClickyLink>
          )}
        </div>
      </NavbarDialog>
    </>
  )
}

/**
 * Primary menu utilized by mobile layouts and screenreaders
 *
 * @example
 * <NavbarMenuDialogMain
 *   authenticated={authenticated}
 *   authenticating={authenticating}
 *   hide={hideDialog}
 *   isExpanded={isDialogActive}
 *   routesLearn={routesLearn}
 *   routesProfile={routesProfile}
 *   signOut={signOut}
 *   toggle={toggleDialog}
 * />
 */
export default memo(NavbarMenuDialogMain)
