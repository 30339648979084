import clsx from '@vangst/lib/clsx'
import useCompany from '@vangst/services/oogst/company/useCompany'
import Routes from '@vangst/services/routes'
import { memo } from 'react'
import ClickyBtn from '../../components/navigation/ClickyBtn'
import ClickyLink from '../../components/navigation/ClickyLink'

type PropsType = React.HTMLAttributes<HTMLAnchorElement> & {
  type?: 'btn' | 'link'
  slug?: string
}

function PostAJob(props: PropsType) {
  const { className, slug, type = 'btn', ...rest } = props
  const { data } = useCompany(
    { displayname: slug || '' },
    { enabled: slug != null },
  )
  if (!data?.getClient?.permissions.edit) return null
  const route = Routes.COMPANIES_JOBS_NEW.replace(':displayname', slug || '')

  return type === 'link' ? (
    <ClickyLink
      className={clsx('link-orange underline', className)}
      href={route}
      {...rest}
    >
      Post A Job
    </ClickyLink>
  ) : (
    <ClickyBtn
      className={clsx('mood-orange', className)}
      href={route}
      {...rest}
    >
      Post A Job
    </ClickyBtn>
  )
}

export default memo(PostAJob)
