/**
 * Joins words within a string with a separator
 *
 * @example
 * unionize('I am a sentence', '-') // => 'i-am-a-sentence'
 */
const unionizeAll = (input: string, separator = ' ') =>
  input.split(' ').join(separator)

export default unionizeAll
