import { orderedJobPostingAtsStatuses } from '@vangst/services/oogst/company/statuses'
import { JobPostingAtsStatusEnum } from '@vangst/services/oogst/types'
import { memo } from 'react'
import Select, { SelectType } from '../../../components/forms/Select'

const options = Object.keys(orderedJobPostingAtsStatuses).map((status) => ({
  label: orderedJobPostingAtsStatuses[status as JobPostingAtsStatusEnum],
  value: status,
}))

function SelectAtsStatus(props: SelectType) {
  const { error, hint, label, name, reference, ...rest } = props
  const id = props?.id ?? name

  return (
    <Select
      id={id}
      error={error}
      disabled={props.disabled}
      hint={hint}
      label={label}
      name={name}
      options={options}
      reference={reference}
      {...rest}
    />
  )
}

export default memo(SelectAtsStatus)
