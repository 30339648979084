import {
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import fetcher from '../fetcher'
import {
  CompanyJobPlacementsDocument,
  CompanyJobPlacementsQuery,
  CompanyJobPlacementsQueryVariables as CompanyJobPlacementsVars,
  FetcherError,
} from '../types'
import { getBasePageInfoInfinitePagination } from '../wtf/pagination'

type CompanyJobPlacementsOptions = UseInfiniteQueryOptions<
  CompanyJobPlacementsQuery,
  FetcherError,
  CompanyJobPlacementsQuery
>

/**
 * @private
 * Retrieve the stringified `CompaniesJobPlacementsInfinite` query key used in the internal cache.
 */
function getKey(variables?: CompanyJobPlacementsVars) {
  const name = 'CompaniesJobPlacementsInfinite'
  return variables ? [name, variables] : [name]
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function. If no
 * variables are passed all `CompanyJobPlacements` queries will be
 * invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateCompanyJobPlacements(queryClient, { displayname: 'moontower' })
 * invalidateCompanyJobPlacements(queryClient)
 */
function invalidateCompanyJobPlacements(
  queryClient: QueryClient,
  variables?: CompanyJobPlacementsVars,
) {
  queryClient.invalidateQueries(
    variables ? getKey(variables) : ['CompaniesJobPlacementsInfinite'],
  )
}

/**
 * Fetch a `CompanyJobPlacementsFragment`.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data, uri } = useCompanyJobPlacements({ id: jobPosting.id })
 * const { data, uri } = useCompanyJobPlacements({ id: node.id }, { initialData: node })
 *
 * @TODO Add `optimisticCreate|Update|Delete`
 * @TODO Support `prefetchCompanyJobPlacements`?
 * @TODO Compute data in select?
 * @TODO Tune options
 */
function useCompanyJobPlacements(
  variables: CompanyJobPlacementsVars,
  options?: CompanyJobPlacementsOptions,
) {
  const query = useInfiniteQuery<
    CompanyJobPlacementsQuery,
    FetcherError,
    CompanyJobPlacementsQuery
  >(
    getKey(variables),
    ({ pageParam }) =>
      fetcher<CompanyJobPlacementsQuery, CompanyJobPlacementsVars>(
        CompanyJobPlacementsDocument,
        { ...variables, ...pageParam },
      )(),
    {
      getNextPageParam: (lastPage) => {
        const edges = lastPage.getPlacements.edges
        const after = edges ? edges[edges.length - 1]?.cursor : undefined
        return { after }
      },
      getPreviousPageParam: (lastPage) => {
        const edges = lastPage.getPlacements.edges
        const before = edges ? edges[0]?.cursor : undefined
        return { before }
      },
      keepPreviousData: true,
      ...options,
    },
  )
  const pages = query?.data?.pages

  const pageInfo = getBasePageInfoInfinitePagination(
    pages?.map((p) => p.getPlacements.pageInfo) || [],
    variables?.first || variables?.last || 24,
  )

  const nextPage = pageInfo?.nextHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchNextPage()
      }
    : undefined

  const prevPage = pageInfo?.prevHref
    ? (e: React.SyntheticEvent<HTMLAnchorElement>) => {
        e?.preventDefault()
        query.fetchPreviousPage()
      }
    : undefined

  const pagination = { ...pageInfo, nextPage, prevPage }
  const isZero = query.data && pagination?.totalCount === 0
  return { ...query, isZero, pagination /*, uri*/ }
}
// -------------------------------------

export type { CompanyJobPlacementsOptions, CompanyJobPlacementsVars }
export { getKey, invalidateCompanyJobPlacements }
export default useCompanyJobPlacements
