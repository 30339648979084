import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { invalidateCompanyFollowers } from '../company/useCompanyFollowers'
import { invalidateNodes } from '../nodes/useNodes'
import {
  ConnectionStatusEnum,
  MemberFragment,
  useAcceptMemberConnectionMutation,
  useConnectWithMemberMutation,
  useDeclineMemberConnectionMutation,
  useDisconnectWithMemberMutation,
} from '../types'
import { invalidateMember, optimisticUpdateMember } from './useMember'
import { invalidateMemberConnections } from './useMemberConnections'

function useConnect(member: MemberFragment) {
  const connectionStatus = member.connectionStatus
  const username = member.username
  const queryClient = useQueryClient()

  const isConnect =
    connectionStatus === ConnectionStatusEnum.Unconnected ||
    connectionStatus === ConnectionStatusEnum.DeclinedSent ||
    connectionStatus === ConnectionStatusEnum.DisconnectedSent

  const isPending =
    connectionStatus === ConnectionStatusEnum.PendingSent ||
    connectionStatus === ConnectionStatusEnum.DeclinedReceived ||
    connectionStatus === ConnectionStatusEnum.DisconnectedReceived // Only the disconnector can reconnect

  const options = useCallback(
    (cs: ConnectionStatusEnum) => {
      const data = { connectionStatus: cs }
      return {
        onMutate: () => {
          optimisticUpdateMember(queryClient, { username }, data)
        },
        onSettled: () => {
          invalidateNodes(queryClient)
          invalidateMember(queryClient)
          invalidateMemberConnections(queryClient)
          invalidateCompanyFollowers(queryClient)
          queryClient.invalidateQueries(['Notifications'])
        },
      }
    },
    [queryClient, username],
  )

  const { mutate: disconnect } = useDisconnectWithMemberMutation(
    options(ConnectionStatusEnum.DisconnectedSent),
  )

  const { mutate: accept } = useAcceptMemberConnectionMutation(
    options(ConnectionStatusEnum.Accepted),
  )

  const { mutate: connect } = useConnectWithMemberMutation(
    options(ConnectionStatusEnum.PendingSent),
  )

  const { mutate: decline } = useDeclineMemberConnectionMutation(
    options(ConnectionStatusEnum.DeclinedSent),
  )

  const changeMutation = useCallback(async () => {
    const vars = { input: { username } }
    connectionStatus === ConnectionStatusEnum.Accepted
      ? disconnect(vars)
      : connectionStatus === ConnectionStatusEnum.PendingReceived
      ? accept(vars)
      : connect(vars)
  }, [accept, connect, connectionStatus, disconnect, username])

  const declineMutation = useCallback(async () => {
    decline({ input: { username } })
  }, [decline, username])

  return {
    acceptMemberConnection:
      connectionStatus === ConnectionStatusEnum.PendingReceived
        ? changeMutation
        : undefined,
    declineMemberConnection:
      connectionStatus === ConnectionStatusEnum.PendingReceived
        ? declineMutation
        : undefined,
    connectWithMember: isConnect ? changeMutation : undefined,
    disconnectWithMember:
      connectionStatus === ConnectionStatusEnum.Accepted
        ? changeMutation
        : undefined,
    isConnected: connectionStatus === ConnectionStatusEnum.Accepted,
    isPending: isPending,
    isSelf: connectionStatus === ConnectionStatusEnum.Self,
  }
}

export default useConnect
