import {
  MemberSkillFragment,
  SkillFragment,
} from '@vangst/services/oogst/types'
import ClickyLink from '../../../components/navigation/ClickyLink'
import NonZeroState from './NonZeroState'
import ZeroState from './ZeroState'

type PropsType = {
  href: string
  showEmptyState?: boolean
  skills?: MemberSkillFragment[]
}

export default function Skills(props: PropsType) {
  const { skills, showEmptyState, href } = props

  return (
    <>
      {!showEmptyState ? (
        <NonZeroState title="Top Skills:">
          {skills?.map((skill) => (
            <li
              className="mood-blue-dark mb-2 max-w-fit rounded-full px-4 py-2 text-sm font-700 uppercase"
              key={skill.id}
            >
              {skill?.skill.name} - {skill?.years} yrs
            </li>
          ))}
        </NonZeroState>
      ) : (
        <ZeroState title="! Top Skills:" border>
          <p className="flow-y-sm italic">
            At least one skill is required to 1-click apply, but five is best.
            <ClickyLink href={href} className="link-orange max-w-fit underline">
              Add skills by editing your profile.
            </ClickyLink>{' '}
          </p>
        </ZeroState>
      )}
    </>
  )
}

// --------------- Serializers ---------------------------
export const serializeSkillsForForm = (skills: SkillFragment[]) => {
  return skills?.map((skill: any) => ({
    years: skill?.years,
    recordId: skill?.id,
    skillId: skill?.skill?.id,
  }))
}
