import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import unionize from '@vangst/lib/unionize'
import {
  MemberComputed,
  MemberOptions,
  MemberVars,
} from '@vangst/services/oogst/member/useMember'
import {
  ApplyDefaultsEnum,
  MemberCredentialFragment,
  MemberEducationFragment,
  MemberExperienceFragment,
  MemberLicenseFragment,
  MemberSkillFragment,
  MemberSummaryFragment,
} from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { useRouter } from 'next/router'
import { MdCalendarMonth, MdOutlineDescription } from 'react-icons/md'
import ClickyLink from '../../../components/navigation/ClickyLink'
import ActivitiesRenderer from '../../application/ActivitiesRenderer'
import SearchRenderer from '../../search/SearchRenderer'
import Connections from './Connections'
import Credentials from './Credentials'
import Education from './Education'
import Employment from './Employment'
import Licenses from './Licenses'
import NonZeroState from './NonZeroState'
import Skills from './Skills'
import Social from './Social'
import StatusTools from './StatusTools'
import Summary from './Summary'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly jobPostingId?: string
  readonly member?: MemberComputed
  readonly title: string
  readonly variables: MemberVars
  readonly options?: MemberOptions
}

function MemberDetail(props: PropsType) {
  const { jobPostingId, member, variables } = props
  const { viewer } = useViewer()
  const { pathname } = useRouter()
  const user = member
  const skills = member?.candidate?.skillRelations
  const experiences = member?.candidate?.experiences
  const credentials = member?.candidate?.credentials
  const credentialCount = member?.candidate?.credentialCount
  const education = member?.candidate?.education
  const licenses = member?.candidate?.candidateLicenses
  const summary = member?.candidate?.summary
  const linkedin = member?.social?.linkedin
  const numConnections = Number(member?.connectionCount ?? 0)
  const emptySocial = !linkedin
  const isCurrentUser = member?.isCurrentUser

  return (
    <section className="contain-c template-content-aside min-h-[calc(100vh-15rem)]">
      <div className="contain-xy flex w-full flex-col gap-4">
        {viewer?.permissions?.internalUser && user?.username != null && (
          <section>
            <h2 className="mood-orange flex h-16 items-center rounded-t-md pl-6 font-600">
              Recruiter Info
            </h2>
            <div className="mood-grey-lightest rounded-b-md p-4">
              <div className="mt-4 pl-2 pr-6">
                {member?.candidate?.dnu == 'true' && (
                  <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
                    <span className="mood-red mb-4 mr-4 block w-32 self-center rounded-sm px-2 py-1 text-[16px] font-600 uppercase">
                      DO NOT USE
                    </span>
                  </dl>
                )}

                <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
                  <dt>Background Check Date:</dt>
                  <dd>{member?.candidate?.backgroundCheckDate}</dd>
                </dl>
                <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
                  <dt>Assigned Recruiters:</dt>
                  {member?.candidate?.recruiters?.map((user) => {
                    return (
                      <dd key={user.id} className="text-sm">
                        {unionize(user.firstName, user.lastName, ' ')}
                      </dd>
                    )
                  })}
                </dl>
                <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
                  <dt>I-9 Date:</dt>
                  <dd>{member?.candidate?.i9Date}</dd>
                </dl>
                <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
                  <dt>Salary Expectations:</dt>
                  <dd>{member?.candidate?.salaryExpectations}</dd>
                </dl>
                <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
                  <dt>Ideal Employment:</dt>
                  <dd>{member?.candidate?.idealEmployment}</dd>
                </dl>
              </div>
            </div>
          </section>
        )}
        {viewer?.permissions?.internalUser && user?.username != null && (
          <section>
            <h2 className="mood-orange flex h-16 items-center rounded-t-md pl-6 font-600">
              Activities
            </h2>
            <div className="mood-grey-lightest rounded-b-md p-4">
              <ActivitiesRenderer
                className="mt-4 border border-grey-light p-2 shadow-inner"
                classNameList="flow-y-xs overflow-y-auto max-h-96"
                endpoint="getMemberActivities"
                variables={{ username: member?.username ?? '' }}
                relayId={member?.id ?? ''}
              />
            </div>
          </section>
        )}
        {user?.candidate?.permissions?.readCredentials && (
          <section>
            <h2 className="mood-orange flex h-16 items-center rounded-t-md pl-6 font-600">
              Credential Achievements
            </h2>
            <div className="mood-grey-lightest rounded-b-md p-4">
              <Credentials
                credentials={credentials as MemberCredentialFragment[]}
                showEmptyState={isEmptyOrNil(credentials)}
              />
            </div>
          </section>
        )}
        <section>
          <h2 className="mood-orange flex h-16 items-center rounded-t-md pl-6 font-600">
            About Me
          </h2>
          <div className="mood-grey-lightest grid gap-4 rounded-b-md p-4">
            <Summary
              href={`${member?.routes?.edit}#about-me` ?? ''}
              isCurrentUser={isCurrentUser}
              summary={summary as MemberSummaryFragment}
              showEmptyState={isEmptyOrNil(summary)}
            />
          </div>
        </section>
        <section>
          <h2 className="mood-orange flex h-16 items-center rounded-t-md pl-6 font-600">
            Experience
          </h2>
          <div className="mood-grey-lightest grid gap-4 rounded-b-md p-4">
            <Employment
              experiences={experiences as MemberExperienceFragment[]}
              href={`${member?.routes?.edit}#employment-info`}
              isCurrentUser={isCurrentUser}
              showEmptyState={
                isEmptyOrNil(experiences) &&
                isEmptyOrNil(member?.candidate?.resumeUrl)
              }
              readPermissions={member?.candidate?.permissions?.readResume}
              resumeUrl={member?.candidate?.resumeUrl}
            />
          </div>
        </section>
        <section>
          <h2 className="mood-orange flex h-16 items-center rounded-t-md pl-6 font-600">
            Education
          </h2>
          <div className="mood-grey-lightest grid gap-4 rounded-b-md p-4">
            <Education
              education={education as MemberEducationFragment[]}
              href={`${member?.routes?.edit}#educational-experiences`}
              isCurrentUser={isCurrentUser}
              showEmptyState={isEmptyOrNil(education)}
            />
          </div>
        </section>
        {isCurrentUser && (
          <section>
            <h2 className="mood-orange flex h-16 items-center rounded-t-md pl-6 font-600">
              Recommended Jobs
            </h2>
            <div className="mood-grey-lightest rounded-b-md p-4">
              <SearchRenderer
                as="card"
                at="preview"
                className="mt-4"
                classNameList="grid-three-up"
                endpoint="searchJobPostings"
                variables={{
                  first: 3,
                  applyDefaults: ApplyDefaultsEnum.Recommended,
                }}
              />
            </div>
          </section>
        )}
      </div>

      <aside
        className="contain-y rounded-md sm:mood-grey-lightest"
        aria-label="More Information"
      >
        {member?.permissions.internalUser && (
          <StatusTools jobPostingId={jobPostingId} member={member} />
        )}
        <div className="px-4">
          {!isEmptyOrNil(member?.candidate?.resumeUrl) && (
            <ClickyLink
              as="icon-text"
              icon={MdOutlineDescription}
              className="link-orange flex font-700 underline"
              href={member?.candidate?.resumeUrl}
            >
              View Resume
            </ClickyLink>
          )}
          {(viewer?.permissions?.internalUser || isCurrentUser) && (
            <>
              <hr className="mt-4" />
              <NonZeroState title="Interview Availability:">
                <span className="flow-y-xs">
                  {member?.social?.calendly && (
                    <ClickyLink
                      as="icon-text"
                      className="link-orange text-sm font-600 underline"
                      href={member?.social?.calendly}
                      icon={MdCalendarMonth}
                    >
                      Calendly
                    </ClickyLink>
                  )}
                  {member?.availability && (
                    <span className="text-sm">{member?.availability}</span>
                  )}
                </span>
              </NonZeroState>
            </>
          )}
          {!user?.candidate?.permissions?.readCredentials && (
            <Credentials
              as="sidebar"
              credentialCount={credentialCount}
              showEmptyState={user?.candidate?.credentials == null}
              credentials={credentials as MemberCredentialFragment[]}
            />
          )}

          {(isCurrentUser || !isEmptyOrNil(skills)) && (
            <Skills
              skills={skills as MemberSkillFragment[]}
              href={`${member?.routes?.edit}#top-skills`}
              showEmptyState={isEmptyOrNil(skills)}
            />
          )}

          {(isCurrentUser || !isEmptyOrNil(licenses)) && (
            <Licenses
              href={`${member?.routes?.edit}#licenses`}
              showEmptyState={!licenses || !licenses.length}
              licenses={licenses as MemberLicenseFragment[]}
              readPermissions={member?.candidate?.permissions?.readLicense}
            />
          )}

          {(isCurrentUser || numConnections > 0) && (
            <Connections
              showEmptyState={numConnections === 0}
              numConnections={numConnections}
              vars={variables}
              href={member?.routes?.connections}
            />
          )}

          {(isCurrentUser || !emptySocial) &&
            !/applications/gi.test(pathname) && (
              <Social
                href={`${user?.routes?.edit}#social-media`}
                showEmptyState={emptySocial}
                socials={user?.social}
              />
            )}
        </div>
      </aside>
    </section>
  )
}

export default MemberDetail
