import useMember, {
  MemberOptions,
  MemberVars,
} from '@vangst/services/oogst/member/useMember'
import { memo } from 'react'
import MemberMenu from './MemberMenu'

type PropsType = React.HTMLAttributes<HTMLElement> & {
  readonly jobPostingId?: string
  readonly options?: MemberOptions
  readonly popoverClassName?: string
  readonly url?: string
  readonly variables: MemberVars
}

function MemberActionsMenu(props: PropsType) {
  const { jobPostingId, options, popoverClassName, url, variables, ...rest } =
    props
  const { data } = useMember(variables, options)
  const member = data?.getUser

  return (
    <>
      {member != null && (
        <MemberMenu
          jobPostingId={jobPostingId}
          member={member}
          popoverClassName={popoverClassName}
          url={url}
          {...rest}
        />
      )}
    </>
  )
}

/**
 * The menu contains navigation, relationship management and other call to actions.
 *
 * Loads the `ConnectMenu` used in Member and JobApplicants views
 *
 * @example
 * <MemberActionsMenu variables={{ username: 'marlene-dietrich' }} />
 */
export default memo(MemberActionsMenu)
