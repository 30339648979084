import { CredentialFragment } from '@vangst/services/oogst/types'
import React, { useMemo } from 'react'
import Select, { SelectType } from '../../../components/forms/Select'

type PropsType = SelectType & {
  readonly fieldLabel?: string
  readonly credentials: CredentialFragment[]
}

function SelectCredential(props: PropsType & { fieldLabel?: string }) {
  const {
    credentials,
    error,
    hideLabel,
    hint,
    label,
    name,
    reference,
    ...rest
  } = props

  const listOfCredentials = useMemo(() => {
    return credentials?.map((cred: CredentialFragment) => ({
      label: cred?.title ?? '',
      value: cred?.id ?? '',
    }))
  }, [credentials])

  const credentialOptions = [
    { label: 'Select Credential', value: '' },
    ...(listOfCredentials ?? []),
  ]

  return (
    <Select
      id="Credentials"
      disabled={props.disabled}
      error={error}
      hideLabel={hideLabel}
      hint={hint}
      label={label}
      name={name}
      options={credentialOptions}
      reference={reference}
      {...rest}
    />
  )
}

export default React.memo(SelectCredential)

// ----------------------------------------------------
