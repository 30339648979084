import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import fetcher from '../fetcher'
import {
  FetcherError,
  LicensesDocument,
  LicensesQuery,
  LicensesQueryVariables as LicensesVars,
  useLicensesQuery as useEndpoint,
} from '../types'

type LicensesOptions = UseQueryOptions<
  LicensesQuery,
  FetcherError,
  LicensesQuery
>

/**
 * @private
 * Retrieve the stringified `Licenses` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

/**
 * @private
 * Retrieve the `Licenses` from the queryClient's cache.
 */
function getCache(queryClient: QueryClient, variables?: LicensesVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<LicensesQuery>(key)
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateLicenses(queryClient)
 */
function invalidateLicenses(
  queryClient: QueryClient,
  variables?: LicensesVars,
) {
  queryClient.invalidateQueries(variables ? getKey(variables) : ['Licenses'])
}

/**
 * Prefetch and return the list of `Licenses`.
 *
 * @example
 * const licenses = await prefetchLicenses(queryClient)
 */
async function prefetchLicenses(
  queryClient: QueryClient,
  variables?: LicensesVars,
) {
  const key = getKey(variables)
  const fn = fetcher<LicensesQuery, LicensesVars>(LicensesDocument, variables)
  await queryClient.prefetchQuery(key, fn)
  return getCache(queryClient, variables)
}

/**
 * Fetch a `LicensesFragment` and derive a `LicensesComputed` data set.
 * Passing `initialData` will hydrate the cache.
 *
 * The staleTime is set to Infinity for a single fetch.
 *
 * @example
 * const { data, uri } = useLicenses()
 * const { data, uri } = useLicenses(_, { initialData: node })
 */

function useLicenses(variables?: LicensesVars, options?: LicensesOptions) {
  const query = useEndpoint(variables, {
    staleTime: Infinity,
    ...options,
  })
  return query
}

// -------------------------------------

export { invalidateLicenses, prefetchLicenses }
export default useLicenses
