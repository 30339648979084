import { forwardRef } from 'react'
import Input, { InputType } from './Input'
import useCount, { useCountType } from './useCount'

type PropsType = useCountType & InputType

/**
 * The `Input` component displayed with a character counter.
 *
 * @example
 * <InputCounter
 *   defaultValue="Type more things"
 *   label="InputCounter"
 *   len={10}
 *   max={20}
 *   name="InputCounter"
 * />
 */
const InputCounter = forwardRef<HTMLInputElement, PropsType>(
  function InputCounter(props, ref) {
    const { len, max } = props
    const { handleChange, count } = useCount({ len, max })
    return <Input count={count} {...props} onChange={handleChange} ref={ref} />
  },
)

export default InputCounter
