import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import Routes from '../../routes'
import fetcher from '../fetcher'
import {
  CompanyPhotosDocument,
  CompanyPhotosQuery,
  CompanyPhotosQueryVariables as CompanyPhotosVars,
  FetcherError,
  useCompanyPhotosQuery as useEndpoint,
} from '../types'

type CompanyPhotosOptions = UseQueryOptions<
  CompanyPhotosQuery,
  FetcherError,
  CompanyPhotosQuery
>

/**
 * @private
 * Retrieve the stringified `CompanyPhotos` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

/**
 * @private
 * Retrieve the `CompanyPhotos` from the queryClient's cache.
 */
function getCache(queryClient: QueryClient, variables: CompanyPhotosVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<CompanyPhotosQuery>(key)
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function. If no
 * variables are passed all `CompanyPhotos` queries will be
 * invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateCompanyPhotos(queryClient, { displayname: 'moontower' })
 * invalidateCompanyPhotos(queryClient)
 */
function invalidateCompanyPhotos(
  queryClient: QueryClient,
  variables?: CompanyPhotosVars,
) {
  queryClient.invalidateQueries(
    variables ? getKey(variables) : ['CompanyPhotos'],
  )
}

/**
 * Prefetch and return the computed `CompanyPhotos` from a `displayname`.
 *
 * @example
 * const company = await prefetchCompanyPhotos(queryClient, { displayname: 'moontower' })
 */
async function prefetchCompanyPhotos(
  queryClient: QueryClient,
  variables: CompanyPhotosVars,
) {
  const key = getKey(variables)
  const fn = fetcher<CompanyPhotosQuery, CompanyPhotosVars>(
    CompanyPhotosDocument,
    variables,
  )
  await queryClient.prefetchQuery(key, fn)
  return getCache(queryClient, variables)
}

/**
 * Fetch a `CompanyPhotosFragment`.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data, uri } = useCompanyPhotos({ displayname: 'moontower' })
 * const { data, uri } = useCompanyPhotos({ displayname: node.displayname }, { initialData: node })
 *
 * @TODO This endpoint should change shortly to support pagination
 * @TODO Add `optimisticCreate|Update|Delete`
 * @TODO Tune options
 */
function useCompanyPhotos(
  variables: CompanyPhotosVars,
  options?: CompanyPhotosOptions,
) {
  const query = useEndpoint(variables, {
    enabled: options?.initialData == null,
    ...options,
  })
  const uri = Routes.COMPANIES_DETAIL.replace(
    ':displayname',
    variables.displayname,
  )
  const data = (options?.initialData as CompanyPhotosQuery) || query?.data
  return { ...query, data, uri }
}

// -------------------------------------

export type { CompanyPhotosOptions, CompanyPhotosVars }
export { invalidateCompanyPhotos, prefetchCompanyPhotos }
export default useCompanyPhotos
