import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import { invalidateCompanyJobApplication } from '../company/useCompanyJobApplication'
import { invalidateCompanyJobPlacements } from '../company/useCompanyJobPlacements'
import { invalidateMember } from '../member/useMember'
import {
  DeletePlacementMutation,
  DeletePlacementMutationVariables,
  FetcherError,
  useDeletePlacementMutation,
} from '../types'

type DeleteOptions = UseMutationOptions<
  DeletePlacementMutation,
  FetcherError,
  DeletePlacementMutationVariables
>

/**
 * Deletes an existing `JobPosting`.
 *
 * When overriding `onSettled` be sure to provide necessary invalidations.
 *
 * @example
 * const deleteJobPosting = useDeletePlacement({onSuccess: () => push(url)})
 * const deleteJobPosting.mutate({ input: {id: '8675309'} })
 *
 * @TODO Invalidate searchJobPostings?
 * @TODO Call optimistic Deletes?
 */
function useDeletePlacement(options?: DeleteOptions) {
  const queryClient = useQueryClient()
  return useDeletePlacementMutation({
    onSettled: () => {
      invalidateCompanyJobPlacements(queryClient)
      invalidateMember(queryClient)
      invalidateCompanyJobApplication(queryClient)
      invalidateCompanyJobPlacements(queryClient)
    },
    ...options,
  })
}

export default useDeletePlacement
