/**
 * Various data collection lists within HubSpot
 */
export enum HubSpotFormIDs {
  BlogContact = '683bb1d6-fe2c-41fa-bc5a-d8587f7b1d14', // no longer used
  CareerFair = 'fe701815-5f74-4527-bc10-c5a3ba58d894', // no longer used
  Contact = 'b38655e1-57a5-48d3-b152-a0dd5797af53', // about/contact
  ContactSales = '47a504d5-a283-4749-96b5-631bcf94581c', // events/slug/index (moved to vangst.com and no longer used?)
  Croptober = 'ddf02feb-c0ce-4d22-a583-8214a424cd8c', // no longer used
  CourseFeedback = '4a19c4ff-9b85-4401-9501-6c5185a8f739', // DialogCourseFeedback
  Expert = 'ea6eee7a-2e13-4e77-bbc0-c155f70782ba', // no longer used
  Newsletter = '10bec0ef-5644-4f20-9506-b6f23e3e0aec', // NewsletterForm
  PostJob = 'b2b9c58b-2417-4b54-a41c-4a72fac4ed8d', // no longer used
  Report = '0fb204fc-9e57-495b-9d62-48b82f741edd', // reports/salary-guide
}

// HubSpotForm Used In:
// reports/salary-guide
// help/index
// events/[slug]/index
// about/contact
// DialogContact
// DialogCourseFeedback
// NewsletterForm
//

/**
 * Send data to HubSpot's API routed to a collection list
 */
export async function postToHubSpot(formId: HubSpotFormIDs, data = {}) {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.NEXT_PUBLIC_HS_PORTAL_ID}/${formId}`
  const response = await fetch(url, {
    body: JSON.stringify(data),
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    mode: 'cors',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  })
  return await response.json()
}

// TODO: fetch values from form dynamically:
// https://legacydocs.hubspot.com/docs/methods/forms/v2/get_field

// https://app.hubspot.com/property-settings/5711383/properties?type=0-1&property=contact_subject&action=edit
export enum HUBSPOT_SUBJECTS {
  NULL = 'ChooseSubject',
  MEMBER_SUPPORT = 'MemberSupport',
  GIGS_CANDIDATE_SUPPORT = 'GigsCandidateSupport',
  DH_CANDIDATE_SUPPORT = 'DHCandidateSupport',
  CANDIDATE_SUPPORT = 'CandidateSupport',
  COMPANY_SUPPORT = 'CompanySupport',
  COMPANY_DEMO_SUPPORT = 'DemoRequest',
  COMPANY_GIGS_SUPPORT = 'CompanyGigsSupport',
  COMPANY_DH_SUPPORT = 'CompanyDHSupport',
  COMPANY_MISC_SUPPORT = 'CompanyMiscSupport',
  OTHER = 'Other',
}

const SUBJECTS = {
  [HUBSPOT_SUBJECTS.NULL]: 'Choose Subject',
  [HUBSPOT_SUBJECTS.MEMBER_SUPPORT]:
    "I'm a Candidate with a question about my Vangst Member profile",
  [HUBSPOT_SUBJECTS.GIGS_CANDIDATE_SUPPORT]:
    "I'm a Candidate with a question about Gig (temporary) job opportunities",
  [HUBSPOT_SUBJECTS.DH_CANDIDATE_SUPPORT]:
    "I'm a Candidate with a question about Direct Hire (permanent) job opportunities",
  [HUBSPOT_SUBJECTS.CANDIDATE_SUPPORT]:
    "I'm a Candidate with a question not listed",
  [HUBSPOT_SUBJECTS.COMPANY_SUPPORT]:
    "I'm a Company with a question about my Vangst Company profile",
  [HUBSPOT_SUBJECTS.COMPANY_DEMO_SUPPORT]:
    "I'm a Company with a question about a demo",
  [HUBSPOT_SUBJECTS.COMPANY_GIGS_SUPPORT]:
    "I'm a Company with a question about Gig (temporary) employees",
  [HUBSPOT_SUBJECTS.COMPANY_DH_SUPPORT]:
    "I'm a Company with a question about Direct Hire (permanent) employees",
  [HUBSPOT_SUBJECTS.COMPANY_MISC_SUPPORT]:
    "I'm a Company with a question not listed",
  [HUBSPOT_SUBJECTS.OTHER]: "Other, I'll write what I need help with",
}

export const SUBJECT_OPTIONS = Object.keys(SUBJECTS).map(
  (subject: string, index: number) => ({
    id: index,
    label: SUBJECTS[subject as HUBSPOT_SUBJECTS],
    value: subject,
  }),
)

const hubspotRoles = ['Company', 'Investor', 'Job Seeker', 'Media', 'Other']

export const ROLE_OPTIONS = hubspotRoles.map((role: string, index: number) => ({
  id: index,
  label: role,
  value: role,
}))
