import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import letterize from '@vangst/lib/letterize'
import truncate from '@vangst/lib/truncate'
import unionize from '@vangst/lib/unionize'
import R from '../../routes'
import fetcher from '../fetcher'
import {
  CompanyDocument,
  CompanyFragment,
  CompanyQuery,
  CompanyQueryVariables as CompanyVars,
  CompanySizeEnum,
  FetcherError,
  useCompanyQuery as useEndpoint,
} from '../types'

type CompanyOptions = UseQueryOptions<CompanyQuery, FetcherError, CompanyQuery>

export const companySizeMap = {
  [CompanySizeEnum.Xs]: '10 or fewer employees',
  [CompanySizeEnum.S]: '11-50 employees',
  [CompanySizeEnum.M]: '51-200 employees',
  [CompanySizeEnum.Ml]: '201-500 employees',
  [CompanySizeEnum.L]: '501-1000 employees',
  [CompanySizeEnum.Xl]: '1000+ employees',
}

export const companySizes = [
  { label: 'Company Size', value: '' },
  { label: companySizeMap?.[CompanySizeEnum.Xs], value: CompanySizeEnum.Xs },
  { label: companySizeMap?.[CompanySizeEnum.S], value: CompanySizeEnum.S },
  { label: companySizeMap?.[CompanySizeEnum.M], value: CompanySizeEnum.M },
  { label: companySizeMap?.[CompanySizeEnum.Ml], value: CompanySizeEnum.Ml },
  { label: companySizeMap?.[CompanySizeEnum.L], value: CompanySizeEnum.L },
  { label: companySizeMap?.[CompanySizeEnum.Xl], value: CompanySizeEnum.Xl },
]

export const serializeCompanySize = (size?: string) => {
  if (!size) return undefined
  const map = {
    XS: CompanySizeEnum.Xs,
    S: CompanySizeEnum.S,
    M: CompanySizeEnum.M,
    ML: CompanySizeEnum.Ml,
    L: CompanySizeEnum.L,
    XL: CompanySizeEnum.Xl,
  }
  return map[size as keyof typeof map]
}

/**
 * @private
 * Retrieve the stringified `Company` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

/**
 * @private
 * Retrieve the `Company` from the queryClient's cache.
 */
function getCache(queryClient: QueryClient, variables: CompanyVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<CompanyQuery>(key)
}

// -------------------------------------

/**
 * @protected
 * Derives data not contained in the API.
 */
function getDerived(company?: CompanyFragment | null) {
  if (company == null) return company
  const displayname = company.displayname
  const citystate = unionize(
    company.location?.city,
    company.location?.state,
    ', ',
  )

  return {
    companySizeChunk: companySizes.find(
      (s) => s.value === company?.companySize,
    ),
    citystate,
    citystatezip: unionize(citystate, company?.location?.postalCode, ' '),
    descriptionTruncated: company?.companyDescription
      ? truncate(company.companyDescription, 160)
      : undefined,
    initials: letterize(company?.name),
    routes: {
      detail: R.COMPANIES_DETAIL.replace(':displayname', displayname),
      edit: R.COMPANIES_EDIT.replace(':displayname', displayname),
      followers: R.COMPANIES_FOLLOWERS.replace(':displayname', displayname),
      jobsIndex: R.COMPANIES_JOBS.replace(':displayname', displayname),
      jobsEdit: R.COMPANIES_JOBS_EDIT.replace(':displayname', displayname),
      jobsGigs: R.COMPANIES_JOBS_GIGS.replace(':displayname', displayname),
      jobsNew: R.COMPANIES_JOBS_NEW.replace(':displayname', displayname),
      jobPostings:
        R.COMPANIES_DETAIL.replace(':displayname', displayname) +
        '#open-positions',
      trainingsIndex: R.COMPANIES_TRAININGS.replace(
        ':displayname',
        displayname,
      ),
    },
    webname: company?.website
      ? company.website.replace(/^(?:https?:\/\/)?(?:www\.)?/gi, '')
      : undefined,
    shouldShowBillingOptions: true,
  }
}

/**
 * @private
 * Merge the `CompanyFragment` with derived `Company` data.
 */
function getComputed(company?: CompanyFragment) {
  if (company == null) return company
  return { ...company, ...getDerived(company) }
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 * If no variables are passed all `Company` queries will be invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateCompany(queryClient, { displayname: 'moontower' })
 * invalidateCompany(queryClient)
 */
function invalidateCompany(queryClient: QueryClient, variables?: CompanyVars) {
  queryClient.invalidateQueries(variables ? getKey(variables) : ['Company'])
}

/**
 * Create a new `Company` within the internal cache's result set.
 *
 * @example
 * optimisticCreateCompany(queryClient, { displayname: 'moontower'}, { name: 'Moon Tower' })
 */
function optimisticCreateCompany(
  queryClient: QueryClient,
  variables: CompanyVars,
  data: Partial<CompanyFragment>,
) {
  const key = getKey(variables)
  queryClient.setQueryData(key, data)
}

/**
 * Find and update a `Company` within the internal cache's result set. If an
 * existing result is not found a new one will be optimistically created.
 *
 * @example
 * optimisticUpdateCompany(queryClient, { displayname: 'moontower'}, { name: 'Moon Tower, THC' })
 */
function optimisticUpdateCompany(
  queryClient: QueryClient,
  variables: CompanyVars,
  data: Partial<CompanyFragment>,
) {
  const key = getKey(variables)
  const prev = queryClient.getQueryData<CompanyQuery>(key)
  if (!prev) {
    optimisticCreateCompany(queryClient, variables, data)
    return
  }
  const next = { getClient: { ...prev.getClient, ...data } }
  queryClient.setQueryData(key, () => next)
}

/**
 * Removes the `Company` from the internal cache's result set.
 *
 * @example
 * optimisticDeleteCompany(queryClient, { displayname: 'moontower'})
 */
function optimisticDeleteCompany(
  queryClient: QueryClient,
  variables: CompanyVars,
) {
  const key = getKey(variables)
  queryClient.removeQueries(key, { exact: true })
}

/**
 * Prefetch and return the computed `Company` from a `displayname`.
 *
 * @example
 * const company = await prefetchCompany(queryClient, { displayname: 'moontower' })
 */
async function prefetchCompany(
  queryClient: QueryClient,
  variables: CompanyVars,
) {
  const key = getKey(variables)
  const fn = fetcher<CompanyQuery | FetcherError, CompanyVars>(
    CompanyDocument,
    variables,
  )

  let data

  try {
    data = await queryClient.fetchQuery(key, fn)
  } catch (e) {
    // Silently catching this for now :|
  }

  const is403 = !!(data as FetcherError)?.response?.errors?.some(
    (e) =>
      e.extensions?.code === 'UNAUTHORIZED' ||
      e.extensions?.code === 'ACTION_UNAUTHORIZED',
  )
  const cache = getCache(queryClient, variables)
  return cache?.getClient
    ? { getClient: getComputed(cache.getClient), is403 }
    : { ...cache, is403 }
}

/**
 * Fetch a `CompanyFragment` with derived data.
 * Passing `initialData` will hydrate the cache.
 *
 * @example
 * const { data, uri } = useCompany({ displayname: 'moontower' })
 * const { data, uri } = useCompany({ displayname: node.displayname }, { initialData: node })
 *
 * @TODO Compute data in `select`
 * @TODO Tune options
 */
function useCompany(variables: CompanyVars, options?: CompanyOptions) {
  const query = useEndpoint(variables, {
    enabled: options?.initialData == null,
    // staleTime: 10000,
    ...options,
  })
  const uri = R.COMPANIES_DETAIL.replace(':displayname', variables.displayname)
  const d = (options?.initialData as CompanyQuery) || query?.data
  const data = d ? { getClient: getComputed(d.getClient) } : d
  return { ...query, data, uri }
}

// -------------------------------------

export type CompanyComputed = ReturnType<typeof getComputed>

export type { CompanyFragment, CompanyOptions, CompanyVars }
export {
  getDerived,
  invalidateCompany,
  optimisticCreateCompany,
  optimisticDeleteCompany,
  optimisticUpdateCompany,
  prefetchCompany,
}

export default useCompany
