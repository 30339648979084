import clsx from '@vangst/lib/clsx'
import { JobPostingComputed } from '@vangst/services/oogst/jobPosting/useJobPosting'
import {
  MemberComputed,
  MemberFragment,
} from '@vangst/services/oogst/member/useMember'
import { memo } from 'react'
import { MdCheck, MdClose } from 'react-icons/md'
import FilterPopover from '../../../components/feedback/FilterPopover'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'
import ActivitiesRenderer from '../../application/ActivitiesRenderer'
import { emptyText, TableBodyDetailList } from '../../application/TableRow'
import { serializeJsonToReact } from '../../editor/serializers'
import useMessages from '../../messaging/useMessages'
import TableRowPlacements from './JobPostingTableRowPlacements'

type PropsType = React.HTMLAttributes<HTMLDetailsElement> & {
  readonly jobPosting?: JobPostingComputed
  readonly title: string
  readonly uri: string
}

function JobPostingTableRowBody(props: PropsType) {
  const { jobPosting } = props

  const salesReps = (jobPosting?.accountExecs || []) as MemberComputed[]
  const recruiters = (jobPosting?.recruiters || []) as MemberComputed[]
  const timeSheetMgrs = (jobPosting?.timeSheetManagers ||
    []) as MemberComputed[]
  const additionalReqs = jobPosting?.gigsInfo?.additionalRequirements
  const billingAddress = jobPosting?.client?.billingAddress

  const engagementDepositMade = jobPosting?.gigsInfo?.engagementDeposit
  const costCenterCreated = jobPosting?.gigsInfo?.costCenterCreated
  const companyOnboarded = jobPosting?.gigsInfo?.companyOnboarded
  const statuses = [
    { state: engagementDepositMade, name: 'Engagement Deposit Made' },
    { state: costCenterCreated, name: 'Cost Center Created' },
    { state: companyOnboarded, name: 'Company Onboarded' },
  ]

  const { handleMessageUser } = useMessages({
    isWidget: true,
  })
  const numComplete = statuses.filter((status) => status?.state)?.length
  return (
    <div className="flow-y-sm border-t border-t-grey-mid bg-grey-lightest p-2">
      <div className="grid pt-4 sm:grid-cols-5">
        <TableBodyDetailList title="Assigned Sales Rep(s)">
          <ul className="flow-y-xs">
            {salesReps && salesReps?.length > 0
              ? salesReps.map((rep) => (
                  <li key={rep?.id}>
                    <ClickyLink
                      onClick={() => handleMessageUser(rep as MemberFragment)}
                      title={`Send message to ${rep?.fullName}`}
                      className="link-orange "
                    >
                      <span className="flow-x-xs items-center">
                        <Avatar
                          alt={`${rep?.firstName[0]}${rep?.lastName[0]}`}
                          className="mood-grey size-6 shrink-0 text-[12px]"
                          src={rep?.avatarUrl || undefined}
                        />
                        {rep?.firstName} {rep?.lastName}
                      </span>
                    </ClickyLink>
                  </li>
                ))
              : emptyText}
          </ul>
        </TableBodyDetailList>
        <TableBodyDetailList title="Assigned Recruiter(s)">
          <ul className="flow-y-xs">
            {recruiters && recruiters?.length > 0
              ? recruiters.map((rep) => (
                  <li key={rep?.id}>
                    <ClickyLink
                      onClick={() => handleMessageUser(rep as MemberFragment)}
                      title={`Send message to ${rep?.fullName}`}
                      className="flow-x-xs link-orange items-center"
                    >
                      <span className="flow-x-xs items-center">
                        <Avatar
                          alt={`${rep?.firstName[0]}${rep?.lastName[0]}`}
                          className="mood-grey size-6 shrink-0 text-[12px]"
                          src={rep?.avatarUrl || undefined}
                        />
                        {rep?.firstName} {rep?.lastName}
                      </span>
                    </ClickyLink>
                  </li>
                ))
              : emptyText}
          </ul>
        </TableBodyDetailList>
        <TableBodyDetailList title="Assigned Time Sheet Manager(s)">
          <ul className="flow-y-xs">
            {timeSheetMgrs && timeSheetMgrs?.length > 0
              ? timeSheetMgrs.map((rep) => (
                  <li key={rep?.id}>
                    <ClickyLink
                      onClick={() => handleMessageUser(rep as MemberFragment)}
                      title={`Send message to ${rep?.fullName}`}
                      className="flow-x-xs link-orange items-center"
                    >
                      <span className="flow-x-xs items-center">
                        <Avatar
                          alt={`${rep?.firstName[0]}${rep?.lastName[0]}`}
                          className="mood-grey size-6 shrink-0 text-[12px]"
                          src={rep?.avatarUrl || undefined}
                        />
                        {rep?.firstName} {rep?.lastName}
                      </span>
                    </ClickyLink>
                  </li>
                ))
              : emptyText}
          </ul>
        </TableBodyDetailList>
        <TableBodyDetailList
          title={`All Applicants (${jobPosting?.countJobApplicationsTotal})`}
        >
          <ClickyLink
            className="link-orange"
            href={jobPosting?.routes?.jobsApplications}
          >
            View All Applications
          </ClickyLink>
        </TableBodyDetailList>

        <FilterPopover
          className="w-32"
          isFiltering={false}
          label={`Gigs Info ${numComplete}/${statuses.length}`}
          minimal
        >
          {statuses.map((status) => (
            <ul key={status.name} title={status.name}>
              <span
                className={clsx(
                  'flow-x-xs items-center',
                  !status.state && 'text-grey-mid',
                )}
              >
                {status.state ? (
                  <MdCheck className="size-4" />
                ) : (
                  <MdClose className="size-4" />
                )}
                {status.name}
              </span>
            </ul>
          ))}
        </FilterPopover>
      </div>
      <hr />
      <TableRowPlacements jobPosting={jobPosting} />
      <hr />
      <div className="grid gap-2 sm:grid-cols-3">
        <TableBodyDetailList title="Billing Address">
          {billingAddress || emptyText}
        </TableBodyDetailList>
        <TableBodyDetailList title="Additional Requirements">
          {additionalReqs ? serializeJsonToReact(additionalReqs) : emptyText}
        </TableBodyDetailList>
      </div>
      {jobPosting?.permissions.internalUser && (
        <>
          <hr />
          <div className="grid">
            <TableBodyDetailList title="Activity">
              <span className="block max-h-80 overflow-y-auto">
                <ActivitiesRenderer
                  variables={{ id: jobPosting?.id ?? '', first: 10 }}
                  endpoint="getJobPostingActivities"
                  relayId={jobPosting?.id}
                />
              </span>
            </TableBodyDetailList>
          </div>
        </>
      )}
    </div>
  )
}

export default memo(JobPostingTableRowBody)
