import {
  ActivitiesAlertsEndpoints,
  ActivitiesAlertsQueryVariables,
} from '@vangst/services/oogst/wtf/useActivitiesAlerts'
import { HTMLAttributes } from 'react'
import { Controller } from 'react-hook-form'
import EditorContainer from '../../editor/Editor'
import useCreateActivity from '../useCreateActivity'
import validations from '../validations'

type PropsType = {
  readonly endpoint: ActivitiesAlertsEndpoints
  readonly relayId: string
  readonly handleCancel: () => void
  readonly variables: ActivitiesAlertsQueryVariables
} & HTMLAttributes<HTMLDivElement>

export default function FormCreateActivity(props: PropsType) {
  const { endpoint, handleCancel, relayId, variables } = props
  const { control, errors, handleSubmit, submitActivity } = useCreateActivity({
    endpoint,
    handleCancel,
    relayId: relayId ?? '',
    variables,
  })

  return (
    <form
      className="relative grid w-full gap-4"
      onReset={handleCancel}
      onSubmit={handleSubmit(submitActivity)}
    >
      <Controller
        render={({ field }) => (
          <EditorContainer
            mentionsEnabled
            handleSubmit={handleSubmit(submitActivity)}
            error={errors?.notes?.message}
            label="Notes"
            {...field}
          />
        )}
        name="notes"
        control={control}
        rules={{
          validate: {
            // @ts-ignore
            maxLength: validations.slateMaxLength,
          },
        }}
      />
    </form>
  )
}
