import { ScreeningFragment } from '@vangst/services/oogst/types'
import useScreenings from '@vangst/services/oogst/wtf/useScreenings'
import React, { useMemo } from 'react'
import Busy from '../../../components/feedback/Busy'
import Select, { SelectType } from '../../../components/forms/Select'

type PropsType = SelectType

function SelectScreening(props: PropsType & { fieldLabel?: string }) {
  const { error, hideLabel, hint, label, name, reference, ...rest } = props

  const { data: ld, isLoading } = useScreenings()
  const screenings = ld?.screenings

  const id = props?.id ?? name

  const listOfScreenings = useMemo(() => {
    return screenings?.map((screening: ScreeningFragment) => ({
      label: screening.name || '',
      value: screening.id ?? '',
    }))
  }, [screenings])

  const screeningOptions = [
    { label: 'Select Screening', value: '' },
    ...(listOfScreenings ?? []),
  ]

  if (isLoading) return <Busy />

  return (
    <Select
      id={id}
      disabled={props.disabled}
      error={error}
      hideLabel={hideLabel}
      hint={hint}
      label={label}
      name={name}
      options={screeningOptions}
      reference={reference}
      {...rest}
    />
  )
}

export default React.memo(SelectScreening)
