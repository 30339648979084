import SocialLinkedIn from '../../../components/assets/social/SocialLinkedIn'
import ClickyLink from '../../../components/navigation/ClickyLink'
import NonZeroState from './NonZeroState'
import ZeroState from './ZeroState'

type PropsType = {
  href: string
  showEmptyState?: boolean
  socials?: {
    linkedin?: string
  }
}

export default function Social(props: PropsType) {
  const { href, showEmptyState, socials } = props
  const linkedin = socials?.linkedin

  return (
    <>
      {!showEmptyState ? (
        <NonZeroState title="Social Media Links:">
          {linkedin != null && (
            <li className="flex items-center gap-2 text-sm font-600">
              <SocialLinkedIn className="text-blue-dark" />
              <ClickyLink href={linkedin} className="link-orange underline">
                LinkedIn
              </ClickyLink>
            </li>
          )}
        </NonZeroState>
      ) : (
        <ZeroState title="! Social Media Links:" border>
          <p className="italic">
            <ClickyLink href={href} className="link-orange underline">
              Add social links
            </ClickyLink>{' '}
            by editing your profile. Social links help with networking and
            providing potential employers with more information about you.
          </p>
        </ZeroState>
      )}
    </>
  )
}
