import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import { memo, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { MdError } from 'react-icons/md'
import { TeamEnum } from '../../../../packages/services/oogst/types'
import AlertText from '../../components/feedback/AlertText'
import FilterPopover from '../../components/feedback/FilterPopover'
import Input from '../../components/forms/Input'
import ClickyBtn from '../../components/navigation/ClickyBtn'
import QueryErrorFallback from '../application/feedback/QueryErrorFallback'
import ChooseBoolean from '../application/forms/ChooseBoolean'
import validations from '../application/validations'
import ChooseByRoleCombo from '../recruiter/forms/ChooseByRoleCombo'
import ChooseElasticSearch from '../search/forms/ChooseElasticSearch'
import useFormNewGigsJob from './forms/useFormNewGigsJob'

type PropsType = React.HTMLAttributes<HTMLFormElement> & {
  readonly displayname?: string
  readonly isDialog?: boolean
  readonly focusRef?: any
}

function JobPostingNewGigForm(props: PropsType) {
  const { displayname, isDialog, focusRef } = props
  const [showAssociatedClientError, setShowAssociatedClientError] =
    useState(false)
  const [showAccountExecError, setShowAccountExecError] = useState(false)
  const [clientDisplayname, setClientDisplayname] = useState(displayname)
  const [toggle, setToggle] = useState(true)

  const { error, methods, saving, submit } = useFormNewGigsJob({
    displayname: clientDisplayname,
    setShowAssociatedClientError,
    setShowAccountExecError,
  })

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = methods
  const accountExecsValue = getValues('accountExecIds')

  const handleValueChange = (name: any, val: string[]) => {
    setValue(name, val)
    setToggle(!toggle)
  }

  // when the associatedClient changes, refetches company data to repopulate the time sheet managers field
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'associatedClient') {
        setClientDisplayname(value.associatedClient as string)
        setShowAssociatedClientError(false)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, clientDisplayname])
  const { ref: accountExecsRef, ...accountExecsProps } =
    register(`accountExecIds`)
  return (
    <FormProvider {...methods}>
      <form
        className="contain-c template-content-aside"
        onSubmit={handleSubmit(submit)}
      >
        <div className="contain-y mood-white w-full pt-0">
          <div className="flow-y-sm">
            <fieldset>
              <section ref={focusRef} className="border-4 border-blue-light">
                <h2 className="mood-orange flex h-16 items-center pl-6 font-600">
                  Job Order Details
                </h2>
                <div className="mood-grey-lightest flex w-full flex-col gap-8 p-4">
                  <div className="mood-grey-lightest flex w-full flex-col gap-8 p-4">
                    <Input
                      error={errors?.title?.message}
                      label="Title"
                      required
                      {...register('title', validations.maxLength80Required)}
                    />
                    <span className="-mb-6 font-600 after:left-1 after:ml-1 after:scale-125 after:text-red after:content-['*']">
                      Associated Company
                    </span>
                    <ChooseElasticSearch
                      endpoint="searchClientsByRecruiters"
                      onChange={(e) => {
                        // @ts-ignore - complaining about the type of e here
                        setValue('associatedClient', e)
                      }}
                    >
                      {clientDisplayname || 'Which Company is this job for?'}
                    </ChooseElasticSearch>
                    <Input
                      error={errors?.quantity?.message}
                      label="Number of Positions"
                      type="number"
                      defaultValue={1}
                      required
                      {...register('quantity')}
                    />
                    <ChooseBoolean
                      label="Include a Kickoff Call?"
                      name="gigsInfo.kickoffCall"
                    />
                    <FilterPopover
                      isFiltering={!isEmptyOrNil(accountExecsValue)}
                      label="Assigned Sales People"
                    >
                      <ChooseByRoleCombo
                        label="Assigned Sales People"
                        reference={accountExecsRef}
                        setValue={setValue}
                        value={accountExecsValue}
                        handleValueChange={handleValueChange}
                        teamEnum={TeamEnum.Sales}
                        vars={{ isAccountExec: true }}
                        // @ts-ignore
                        control={control}
                        {...accountExecsProps}
                      />
                    </FilterPopover>
                    <Input
                      error={errors?.depositAmount?.message}
                      required
                      label="Amount"
                      type="number"
                      step="0.01"
                      {...register('depositAmount', validations.generic)}
                    />
                  </div>
                </div>
              </section>
            </fieldset>

            {showAssociatedClientError && (
              <AlertText
                className="mt-4 text-sm italic text-red"
                icon={MdError}
              >
                Job orders must have an associated company selected.
              </AlertText>
            )}
            {showAccountExecError && (
              <AlertText
                className="mt-4 text-sm italic text-red"
                icon={MdError}
              >
                Job orders must have an assigned sales person.
              </AlertText>
            )}
            <div className="-mb-4">
              <ClickyBtn
                as="text-icon"
                className="btn mood-orange"
                disabled={saving}
                type="submit"
              >
                {saving ? 'Saving' : 'Create Job Order'}
              </ClickyBtn>
            </div>
          </div>
        </div>
        {!isDialog && (
          <aside className="contain-xy mood-grey-lightest">
            <ul className="flex flex-col sm:pl-8 md:sticky md:top-24">
              <li>
                <ClickyBtn
                  as="text-icon"
                  className="btn mood-orange"
                  disabled={saving}
                  type="submit"
                >
                  {saving ? 'Saving' : 'Create Job Order'}
                </ClickyBtn>
              </li>
              <li className="mt-4">
                <ClickyBtn
                  as="text-icon"
                  className="text-sm font-700"
                  disabled={saving}
                  type="reset"
                >
                  Cancel Changes
                </ClickyBtn>
              </li>
              {!isEmptyOrNil(errors) && (
                <li>
                  <AlertText
                    aria-live="assertive"
                    className="mt-8 text-sm text-red"
                    icon={MdError}
                  >
                    <span>A validation error occurred.</span>
                  </AlertText>
                </li>
              )}
              {error != null && (
                <li className="mt-8">
                  <QueryErrorFallback kind="block" error={error} />
                </li>
              )}
            </ul>
          </aside>
        )}
      </form>
    </FormProvider>
  )
}

export default memo(JobPostingNewGigForm)
