import clsx from '@vangst/lib/clsx'
import ClickyLink, {
  ClickyType,
} from '../../../components/navigation/ClickyLink'

/**
 * Links in the `Footer` with a trailing bullet
 */
export default function FooterLink(props: ClickyType) {
  const { children, className, id, ...rest } = props
  const identity =
    id ||
    `footer-${(props.title || children?.toString())
      ?.toLowerCase()
      .replace(/\s/gi, '-')}`

  return (
    <li>
      <ClickyLink
        activate
        className={clsx(
          'inline-flex items-center gap-2',
          'after:text-[1.25em] after:font-700 after:content-["•"]',
          'after:-translate-y-0.5 after:scale-0 after:transition after:ease-bounce',
          'hover:after:scale-150 hover:after:delay-200 hocus:text-orange',
          'aria-[current=true]:text-orange aria-[current=true]:after:scale-150',
          className,
        )}
        id={identity}
        {...rest}
      >
        {children}
      </ClickyLink>
    </li>
  )
}
