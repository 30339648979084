/**
 * Joins 2 words with a separator
 *
 * @example
 * unionize('Denver', 'Colorado', ', ') // => 'Denver, Colorado'
 * unionize(undefined, 'Colorado', ', ') // => 'Colorado'
 */
const unionize = (
  first?: string | null,
  last?: string | null,
  separator = ' ',
) => [first, last].filter((f) => !!f?.length).join(separator)

export default unionize
