import clsx from '@vangst/lib/clsx'
import useElasticSearch from '@vangst/services/oogst/search/useElasticSearch'
import Routes from '@vangst/services/routes'
import { Fragment, memo, useEffect } from 'react'
import { EsResultFragment } from '../../../../packages/services/oogst/types'
import Spin from '../../components/assets/Spinner'
import useScroll from '../../components/hooks/useScroll'
import Avatar from '../../components/views/Avatar'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly term: string
  readonly mentionIndex: number
  readonly mentionClick: (event: any, user: EsResultFragment) => void
  readonly mentionRefs: any
  readonly pageSize: number
}

function MentionSearchRenderer(props: PropsType) {
  const { term, mentionIndex, mentionClick, mentionRefs, pageSize, ...rest } =
    props

  const { isFetching, subjects } = useElasticSearch('searchSlugNames', {
    first: pageSize,
    term,
  })
  const { scrollToHash } = useScroll()

  const isBusy = isFetching || subjects == null

  useEffect(() => {
    scrollToHash(`${mentionIndex}-suggestion`, 'center')
  }, [mentionIndex, scrollToHash])

  return (
    <div {...rest}>
      <p className="relative -mb-4">
        {isBusy && (
          <Spin className="absolute right-0 top-0" width={16} height={16} />
        )}
      </p>
      <div>
        {subjects?.map((page, index) => {
          return (
            <Fragment key={`elastic-search-page-${index}`}>
              <div className="absolute max-h-52 overflow-y-scroll rounded bg-white drop-shadow-md">
                {page?.map((user, i) => {
                  return (
                    <button
                      ref={mentionRefs[i]}
                      key={i}
                      id={`${i}-suggestion`}
                      className={clsx(
                        'z-20 my-2 flex w-full',
                        mentionIndex === i && 'bg-blue-dark',
                      )}
                      onClick={(event) => mentionClick(event, user)}
                    >
                      <Avatar
                        alt={'alt'}
                        className="my-1 ml-2 mr-1 size-10 bg-grey-dark text-white"
                        // @ts-ignore
                        src={user.avatarUrl || Routes.GENERIC_USER_AVATAR}
                        height={28}
                      />
                      <div
                        className={clsx(
                          'mt-1 p-2',
                          mentionIndex === i && 'text-white',
                        )}
                      >
                        {/* @ts-ignore */}
                        <span className="font-700">{user.name}</span> (
                        {user.slug})
                      </div>
                    </button>
                  )
                })}
              </div>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default memo(MentionSearchRenderer)
