import {
  CredentialFragment,
  JobPosting_RecommendedCredentialFragment,
} from '@vangst/services/oogst/types'
import { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import HiddenInput from '../../../components/forms/HiddenInput'
import ClickyLink from '../../../components/navigation/ClickyLink'
import SelectCredential from '../../member/forms/SelectCredential'

type PropsType = {
  readonly credentials: CredentialFragment[]
  readonly defaultValue?: JobPosting_RecommendedCredentialFragment
  readonly index: number
  readonly remove: () => void
}

function FieldsCredentialsJob(props: PropsType) {
  const { credentials, defaultValue, index, remove } = props
  const { formState, register } = useFormContext()
  const { ref: credentialIdRef, ...credentialIdRefProps } = register(
    `recommendedCredentials.${index}.credentialId`,
  )

  return (
    <div className="flex w-full items-center gap-2">
      <SelectCredential
        credentials={credentials}
        defaultValue={defaultValue?.credential?.id || undefined}
        error={formState?.errors?.credentialId?.message}
        hideLabel
        label="Choose credential"
        reference={credentialIdRef}
        {...credentialIdRefProps}
      />
      <HiddenInput
        defaultValue={''}
        {...register(`recommendedCredentials.${index}.id`)}
      />
      <ClickyLink
        as="icon"
        className="link-orange"
        icon={MdClose}
        onClick={remove}
        title="Remove"
      >
        Remove
      </ClickyLink>
    </div>
  )
}

// -------------------------------------

export default memo(FieldsCredentialsJob)
