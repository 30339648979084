import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import fetcher from '../fetcher'
import {
  FetcherError,
  NodesDocument,
  NodesQuery,
  NodesQueryVariables as NodesVars,
  useNodesQuery as useEndpoint,
} from '../types'

type NodesOptions = UseQueryOptions<NodesQuery, FetcherError, NodesQuery>

/**
 * @private
 * Retrieve the stringified `Nodes` query key used in the internal cache.
 *
 * @example
 * const key = getKey({ ids: ['id1', 'id2'] })
 * => ["Nodes",{ "ids": ["id1", "id2"]}]
 */
const getKey = useEndpoint.getKey

/**
 * @private
 * Retrieve the `Nodes` list from the queryClient's cache.
 */
function getCache(queryClient: QueryClient, variables: NodesVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<NodesQuery>(key)
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 * If no variables are passed all `Node` queries will be invalidated.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateNodes(queryClient, { ids: ['bb8675309', 'mk9000'] })
 * invalidateNodes(queryClient)
 */
function invalidateNodes(queryClient: QueryClient, variables?: NodesVars) {
  queryClient.invalidateQueries(variables ? getKey(variables) : ['Nodes'])
}

/**
/**
 * Prefetch and return the list of `Nodes` from a list of `ids`.
 *
 * @example
 * const nodes = await prefetchNodes(queryClient, { ids })
 */
async function prefetchNodes(queryClient: QueryClient, variables: NodesVars) {
  const key = getKey(variables)
  const fn = fetcher<NodesQuery, NodesVars>(NodesDocument, variables)
  await queryClient.prefetchQuery(key, fn)
  return getCache(queryClient, variables)
}

/**
 * Fetch a list of `Nodes` via the `nodes` API.
 *
 * @example
 * const { data } = useNodes({ ids: ['id1', 'id2'] })
 *
 * @TODO: Add optimistic Create|Update|Delete?
 * @TODO: Tune options
 */
function useNodes(variables: NodesVars, options?: NodesOptions) {
  return useEndpoint(variables, { keepPreviousData: true, ...options })
}

// -------------------------------------

export type { NodesOptions, NodesQuery, NodesVars }
export { invalidateNodes, prefetchNodes }
export default useNodes
