import Spinner from '../assets/Spinner'
import AlertText from './AlertText'

type PropsType = React.HTMLAttributes<HTMLSpanElement>

/**
 * Display the loading/fetching/busy state of a section, component or entire page.
 *
 * @example
 * <Busy />
 * <Busy>Authenticating</Busy>
 */
function Busy({ children, ...rest }: PropsType) {
  return (
    <AlertText icon={Spinner} role="status" {...rest}>
      {(children || 'Loading') + '...'}
    </AlertText>
  )
}

export default Busy
