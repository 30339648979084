import clsx from '@vangst/lib/clsx'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { memo } from 'react'
import VangstMark from '../../../components/assets/marks/VangstMark'
import ClickyLink from '../../../components/navigation/ClickyLink'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  as: 'one' | 'two'
}

function SearchPromotion(props: PropsType) {
  const { as, className, ...rest } = props
  const { authenticated } = useViewer()

  return (
    <div
      className={clsx(
        'relative flex h-80 w-full flex-col gap-4 rounded-md p-4 sm:col-span-2',
        as === 'one' && 'mood-green-white border border-green',
        as === 'two' && 'mood-blue-white border border-blue',
        className,
      )}
      {...rest}
    >
      {as === 'one' && authenticated === false && <PromoteSignUp />}
      {as === 'two' && authenticated === false && <PromoteTraining />}

      {as === 'one' && authenticated === true && <PromoteRecruiter />}
      {as === 'two' && authenticated === true && <PromoteTraining />}
    </div>
  )
}

const PromoteSignUp = () => (
  <>
    <Title>Create your Vangst profile</Title>
    <h3 className="font-600 capitalize">Become a Vangst Member Today!</h3>
    <p className="text-sm">
      Join the thousands of other cannabis enthusiasts who are part of our
      network. Make connections, find a job, build a career
    </p>
    <ul className="hidden list-inside list-disc text-sm sm:grid sm:grid-cols-2">
      <li>
        <span className="font-600 text-orange-medium">200,000</span> Vangst GIGs
        Filled
      </li>
      <li>
        <span className="font-600 text-orange-medium">2,500+</span> Senior-level
        positions filled
      </li>
      <li>
        <span className="font-600 text-orange-medium">3,000+</span> Jobs at
        Vangst.com
      </li>
      <li>
        <span className="font-600 text-orange-medium">1,500+</span> Top cannabis
        businesses
      </li>
    </ul>
    <ClickyLink
      as="text-icon"
      className="motion-east mt-auto max-w-76 hover:underline"
      href={Routes.SIGN_UP + '?utm=searchin'}
    >
      Join Vangst
    </ClickyLink>
  </>
)

const PromoteTraining = () => (
  <>
    <Title>Level Up Your Job Search</Title>
    <h3 className="font-600 capitalize">Check out Vangst Learn Now!</h3>
    <p className="text-sm">
      Vangst Learn isn’t just about learning, but applying that knowledge to the
      job search; whether you’re looking to get into the cannabis industry, or
      looking to get ahead.
    </p>
    <ClickyLink
      as="text-icon"
      className="motion-east mt-auto max-w-76 hover:underline"
      href={Routes.TRAININGS + '?utm=searchin'}
    >
      Browse Trainings
    </ClickyLink>
  </>
)

const PromoteRecruiter = () => (
  <>
    <Title>
      Harness the <span className="hidden sm:inline">Vangst</span> Network
    </Title>
    <h3 className="font-600 capitalize">
      Let Vangst help you find the right match!
    </h3>
    <p className="text-sm">
      Whether you're looking for that next career move or a short gig next week,
      Vangst recruiters bring their expertise, networks, and insider knowledge
      to help you in your job search.
    </p>
    <ClickyLink
      as="text-icon"
      className="motion-east mt-auto max-w-76 hover:underline"
      href={Routes.MEMBERS + '?isRecruiter=true&utm=searchin'}
    >
      View Vangst Recruiters
    </ClickyLink>
  </>
)

const Title = (props: React.HTMLAttributes<HTMLHeadingElement>) => (
  <h2 className="flex items-center gap-4 text-lg font-600 capitalize">
    <span className="flex size-12 items-center justify-center rounded-full bg-black">
      <VangstMark className="size-10 text-orange" />
    </span>
    <span>{props.children}</span>
  </h2>
)

export default memo(SearchPromotion)
