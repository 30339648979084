import clsx from '@vangst/lib/clsx'

export type LabelPropsType = React.HTMLAttributes<HTMLSpanElement> & {
  readonly hideLabel?: boolean
  readonly label: string
  readonly labelSubtext?: string
  readonly required?: boolean
}

/**
 * A text component used in conjunction with form elements.
 *
 * - Supports the `required` attribute which diplsays the `*`
 * - Displays `labelSubtext` in a lighter weight font tuned for mobile
 *
 * @example
 * <Label label="Label" />
 * <Label
 *   label="Required Label"
 *   labelSubtext="with Subtext"
 *   required
 * />
 */
function Label(props: LabelPropsType) {
  const { hideLabel, label, labelSubtext, required } = props
  return (
    <span className={clsx('sm:flex sm:gap-1', hideLabel && 'sr-only')}>
      <span
        className={clsx(
          'font-600 capitalize',
          required &&
            "after:left-1 after:ml-1 after:scale-125 after:text-red after:content-['*']",
        )}
      >
        {label}
      </span>
      {labelSubtext && (
        <span className="block font-400 sm:inline">{labelSubtext}</span>
      )}
    </span>
  )
}

export default Label
