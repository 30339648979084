import {
  BucketIdentifierUnion,
  getBucketByIdentifier,
  S3ErrorType,
  S3SuccessType,
} from '@vangst/services/aws'
import HttpError from '@vangst/services/HttpError'
import React from 'react'

enum Status {
  Destroy = 'DESTROY',
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

type AttachmentType = {
  readonly id?: string | null
  readonly blob?: File
  readonly error?: string
  readonly url?: string | null
}

type PropsType = {
  readonly attachment?: AttachmentType
  readonly attachments?: AttachmentType[]
  readonly bucketType?: BucketIdentifierUnion
  readonly reportInvalidType?: (state: boolean) => void
  readonly requiredType?: 'pdf' | 'image' | 'both'
}

type StateType = {
  readonly attachments: AttachmentType[]
}

type ActionType = {
  readonly payload: AttachmentType
  readonly type: Status
}

function reducerSingle(state: StateType, action: ActionType) {
  switch (action.type) {
    case Status.Destroy:
      return { ...state, attachments: [] }
    case Status.Pending:
    case Status.Failure:
    case Status.Success:
      return { ...state, attachments: [action.payload] }
  }
}

function reducerMultiple(state: StateType, action: ActionType) {
  switch (action.type) {
    case Status.Destroy: {
      const index = state.attachments.findIndex(
        (a) => a.id === action.payload.id,
      )
      if (index == null || index < 0) return state
      return {
        ...state,
        attachments: [
          ...state.attachments.slice(0, index),
          ...state.attachments.slice(index + 1),
        ],
      }
    }

    case Status.Pending:
      return { ...state, attachments: [...state.attachments, action.payload] }

    case Status.Failure:
    case Status.Success: {
      const index = state.attachments.findIndex(
        (a) => a.id === action.payload.id,
      )
      const attachments =
        index != null && index >= 0
          ? [
              ...state.attachments.slice(0, index),
              action.payload,
              ...state.attachments.slice(index + 1),
            ]
          : [...state.attachments, action.payload]
      return { ...state, attachments }
    }
  }
}

function useAttachmentz(props: PropsType) {
  const {
    attachment,
    attachments = [],
    reportInvalidType,
    requiredType,
    bucketType = 'defaultBucket',
  } = props
  const reducer = attachments != null ? reducerMultiple : reducerSingle
  const attached = attachment ? [attachment] : attachments
  const [state, dispatch] = React.useReducer(reducer, { attachments: attached })
  const bucket = getBucketByIdentifier(bucketType)
  const handleSelected = React.useCallback(
    async (e: React.SyntheticEvent<HTMLInputElement>) => {
      e.preventDefault()
      reportInvalidType != null && reportInvalidType(false)
      Array.from(e?.currentTarget.files || []).forEach(async (blob) => {
        const id = `${blob.name}-${Date.now()}`
        if (
          requiredType === 'pdf' &&
          blob?.type !== 'application/pdf' &&
          reportInvalidType != null
        ) {
          reportInvalidType(true)
          return
        }
        if (
          requiredType === 'image' &&
          !blob?.type.includes('image') &&
          reportInvalidType != null
        ) {
          reportInvalidType(true)
          return
        }
        if (
          requiredType === 'both' &&
          blob?.type !== 'application/pdf' &&
          !blob?.type.includes('image') &&
          reportInvalidType != null
        ) {
          reportInvalidType(true)
          return
        }
        dispatch({
          type: Status.Pending,
          payload: { id, blob, error: undefined, url: undefined },
        })
        await bucket()
          .uploadFile(blob)
          .then((data: S3SuccessType) => {
            dispatch({
              type: Status.Success,
              payload: { id, blob, error: undefined, url: data.location },
            })
          })
          .catch((err: S3ErrorType) => {
            const error = `${err.statusText} ${err.status}`
            dispatch({
              type: Status.Failure,
              payload: { id, blob, error, url: undefined },
            })
            throw new HttpError(500)
          })
      })
    },
    [reportInvalidType, bucket, requiredType],
  )

  const remove = React.useCallback((id: string) => {
    dispatch({ type: Status.Destroy, payload: { id } })
  }, [])

  return {
    attachment: state.attachments[0],
    attachments: state.attachments,
    handleSelected,
    remove,
  }
}

// -------------------------------------

export default useAttachmentz

// TODO:
// - Remove Attachment from S3
// - Add progress?
