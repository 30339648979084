import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import toBoolean from '@vangst/lib/toBoolean'
import toNumber from '@vangst/lib/toNumber'
import useCreateJobPosting from '@vangst/services/oogst/jobPosting/useCreateJobPosting'
import useUpdateJobPosting from '@vangst/services/oogst/jobPosting/useUpdateJobPosting'
import {
  AtsRecordTypeEnum,
  JobPostingAtsStatusEnum,
  JobPostingInput,
} from '@vangst/services/oogst/types'
import Routes from '@vangst/services/routes'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { serializeSlateForForm } from '../../editor/serializers'

type PropsType = {
  readonly displayname?: string
  readonly setShowAssociatedClientError?: (state: boolean) => void
  readonly setShowAccountExecError?: (state: boolean) => void
}

export default function useFormNewGigsJob(props: PropsType) {
  const { displayname, setShowAssociatedClientError, setShowAccountExecError } =
    props
  const { push } = useRouter()

  const pusher = useCallback(
    (id: string) => push(Routes.JOBS_DETAIL.replace(':id', id)),
    [push],
  )

  const methods = useForm<JobPostingInput>({
    defaultValues: {
      // @ts-ignore input shape differs from fragment shape
      associatedClient: displayname,
      description: serializeSlateForForm(''),
      gigsInfo: {
        // @ts-ignore
        kickoffCall: 'false',
      },
    },
  })

  const create = useCreateJobPosting({
    onSuccess: (data) => pusher(data?.createJobPosting.slug),
  })

  const update = useUpdateJobPosting({
    onSuccess: (data) => {
      pusher(data?.updateJobPosting.slug)
    },
  })

  const error = create.error || update.error
  const saving = create.isLoading || update.isLoading

  const submit = useCallback(
    async (input: JobPostingInput) => {
      if (
        isEmptyOrNil(input?.associatedClient) &&
        setShowAssociatedClientError != null
      ) {
        setShowAssociatedClientError(true)
        return
      }
      if (
        isEmptyOrNil(input?.accountExecIds) &&
        setShowAccountExecError != null
      ) {
        setShowAccountExecError(true)
        return
      }
      const nextInput = {
        ...input,
        atsRecordType: AtsRecordTypeEnum.BoutiqueGigs,
        atsStatus: JobPostingAtsStatusEnum.Pending,
        isPublished: false,
        isRemote: true,
        accountExecIds: input?.accountExecIds,
        quantity: toNumber(input?.quantity),
        depositAmount: toNumber(input?.depositAmount),
        gigsInfo: {
          kickoffCall: toBoolean(input?.gigsInfo?.kickoffCall),
        },
      }

      nextInput.client = { displayname: 'vangst-recruiting' }
      nextInput.associatedClient = { displayname }

      create.mutate({
        input: {
          ...nextInput,
        },
      })
    },
    [
      create,
      displayname,
      setShowAssociatedClientError,
      setShowAccountExecError,
    ],
  )

  return {
    error,
    methods,
    saving,
    submit,
  }
}
