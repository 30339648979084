/**
 * Add a leading zero to a counter below 10
 *
 * @example
 * leadingZero(6) // => '06'
 */
function leadingZero(count?: number) {
  return count != null ? `${count < 10 ? '0' : ''}${count}` : ''
}

export default leadingZero
