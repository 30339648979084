import clsx from '@vangst/lib/clsx'
import { forwardRef } from 'react'
import ClickyLink, {
  ClickyType,
} from '../../../components/navigation/ClickyLink'

/**
 * The icon buttons used within the Navbar's toolbar
 *
 * This button is used as a `Menu.Button`, hence the `forwardRef`
 *
 * @see [headlessui Menu component](https://headlessui.com/react/menu)
 *
 * @example
 * <NavbarClickyIcon aria-expanded="true" icon={MdClose}>Expanded</NavbarClickyIcon>
 * <NavbarClickyIcon icon={MdMenu}>Collapsed</NavbarClickyIcon>
 *
 * <Menu.Button as={Fragment}>
 *   <NavbarClickyIcon icon={MdPerson}>
 *     Profile
 *   </NavbarClickyIcon>
 * </Menu.Button>
 */
const NavbarClickyIcon = forwardRef<HTMLElement, ClickyType>(
  function NavbarClickyIcon(props: ClickyType, ref) {
    const { className, ...rezt } = props
    const rest = { ...rezt, ref }
    return (
      <ClickyLink
        activate
        as="icon"
        className={clsx(
          'h-full w-12 justify-center border-r border-grey-light hocus:bg-green sm:w-16',
          'aria-[expanded=true]:bg-orange aria-[expanded=true]:text-white',
          className,
        )}
        {...rest}
      />
    )
  },
)

export default NavbarClickyIcon
