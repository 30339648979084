import { IHttpError } from '@vangst/services/HttpError'
import { FetcherError as OogstError } from '@vangst/services/oogst/types'
import dynamic from 'next/dynamic'
import { memo } from 'react'

type ErrorTypeUnion = Error | IHttpError | OogstError

type PropsType = {
  readonly error: ErrorTypeUnion
  readonly kind?: 'block' | 'page' | 'status'
}

const components = {
  ClientError: dynamic(() => import('./AlertClientError')),
  HttpError: dynamic(() => import('./AlertHttpError')),
  OogstError: dynamic(() => import('./AlertOogstError')),
}

function Fallback(props: PropsType) {
  const { error, kind = 'status' } = props
  return (error as OogstError).origin === 'oogst' ? (
    <components.OogstError error={error as OogstError} kind={kind} />
  ) : (error as IHttpError).code != null ? (
    <components.HttpError error={error as IHttpError} kind={kind} />
  ) : (
    <components.ClientError error={error} kind={kind} />
  )
}

const QueryErrorBlockFallback = memo(function QueryErrorBlockFallback(
  props: PropsType,
) {
  return <Fallback {...props} kind="block" />
})

const QueryErrorPageFallback = memo(function QueryErrorPageFallback(
  props: PropsType,
) {
  return <Fallback {...props} kind="page" />
})

const QueryErrorStatusFallback = memo(function QueryErrorStatusFallback(
  props: PropsType,
) {
  return <Fallback {...props} kind="status" />
})

function QueryErrorFallback(props: PropsType) {
  return props.kind === 'block' ? (
    <QueryErrorBlockFallback {...props} />
  ) : props.kind === 'page' ? (
    <QueryErrorPageFallback {...props} />
  ) : (
    <QueryErrorStatusFallback {...props} />
  )
}

export { QueryErrorPageFallback, QueryErrorStatusFallback }
export default memo(QueryErrorFallback)

// ---------------------

export const formatMessage = (message: string) => {
  const nextMsg =
    'Please copy and paste the rest of this error to support@vangst.com if you need help: ' +
    message
  if (message?.includes('rawLocation')) {
    return (
      'There is a problem with the address you entered - please make sure you have updated your address to a full street address before saving. ' +
      nextMsg
    )
  }
  return message
}
