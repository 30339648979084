import {
  EducationLevelEnum,
  MemberEducationFragment,
} from '@vangst/services/oogst/types'
import ClickyLink from '../../../components/navigation/ClickyLink'
import ZeroState from './ZeroState'

type PropsType = {
  education: MemberEducationFragment[]
  href: string
  isCurrentUser?: boolean
  showEmptyState?: boolean
}

export const educationLevelFormatting = Object.freeze({
  [EducationLevelEnum.NoFormalEducation]: 'No Formal Education',
  [EducationLevelEnum.AssociateSDegree]: "Associate's Degree",
  [EducationLevelEnum.PrimaryEducation]: 'Primary Education',
  [EducationLevelEnum.SecondaryEducationOrHighSchool]: 'Secondary Education',
  [EducationLevelEnum.Ged]: 'GED',
  [EducationLevelEnum.VocationalQualification]: 'Vocational Qualification',
  [EducationLevelEnum.BachelorSDegree]: "Bachelor's Degree",
  [EducationLevelEnum.MasterSDegree]: "Master's Degree",
  [EducationLevelEnum.DoctorateOrHigher]: 'Doctorate or Higher',
} as const)

export default function Education(props: PropsType) {
  const { href, isCurrentUser, education, showEmptyState } = props

  return (
    <>
      {!showEmptyState ? (
        education?.map((education) => (
          <div key={education.id} className="flow-y-xs">
            <h2 className="heading-sm">{education?.school}</h2>
            {education.educationLevel && (
              <h3 className="-mt-2">
                {educationLevelFormatting[education.educationLevel]} -{' '}
                {education?.degree}
              </h3>
            )}
            <p>{education?.yearsAttended} Years Attended</p>
          </div>
        ))
      ) : (
        <ZeroState
          title={
            isCurrentUser
              ? '! Some roles and employers look for this information'
              : undefined
          }
        >
          {isCurrentUser ? (
            <p className="flow-y-sm italic text-grey-dark">
              <span>
                Many employers do not require a degree but providing your
                education history allows potential employers to gain more
                insight into your profile and can increase your odds of being
                contacted.
              </span>
              <ClickyLink href={href} className="link-orange underline">
                Include your education history!
              </ClickyLink>
            </p>
          ) : (
            <p className="italic text-grey-dark">
              No information has been provided by this user yet. Feel free to
              connect with them to inquire personally if you’re interested.
            </p>
          )}
        </ZeroState>
      )}
    </>
  )
}
