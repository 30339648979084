import { QueryClient, UseMutationOptions } from '@tanstack/react-query'
import {
  FetcherError,
  LoginMutation,
  LoginMutationVariables,
  useLoginMutation,
} from '../types'
import { initializeViewer, invalidateViewer } from './useViewer'

type TOptions = UseMutationOptions<
  LoginMutation,
  FetcherError,
  LoginMutationVariables
>

function useLogin(
  queryClient: QueryClient,
  options?: TOptions,
  skipRedirect?: boolean,
) {
  return useLoginMutation({
    onSuccess: (data) => {
      invalidateViewer(queryClient)
      queryClient.clear()
      initializeViewer(data.loginUser, skipRedirect)
    },
    ...options,
  })
}

export default useLogin
