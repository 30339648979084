import {
  JobApplicationStatusEnum,
  JobPostingAtsStatusEnum,
  OnboardingStatusEnum,
  PlacementStatusEnum,
} from '../types'

export const orderedPlacementStatus: Record<PlacementStatusEnum, string> = {
  [PlacementStatusEnum.Pending]: 'Pending',
  [PlacementStatusEnum.PendingNeedsInterview]: 'Schedule Interview',
  [PlacementStatusEnum.Approved]: 'Approved',
  [PlacementStatusEnum.Denied]: 'Denied',
  [PlacementStatusEnum.OnboardingScheduled]: 'Onboarding Scheduled',
  [PlacementStatusEnum.Onboarding]: 'Onboarding',
  [PlacementStatusEnum.OnboardingFinished]: 'Onboarding Finished',
  [PlacementStatusEnum.Active]: 'Active',
  [PlacementStatusEnum.Converted]: 'Converted',
  [PlacementStatusEnum.Completed]: 'Completed',
  [PlacementStatusEnum.MovedToDifferentRole]: 'Different role',
  [PlacementStatusEnum.Ncns]: 'NCNS',
  [PlacementStatusEnum.Released]: 'Released',
  [PlacementStatusEnum.Quit]: 'Quit',
}

export const orderedJobApplicationStatus: Record<
  JobApplicationStatusEnum,
  string
> = {
  [JobApplicationStatusEnum.Lead]: 'Lead', // only used for ingested jobs
  [JobApplicationStatusEnum.Add]: 'Add', // status whens sourced / added to job not applied directly
  [JobApplicationStatusEnum.New]: 'New', // status when someone applies themself to a job
  [JobApplicationStatusEnum.ScreeningScheduled]: 'Screening Scheduled',
  [JobApplicationStatusEnum.Rejected]: 'Rejected',
  [JobApplicationStatusEnum.Benched]: 'Benched',
  [JobApplicationStatusEnum.Submitted]: 'Submitted',
  [JobApplicationStatusEnum.Offer]: 'Offer',
}

export const allApplicationStatuses = Object.keys(
  orderedJobApplicationStatus,
) as JobApplicationStatusEnum[]

export const orderedOnboardingStatus: Record<OnboardingStatusEnum, string> = {
  [OnboardingStatusEnum.Saved]: 'Saved',
  [OnboardingStatusEnum.Eligible]: 'Eligible',
  [OnboardingStatusEnum.Unscreened]: 'Unscreened',
  [OnboardingStatusEnum.ScreeningScheduled]: 'Screening Scheduled',
  [OnboardingStatusEnum.Screening]: 'Screening',
  [OnboardingStatusEnum.Onboarding]: 'Onboarding',
  [OnboardingStatusEnum.Onboarded]: 'Onboarded',
  [OnboardingStatusEnum.Offboarded]: 'Offboarded',
  [OnboardingStatusEnum.Rejected]: 'Rejected',
  [OnboardingStatusEnum.Terminated]: 'Terminated',
}

export const orderedJobPostingAtsStatuses: Record<
  JobPostingAtsStatusEnum,
  string
> = {
  [JobPostingAtsStatusEnum.NoStage]: 'No Stage',
  [JobPostingAtsStatusEnum.Hold]: 'Hold',
  [JobPostingAtsStatusEnum.Pending]: 'Pending',
  [JobPostingAtsStatusEnum.ReadyToPublish]: 'Ready To Publish',
  [JobPostingAtsStatusEnum.Sourcing]: 'Sourcing',
  [JobPostingAtsStatusEnum.AdditionalInterviews]: 'Additional Interviews',
  [JobPostingAtsStatusEnum.ActivePlacements]: 'Active Placements',
  [JobPostingAtsStatusEnum.Screening]: 'Screening',
  [JobPostingAtsStatusEnum.Interviewing]: 'Interviewing',
  [JobPostingAtsStatusEnum.FirstInterview]: 'First Interview',
  [JobPostingAtsStatusEnum.FinalInterview]: 'Final Interview',
  [JobPostingAtsStatusEnum.Onboarding]: 'Onboarding',
  [JobPostingAtsStatusEnum.Assigned]: 'Assigned',
  [JobPostingAtsStatusEnum.Complete]: 'Complete',
  [JobPostingAtsStatusEnum.JobOffer]: 'Job Offer',
  [JobPostingAtsStatusEnum.OfferAccepted]: 'Offer Accepted',
  [JobPostingAtsStatusEnum.Submitals]: 'Submitals',
  [JobPostingAtsStatusEnum.ClosedLost]: 'Closed Lost',
  [JobPostingAtsStatusEnum.WinsPast]: 'Wins Past',
}

export const activeGigsAtsStatuses = [
  JobPostingAtsStatusEnum.ReadyToPublish,
  JobPostingAtsStatusEnum.Sourcing,
  JobPostingAtsStatusEnum.Screening,
  JobPostingAtsStatusEnum.Submitals,
  JobPostingAtsStatusEnum.Interviewing,
  JobPostingAtsStatusEnum.FirstInterview,
  JobPostingAtsStatusEnum.AdditionalInterviews,
  JobPostingAtsStatusEnum.FinalInterview,
  JobPostingAtsStatusEnum.JobOffer,
  JobPostingAtsStatusEnum.OfferAccepted,
  JobPostingAtsStatusEnum.Onboarding,
  JobPostingAtsStatusEnum.Assigned,
  JobPostingAtsStatusEnum.ActivePlacements,
]
