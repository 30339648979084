import { formatReadableDateWords } from '@vangst/lib/formatDates'
import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import { MemberExperienceFragment } from '@vangst/services/oogst/types'
import ClickyLink from '../../../components/navigation/ClickyLink'
import ZeroState from './ZeroState'

type PropsType = {
  href: string
  isCurrentUser?: boolean
  experiences: MemberExperienceFragment[]
  readPermissions?: boolean
  resumeUrl?: string
  showEmptyState?: boolean
}

export default function Employment(props: PropsType) {
  const {
    href,
    isCurrentUser,
    experiences,
    readPermissions,
    resumeUrl,
    showEmptyState,
  } = props

  return (
    <>
      {!showEmptyState ? (
        <div className="flow-y-sm">
          {!isEmptyOrNil(resumeUrl) && readPermissions && (
            <ClickyLink
              className="link-orange font-700 underline"
              href={resumeUrl}
            >
              View Resume
            </ClickyLink>
          )}
          {experiences?.map((experience) => {
            const start =
              experience?.startDate != null
                ? `${formatReadableDateWords(new Date(experience?.startDate))}`
                : null
            const end =
              experience?.endDate != null
                ? `- ${formatReadableDateWords(new Date(experience?.endDate))}`
                : '- Unknown End Date'
            const date = `${start} ${!experience.current ? end : '- Present'}`
            return (
              <div key={experience.id} className="flow-y-xs">
                <h2 className="heading-sm">{experience?.companyName}</h2>
                <h3 className="-mt-2">{experience?.jobTitle}</h3>
                {start != null && <span>{date}</span>}
                <p>{experience?.responsibilities}</p>
              </div>
            )
          })}{' '}
        </div>
      ) : (
        <ZeroState
          title={
            isCurrentUser
              ? '! Experience is required for one-click apply:'
              : undefined
          }
        >
          {isCurrentUser ? (
            <p className="flow-y-sm italic text-grey-dark">
              <span>
                Provide the accomplishments you are most proud of, things you
                know, and what you’re looking to do next. Confidence is key, but
                bravado is a turn-off. At the end of the day, the more detailed
                your proof points, the better your chances.
              </span>
              <ClickyLink href={href} className="link-orange underline">
                Build out your work experience, upload your resume, or both!
              </ClickyLink>
            </p>
          ) : (
            <p className="italic text-grey-dark">
              Please connect with this user to inquire personally if you’re
              interested in work experience.
            </p>
          )}
        </ZeroState>
      )}
    </>
  )
}
