import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import { invalidateCompany } from '../company/useCompany'
import { invalidateCompanyJobApplications } from '../company/useCompanyJobApplications'
import { invalidateCompanyJobPostings } from '../company/useCompanyJobPostings'
import {
  DeleteJobPostingMutation,
  DeleteJobPostingMutationVariables,
  FetcherError,
  useDeleteJobPostingMutation,
} from '../types'
import useViewer, { invalidateViewer } from '../viewer/useViewer'
import { invalidateJobPosting } from './useJobPosting'

type DeleteOptions = UseMutationOptions<
  DeleteJobPostingMutation,
  FetcherError,
  DeleteJobPostingMutationVariables
>

/**
 * Deletes an existing `JobPosting`.
 *
 * When overriding `onSettled` be sure to provide necessary invalidations.
 *
 * @example
 * const deleteJobPosting = useDeleteJobPosting({onSuccess: () => push(url)})
 * const deleteJobPosting.mutate({ input: {id: '8675309'} })
 *
 * @TODO Invalidate searchJobPostings?
 * @TODO Call optimistic Deletes?
 */
function useDeleteJobPosting(options: DeleteOptions) {
  const { viewer } = useViewer()
  const queryClient = useQueryClient()

  return useDeleteJobPostingMutation({
    onSettled: (data) => {
      const id = data?.deleteJobPosting.id
      const displayname = viewer?.nameOrId

      if (id) {
        invalidateJobPosting(queryClient, { id })
        invalidateCompanyJobApplications(queryClient)
      }
      if (displayname) {
        invalidateCompany(queryClient, { displayname })
        invalidateCompanyJobPostings(queryClient, { displayname })
      }
      invalidateViewer(queryClient)
    },
    ...options,
  })
}

export default useDeleteJobPosting
