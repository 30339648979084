/**
 * Converts 2 words into initials
 *
 * letterize('Mitch', 'Kramer') // => 'MK'
 * letterize('Acme') // => 'A'
 */
function letterize(first?: string, last?: string) {
  return `${(first && first[0]) || ''}${(last && last[0]) || ''}`
}

export default letterize
