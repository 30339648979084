import {
  Kontext_ClientFragment,
  Kontext_JobApplicationFragment,
  Kontext_JobPostingFragment,
  Kontext_MemberFragment,
  Kontext_PlacementFragment,
} from '@vangst/services/oogst/types'
import {
  MdAdsClick,
  MdHelpOutline,
  MdOutlineBadge,
  MdOutlineContactPage,
  MdOutlineDescription,
  MdOutlineMessage,
  MdOutlinePerson,
  MdOutlineStore,
} from 'react-icons/md'

// type ChunkTypeUnion = ChatMessageFragment | Kontext_ClientFragment | Kontext_JobApplicationFragment | Kontext_JobPostingFragment | Kontext_PlacementFragment | Kontext_MemberFragment
function useNodesActivityFactory() {
  const generateDetails = (typename: string, chunk: any) => {
    if (typename === 'ChatMessage') {
      return {
        type: 'ChatMessage',
        id: chunk?.id,
        icon: MdOutlineMessage,
        uri: undefined,
      }
    }

    if (typename === 'Client') {
      const chunkz = chunk as Kontext_ClientFragment
      return {
        type: 'Client',
        id: chunkz?.displayname,
        icon: MdOutlineStore,
        uri: chunkz?.uri,
      }
    }

    if (typename === 'JobApplication') {
      const chunkz = chunk as Kontext_JobApplicationFragment
      return {
        type: 'JobApplication',
        id: chunkz?.user?.username,
        icon: MdOutlineContactPage,
        uri: chunkz?.uri,
      }
    }

    if (typename === 'JobPosting') {
      const chunkz = chunk as Kontext_JobPostingFragment
      return {
        type: 'JobPosting',
        id: chunkz?.slug,
        icon: MdOutlineDescription,
        uri: chunkz?.uri,
      }
    }

    if (typename === 'Placement') {
      const chunkz = chunk as Kontext_PlacementFragment
      return {
        type: 'Placement',
        id: chunkz?.user?.username,
        icon: MdOutlineBadge,
        uri: chunkz?.uri,
      }
    }

    if (typename === 'User') {
      const chunkz = chunk as Kontext_MemberFragment
      return {
        type: 'User',
        id: chunkz?.username,
        icon: MdOutlinePerson,
        uri: chunkz?.uri,
      }
    }

    if (typename == null) {
      return {
        type: 'Note',
        // @ts-ignore
        id: chunk?.id ?? 'No ID',
        icon: MdAdsClick,
        uri: undefined,
      }
    }

    return {
      type: `UNHANDLED: ${typename}`,
      // @ts-ignore
      id: chunk?.id,
      icon: MdHelpOutline,
      uri: undefined,
    }
  }
  return { generateDetails }
}

export default useNodesActivityFactory
