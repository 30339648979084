import { BlogPostFragment } from '@vangst/services/graphcms/types'
import { CompanyFragment } from '@vangst/services/oogst/company/useCompany'
import { JobPostingFragment } from '@vangst/services/oogst/jobPosting/useJobPosting'
import { MemberFragment } from '@vangst/services/oogst/member/useMember'
import {
  CredentialFragment,
  TrainingFragment,
} from '@vangst/services/oogst/types'
import { memo } from 'react'
import CompanyRenderer from '../company/CompanyRenderer'
import TrainingRenderer from '../course/TrainingRenderer'
import JobPostingRenderer from '../job/JobPostingRenderer'
import MemberRenderer from '../member/MemberRenderer'

export const ViewTypeMap = {
  infinite: ['card', 'face-card', 'table-row'],
  preview: ['avatar', 'slip', 'card', 'face-card', 'table-row'],
  sidebar: ['avatar', 'slip', 'card', 'face-card', 'table-row'],
  lanes: ['slip', 'face-card'],
}

export type AtTypeUnion = 'infinite' | 'preview' | 'sidebar'

export type AsTypeUnion =
  | 'avatar'
  | 'card'
  | 'chavatar'
  | 'detail'
  | 'face-card'
  | 'header'
  | 'menuitem'
  | 'slip'
  | 'table-row'

type RendererTypesUnion =
  | 'BlogPost'
  | 'Client'
  | 'JobPosting'
  | 'User'
  | 'Credential'

type RendererFragments =
  | CredentialFragment
  | BlogPostFragment
  | CompanyFragment
  | JobPostingFragment
  | MemberFragment

type PropsType = React.HtmlHTMLAttributes<HTMLElement> & {
  readonly as: AsTypeUnion
  readonly enabled: boolean
  readonly initialData?: RendererFragments
  readonly label?: string
  readonly slug: string
  readonly type: RendererTypesUnion
}
function RendererFactory(props: PropsType) {
  const { as, enabled = true, initialData, label, slug, type, ...rest } = props
  if (type === 'JobPosting') {
    return (
      <JobPostingRenderer
        as={as}
        label={label}
        variables={{ id: slug }}
        options={{
          enabled,
          initialData:
            initialData?.__typename === 'JobPosting'
              ? { getJobPosting: initialData }
              : undefined,
        }}
        {...rest}
      />
    )
  }
  if (type === 'Credential') {
    const credential = initialData as CredentialFragment
    return (
      <TrainingRenderer
        key={initialData?.id}
        as={as}
        training={credential?.training as TrainingFragment}
      />
    )
  }
  if (type === 'Client') {
    return (
      <CompanyRenderer
        as={as}
        variables={{ displayname: slug }}
        options={{
          enabled,
          initialData:
            initialData?.__typename === 'Client'
              ? { getClient: initialData }
              : undefined,
        }}
        {...rest}
      />
    )
  }
  if (type === 'User') {
    return (
      <MemberRenderer
        as={as}
        variables={{ username: slug }}
        options={{
          enabled,
          initialData:
            initialData?.__typename === 'User'
              ? { getUser: initialData }
              : undefined,
        }}
        {...rest}
      />
    )
  }
  return null
}

// -------------------------------------

export type { RendererFragments, RendererTypesUnion }
export default memo(RendererFactory)
