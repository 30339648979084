import { SalaryRangeInput } from '@vangst/services/oogst/types'
import { memo } from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'
import Input from '../../../components/forms/Input'
import Label from '../../../components/forms/Label'
import validations from '../../application/validations'
import SelectJobSalaryPeriod from './SelectJobSalaryPeriod'

type ErrorsType = {
  readonly maxValue?: FieldError
  readonly minValue?: FieldError
  readonly payPeriod?: FieldError
}

type PropsType = {
  readonly defaultValue?: SalaryRangeInput
  readonly errors?: ErrorsType
  readonly name: string
  readonly payPeriodRequired: boolean
  readonly unpublishedJobOrder?: boolean
  readonly register: UseFormRegister<any>
}

function FieldsSalaryRange(props: PropsType) {
  const {
    defaultValue,
    errors,
    name,
    payPeriodRequired,
    unpublishedJobOrder = false,
    register,
  } = props
  const { ref: salaryPeriodRef, ...salaryPeriodProps } = register(
    `${name}.payPeriod`,
    payPeriodRequired ? validations?.generic : undefined,
  )

  const { ref: salaryMinValueRef, ...salaryMinValueProps } = register(
    `${name}.minValue`,
    !unpublishedJobOrder ? validations.generic : undefined,
  )

  const { ref: salaryMaxValueRef, ...salaryMaxValueProps } = register(
    `${name}.maxValue`,
  )

  return (
    <div>
      <Label required label="Salary Range" />
      <div className="mt-4 grid content-start gap-4 sm:grid-cols-3">
        <Input
          defaultValue={defaultValue?.minValue}
          error={errors?.minValue?.message}
          label="Minimum"
          min={1}
          type="number"
          step="0.01"
          {...salaryMinValueProps}
          name={`${name}.minValue`}
          required
        />
        <Input
          defaultValue={defaultValue?.maxValue}
          error={errors?.maxValue?.message}
          label="Maximum"
          min={1}
          type="number"
          step="0.01"
          {...salaryMaxValueProps}
          name={`${name}.maxValue`}
        />
        <SelectJobSalaryPeriod
          error={errors?.payPeriod?.message}
          label="Pay Rate"
          required={payPeriodRequired}
          reference={salaryPeriodRef}
          {...salaryPeriodProps}
          name={`${name}.payPeriod`}
        />
      </div>
    </div>
  )
}

// -------------------------------------

export default memo(FieldsSalaryRange)
