import { MemberConnectionsVars } from '@vangst/services/oogst/member/useMemberConnections'
import Routes from '@vangst/services/routes'
import ClickyLink from '../../../components/navigation/ClickyLink'
import MemberConnectionsGrid from '../MemberConnectionsGrid'
import NonZeroState from './NonZeroState'
import ZeroState from './ZeroState'

type PropsType = {
  href?: string
  numConnections: number
  showEmptyState?: boolean
  vars: MemberConnectionsVars
}

export default function Connections(props: PropsType) {
  const { href, numConnections, showEmptyState, vars } = props
  return (
    <>
      {!showEmptyState ? (
        <NonZeroState title="Connections" subText={`(${numConnections}):`}>
          <li className="ml-4 text-sm font-600">
            <ClickyLink
              href={href ?? ''}
              className="bullet link-orange underline"
            >
              View all connections
            </ClickyLink>
          </li>
          <li className="mt-4">
            <MemberConnectionsGrid
              as="avatar"
              at="preview"
              classNameList="grid-avatars !min-h-12"
              take={12}
              variables={vars}
            />
          </li>
        </NonZeroState>
      ) : (
        <ZeroState title="! Connections" subText="(0):" border>
          <p className="italic">
            <ClickyLink href={Routes.MEMBERS} className="link-orange underline">
              Search the Vangst network
            </ClickyLink>{' '}
            and invite other members to connect. <br />
            <ClickyLink
              href={Routes.COMPANIES}
              className="link-orange underline"
            >
              Find and follow companies that interest you.
            </ClickyLink>
          </p>
        </ZeroState>
      )}
    </>
  )
}
