import clsx from '@vangst/lib/clsx'
import { CompanyComputed } from '@vangst/services/oogst/company/useCompany'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly company?: CompanyComputed
  readonly title: string
  readonly uri: string
}

function CompanyAvatar(props: PropsType) {
  const { company, title, uri } = props

  return (
    <ClickyLink
      className={clsx(
        'size-12 ease-bounce hocus:scale-105',
        company == null && 'scale-90',
        props.className,
      )}
      href={uri}
      title={title}
    >
      <Avatar
        alt={company?.initials ?? '...'}
        className="mood-grey"
        src={company?.avatarUrl || undefined}
      />
    </ClickyLink>
  )
}

export default CompanyAvatar
