import clsx from '@vangst/lib/clsx'
import {
  dateAndTimeToWords,
  getDynamicTimestamp,
} from '@vangst/lib/formatDates'
import { ChatMessageConnectionFragment } from '@vangst/services/oogst/types'
import Routes from '@vangst/services/routes'
import { HTMLAttributes } from 'react'
import { MdComment } from 'react-icons/md'
import ClickyLink from '../../components/navigation/ClickyLink'
import Avatar from '../../components/views/Avatar'
import { serializeJsonToReact } from '../editor/serializers'
import ChatPromotion from './ChatPromotion'
import useMessages from './useMessages'

type PropsType = HTMLAttributes<HTMLUListElement> & {
  readonly isWidget: boolean
  readonly messageConnection: ChatMessageConnectionFragment
  readonly viewerId: string
}

// TODO: DRY this up with MessagesDetail & move logic to derived data (initials, chatbuddy etc)
function MessagesList(props: PropsType) {
  const { className, isWidget, messageConnection, viewerId } = props
  const { handleSelectThread, recipient } = useMessages({ isWidget })
  const messages = messageConnection.edges ?? []

  const isZero = messages != null && messages.length === 0

  if (isZero && isWidget && recipient == null) {
    return <ChatPromotion />
  }

  if (isZero && !isWidget && recipient == null) {
    return (
      <span className="flex h-88 items-center justify-center text-grey-light">
        <MdComment />
      </span>
    )
  }

  return (
    <ul
      key={recipient?.id}
      data-human={recipient?.id}
      className={clsx('flex flex-col overflow-y-auto text-sm', className)}
    >
      {messages.map((m) => {
        if (m?.node == null) return
        const message = m.node
        const messageId = message?.id
        const chatBuddy =
          message?.recipient.id === viewerId
            ? message?.sender
            : message?.recipient
        const viewerIsSender = message.sender.id === viewerId
        const isUnread = !viewerIsSender && !message.recipientRead
        const dateTime = dateAndTimeToWords(m.node.updatedAt)

        const ClickyMessage = () => (
          <ClickyLink
            className="w-full p-2 text-left"
            onClick={() => handleSelectThread(chatBuddy)}
          >
            <div className="flex items-start justify-start gap-2">
              <Avatar
                className="size-12"
                alt={`${chatBuddy.firstName[0]}${chatBuddy.lastName[0]}`}
                src={chatBuddy?.avatarUrl}
              />

              <div className="flex grow flex-col content-start">
                <span className="flex justify-between">
                  <span className="flex items-center justify-start gap-2">
                    <span className="text-sm font-600">
                      {`${chatBuddy?.firstName} ${chatBuddy?.lastName}`}
                    </span>

                    <span
                      className="text-[12px] font-400 text-grey-dark"
                      title={`${dateTime.date} ${dateTime.time}`}
                    >
                      {getDynamicTimestamp(
                        new Date(),
                        new Date(message.updatedAt),
                      )}
                    </span>
                  </span>
                  <span
                    className={clsx(
                      'size-2 rounded-full',
                      isUnread ? 'block bg-blue' : 'hidden',
                    )}
                  />
                </span>
                <div className="flow-x-xs">
                  <span
                    className={clsx(
                      'my-2 mr-2 min-w-12 truncate',
                      isUnread && 'font-600',
                    )}
                  >
                    {viewerIsSender ? 'You' : message?.sender?.firstName}:
                  </span>
                  <div className="max-h-11 overflow-y-hidden font-400">
                    {serializeJsonToReact(message?.slateMessage)}
                  </div>
                </div>
              </div>
            </div>
          </ClickyLink>
        )

        const Message = () => (
          <div className="w-full p-2 text-left">
            <div className="flex items-start justify-start gap-2">
              <Avatar
                className="size-12"
                alt={`${chatBuddy} Avatar`}
                src={chatBuddy.avatarUrl ?? Routes.GENERIC_USER_AVATAR}
              />

              <div className="flex grow flex-col content-start">
                <span className="flex justify-between">
                  <span className="flex items-center justify-start gap-2">
                    <span className="text-sm font-600">
                      {`${chatBuddy?.firstName} ${chatBuddy?.lastName}`}
                    </span>

                    <span
                      className="text-[12px] font-400 text-grey-dark"
                      title={`${dateTime.date} ${dateTime.time}`}
                    >
                      {getDynamicTimestamp(
                        new Date(),
                        new Date(message.updatedAt),
                      )}
                    </span>
                  </span>
                  <span
                    className={clsx(
                      'size-2 rounded-full',
                      isUnread ? 'block bg-blue' : 'hidden',
                    )}
                  />
                </span>
                <div className="flow-x-xs">
                  <span
                    className={clsx(
                      'my-2 mr-2 min-w-12 truncate',
                      isUnread && 'font-600',
                    )}
                  >
                    {viewerIsSender ? 'You' : message?.sender?.firstName}:
                  </span>
                  <div className="max-h-11 overflow-y-hidden font-400">
                    {serializeJsonToReact(message?.slateMessage)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

        return (
          <li
            key={`preview-${messageId}`}
            className={clsx(
              'list-none border-b border-b-grey-mid text-sm hover:mood-green',
              recipient?.id === chatBuddy?.id
                ? 'mood-grey-light'
                : 'mood-white',
            )}
          >
            {handleSelectThread != null ? <ClickyMessage /> : <Message />}
          </li>
        )
      })}
    </ul>
  )
}

export default MessagesList
