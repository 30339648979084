import clsx from '@vangst/lib/clsx'
import {
  MemberComputed,
  MemberOptions,
  MemberVars,
} from '@vangst/services/oogst/member/useMember'
import { PlacementStatusEnum } from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Avatar from '../../../components/views/Avatar'
import MemberActionsMenu from '../navigation/MemberActionsMenu'
import MemberStatsuses from './MemberStatuses'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly jobPostingId?: string
  readonly member?: MemberComputed
  readonly title: string
  readonly uri: string
  readonly variables: MemberVars
  readonly options?: MemberOptions
}

function MemberSlip(props: PropsType) {
  const { viewer } = useViewer()
  const {
    className,
    jobPostingId,
    member,
    title,
    uri,
    variables,
    options,
    ...rest
  } = props
  const loading = member == null ? '•••' : undefined

  const highlight =
    viewer?.permissions?.internalUser &&
    member?.candidate?.placements?.find(
      (p) => p.status === PlacementStatusEnum.Approved,
    )
  return (
    <div
      className={clsx(
        'boxshadow relative w-full rounded-md border p-4',
        highlight ? 'bg-green/20' : 'mood-white',
        className,
      )}
      {...rest}
    >
      <span className="absolute right-2 top-2">
        <MemberActionsMenu
          jobPostingId={jobPostingId}
          variables={variables}
          options={options}
          url={uri}
        />
      </span>
      <div className="flow-x-xs items-center">
        <div>
          <Avatar
            alt={loading ?? member?.initials ?? ''}
            className={clsx(
              'mood-grey',
              member?.permissions?.hasRecruiterLabel &&
                'border-2 border-orange',
            )}
            src={member?.avatarUrl || undefined}
          />
        </div>
        <div>
          <h2 className="line-clamp-1">{title}</h2>
          <span className="line-clamp-1 flex text-sm text-grey">
            {loading ?? member?.citystate ?? ''}
          </span>
        </div>
      </div>
      {member != null && (
        <MemberStatsuses jobPostingId={jobPostingId} member={member} />
      )}
    </div>
  )
}

export default MemberSlip
