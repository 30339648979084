import { MemberFragment } from '@vangst/services/oogst/types'
import { create } from 'zustand'
import { AsChatTypeUnion } from '../messaging/MessagesRenderer'

type State = {
  recipient: MemberFragment | undefined
  messagingWidgetIsVisible: boolean
  widgetView: AsChatTypeUnion
}

type Actions = {
  hideMessagingWidget: () => void
  showMessagingWidget: () => void
  setRecipient: (recipient?: MemberFragment) => void
  setWidgetView: (view: AsChatTypeUnion) => void
}

export const useMessagingStore = create<State & Actions>()((set) => ({
  recipient: undefined,
  messagingWidgetIsVisible: false,
  setRecipient: (recipient) => set(() => ({ recipient })),
  setWidgetView: (widgetView) => set(() => ({ widgetView })),
  showMessagingWidget: () => set(() => ({ messagingWidgetIsVisible: true })),
  hideMessagingWidget: () => set(() => ({ messagingWidgetIsVisible: false })),
  widgetView: 'list',
}))
