import clsx from '@vangst/lib/clsx'
import { formatReadableDateWords } from '@vangst/lib/formatDates'
import { MemberComputed } from '@vangst/services/oogst/member/useMember'
import { PlacementStatusEnum } from '@vangst/services/oogst/types'
import { useRouter } from 'next/router'

type PropsType = React.HTMLAttributes<HTMLElement> & {
  readonly jobPostingId?: string
  readonly member: NonNullable<MemberComputed>
}

function MemberStatsuses(props: PropsType) {
  const { className, jobPostingId, member } = props
  const { asPath } = useRouter()

  const placement = member.candidate?.placements?.find(
    (p) => p.jobPosting.slug === jobPostingId,
  )

  const { readPlacements } = placement?.jobPosting.permissions ?? {}

  if (!readPlacements) return null

  const isOnboardingStatus =
    placement?.status === PlacementStatusEnum.Onboarding ||
    placement?.status === PlacementStatusEnum.OnboardingFinished ||
    placement?.status === PlacementStatusEnum.OnboardingScheduled

  return (
    <div
      className={clsx(
        'flex flex-col divide-y text-[12px] capitalize',
        className,
      )}
    >
      {placement?.status && !asPath.includes('/manage') && (
        <span
          className={clsx(
            placement?.status === PlacementStatusEnum.Approved && 'bg-green/20',
            'truncate p-2',
          )}
        >
          {placement.status.toLowerCase().split('_').join(' ')}
        </span>
      )}
      {placement?.interviewDate &&
        placement?.status === PlacementStatusEnum.PendingNeedsInterview && (
          <span className="truncate p-2">
            Interview:{' '}
            {formatReadableDateWords(
              new Date(placement.interviewDate),
              'MM/dd/yy',
            )}
          </span>
        )}
      {placement?.startDate && isOnboardingStatus && (
        <span className="truncate p-2">
          Start Date:{' '}
          {formatReadableDateWords(new Date(placement.startDate), 'MM/dd/yy')}
        </span>
      )}
    </div>
  )
}

export default MemberStatsuses
