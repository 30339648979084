/**
 * Formats a string of digits to US dollars
 *
 * @example
 * formatDollars('123456') // '$123,456.00'
 */
function formatDollars(dollars: number | string | null | Date) {
  return `$${Number(dollars)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

export default formatDollars
