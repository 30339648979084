import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import titleize from '@vangst/lib/titleize'
import unionize from '@vangst/lib/unionize'
import { JobPostingComputed } from '@vangst/services/oogst/jobPosting/useJobPosting'
import ClickyLink from '../../../components/navigation/ClickyLink'
import { serializeJsonToReact } from '../../editor/serializers'
import JobDetailBlock from './JobDetailBlock'

type PropsType = {
  jobPosting: JobPostingComputed
}

export default function GigsInfo(props: PropsType) {
  const { jobPosting } = props
  const atsMap = {
    Platform_Free: 'Self Service (Platform Free)',
    Boutique_GIGS: 'GIGS',
    Boutique_DH: 'Direct Hire',
  } as const

  return (
    <section>
      <h2 className="mood-orange flex h-16 items-center pl-6 font-600">
        Recruiter Info
      </h2>
      <JobDetailBlock
        className="border-t border-grey pt-4"
        title=""
        subtext="Visible only to internal users"
      >
        <div className="mt-4 pl-2 pr-6">
          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>ATS Record Type:</dt>
            {jobPosting?.atsRecordType != null ? (
              <dd>{atsMap[jobPosting.atsRecordType as keyof typeof atsMap]}</dd>
            ) : (
              <dd>No ATS Record Type Information</dd>
            )}
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Ats Status:</dt>
            <dd>{titleize(jobPosting?.atsStatus?.replace('_', ' ') || '')}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Screening:</dt>
            <dd>{jobPosting?.jobPostingScreening?.screening?.name || ''}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Quantity:</dt>
            <dd>{jobPosting?.quantity || ''}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Level:</dt>
            <dd>{jobPosting?.gigsInfo?.level}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Bill Rate:</dt>
            <dd>{jobPosting?.gigsInfo?.billRate}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Pay Rate:</dt>
            <dd>{jobPosting?.gigsInfo?.payRate}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Salary Pay Period:</dt>
            <dd>{jobPosting?.gigsInfo?.salaryPayPeriod}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Accounts Payable Email:</dt>
            <dd>{jobPosting?.accountsPayableEmail}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Kickoff Call?:</dt>
            <dd>{jobPosting?.gigsInfo?.kickoffCall?.toString()}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Deposit?:</dt>
            <dd>{jobPosting?.gigsInfo?.engagementDeposit?.toString()}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Amount:</dt>
            <dd>{jobPosting?.depositAmount?.toString()}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr]">
            <dt>Shift:</dt>
            <dd>{jobPosting?.shift}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Syndicate To Indeed?:</dt>
            <dd>{jobPosting?.syndicateIndeed?.toString()}</dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Additional Requirements:</dt>
            {!isEmptyOrNil(jobPosting?.gigsInfo?.additionalRequirements) ? (
              <dd className="text-sm">
                {serializeJsonToReact(
                  jobPosting?.gigsInfo?.additionalRequirements || '',
                )}
              </dd>
            ) : (
              <dd className="text-sm">No additional requirements.</dd>
            )}
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Special Requests:</dt>
            {!isEmptyOrNil(jobPosting?.gigsInfo?.specialRequests) ? (
              <dd className="text-sm">
                {serializeJsonToReact(
                  jobPosting?.gigsInfo?.specialRequests || '',
                )}
              </dd>
            ) : (
              <dd className="text-sm">No special requests.</dd>
            )}
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Dress Code:</dt>
            {!isEmptyOrNil(jobPosting?.gigsInfo?.dressCode) ? (
              <dd className="text-sm">
                {serializeJsonToReact(jobPosting?.gigsInfo?.dressCode || '')}
              </dd>
            ) : (
              <dd className="text-sm">No dress code info.</dd>
            )}
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Parking Facility Information:</dt>
            {!isEmptyOrNil(jobPosting?.gigsInfo?.parkingFacilityInformation) ? (
              <dd className="text-sm">
                {serializeJsonToReact(
                  jobPosting?.gigsInfo?.parkingFacilityInformation || '',
                )}
              </dd>
            ) : (
              <dd className="text-sm">No parking info.</dd>
            )}
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Assigned Recruiters:</dt>
            {jobPosting?.recruiters?.map((user) => {
              return (
                <dd key={user.id} className="text-sm">
                  {unionize(user.firstName, user.lastName, ' ')}
                </dd>
              )
            })}
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Assigned Account Execs:</dt>
            {jobPosting?.accountExecs?.map((user) => {
              return (
                <dd key={user.id} className="text-sm">
                  {unionize(user.firstName, user.lastName, ' ')}
                </dd>
              )
            })}
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Assigned Timesheet Managers:</dt>
            {jobPosting?.timeSheetManagers?.map((user) => {
              return (
                <dd key={user.id} className="text-sm">
                  {unionize(user.firstName, user.lastName, ' ')}
                </dd>
              )
            })}
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Primary Contact:</dt>
            <dd className="text-sm">
              {(jobPosting?.primaryContact?.firstName || '') +
                ' ' +
                (jobPosting?.primaryContact?.lastName || '')}
            </dd>
          </dl>

          <dl className="grid w-full items-start border-b sm:grid-cols-[2fr_5fr] sm:gap-2">
            <dt>Submit-Application Url:</dt>
            {!isEmptyOrNil(jobPosting?.submitApplicationUrl) ? (
              <dd>
                <ClickyLink
                  className="link-blue-dark"
                  href={jobPosting?.submitApplicationUrl}
                >
                  Link To Application
                </ClickyLink>
              </dd>
            ) : (
              <dd>No application url</dd>
            )}
          </dl>
        </div>
      </JobDetailBlock>
    </section>
  )
}
