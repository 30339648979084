import { useCallback } from 'react'

function useScroll() {
  const scrollToHash = useCallback(
    (
      hash: string,
      block?: 'start' | 'end' | 'center' | 'nearest',
      behavior?: 'auto' | 'smooth',
    ) => {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element != null) {
        element.scrollIntoView({
          behavior: behavior || 'smooth',
          block: block || 'start',
        })
      }
    },
    [],
  )
  return { scrollToHash }
}

export default useScroll
