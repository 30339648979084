'use client'
/* eslint-disable react/display-name */
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import ClickyLink from '../navigation/ClickyLink'

const flatten = (text: any, child: any): any =>
  typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)

// h1-h6
const defaultHeadings = [
  'heading-lg',
  'heading-md',
  'heading-sm mt-12',
  'text-lg',
  'text-lg',
  'text-lg',
]

// TODO: Replace the id portion with a plugin?
// @see https://github.com/remarkjs/react-markdown
// @see https://github.com/remarkjs/remark/blob/main/doc/plugins.md#list-of-plugins
const headingRenderer = (props: any, classes = defaultHeadings) => {
  const { level, children } = props
  const kids = React.Children.toArray(children)
  const text = kids.reduce(flatten, '')
  const slug = text.toLowerCase().replace(/\W/g, '-')
  const className = classes[level - 1] || defaultHeadings[level - 1]
  return React.createElement('h' + level, { id: slug, className }, children)
}

const baseRenderer = {
  h1: headingRenderer,
  h2: headingRenderer,
  h3: headingRenderer,
  h4: headingRenderer,
  h5: headingRenderer,
  h6: headingRenderer,
  p: (props: any) => <p className="my-4">{props.children}</p>,
  code: (props: any) => (
    <pre className="whitespace-normal">{props.children}</pre>
  ),
  a: (props: any) => <MarkdownLinks {...props} />,
  ol: (props: any) => (
    <ol className="my-4 list-inside list-decimal">{props.children}</ol>
  ),
  ul: (props: any) => (
    <ul className="my-4 list-inside list-disc">{props.children}</ul>
  ),
  li: (props: any) => <li className="my-2">{props.children}</li>,
  blockquote: (props: any) => <MarkdownQuotes {...props} />,
}

const basicRenderer = {
  h1: headingRenderer,
  h2: headingRenderer,
  h3: headingRenderer,
  h4: headingRenderer,
  h5: headingRenderer,
  h6: headingRenderer,
  p: (props: any) => <p>{props.children}</p>,
  code: (props: any) => (
    <pre className="whitespace-normal">{props.children}</pre>
  ),
  a: (props: any) => <MarkdownLinks {...props} />,
  ol: (props: any) => (
    <ol className="flex list-inside list-decimal flex-col gap-2">
      {props.children}
    </ol>
  ),
  ul: (props: any) => (
    <ul className="flex list-inside list-disc flex-col gap-2">
      {props.children}
    </ul>
  ),
  blockquote: (props: any) => <MarkdownQuotes {...props} />,
}

const blogHeadings = [
  'heading-lg my-4',
  'heading-md my-4',
  'heading-sm my-4',
  'text-lg my-4',
  'text-lg my-2',
  'text-lg my-2',
]

function MarkdownQuotes(props: any) {
  const { children } = props
  const kids = React.Children.toArray(children).reduce(flatten, '')
  const newText = kids.split('\n').map((kid: string, i: number) => {
    return <p key={i}>{kid}</p>
  })
  return <span className="ml-4 italic text-grey">{newText}</span>
}

function MarkdownLinks(props: any) {
  const { title, href, children } = props
  const kids = React.Children.toArray(children).reduce(flatten, '')
  return (
    <ClickyLink className="link-orange underline" href={href} id={title}>
      {kids}
    </ClickyLink>
  )
}

export const blogRenderer = {
  h1: (props: any) => headingRenderer(props, blogHeadings),
  h2: (props: any) => headingRenderer(props, blogHeadings),
  h3: (props: any) => headingRenderer(props, blogHeadings),
  h4: (props: any) => headingRenderer(props, blogHeadings),
  h5: (props: any) => headingRenderer(props, blogHeadings),
  h6: (props: any) => headingRenderer(props, blogHeadings),
  p: (props: any) => <p className="my-4">{props.children}</p>,
  a: (props: any) => <MarkdownLinks {...props} />,
  ol: (props: any) => (
    <ol className="my-4 grid list-inside list-decimal space-y-4 text-grey-dark">
      {props.children}
    </ol>
  ),
  ul: (props: any) => (
    <ul className="my-4 grid list-inside list-disc space-y-2 text-grey-dark">
      {props.children}
    </ul>
  ),
  blockquote: (props: any) => <MarkdownQuotes {...props} />,
}

const jobHeadings = [
  'heading-lg mb-2',
  'heading-md mb-2',
  'heading-sm mb-2',
  'text-lg mb-2',
  'text-lg mb-2',
  'text-lg mb-2',
]

const jobRenderer = {
  h1: (props: any) => headingRenderer(props, jobHeadings),
  h2: (props: any) => headingRenderer(props, jobHeadings),
  h3: (props: any) => headingRenderer(props, jobHeadings),
  h4: (props: any) => headingRenderer(props, jobHeadings),
  h5: (props: any) => headingRenderer(props, jobHeadings),
  h6: (props: any) => headingRenderer(props, jobHeadings),
  p: (props: any) => <p className="text-md">{props.children}</p>,
  a: (props: any) => <MarkdownLinks {...props} />,
  li: (props: any) => <li>{props.children}</li>,
  ol: (props: any) => (
    <ol className="my-2 grid list-inside list-decimal space-y-2">
      {props.children}
    </ol>
  ),
  ul: (props: any) => (
    <ul className="my-2 grid list-inside list-disc space-y-2">
      {props.children}
    </ul>
  ),
  blockquote: (props: any) => <MarkdownQuotes {...props} />,
}
function Markdown(
  props: ReactMarkdownOptions & {
    renderType?: 'blog' | 'base' | 'basic' | 'job'
  },
) {
  const { renderType = 'base', ...rest } = props

  const renderer = {
    base: baseRenderer,
    basic: basicRenderer,
    blog: blogRenderer,
    job: jobRenderer,
  }
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
      components={renderer[renderType]}
      {...rest}
    >
      {props.children}
    </ReactMarkdown>
  )
}

// -------------------------------------

export default React.memo(Markdown)
