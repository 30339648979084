import useJobPosting, {
  JobPostingOptions,
  JobPostingVars,
} from '@vangst/services/oogst/jobPosting/useJobPosting'
import { memo } from 'react'
import { AsTypeUnion } from '../nodes/RendererFactory'
import JobPostingAvatar from './views/JobPostingAvatar'
import JobPostingCard from './views/JobPostingCard'
import JobPostingDetail from './views/JobPostingDetail'
import JobPostingMenuItem from './views/JobPostingMenuItem'
import JobPostingSlip from './views/JobPostingSlip'
import JobPostingTableRow from './views/JobPostingTableRow'

type PropsType = React.HTMLAttributes<HTMLElement> & {
  readonly as: AsTypeUnion
  readonly label?: string
  readonly variables: JobPostingVars
  readonly options?: JobPostingOptions
}

function JobPostingRenderer(props: PropsType) {
  const { as, variables, label, options, ...rest } = props
  const { data, uri } = useJobPosting(variables, options)
  const jobPosting = data?.getJobPosting

  const title = jobPosting ? jobPosting.title : label ? label : '...'

  if (as === 'avatar') {
    return (
      <JobPostingAvatar
        jobPosting={jobPosting}
        title={title}
        uri={uri}
        {...rest}
      />
    )
  }

  if (as === 'slip') {
    return (
      <JobPostingSlip
        jobPosting={jobPosting}
        title={title}
        uri={uri}
        {...rest}
      />
    )
  }

  if (as === 'detail') {
    return <JobPostingDetail jobPosting={jobPosting} {...rest} />
  }

  if (as === 'menuitem') {
    return (
      <JobPostingMenuItem
        title={title}
        uri={uri}
        jobPosting={jobPosting}
        {...rest}
      />
    )
  }

  if (as === 'table-row') {
    return (
      <JobPostingTableRow
        title={title}
        uri={uri}
        jobPosting={jobPosting}
        {...rest}
      />
    )
  }

  // as card
  return (
    <JobPostingCard jobPosting={jobPosting} uri={uri} title={title} {...rest} />
  )
}

export default memo(JobPostingRenderer)
