import clsx from '@vangst/lib/clsx'
import useMember from '@vangst/services/oogst/member/useMember'
import useMemberConnections, {
  MemberConnectionsOptions,
  MemberConnectionsVars,
} from '@vangst/services/oogst/member/useMemberConnections'
import Routes from '@vangst/services/routes'
import { Fragment, memo } from 'react'
import { MdArrowDownward, MdPerson } from 'react-icons/md'
import Spinner from '../../components/assets/Spinner'
import EmptyState from '../../components/feedback/EmptyState'
import ClickyExplore from '../../components/navigation/ClickyExplore'
import ClickyLink from '../../components/navigation/ClickyLink'
import Counter from '../../components/views/Counter'
import CompanyRenderer from '../company/CompanyRenderer'
import MemberRenderer from './MemberRenderer'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly as: 'avatar' | 'card' | 'slip'
  readonly at: 'infinite' | 'preview' | 'sidebar'
  readonly classNameList?: string
  readonly take?: number
  readonly variables: MemberConnectionsVars
  readonly options?: MemberConnectionsOptions
}

function MemberConnectionsGrid(props: PropsType) {
  const { as, at, classNameList, take, variables, options, ...rest } = props
  const { data, isFetching, isZero, pagination, uri } = useMemberConnections(
    variables,
    options,
  )

  const canTake = take != null && (at === 'preview' || at === 'sidebar')
  const pages = canTake && data?.pages ? [data.pages[0]] : data?.pages
  const member = useMember({ username: variables.username })
  const canEdit = member?.data?.getUser?.permissions?.edit

  return (
    <div {...rest}>
      {at === 'sidebar' && (
        <p className="relative mb-8 text-sm">
          <span className="mr-4 text-sm">
            <Counter to={pagination.totalCount} />{' '}
            <span className="ml-1">Connections</span>
          </span>
          <ClickyLink className="link-blue-dark mr-2 text-sm" href={uri}>
            View Connections
          </ClickyLink>
          {isFetching && (
            <Spinner
              className="absolute right-0 top-0"
              width={16}
              height={16}
            />
          )}
        </p>
      )}

      {at === 'infinite' && (
        <p className="relative mb-8">
          <span className="mr-2">Results</span>
          <Counter to={pagination.totalCount} />
          {isFetching && (
            <Spinner
              className="absolute right-0 top-0"
              width={16}
              height={16}
            />
          )}
        </p>
      )}

      <ul className={clsx('min-h-40 w-full', !isZero && classNameList)}>
        {isZero && (
          <li>
            <EmptyState className="sm:h-60" icon={MdPerson}>
              {canEdit ? (
                <span>
                  <ClickyLink className="link-blue-dark" href={Routes.MEMBERS}>
                    Search the Vangst network
                  </ClickyLink>{' '}
                  and invite other members to connect, or{' '}
                  <ClickyLink
                    href={Routes.COMPANIES}
                    className="link-blue-dark"
                  >
                    find and follow companies
                  </ClickyLink>{' '}
                  that interest you.
                </span>
              ) : (
                <ClickyLink href={Routes.MEMBERS} className="link-blue-dark">
                  Be the first to connect.
                </ClickyLink>
              )}
            </EmptyState>
          </li>
        )}

        {!isZero &&
          pages?.map((page, i) => {
            const list = page.getUser.connections.edges
            const edges = canTake ? list?.slice(0, take) : list
            return (
              <Fragment key={`member-connections-renderer-page-${i}`}>
                {edges?.map((edge) => {
                  const node = edge?.node
                  return (
                    <li className="list-none" key={node?.id || `item-${i}`}>
                      {node?.__typename === 'Client' ? (
                        <CompanyRenderer
                          as={as}
                          key={node.id}
                          variables={{ displayname: node.displayname }}
                          options={{ initialData: { getClient: node } }}
                        />
                      ) : node?.__typename === 'User' ? (
                        <MemberRenderer
                          as={as}
                          variables={{ username: node.username }}
                          options={{ initialData: { getUser: node } }}
                        />
                      ) : null}
                    </li>
                  )
                })}
                {!isZero && at === 'infinite' && (
                  <li className="col-span-full my-4 border-b pb-2 text-right text-sm">
                    Page {i + 1} of {pagination?.totalPages}
                  </li>
                )}
              </Fragment>
            )
          })}
      </ul>
      {at === 'infinite' && pagination.nextHref && pagination.nextPage && (
        <ClickyExplore
          as="icon-text"
          className={clsx('w-full', !isFetching && 'motion-south')}
          href={pagination.nextHref}
          icon={isFetching ? Spinner : MdArrowDownward}
          onClick={pagination.nextPage}
        >
          {isFetching ? 'Loading...' : 'Load Next Page'}
        </ClickyExplore>
      )}
    </div>
  )
}

export default memo(MemberConnectionsGrid)
