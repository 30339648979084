type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  subText?: string
  title: string
  children: React.ReactNode
}

export default function NonZeroState(props: PropsType) {
  const { subText, title, children, ...rest } = props
  return (
    <div className="flow-y-sm border-b border-b-grey py-4" {...rest}>
      <h3 className="text-lg">
        {title} <span>{subText}</span>
      </h3>
      <ul className="flow-y-xs">{children}</ul>
    </div>
  )
}
