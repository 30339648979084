import hotkeys, { HotkeysEvent } from 'hotkeys-js'
import { useEffect } from 'react'

type CallbackType = (event: KeyboardEvent, handler: HotkeysEvent) => void

function useHotkeys(keys: string, callback: CallbackType) {
  useEffect(() => {
    hotkeys(keys, (event, handler) => callback(event, handler))
    return function dispose() {
      hotkeys.unbind(keys)
    }
  })
}

export default useHotkeys
