type PropsType = React.SVGAttributes<SVGElement> & {
  readonly size?: string | number
}

export default function SocialFacebook(props: PropsType) {
  const { size = '2em' } = props
  return (
    <svg height={size} width={size} viewBox="0 0 18 18" {...props}>
      <g fill="currentColor" fillRule="evenodd" stroke="none" strokeWidth="0">
        <path
          d="M10.987 7.476l-.104 1.346h-1.38V13.5H7.76V8.822h-.93V7.476h.93v-.904c0-.399.01-1.014.3-1.395.305-.403.724-.678 1.444-.678 1.174 0 1.668.168 1.668.168l-.232 1.379s-.388-.112-.75-.112-.686.13-.686.491v1.051zM9 0a9 9 0 00-9 9 9 9 0 1018 0 9 9 0 00-9-9z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
