import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import useUpdateMemberUser from '@vangst/services/oogst/member/useUpdateMemberUser'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { HTMLAttributes, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdCalendarMonth, MdError } from 'react-icons/md'
import AlertText from '../../../components/feedback/AlertText'
import { DialogModal } from '../../../components/feedback/DialogModal'
import Input from '../../../components/forms/Input'
import Textarea from '../../../components/forms/Textarea'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import ClickyLink from '../../../components/navigation/ClickyLink'
import validations from '../../application/validations'

type PropsType = {
  readonly handleCancel: () => void
} & HTMLAttributes<HTMLDivElement>

type FormInput = {
  calendly: string
  availability: string
}

function DialogScreenApplicant(props: PropsType) {
  const [requiredError, setRequiredError] = useState(false)
  const { handleCancel } = props
  const { viewer } = useViewer()

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormInput>({
    defaultValues: {
      calendly: viewer?.social?.calendly ?? '',
      availability: viewer?.availability ?? '',
    },
  })

  const { mutate, isLoading } = useUpdateMemberUser({
    onSuccess: () => handleCancel(),
  })

  const send = (fd: FormInput) => {
    if (isEmptyOrNil(fd.calendly) && isEmptyOrNil(fd.availability)) {
      setRequiredError(true)
      return
    }

    const memberEditInput = {
      id: viewer?.id ?? '',
      availability: fd.availability,
      social: { calendly: fd.calendly },
    }

    mutate({ input: memberEditInput })
  }

  return (
    <DialogModal onClose={handleCancel} open>
      <DialogModal.Panel icon={MdCalendarMonth} title="Interview Availability">
        <DialogModal.Description>
          <div className="flow-y-xs text-sm">
            Please make sure your interview availability is up to date to help
            our recruiters set up future interviews.
          </div>
        </DialogModal.Description>
        <form className="flow-y-sm w-full" onSubmit={handleSubmit(send)}>
          <span className="flow-y-xs">
            <Input
              id="calendly"
              error={errors?.calendly?.message}
              label="Calendly Link:"
              placeholder="https://calendly.com/..."
              {...register('calendly', validations.url)}
            />
            <AlertText className="text-sm italic">
              Don't have a Calendly account?{' '}
              <ClickyLink
                href="https://calendly.com/"
                target="_blank"
                className="link-orange underline"
              >
                Create one here{' '}
              </ClickyLink>{' '}
              to help our Vangst recruiters easily coordinate interviews at your
              convenience.
            </AlertText>
          </span>
          <Textarea
            id="message"
            error={errors?.availability?.message}
            label="Availability:"
            placeholder="Monday - Friday, 2pm MST - 5pm MST"
            {...register('availability')}
          />
          <span className="flow-x-sm mt-4 w-full">
            <ClickyBtn
              type="submit"
              className="mood-orange w-full"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Submit'}
            </ClickyBtn>
            <ClickyBtn onClick={handleCancel} type="reset" className="w-full">
              Cancel
            </ClickyBtn>
          </span>
          {requiredError && (
            <AlertText icon={MdError} className="text-sm italic text-red">
              Please include either a Calendly link, or a detailed message to
              submit this form.
            </AlertText>
          )}
        </form>
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default DialogScreenApplicant
