import { Combobox } from '@headlessui/react'
import addOrRemove from '@vangst/lib/addOrRemove'
import { memo, useState } from 'react'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import Label, { LabelPropsType } from './Label'

type OptionType = {
  readonly label: string
  readonly value: string
}

export type ComboBoxPropsType = React.HTMLAttributes<HTMLSelectElement> &
  LabelPropsType & {
    readonly error?: string
    readonly hint?: string | null
    readonly name: string
    readonly hideInput?: boolean
    readonly handleValueChange: (name: string, ids: string[]) => void
    readonly value?: string[]
    readonly options: OptionType[]
    readonly reference?: React.Ref<any> | any
  }

function ComboBoxMulti(props: ComboBoxPropsType) {
  const {
    options,
    label,
    hideLabel,
    hideInput,
    labelSubtext,
    required,
    value,
    handleValueChange,
  } = props
  const [query, setQuery] = useState('')
  const handleOnChange = (selectedThings: string[]) => {
    // Unselecting an option is currently just adding it again to the array, so this is a hackiest of hacks for the moment to check for that, and remove a value totally if it is duplicated (ie unselected)
    const ids = selectedThings?.map((v: any) => v?.value || v)
    const lastValue = ids.pop()
    const thisWasADeselect = ids.includes(lastValue)
    let result
    if (thisWasADeselect) {
      // result = ids.filter((val: string) => val !== lastValue)
      result = addOrRemove(ids, lastValue)
    } else {
      ids.push(lastValue)
      result = ids
    }

    handleValueChange(props.name, result)
  }
  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase())
        })
  return (
    <Combobox value={value || []} onChange={handleOnChange} multiple>
      {({ open }) => (
        <div className="flow-y-sm relative">
          <Label
            hideLabel={hideLabel}
            label={label}
            labelSubtext={labelSubtext}
            required={required}
          />
          {!hideInput && (
            <div className="relative w-full overflow-hidden rounded-lg text-left shadow-md sm:text-sm">
              <Combobox.Input
                className="w-full border-none px-4 py-2 text-sm"
                displayValue={(option: OptionType) => {
                  if (open) {
                    return ''
                  }

                  return option?.label
                }}
                onChange={(event) => setQuery(event.target.value)}
              />
            </div>
          )}
          <Combobox.Options static className="max-w-76">
            {filteredOptions.length === 0 && query !== '' ? (
              <li className="relative px-4 py-2 text-grey">No Matches</li>
            ) : (
              filteredOptions.map((option) => {
                const isSelected = value && value.includes(option.value)
                return (
                  <Combobox.Option
                    key={option.value}
                    className="flow-x-xs relative p-2"
                    value={option}
                  >
                    {() => (
                      <>
                        <span className="text-orange">
                          {isSelected ? (
                            <MdCheckBox aria-hidden="true" />
                          ) : (
                            <MdCheckBoxOutlineBlank aria-hidden="true" />
                          )}
                        </span>
                        <span>{option.label}</span>
                      </>
                    )}
                  </Combobox.Option>
                )
              })
            )}
          </Combobox.Options>
        </div>
      )}
    </Combobox>
  )
}

export default memo(ComboBoxMulti)
