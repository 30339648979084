import { Dialog } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import { Router } from 'next/router'
import { useEffect, useRef } from 'react'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly onClose: () => void
  readonly open: boolean
  readonly title: string
}

/**
 * The dialog, dialog panel and dialog title used by Navbar menus.
 *
 * @see [headlessui Dialog component](https://headlessui.com/react/dialog)
 *
 * @example
 * <NavbarDialog
 *   className="fixed inset-0 top-12 z-50 flex animate-fadeIn bg-orange/95 sm:top-16 "
 *   open={isExpanded}
 *   onClose={hide}
 *   title="Navigation"
 * >
 *   ...
 * </NavbarDialog>
 */
export default function NavbarDialog(props: PropsType) {
  const { children, className, title, onClose, open, ...rest } = props
  const focusRef = useRef(null)

  useEffect(() => {
    Router.events.on('routeChangeStart', onClose)
    return () => {
      Router.events.off('routeChangeStart', onClose)
    }
  }, [onClose])

  return (
    <Dialog
      className={clsx(
        'fixed inset-0 top-12 z-50 flex animate-fadeIn bg-green/95 sm:top-16 ',
        className,
      )}
      initialFocus={focusRef}
      onClose={onClose}
      open={open}
      {...rest}
    >
      <Dialog.Panel
        className={clsx(
          'contain-c pointer-events-none flex outline-none',
          className?.includes('justify-start')
            ? 'justify-start'
            : 'justify-end',
        )}
        ref={focusRef}
      >
        <Dialog.Title className="sr-only">{title}</Dialog.Title>
        <div className="mood-white pointer-events-auto flex max-h-fit w-72 flex-col overflow-y-auto border-t border-orange text-orange shadow-lg">
          {children}
        </div>
      </Dialog.Panel>
    </Dialog>
  )
}
