import {
  STATE_LIST,
  STATE_OPTIONS,
  STATE_OPTIONS_CANADA,
} from '@vangst/lib/constants/locations'
import { StatesEnum } from '@vangst/services/oogst/types'
import { memo } from 'react'
import Select, { SelectType } from '../../../components/forms/Select'

export type StateListKeysType = keyof typeof STATE_LIST

type PropsType = SelectType & {
  readonly countryVal?: 'united_states' | 'canada'
}

function StateSelect(props: PropsType) {
  const {
    error,
    hideLabel,
    hint,
    label,
    name,
    reference,
    countryVal,
    ...rest
  } = props
  const id = props?.id ?? name
  return (
    <Select
      id={id}
      disabled={props.disabled}
      error={error}
      hideLabel={hideLabel}
      hint={hint}
      label={label}
      name={name}
      options={countryVal === 'canada' ? STATE_OPTIONS_CANADA : STATE_OPTIONS}
      reference={reference}
      {...rest}
    />
  )
}

// -------------------------------------

const serializeStateEnum = (abbr?: StateListKeysType) => {
  if (abbr == null) return abbr
  const key = STATE_LIST[abbr].replace(/\s/gi, '') as keyof typeof StatesEnum
  return StatesEnum[key]
}

// -------------------------------------

export { serializeStateEnum }
export default memo(StateSelect)
