import { forwardRef, memo } from 'react'
import NextLink, { NextLinkType } from './NextLink'
import NextNavLink, { NextNavLinkType } from './NextNavLink'

type AnchorType = React.PropsWithChildren<
  React.AnchorHTMLAttributes<HTMLAnchorElement>
>
type ButtonType = React.PropsWithChildren<
  React.ButtonHTMLAttributes<HTMLButtonElement>
>
type LinkType = AnchorType | NextLinkType | NextNavLinkType

export type AnchorButtonType =
  | AnchorType
  | ButtonType
  | NextLinkType
  | NextNavLinkType

const AnchorButton = forwardRef<HTMLElement, AnchorButtonType>(
  function AnchorButton(props, ref) {
    const { as, activate, children, ...rezt } = props as NextNavLinkType
    const href = (props as LinkType).href
    const isInternal = href && /^(\/|\?)/gi.test(href)
    const isHash = href && /^#/gi.test(href)
    const isMailTo = href && /^mailto/gi.test(href)
    const isNav = (props as NextNavLinkType).activate != null
    const rest = { ref, ...rezt }

    if (href) {
      return isMailTo ? (
        <a {...(rest as AnchorType)}>{children}</a>
      ) : isHash ? (
        <a {...(rest as AnchorType)}>{children}</a>
      ) : !isInternal ? (
        <a rel="noopener noreferrer" target="_blank" {...(rest as AnchorType)}>
          {children}
        </a>
      ) : isInternal && isNav ? (
        <NextNavLink activate={activate} {...(rest as NextNavLinkType)}>
          {children}
        </NextNavLink>
      ) : (
        <NextLink {...(rest as NextLinkType)}>{children}</NextLink>
      )
    }
    return (
      <button
        title={props.title || 'Clickable Button'}
        type="button"
        {...(rest as ButtonType)}
      >
        <span className="sr-only">{props.title || 'Clickable Button'}</span>
        {children}
      </button>
    )
  },
)

/**
 * A wrapper around `HTMLAnchorElement`, `HTMLButtonElement`, `NextLink` and
 * `NextNavLink`
 *
 * The supplied parameters determine which element to return.
 *
 * @example
 * <AnchorButton href="/">Internal Link</AnchorButton>
 * <AnchorButton href="http://www.google.com">External Link</AnchorButton>
 * <AnchorButton href="mailto:hello@gmail.com">Mail To Link</AnchorButton>
 * <AnchorButton href="#hashish">A Hash Link</AnchorButton>
 * <AnchorButton href="?key=value">A Query Param Link</AnchorButton>
 * <AnchorButton activate href="/anchors-away">
 *   Navigation Link
 * </AnchorButton>
 */
export default memo(AnchorButton)
