import { Element } from 'slate'
import { BlockFormatType, CustomEditor } from './customTypes'
export const withMentions = (editor: CustomEditor) => {
  const { isInline, isVoid } = editor

  editor.isInline = (element: Element) => {
    // @ts-ignore
    return element.type === BlockFormatType.mention ? true : isInline(element)
  }

  editor.isVoid = (element: Element) => {
    // @ts-ignore
    return element.type === BlockFormatType.mention ? true : isVoid(element)
  }

  return editor
}
