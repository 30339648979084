import noop from '@vangst/lib/noop'
import titleize from '@vangst/lib/titleize'
import { MemberFragment } from '@vangst/services/oogst/types'
import Routes from '@vangst/services/routes'
import React, { useRef, useState } from 'react'
import { MdCheckCircleOutline, MdPersonAddAlt1 } from 'react-icons/md'
import AlertText from '../../../components/feedback/AlertText'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import FormAddUserToCompany from '../../company/forms/FormAddUserToCompany'

type PropsType = {
  companyName: string
  displayname: string
  handleCancel: () => void
}

function DialogAddUserToCompany({
  companyName,
  displayname,
  handleCancel,
}: PropsType) {
  const focusRef = useRef(null)
  const [member, setMember] = useState<MemberFragment>()

  return (
    <DialogModal onClose={noop} initialFocus={focusRef} open>
      <DialogModal.Panel
        icon={member?.username == null ? MdPersonAddAlt1 : MdCheckCircleOutline}
        title={member == null ? `Add User To ${companyName}` : 'Success!'}
      >
        {member == null && (
          <FormAddUserToCompany
            displayname={displayname}
            handleCancel={handleCancel}
            setMember={setMember}
          />
        )}
        {member != null && (
          <AlertText>
            <div className="flow-y-sm">
              {titleize(member?.firstName)} {titleize(member?.lastName)} has
              been added to {companyName}.
              <span className="flow-x-sm justify-center">
                <ClickyBtn
                  className="mood-orange"
                  href={Routes.MEMBERS_DETAIL.replace(
                    ':username',
                    member?.username ?? '',
                  )}
                >
                  Visit User Profile
                </ClickyBtn>
                <ClickyBtn onClick={handleCancel} className="mood-white">
                  Close
                </ClickyBtn>
              </span>
            </div>
          </AlertText>
        )}
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default React.memo(DialogAddUserToCompany)
