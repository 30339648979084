import noop from '@vangst/lib/noop'
import React, { useRef } from 'react'
import { MdCheckCircleOutline } from 'react-icons/md'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import JobPostingNewGigForm from '../JobPostingNewGigForm'

type PropsType = {
  handleCancel: () => void
  associatedClientDisplayname?: string
}

function DialogNewJobOrder({
  handleCancel,
  associatedClientDisplayname,
}: PropsType) {
  const focusRef = useRef(null)

  return (
    <DialogModal onClose={noop} initialFocus={focusRef} open>
      <DialogModal.Panel icon={MdCheckCircleOutline} title="New Job Order">
        <JobPostingNewGigForm
          isDialog
          focusRef={focusRef}
          displayname={associatedClientDisplayname}
        />
        <div className="flex w-full items-center justify-center gap-4">
          <ClickyBtn className="mood-orange w-full" onClick={handleCancel}>
            Cancel
          </ClickyBtn>
        </div>
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default React.memo(DialogNewJobOrder)
