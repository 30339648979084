import clsx from '@vangst/lib/clsx'
import {
  MemberComputed,
  MemberOptions,
  MemberVars,
} from '@vangst/services/oogst/member/useMember'
import Avatar from '../../../components/views/Avatar'
import MemberActionsMenu from '../navigation/MemberActionsMenu'
import MemberStatsuses from './MemberStatuses'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly jobPostingId?: string
  readonly member?: MemberComputed
  readonly options?: MemberOptions
  readonly popoverClassName?: string
  readonly title: string
  readonly uri: string
  readonly variables: MemberVars
}

function MemberFaceCard(props: PropsType) {
  const {
    className,
    jobPostingId,
    member,
    options,
    popoverClassName,
    title,
    uri,
    variables,
    ...rest
  } = props

  return (
    <div
      className={clsx(
        'boxshadow mood-white flex flex-col rounded-md border',
        className,
      )}
      {...rest}
    >
      {/* ... */}
      <div className="relative">
        <div className="h-12 p-2">
          <h2 className="line-clamp-1 text-sm" title={`@${variables.username}`}>
            {title}
          </h2>
          <span className="line-clamp-1 flex text-[12px] text-grey">
            {member?.citystate ?? ''}
          </span>
        </div>
        <MemberActionsMenu
          className="!absolute right-0 top-2 z-20"
          jobPostingId={jobPostingId}
          options={options}
          popoverClassName={popoverClassName}
          url={uri}
          variables={variables}
        />
        <div
          className={clsx(
            'relative h-48 w-full',
            member?.permissions?.hasRecruiterLabel && 'border-2 border-orange',
            member == null && 'border-b border-b-grey-light',
          )}
        >
          <Avatar
            as="face-card"
            alt={member?.initials ?? '...'}
            className="mood-grey shrink-0"
            src={member?.avatarUrl || undefined}
          />
        </div>
        {member && (
          <MemberStatsuses jobPostingId={jobPostingId} member={member} />
        )}
      </div>
    </div>
  )
}

export default MemberFaceCard
