import { forwardRef, memo } from 'react'
import Select, { SelectType } from '../../../components/forms/Select'

const SelectTypeOfLead = forwardRef<HTMLInputElement, SelectType>(
  function SelectTypeOfLead(props, ref) {
    const { hint, label, name, error, ...rest } = props
    const id = props?.id ?? name

    const options = [
      { value: '', label: 'Select your role' },
      { value: 'Candidate', label: 'Candidate' },
      { value: 'Client', label: 'Client' },
      { value: 'Investor', label: 'Investor' },
      { value: 'Media', label: 'Media' },
      { value: 'Other', label: 'Other' },
      { value: 'Partner', label: 'Partner' },
    ]
    return (
      <Select
        id={id}
        error={error}
        disabled={props.disabled}
        hint={hint}
        label={label}
        name={name}
        options={options}
        reference={ref}
        {...rest}
      />
    )
  },
)

export default memo(SelectTypeOfLead)
