import useMember, { MemberVars } from '@vangst/services/oogst/member/useMember'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import React from 'react'
import { LabelPercentComplete } from './MemberRenderer'

type PropsType = {
  readonly variables: MemberVars
}

function MemberLabel(props: PropsType) {
  const { variables } = props
  const { data } = useMember(variables)
  const { viewer } = useViewer()
  const member = data?.getUser

  return member?.termsAcceptedAt === null &&
    viewer?.permissions?.internalUser ? (
    <span className="mr-4 self-center rounded-sm bg-red/50 px-2 py-1 text-[10px] font-400 uppercase">
      Unclaimed
    </span>
  ) : member?.permissions?.hasRecruiterLabel ? (
    <span className="mood-orange mr-4 self-center rounded-sm px-2 py-1 text-[10px] font-400 uppercase">
      Recruiter
    </span>
  ) : viewer?.permissions?.internalUser ? (
    <LabelPercentComplete
      as="tag"
      percent={member?.candidate?.profilePercentComplete}
    />
  ) : null
}

export default React.memo(MemberLabel)
