import ClickyLink from '../../components/navigation/ClickyLink'

function SkipLinks() {
  return (
    <div id="skip" className="sr-only">
      <ClickyLink href="#main">Skip to Main Content</ClickyLink>
      <ClickyLink href="#nav">Skip to Primary Navigation</ClickyLink>
      <ClickyLink href="#footer">Skip to Footer</ClickyLink>
    </div>
  )
}

export default SkipLinks
