import clsx from '@vangst/lib/clsx'
import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import Routes from '@vangst/services/routes'
import isHotkey from 'is-hotkey'
import React, { useCallback, useMemo, useState } from 'react'
import { MdError, MdSend } from 'react-icons/md'
import { createEditor, Editor, Range, Transforms } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, ReactEditor, Slate, withReact } from 'slate-react'
import { EsResultFragment } from '../../../../packages/services/oogst/types'
import AlertText from '../../components/feedback/AlertText'
import Label from '../../components/forms/Label'
import ClickyLink from '../../components/navigation/ClickyLink'
import { toggleBlock, toggleMark, Toolbar } from './components'
import {
  BlockFormatType,
  CustomEditor,
  CustomElement,
  CustomLeafElement,
  ElementAttributes,
  LeafAttributes,
  MarkFormatType,
} from './customTypes'
import MentionSearchRenderer from './MentionSearchRenderer'
import { withHtml } from './withHtml'
import { withLinks } from './withLinks'
import { withMentions } from './withMentions'

const MARK_HOTKEYS = {
  'mod+h': MarkFormatType.header,
  'mod+b': MarkFormatType.strong,
  'mod+i': MarkFormatType.emphasis,
}

const BLOCK_HOTKEYS = {
  'mod+o': BlockFormatType.orderedList,
  'mod+l': BlockFormatType.list,
  'mod+k': BlockFormatType.blockquote,
}

export const initialBlank = [
  {
    type: BlockFormatType.paragraph,
    children: [
      {
        text: '',
      },
    ],
  },
]

// Note: if you change this value, you must update the number of refs in the mentionRefs var in this file
const PAGE_SIZE = 10

function EditorContainer({
  as = 'default',
  error,
  handleSubmit,
  label,
  onChange,
  mentionsEnabled = false,
  placeholder = 'Enter information here',
  value,
}: {
  as?: 'default' | 'messaging'
  error?: string
  mentionsEnabled?: boolean
  handleSubmit?: any
  label?: string
  onChange?: any
  placeholder?: string
  value?: any
}) {
  const incomingValue = !isEmptyOrNil(value) ? value : initialBlank
  const [editorState, setEditorState] = useState<any>(incomingValue)
  const [mentionTarget, setMentionTarget] = useState<Range | undefined>()
  const [search, setSearch] = useState('')
  const [index, setIndex] = useState(0)

  const editor = useMemo(
    () =>
      withMentions(withHtml(withLinks(withHistory(withReact(createEditor()))))),
    [],
  )

  const submitTheThing = () => {
    handleSubmit()
    const point = { path: [0, 0], offset: 0 }
    editor.selection = { anchor: point, focus: point }
    editor.history = { redos: [], undos: [] }
    editor.children = initialBlank
  }

  // ELEMENT and LEAF determine how the nodes are styled when shown within the editor:
  const Leaf = ({
    attributes,
    children,
    leaf,
  }: {
    attributes: LeafAttributes
    children: React.ReactNode
    leaf: CustomLeafElement
  }) => {
    if (leaf[MarkFormatType.header]) {
      children = <strong className="text-lg font-700">{children}</strong>
    }

    if (leaf[MarkFormatType.strong]) {
      children = <strong>{children}</strong>
    }

    if (leaf[MarkFormatType.emphasis]) {
      children = <em>{children}</em>
    }

    return <span {...attributes}>{children}</span>
  }

  const Element = ({
    attributes,
    children,
    element,
  }: {
    attributes: ElementAttributes
    children: React.ReactNode
    element: CustomElement
  }) => {
    const linkStyle = 'link-orange'
    const ulStyle = 'list-disc pl-8'
    const olStyle = 'list-decimal pl-8'
    const pStyle = 'text-black my-2'
    const quoteStyle = 'border-l-2 border-l-grey-light text-grey pl-2 my-4'

    switch (element.type) {
      case BlockFormatType.list:
        return (
          <ul className={ulStyle} {...attributes}>
            {children}
          </ul>
        )
      case BlockFormatType.orderedList:
        return (
          <ol className={olStyle} {...attributes}>
            {children}
          </ol>
        )
      case BlockFormatType.link:
        return (
          <a className={linkStyle} {...attributes} href={element.url}>
            {children}
          </a>
        )
      case BlockFormatType.listItem:
        return <li {...attributes}>{children}</li>
      case BlockFormatType.blockquote:
        return (
          <blockquote className={quoteStyle} {...attributes}>
            {children}
          </blockquote>
        )
      case BlockFormatType.mention:
        return (
          <a
            {...attributes}
            contentEditable={false}
            data-cy={`mention-${element.displayname}`}
            href={element.url}
            className="link-orange bg-grey-lightest"
            target="blank"
          >
            @{element.displayname}
            {children}
          </a>
        )
      default:
        return (
          <p className={pStyle} {...attributes}>
            {children}
          </p>
        )
    }
  }

  const insertMention = (editor: CustomEditor, user: any) => {
    // @ts-ignore
    Transforms.select(editor, mentionTarget)
    const url =
      user.type === 'User'
        ? Routes.MEMBERS_DETAIL.replace(':username', user.slug)
        : Routes.COMPANIES_DETAIL.replace(':displayname', user.slug)
    const mention: any = {
      type: BlockFormatType.mention,
      id: user.id,
      displayname: user.name,
      children: [{ text: '@' + user.name }],
      url: url,
    }

    Transforms.insertNodes(editor, mention)
    Transforms.move(editor)
    ReactEditor.focus(editor)
    setSearch('')
    setMentionTarget(undefined)
  }

  const mentionClick = (event: KeyboardEvent, user: EsResultFragment) => {
    event.preventDefault()
    insertMention(editor, user)
  }

  const renderElement = useCallback((props: any) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState)
    onChange(editorState)

    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const { selection } = editor

      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection)
        const wordBefore = Editor.before(editor, start, { unit: 'word' })
        const before = wordBefore && Editor.before(editor, wordBefore)
        const beforeRange = before && Editor.range(editor, before, start)
        const beforeText = beforeRange && Editor.string(editor, beforeRange)
        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
        const after = Editor.after(editor, start)
        const afterRange = Editor.range(editor, start, after)
        const afterText = Editor.string(editor, afterRange)
        const afterMatch = afterText.match(/^(\s|$)/)

        if (beforeMatch && afterMatch) {
          setMentionTarget(beforeRange)
          setSearch(beforeMatch[1])
          setIndex(0)
          return
        }
      }

      setMentionTarget(undefined)
    }
  }

  // used for 'onEnter' handling of keyboard event (selecting a suggested mention with arrow keys)
  // The number of refs in the array must match the PAGE_SIZE const
  const mentionRefs = [
    React.useRef<any>(),
    React.useRef<any>(),
    React.useRef<any>(),
    React.useRef<any>(),
    React.useRef<any>(),
    React.useRef<any>(),
    React.useRef<any>(),
    React.useRef<any>(),
    React.useRef<any>(),
    React.useRef<any>(),
  ]

  return (
    <>
      {label != null && (
        <span className="flow-x-xs -mb-4">
          <Label label={label} />
        </span>
      )}
      <div
        className={clsx(
          'w-full rounded-md border border-grey-light bg-white text-sm text-black',
          handleSubmit != null && 'h-48 overflow-y-scroll  ',
          as === 'default' && 'min-h-56 ',
        )}
      >
        <Slate
          editor={editor}
          value={editorState}
          onChange={onEditorStateChange}
        >
          <Toolbar />
          <div className="w-full p-4">
            <Editable
              aria-label="editor"
              placeholder={placeholder}
              renderElement={renderElement}
              renderPlaceholder={() => (
                <span
                  data-slate-placeholder="true"
                  contentEditable="false"
                  className="pointer-events-none absolute block w-full max-w-full select-none pr-4 italic text-grey opacity-70"
                >
                  {placeholder}
                </span>
              )}
              renderLeaf={renderLeaf}
              onKeyDown={(event) => {
                for (const hotkey in MARK_HOTKEYS) {
                  if (isHotkey(hotkey, event as any)) {
                    event.preventDefault()
                    const mark =
                      MARK_HOTKEYS[hotkey as keyof typeof MARK_HOTKEYS]
                    toggleMark(editor, mark)
                  }
                }
                for (const hotkey in BLOCK_HOTKEYS) {
                  if (isHotkey(hotkey, event as any)) {
                    event.preventDefault()
                    const mark =
                      BLOCK_HOTKEYS[hotkey as keyof typeof BLOCK_HOTKEYS]
                    toggleBlock(editor, mark)
                  }
                }
                if (mentionTarget) {
                  switch (event.key) {
                    case 'ArrowDown': {
                      event.preventDefault()
                      const prevIndex = index >= PAGE_SIZE - 1 ? 0 : index + 1
                      setIndex(prevIndex)
                      break
                    }
                    case 'ArrowUp': {
                      event.preventDefault()
                      const nextIndex = index <= 0 ? PAGE_SIZE - 1 : index - 1
                      setIndex(nextIndex)
                      break
                    }
                    case 'Tab':
                    case 'Enter':
                      event.preventDefault()
                      mentionRefs[index].current.click()
                      break
                  }
                }
                if (
                  event.code === 'Enter' &&
                  !event.shiftKey &&
                  !mentionsEnabled
                ) {
                  event.preventDefault()
                  submitTheThing()
                }
              }}
            />
            <div
              className="max-w-64 flex-col rounded-md border-black"
              data-cy="mentions-portal"
            >
              {search && mentionsEnabled && (
                <MentionSearchRenderer
                  term={search}
                  mentionIndex={index}
                  mentionClick={mentionClick}
                  mentionRefs={mentionRefs}
                  pageSize={PAGE_SIZE}
                />
              )}
            </div>
          </div>
        </Slate>
      </div>
      <div className="flex justify-between">
        {error != null && (
          <div className="flex flex-col p-2">
            {error && (
              <div className="flex items-center">
                <AlertText className="text-sm text-red" icon={MdError}>
                  {error}
                </AlertText>
              </div>
            )}
          </div>
        )}
        {handleSubmit != null && (
          <ClickyLink
            as="text-icon"
            className="motion-east link-orange m-2 ml-auto flex"
            icon={MdSend}
            onClick={submitTheThing}
            type="submit"
          >
            Send{' '}
          </ClickyLink>
        )}
      </div>
    </>
  )
}

export default EditorContainer
