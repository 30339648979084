import clsx from '@vangst/lib/clsx'
import leadingZero from '@vangst/lib/leadingZero'
import {
  CompanyComputed,
  CompanyOptions,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'
import CompanyActionsMenu from '../navigation/CompanyActionsMenu'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly company?: CompanyComputed
  readonly title: string
  readonly uri: string
  readonly variables: CompanyVars
  readonly options?: CompanyOptions
}
function CompanyFaceCard(props: PropsType) {
  const { className, company, title, uri, variables, options, ...rest } = props

  return (
    <div
      className={clsx(
        'boxshadow mood-white flex flex-col rounded-md border',
        className,
      )}
      {...rest}
    >
      {/* ... */}
      <div className="relative">
        <div className="h-12 p-2">
          <h2
            className="!flow-x-xs line-clamp-1 items-center text-sm"
            title={`@${variables.displayname}`}
          >
            {company?.visibleOnPlatform === false && (
              <span
                title="Unpublished Company"
                className="mood-green flex size-4 items-center justify-center rounded-full"
              >
                <MdOutlineVisibilityOff className="size-3" />
              </span>
            )}
            {company?.visibleOnPlatform === true && (
              <span
                title="Published Company"
                className="mood-green-tint flex size-4 items-center justify-center rounded-full"
              >
                <MdOutlineVisibility className="size-3" />
              </span>
            )}
            <ClickyLink href={uri} className="hitarea">
              {title}
            </ClickyLink>
          </h2>
          <span className="line-clamp-1 flex text-[12px] text-grey">
            {company?.citystate ?? ''}
          </span>
        </div>
        <CompanyActionsMenu
          className="!absolute right-0 top-0 z-20"
          variables={variables}
          options={options}
        />
        <div className="relative h-48 w-full border-y border-y-grey-light">
          <Avatar
            as="face-card"
            alt={company?.initials ?? '...'}
            className="mood-grey shrink-0"
            src={company?.avatarUrl || undefined}
          />
        </div>
      </div>
      <div className="mood-white flex w-full flex-col divide-y divide-grey-light px-2 text-sm">
        <span>New Postings: {leadingZero(company?.countRecentPositions)}</span>
        <span>
          Total Postings: {leadingZero(company?.countAvailablePositions)}{' '}
        </span>
      </div>
    </div>
  )
}

export default CompanyFaceCard
