import clsx from '@vangst/lib/clsx'
import { JobPostingComputed } from '@vangst/services/oogst/jobPosting/useJobPosting'
import ClickyLink from '../../../components/navigation/ClickyLink'
import UnpublishedTag from '../../company/UnpublishedTag'
import JobPostingAvatar from './JobPostingAvatar'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly jobPosting?: JobPostingComputed
  readonly title: string
  readonly uri: string
}

function JobPostingSlip(props: PropsType) {
  const { className, jobPosting, title, uri, ...rest } = props
  const loading = jobPosting == null ? '•••' : undefined

  return (
    <div
      className={clsx(
        'boxlink mood-white relative flex h-24 items-center gap-4 rounded-md border p-4',
        className,
      )}
      {...rest}
    >
      <JobPostingAvatar jobPosting={jobPosting} title={title} />
      <div>
        <h2 className="line-clamp-1">{title}</h2>
        <span className="line-clamp-1 flex text-grey">
          {loading ??
            (jobPosting?.isRemote ? 'Remote' : jobPosting?.citystate) ??
            ''}
        </span>
        {jobPosting?.isPublished === false && <UnpublishedTag small />}
      </div>
      <ClickyLink as="icon" className="hitarea link-orange ml-auto" href={uri}>
        View Job Posting
      </ClickyLink>
    </div>
  )
}

export default JobPostingSlip
