import clsx from '@vangst/lib/clsx'
import { forwardRef } from 'react'

type PropsType = React.HTMLAttributes<HTMLUListElement> & {
  as: '•' | '|' | '/' | '>'
  at: 'before' | 'after'
}

/**
 * A `ul` element that adds a pre-defined character to the `::before` or
 * `::after` of each child `li`.
 *
 * - Current supported characters: `'•' | '|' | '/' | '>'`
 * - Spacing is managed by a media query aware flex gap on the `li` element
 * - Pass a single child to the `li` element to manage the spacing
 * - The first or last psuedo character in the list is hidden by default if
 * `flex-col` is not present
 *
 * @TODO
 * - The component supports a forwardRef which Breadcrumbs currently requires.
 * This may be removed in future versions.
 *
 * @example
 * <ListPseudos as="|" at="after" className="flex flex-wrap text-sm">
 *   <li>
 *     <ClickyLiner activate className="hocus:text-blue-dark" href="/">
 *       Home
 *     </Clicky>
 *   </li>
 *   <li>
 *     <ClickyLiner activate className="liner hocus:text-blue-dark" href="/">
 *       Privacy
 *     </ClickyLiner>
 *   </li>
 *   <li>
 *     <ClickyLiner activate className="hocus:text-blue-dark" href="/">
 *       Terms
 *     </ClickyLiner>
 *   </li>
 * </ListPseudos>
 */
const ListPseudos = forwardRef<HTMLUListElement, PropsType>(
  function ListPseudos(props: PropsType, ref) {
    const { as, at, children, className, ...rezt } = props
    const rest = { ...rezt, ref }
    const isRow = !className?.includes('flex-col')
    return (
      <ul
        className={clsx(
          'gap-[0.5em]',
          '[&_>_li]:flex [&_>_li]:items-center [&_>_li]:gap-[0.5em]',
          at === 'before' &&
            clsx(
              as === '•' && '[&_>_li]:before:font-700',
              as === '•' && '[&_>_li]:before:content-["•"]',
              as === '|' && '[&_>_li]:before:content-["|"]',
              as === '/' && '[&_>_li]:before:content-["/"]',
              as === '>' && '[&_>_li]:before:content-[">"]',
              isRow && '[&_>_li:first-of-type]:before:hidden',
            ),
          at === 'after' &&
            clsx(
              as === '•' && '[&_>_li]:after:font-700',
              as === '•' && '[&_>_li]:after:content-["•"]',
              as === '|' && '[&_>_li]:after:content-["|"]',
              as === '/' && '[&_>_li]:after:content-["/"]',
              as === '>' && '[&_>_li]:after:content-[">"]',
              isRow && '[&_>_li:last-of-type]:after:hidden',
            ),
          className,
        )}
        {...rest}
      >
        {children}
      </ul>
    )
  },
)

export default ListPseudos
