import clsx from '@vangst/lib/clsx'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly value?: number
}

/**
 * An indicator showing the completion progress, typically displayed as a bar.
 *
 * - This component is the visual representation only and differs slightly from the
 * native progress element.
 *
 * @see [Native Progress Element](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/progress)
 */
function Progress(props: PropsType) {
  const { className, value = 0, ...rest } = props
  const width = value + '%'
  return (
    <div
      className={clsx('h-1 w-full rounded-sm', className)}
      title={width}
      {...rest}
    >
      <span
        className="block h-full rounded-sm bg-current"
        style={{ width }}
      ></span>
    </div>
  )
}

export default Progress
