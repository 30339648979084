import useCompany, {
  CompanyOptions,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { memo } from 'react'
import { AsTypeUnion } from '../nodes/RendererFactory'
import CompanyAvatar from './views/CompanyAvatar'
import CompanyCard from './views/CompanyCard'
import CompanyDetail from './views/CompanyDetail'
import CompanyFaceCard from './views/CompanyFaceCard'
import CompanyHeader from './views/CompanyHeader'
import CompanyMenuItem from './views/CompanyMenuItem'
import CompanySlip from './views/CompanySlip'
import CompanyTableRow from './views/CompanyTableRow'

type PropsType = React.HTMLAttributes<HTMLElement> & {
  readonly as: AsTypeUnion
  readonly label?: string
  readonly variables: CompanyVars
  readonly options?: CompanyOptions
}

function CompanyRenderer(props: PropsType) {
  const { viewer } = useViewer()
  const isInternal = viewer?.permissions?.internalUser
  const { as, label, variables, options, ...rest } = props
  const displayname = variables.displayname

  const { data, uri } = useCompany({ displayname }, options)
  const company = data?.getClient
  const title = company ? company.name : label ? label : '...'

  if (as === 'avatar') {
    return <CompanyAvatar company={company} title={title} uri={uri} {...rest} />
  }

  if (as === 'detail') {
    return <CompanyDetail company={company} variables={variables} {...rest} />
  }

  if (as === 'header') {
    return (
      <CompanyHeader
        company={company}
        title={title}
        uri={uri}
        variables={variables}
        {...rest}
      />
    )
  }

  if (as === 'slip') {
    return <CompanySlip company={company} title={title} uri={uri} {...rest} />
  }

  if (as === 'menuitem') {
    return (
      <CompanyMenuItem
        company={company}
        title={title}
        uri={uri}
        variables={variables}
        {...rest}
      />
    )
  }

  if (as === 'table-row') {
    return (
      <CompanyTableRow
        company={company}
        options={options}
        uri={uri}
        variables={variables}
        {...rest}
      />
    )
  }

  if (as === 'face-card') {
    return (
      <CompanyFaceCard
        company={company}
        title={title}
        uri={uri}
        variables={variables}
        options={options}
        {...rest}
      />
    )
  }

  // as card
  return (
    <CompanyCard
      company={company}
      title={title}
      uri={uri}
      variables={variables}
      options={options}
      showStats={isInternal}
      {...rest}
    />
  )
}

export default memo(CompanyRenderer)
