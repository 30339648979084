import { Dialog } from '@headlessui/react'
import { memo, useCallback, useRef } from 'react'
import { MdClose } from 'react-icons/md'
import { DialogModal } from '../../../components/feedback/DialogModal'
import Input from '../../../components/forms/Input'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import ClickyLink from '../../../components/navigation/ClickyLink'

type PropsType = {
  handleCancel: () => void
  handleConfirm: (url: string) => void
}
function DialogVideoUrl({ handleCancel, handleConfirm }: PropsType) {
  const urlRef = useRef<HTMLInputElement>(null)
  const submit = useCallback(() => {
    const val = urlRef?.current?.value
    handleConfirm(val || '')
  }, [urlRef, handleConfirm])

  return (
    <DialogModal onClose={handleCancel} open>
      <DialogModal.Panel>
        <Dialog.Title className="sr-only">Add a video url</Dialog.Title>
        <form
          className="relative grid w-full grid-cols-[5fr_1fr] gap-4"
          onReset={handleCancel}
        >
          <Input label="Full Video URL" reference={urlRef} name="copy-url" />
          <ClickyBtn onClick={() => submit()} className="mt-8">
            Save
          </ClickyBtn>
          <ClickyLink
            as="icon"
            icon={MdClose}
            className="link-grey absolute -top-4 right-0 hidden sm:block"
            type="reset"
          >
            Close
          </ClickyLink>
        </form>
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default memo(DialogVideoUrl)
