import clsx from '@vangst/lib/clsx'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { useRef } from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import ClickyLink from '../../../components/navigation/ClickyLink'
import MessagesRenderer, { AsChatTypeUnion } from '../MessagesRenderer'

type PropsType = {
  handleCancel: () => void
  widgetView: AsChatTypeUnion
}

function DialogMessaging(props: PropsType) {
  const { handleCancel, widgetView } = props
  const { viewer } = useViewer()
  const username = viewer?.username ?? ''
  const focusRef = useRef<HTMLButtonElement>(null)

  return (
    <DialogModal onClose={handleCancel} initialFocus={focusRef} open>
      <DialogModal.Panel>
        <DialogModal.Description className="w-full">
          <ClickyBtn
            as="icon"
            className="absolute right-4 top-4"
            onClick={handleCancel}
            ref={focusRef}
          >
            <MdOutlineCancel />
          </ClickyBtn>
          <span className="mood-orange block p-2">
            {widgetView === 'list' ? 'All Messages' : 'Conversation'}
          </span>
          <MessagesRenderer
            as={widgetView}
            isWidget
            className={clsx(
              'border border-grey-mid',
              widgetView === 'list' ? 'h-132' : 'h-56',
            )}
          />
        </DialogModal.Description>
        <div className="hidden w-full items-center gap-4 sm:flex">
          <ClickyBtn
            className="mood-orange w-32"
            onClick={handleCancel}
            ref={focusRef}
          >
            Close
          </ClickyBtn>
          <span className="text-sm">
            Want to start a new conversation?
            <ClickyLink
              className="link-orange ml-2 underline"
              as="text"
              onClick={handleCancel}
              href={Routes.MEMBERS_CONNECTIONS.replace(':username', username)}
            >
              View your connections.
            </ClickyLink>
          </span>
        </div>
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default DialogMessaging
