import useDeleteJobPosting from '@vangst/services/oogst/jobPosting/useDeleteJobPosting'
import Routes from '@vangst/services/routes'
import { useRouter } from 'next/router'
import { memo, useCallback } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyBtn from '../../../components/navigation/ClickyBtn'

type PropsType = {
  readonly id: string
  readonly handleCancel: () => void
}

function DialogDeleteJobPosting({ id, handleCancel }: PropsType) {
  const { replace } = useRouter()

  const deleteJobPosting = useDeleteJobPosting({
    onSuccess: () => replace(Routes.DASHBOARD),
  })

  const submit = useCallback(async () => {
    deleteJobPosting.mutate({ input: { id } })
  }, [id, deleteJobPosting])

  return (
    <DialogModal onClose={handleCancel} open>
      <DialogModal.Panel icon={MdErrorOutline} title="Delete Job Posting?">
        <DialogModal.Description className="my-8">
          Deleting your job posting will also remove access to any candidate
          applications you received for this role — this cannot be undone.
        </DialogModal.Description>
        <form
          className="flex w-full items-center justify-center gap-4"
          onReset={handleCancel}
          onSubmit={submit}
        >
          <ClickyBtn
            className="mood-white w-full"
            disabled={deleteJobPosting.isLoading}
            type="reset"
          >
            Cancel
          </ClickyBtn>
          <ClickyBtn
            className="mood-red w-full"
            disabled={deleteJobPosting.isLoading}
            type="submit"
          >
            {deleteJobPosting.isLoading ? 'Deleting...' : 'Delete'}
          </ClickyBtn>
        </form>
      </DialogModal.Panel>
    </DialogModal>
  )
}

export default memo(DialogDeleteJobPosting)
