const statusCodes = {
  401: {
    message: 'Unauthorized',
  },
  403: {
    message: 'Forbidden',
  },
  404: {
    message: 'Not Found',
  },
  500: {
    message: 'Internal Server Error',
  },
  503: {
    message: 'Service Unavailable',
  },
  666: {
    message: 'Unknown Client Error',
  },
}

export const HTTP_ERROR_NAME = 'HttpError'

export type HttpErrorCodes = keyof typeof statusCodes

export type IHttpError = {
  readonly code: number
} & Error

export default class HttpError extends Error implements IHttpError {
  public override name = HTTP_ERROR_NAME
  constructor(public code: HttpErrorCodes) {
    super(statusCodes[code]?.message || statusCodes[666].message)
    this.code = code
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
