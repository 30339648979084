import { CompanyComputed } from '@vangst/services/oogst/company/useCompany'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly company?: CompanyComputed
  readonly title: string
  readonly uri: string
}

function CompanySlip(props: PropsType) {
  const { company, title, uri, ...rest } = props
  const loading = company == null ? '•••' : undefined

  return (
    <div
      className="boxlink mood-white relative flex h-24 items-center gap-4 rounded-md border p-4"
      {...rest}
    >
      <div>
        <Avatar
          alt={company?.initials ?? '...'}
          className="mood-grey"
          src={company?.avatarUrl || undefined}
        />
      </div>
      <div>
        <h2 className="line-clamp-1">{title}</h2>
        <span className={'line-clamp-1 flex text-grey'}>
          {loading ?? company?.citystate ?? ''}
        </span>
      </div>
      <ClickyLink
        as="icon"
        className="hitarea link-orange ml-auto"
        href={uri}
        title={title}
      >
        View Company Profile
      </ClickyLink>
    </div>
  )
}

export default CompanySlip
