import { useQueryClient } from '@tanstack/react-query'
import { useCreateChatMessageMutation } from '@vangst/services/oogst/types'
import { HTMLAttributes, useCallback, useState } from 'react'
import Editor from '../editor/Editor'
import { isEmptyOrNilEditor } from '../editor/serializers'

type PropsType = HTMLAttributes<HTMLDivElement> & {
  readonly recipientId: string
  readonly isWidget: boolean
}

function CreateMessage(props: PropsType) {
  const { recipientId, isWidget } = props
  const [editorValue, setEditorValue] = useState<any>(null)
  const [formError, setFormError] = useState<string | undefined>()
  const queryClient = useQueryClient()

  const handleChange = (json: Node[]) => {
    setEditorValue(json)
  }

  const { mutate: mutateCreateMessage, error: serverError } =
    useCreateChatMessageMutation({
      onSettled: () => {
        queryClient.invalidateQueries(['AllChatMessages'])
      },
    })

  const submit = useCallback(() => {
    setFormError(undefined)

    if (isEmptyOrNilEditor(editorValue)) {
      setFormError('This field cannot be blank')
      return
    }

    mutateCreateMessage({
      input: {
        recipient: recipientId,
        slateMessage: editorValue,
      },
    })
    setEditorValue(null)
  }, [editorValue, recipientId, mutateCreateMessage])

  return (
    <div className={isWidget ? '' : 'border-t border-t-grey-mid px-4 pt-4'}>
      <Editor
        as="messaging"
        error={serverError?.message || formError}
        handleSubmit={submit}
        onChange={handleChange}
        placeholder="Start your message..."
      />
    </div>
  )
}

export default CreateMessage
