import { formatReadableDateWords } from '@vangst/lib/formatDates'
import { MemberComputed } from '@vangst/services/oogst/member/useMember'
import useUpdateMemberUser from '@vangst/services/oogst/member/useUpdateMemberUser'
import { clsx } from 'clsx'
import { useState } from 'react'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import Disclosure from '../../../components/navigation/Disclosure'
import ChooseByRoleCombo from '../../recruiter/forms/ChooseByRoleCombo'
import SelectJobApplicationStatus from '../../recruiter/SelectJobApplicationStatus'
import SelectPlacementStatus from '../../recruiter/SelectPlacementStatus'
import AddToJob from '../navigation/AddToJob'
import ScreenApplicant from '../navigation/ScreenApplicant'
import ViewScreening from '../navigation/ViewScreening'

type PropsType = {
  readonly jobPostingId?: string
  readonly member: MemberComputed
}

function StatusTools(props: PropsType) {
  const { jobPostingId, member } = props

  const application = member?.candidate?.jobApplications?.find(
    (a) => a.jobPosting.slug === jobPostingId,
  )

  const { mutate } = useUpdateMemberUser()

  const [value, setValue] = useState(
    member?.candidate?.recruiters?.map((r) => r.id) || [],
  )

  const applicationCount = member?.candidate?.jobApplications?.length
  const applications =
    member?.candidate?.jobApplications &&
    applicationCount != null &&
    applicationCount > 0

  const placement = member?.candidate?.placements?.find(
    (p) => p.jobPosting.slug === jobPostingId,
  )

  const internalUser = member?.permissions.internalUser
  const changeStatus = application?.jobPosting.permissions.readJobApps
  const viewTools = internalUser || changeStatus

  const cn = clsx(
    'btn w-full justify-start bg-white text-grey hocus:border-orange',
  )

  // @ts-expect-error: `name` is not needed in this context
  const handleValueChange = (name: string, val: string[]) => {
    setValue(val)
    mutate({
      input: {
        id: member?.id ?? '',
        candidate: { recruiterIds: val },
      },
    })
  }

  if (!viewTools) return null

  return (
    <div className="flow-y-sm mood-grey-light mb-8 px-8 pb-8 pt-4">
      <h2 className="heading-xs">Candidate Tools</h2>
      <ul className="flow-y-sm">
        {internalUser && applications && (
          <ClickyBtn className={cn} href={member?.routes?.jobApplications}>
            View Applications ({applicationCount})
          </ClickyBtn>
        )}
        {internalUser && !application && member.candidate && (
          <li>
            <AddToJob
              className={cn + ' !h-12'}
              candidateId={member.candidate.id}
              slug={jobPostingId}
            />
          </li>
        )}
        {changeStatus && application && (
          <li>
            <SelectJobApplicationStatus
              applicationId={application.id}
              defaultValue={application.status}
              label="Application"
              name="application-status"
            />
          </li>
        )}
        {internalUser && placement?.status && (
          <li>
            <SelectPlacementStatus
              defaultValue={placement.status}
              label="Placement"
              name="placement-status"
              placementId={placement.id}
            />
          </li>
        )}
        {internalUser && (
          <li className="space-y-2">
            <span className="text-md font-600">Assigned Recruiters</span>
            <Disclosure as="sidebar" summary="Assign to Candidate">
              <div className="mood-grey-lightest mt-4 flex w-full max-w-76 flex-col gap-8 p-4">
                <ChooseByRoleCombo
                  value={value}
                  vars={{ isRecruiter: true }}
                  name="recruiterIds"
                  label="hidden"
                  hideLabel
                  handleValueChange={handleValueChange}
                />
              </div>
            </Disclosure>
          </li>
        )}
        {internalUser &&
          !!application?.jobPosting.jobPostingScreening &&
          !application?.screeningSubmissions?.length && (
            <li>
              <ScreenApplicant className={cn} applicationId={application.id} />
            </li>
          )}
        {internalUser && !!application?.screeningSubmissions?.length && (
          <li>
            <ViewScreening className={cn} applicationId={application.id} />
          </li>
        )}
        {internalUser && placement?.interviewDate && (
          <li>
            <span className="pt-2">
              Interview:{' '}
              {formatReadableDateWords(
                new Date(placement.interviewDate),
                'MM/dd/yy',
              )}
            </span>
          </li>
        )}
      </ul>
    </div>
  )
}

export default StatusTools
