import { useQueryClient } from '@tanstack/react-query'
import { SignUpContextEnum, UserRoleEnum } from '@vangst/services/oogst/types'
import useSignUpUser from '@vangst/services/oogst/viewer/useSignUpUser'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { MdErrorOutline } from 'react-icons/md'
import AlertText from '../../components/feedback/AlertText'
import Input from '../../components/forms/Input'
import ClickyBtn from '../../components/navigation/ClickyBtn'
import QueryErrorFallback from '../application/feedback/QueryErrorFallback'
import validations from '../application/validations'
import DialogDuplicateName from '../company/feedback/DialogDuplicateName'
import { GoSignIn, GoTermsAgreement } from './navigation/GoDoThings'

export const defValues = {
  companyName: '',
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  passwordConfirmation: '',
  phoneNumber: '',
  website: '',
}

type FormInput = typeof defValues

type PropsType = {
  readonly as?: 'page' | 'dialog'
  readonly defaultValues?: FormInput
  readonly from: SignUpContextEnum
  readonly handleClose?: () => void
  readonly hideLabels?: boolean
}

function SignUpFormCompany(props: PropsType) {
  const [showDuplicateCompanyNameError, setShowDuplicateCompanyNameError] =
    React.useState(false)
  const queryClient = useQueryClient()
  const { handleClose, hideLabels, from } = props
  const { as, defaultValues = defValues } = props

  const [pwMismatchError, setPwMismatchError] = React.useState(false)
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({ defaultValues })

  const handleCancel = useCallback(
    () => setShowDuplicateCompanyNameError(false),
    [],
  )

  const { mutate, isLoading, error: serverError } = useSignUpUser(queryClient)

  useEffect(() => {
    if (serverError?.message?.toLowerCase()?.includes('displayname')) {
      setShowDuplicateCompanyNameError(true)
    }
  }, [reset, serverError?.message])

  const submit = React.useCallback(
    (fd: FormInput) => {
      const fields: FormInput = {
        companyName: fd.companyName,
        email: fd.email,
        firstName: fd.firstName,
        lastName: fd.lastName,
        password: fd.password,
        passwordConfirmation: fd.passwordConfirmation,
        phoneNumber: fd.phoneNumber,
        website: fd.website,
      }
      const input = {
        ...fields,
        from: from,
        termsAccepted: true,
        userType: UserRoleEnum.Client,
      }
      if (fd.password !== fd.passwordConfirmation) {
        setPwMismatchError(true)
        return
      }
      mutate(
        { input },
        { onSuccess: handleClose != null ? handleClose : undefined },
      )
    },
    [from, handleClose, mutate],
  )

  const send = React.useMemo(() => handleSubmit(submit), [handleSubmit, submit])

  const trackId = 'submit-sign-up-company'

  return (
    <form className="grid gap-8" id="sign-up-form-company" onSubmit={send}>
      <div>
        {!hideLabels && (
          <h2 className="mb-8 border-b pb-4 heading-sm">Company Info</h2>
        )}
        <Input
          error={errors?.companyName?.message}
          label="Company Name"
          required
          {...register('companyName', validations.maxLength255Required)}
        />

        <Input
          error={errors?.phoneNumber?.message}
          label="Contact Number"
          required
          type="tel"
          {...register('phoneNumber', validations.generic)}
        />
        <Input
          error={errors?.website?.message}
          label="Company Website"
          required
          {...register('website', {
            ...validations.generic,
            ...validations.url,
          })}
        />
      </div>
      <div>
        {!hideLabels && (
          <h2 className="mb-8 border-b pb-4 heading-sm">Contact Info</h2>
        )}
        <fieldset className="flex flex-col space-y-4">
          <Input
            error={errors?.firstName?.message}
            label="First Name"
            required
            {...register('firstName', validations.firstName)}
          />
          <Input
            error={errors?.lastName?.message}
            label="Last Name"
            required
            {...register('lastName', validations.lastName)}
          />
          <Input
            error={errors?.email?.message}
            label="email"
            readOnly={from === SignUpContextEnum.ClaimInvitationLink}
            {...register('email', validations.email)}
            required
          />
          <Input
            error={errors?.password?.message}
            label="password"
            type="password"
            required
            {...register('password', validations.password)}
          />
          <Input
            error={errors?.password?.message}
            label="password confirmation"
            type="password"
            required
            {...register('passwordConfirmation', validations.password)}
          />
        </fieldset>
      </div>
      <div className="flow-y-xs">
        {pwMismatchError && (
          <AlertText className="text-sm italic text-red" icon={MdErrorOutline}>
            Passwords do not match.
          </AlertText>
        )}
        {as === 'page' && <GoSignIn />}
        <GoTermsAgreement />
        <div className="flex w-full items-center justify-end gap-4">
          <ClickyBtn
            id={trackId}
            className="border border-orange-medium"
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? 'Sending' : 'Sign Up'}
          </ClickyBtn>
          {serverError != null && <QueryErrorFallback error={serverError} />}
        </div>
      </div>
      {showDuplicateCompanyNameError && (
        <DialogDuplicateName handleCancel={handleCancel} />
      )}
    </form>
  )
}

export default React.memo(SignUpFormCompany)
