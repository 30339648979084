import { PayPeriodEnum } from '@vangst/services/oogst/types'
import { memo } from 'react'
import Select, { SelectType } from '../../../components/forms/Select'

type PeriodType = {
  readonly label: string
  readonly value: PayPeriodEnum
}
const options = [
  {
    label: 'Select Pay Period',
    value: '',
  },
  {
    label: 'Hourly',
    value: PayPeriodEnum.Hour,
  },
  {
    label: 'Weekly',
    value: PayPeriodEnum.Week,
  },
  {
    label: 'Monthly',
    value: PayPeriodEnum.Month,
  },
  {
    label: 'Annually',
    value: PayPeriodEnum.Year,
  },
] as PeriodType[]

function SelectJobSalaryPeriod(
  props: SelectType & { readonly isJobOrderForm?: boolean },
) {
  const { error, hint, label, name, reference, isJobOrderForm, ...rest } = props
  const filteredOptions = isJobOrderForm
    ? options.filter((option) => option.value !== PayPeriodEnum.Hour)
    : options

  const id = props?.id ?? name
  return (
    <Select
      id={id}
      error={error}
      disabled={props.disabled}
      hint={hint}
      label={label}
      name={name}
      options={filteredOptions}
      reference={reference}
      {...rest}
    />
  )
}

export default memo(SelectJobSalaryPeriod)
