import { Combobox } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import stripHost from '@vangst/lib/stripHost'
import { TrainingFragment } from '@vangst/services/oogst/types'
import Routes from '@vangst/services/routes'
import { memo } from 'react'
import {
  MdAddTask,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdDynamicFeed,
  MdOutlineVerified,
  MdQuestionAnswer,
  MdSchedule,
} from 'react-icons/md'
import ClickyLink from '../../components/navigation/ClickyLink'
import Avatar from '../../components/views/Avatar'
import { AsTypeUnion } from '../nodes/RendererFactory'

type PropsType = React.HTMLAttributes<HTMLElement> & {
  readonly as: AsTypeUnion
  readonly label?: string
  readonly training: TrainingFragment
}

function TrainingRenderer(props: PropsType) {
  const { as, label, training, className, ...rest } = props
  const displayname = training?.creatorClient?.displayname

  const trainingMembersUri = Routes?.COMPANIES_TRAINING_MEMBERS?.replace(
    ':displayname',
    displayname,
  ).replace(':tid', training?.id)

  const title = label ? label : training?.title
  // This isn't be used yet
  if (as === 'avatar') {
    return (
      <ClickyLink
        className={clsx(
          'flex size-12 items-center justify-center ease-bounce hocus:scale-105',
          className,
        )}
        href={stripHost(training?.trainingUrl)}
        title={title || undefined}
      >
        <MdQuestionAnswer />
      </ClickyLink>
    )
  }

  if (as === 'slip') {
    return (
      <div
        className={clsx(
          'boxlink mood-white relative flex h-24 items-center gap-4 border p-4',
          className,
        )}
        {...rest}
      >
        <Avatar
          alt={displayname?.charAt(0) || '...'}
          className="mood-grey min-w-12"
          src={training?.creatorClient?.avatarUrl || undefined}
          role="presentation"
        />
        <div>
          <h2 className="line-clamp-2">{training?.title}</h2>
        </div>
        <ClickyLink
          as="icon"
          className="hitarea link-orange ml-auto"
          href={stripHost(training?.trainingUrl)}
        >
          Start Course
        </ClickyLink>
      </div>
    )
  }

  if (as === 'menuitem') {
    return (
      <Combobox.Option
        as="div"
        key={training?.id ?? label}
        value={training?.id ?? label}
        className={clsx(
          'group/comboboxoption flow-x-xs mood-white cursor-pointer items-center p-2',
          'aria-selected:mood-green hocus:mood-green aria-selected:font-600',
          className,
        )}
        title={label}
        {...rest}
      >
        <span className="text-orange">
          <MdCheckBox
            aria-hidden="true"
            className="hidden group-aria-selected/comboboxoption:inline"
          />
          <MdCheckBoxOutlineBlank
            aria-hidden="true"
            className="group-aria-selected/comboboxoption:hidden"
          />
        </span>
        <Avatar
          alt={displayname?.charAt(0) || '...'}
          className="size-8"
          src={training?.creatorClient?.avatarUrl || undefined}
        />
        <span>{title}</span>
      </Combobox.Option>
    )
  }

  // as card
  return (
    <div
      className={clsx(
        'boxlink mood-white relative flex h-80 w-full flex-col justify-between border p-4',
        props.className,
      )}
    >
      <div className="relative grid grid-cols-[3rem_1fr] items-center gap-4">
        <Avatar
          alt={displayname?.charAt(0) || '...'}
          className="mood-grey"
          width={36}
          height={36}
          src={training?.creatorClient?.avatarUrl || undefined}
        />
        <span className="line-clamp-1 text-md font-700 capitalize">
          {displayname}
        </span>
      </div>
      <div className="mt-4 grid h-full content-start gap-4">
        <h2 className="text-lg font-600">{training?.title}</h2>
        <div className="flex items-center divide-x divide-blue-dark text-blue-dark">
          {training?.currentUserPassed ? (
            <span className="flex items-center justify-center gap-x-2 text-center">
              <span className="block sm:inline-block">
                <MdOutlineVerified />
              </span>
              <span>You passed!</span>
            </span>
          ) : training?.quizzes?.length > 1 ? (
            <span className="flex items-center gap-x-2 text-sm text-blue-dark">
              <MdDynamicFeed />
              <span>{training?.quizzes?.length} Part Course</span>
            </span>
          ) : (
            <div className="flex justify-start divide-x">
              <span
                style={{ fontSize: '12px' }}
                className="mr-2 flex items-center gap-x-2 text-center text-blue-dark"
              >
                <MdSchedule />
                <span>
                  <span>{training?.quizzes?.[0]?.timeToComplete} </span>
                  <span> Minutes</span>
                </span>
              </span>

              <span
                style={{ fontSize: '12px' }}
                className="flex items-center gap-x-2 pl-2 text-center text-blue-dark"
              >
                <MdAddTask />
                <span>
                  <span>{training?.quizzes?.[0]?.totalQuestions} </span>
                  <span> Questions</span>
                </span>
              </span>
            </div>
          )}
        </div>
        <span className="line-clamp-3 text-ellipsis text-sm text-grey-dark">
          {training?.description}
        </span>
        {training?.permissions?.viewTrainingMembers && (
          <ClickyLink
            className="hitarea link-blue-dark relative z-10 text-sm underline"
            href={trainingMembersUri}
          >
            <span>
              Course taken
              <span> {training?.countCandidatesCompletedTraining} </span> time
              {training?.countCandidatesCompletedTraining === 1 ? '' : 's'}
            </span>
          </ClickyLink>
        )}
      </div>
      <ClickyLink
        as="text-icon"
        className="hitarea link-orange justify-self-end font-600"
        href={stripHost(training?.trainingUrl)}
      >
        Start Course
      </ClickyLink>
    </div>
  )
}

export default memo(TrainingRenderer)
