type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly subtext?: string
  readonly title: string
}

function JobDetailBlock(props: PropsType) {
  const { children, subtext, title, ...rest } = props
  if (children == null) return null
  return (
    <div {...rest}>
      <h2 className="flex flex-col heading-sm sm:flex-row sm:items-baseline sm:gap-2">
        {title}
        <span className="text-sm font-400 italic text-grey">{subtext}</span>
      </h2>
      <div className="text-sm">{children}</div>
    </div>
  )
}

export default JobDetailBlock
